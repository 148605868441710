<template>
  <div class="flex items-center justify-center w-full h-full md:p-0 p-4">
    <div
      :class="`${
        isLoading ? 'pointer-events-none bg-opacity-30' : ''
      } md:w-[30vw] w-full bg-white rounded-2xl p-7`"
    >
      <div class="flex flex-col space-y-4" v-if="!isSuccess">
        <span class="font-bold text-[22px] text-center"
          >Reset your password</span
        >
        <div class="flex flex-col space-y-4">
          <span class="text-lightTextColor text-[13px]">
            Use the form below to create a new password for the following email:
          </span>
          <span class="font-bold text-btnPrimary text-[15px]">{{ email }}</span>
        </div>
        <div class="flex flex-col space-y-1 pt-2">
          <span class="font-semibold text-[14px] text-lightTextColor"
            >Create password</span
          >
          <span class="text-[13px]  text-lightTextColor"
            >To confirm with our Strong Password policy, you are required to use
            a sufficiently strong password. Password must be more than 7
            characters.</span
          >
          <div class="flex flex-col space-y-1">
            <div class="flex flex-col space-y-3">
              <PasswordStrengthComponent
                :password="newPassword"
                @password-strength="passwordStrengthHandler"
              />
              <TextInput
                :value="newPassword"
                name="newPassword"
                :handleChange="inputChange"
                placeholder="Choose a Strong Password"
                :className="` h-[6vh] ${
                  errorMessage ? 'border-2 border-danger rounded-md' : ''
                }`"
              />
              <div class="flex flex-col space-y-1">
                <TextInput
                  :value="newPasswordConfirm"
                  name="newPasswordConfirm"
                  placeholder="Confirm Password"
                  :handleChange="inputChange"
                  @keypressEnter="resetPasswordHandler"
                  :className="` h-[6vh] ${
                    errorMessage ? 'border-2 border-danger rounded-md' : ''
                  }`"
                />
                <span v-if="errorMessage" class="text-danger text-[13px]">{{
                  errorMessage
                }}</span>
              </div>
              <CustomButton
                title="Confirm Reset Password"
                :isLoading="isLoading"
                loaderText="Resetting your password"
                buttonType="primary"
                customStyle="h-[6vh]"
                @onClick="resetPasswordHandler"
              />
              <div class="w-full flex items-center justify-center">
                <span
                  @click="backToLogin"
                  class="text-[13px] text-btnPrimary hover:underline cursor-pointer"
                  >Back to Login</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col space-y-4">
          <div class="flex items-center justify-center space-x-3">
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                  fill="#3EAF3F"
                />
              </svg>
            </div>
            <div>
              <span class="text-[23px] font-bold text-green">Success!</span>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <span class="text-center text-lightTextColor text-[14px]"
              >Your password has been reset successfully.</span
            >
          </div>
          <div class="flex items-center justify-center">
            <img src="../../../assets/svgs/resetSuccess.svg" />
          </div>
          <div class="pt-2">
            <CustomButton
              title="Back to Login"
              buttonType="primary"
              customStyle="h-[5vh]"
              @onClick="backToLogin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserDetailsFromStore } from "../../../store";
import PasswordStrengthComponent from "../newSignup/PasswordStrengthComponent.vue";
import TextInput from "../../../components/SharedComponents/TextInput.vue";
import CustomButton from "../../../components/SharedComponents/CustomButton.vue";
import userApi from "../../../services/userApi";
export default {
  name: "ResetPassword",
  components: {
    PasswordStrengthComponent,
    TextInput,
    CustomButton,
  },
  data() {
    return {
      newPassword: "",
      newPasswordConfirm: "",
      token: "",
      email: "",
      isLoading: false,
      isSuccess: false,
      errorMessage: "",
      passwordStrength: "",
    };
  },
  methods: {
    backToLogin() {
      this.$router.push("/login");
    },
    inputChange(e) {
      this[e.target.name] = e.target.value;
    },
    getTokenfromURL() {
      // Get the URL parameters
      const urlParams = new URLSearchParams(window.location.search);

      // Get the value of the 'token' parameter
      const token = urlParams.get("token");

      // Check if the token is present
      if (token) {
        console.log("Token:", token);
        this.token = token;
        // Now you can use the 'token' variable in your code
      } else {
        console.log("Token not found in the URL");
      }
    },
    async resetPasswordHandler() {
      if (this.newPassword && this.newPasswordConfirm && this.token) {
        if (this.newPassword === this.newPasswordConfirm) {
          if (
            this.passwordStrength === "Medium" ||
            this.passwordStrength === "Strong"
          ) {
            this.isLoading = true;
            try {
              let payload = {
                token: this.token,
                password: this.newPassword,
                confirmPassword: this.newPasswordConfirm,
              };
              const response = await userApi.resetPassword(payload);
              if (response?.data?.status) {
                this.isLoading = false;
                this.isSuccess = true;
                console.log("response", response.data);
              } else {
                console.log("response err", response.data);
                this.isLoading = false;
                this.errorMessage = response?.data?.message;
              }
            } catch (err) {
              console.log(err);
              this.isLoading = false;
            }
          } else {
            this.errorMessage =
              "Password should be atleast 8 characters long, 1 uppercase letter and/or 1 special character";
          }
        } else {
          this.errorMessage = "Passwords does not match";
        }
      } else {
        this.errorMessage = "Please fill all the fields";
      }
    },
    passwordStrengthHandler(strength) {
      this.passwordStrength = strength;
    },
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    userEmail() {
      let user = this.userDetails;
      console.log("user", user);
      return user.email;
    },
  },
  mounted() {
    this.getTokenfromURL();
    this.email = localStorage.getItem("email");
  },
};
</script>
