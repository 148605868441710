<template>
  <div class="">
    <div
      :class="` bg-white dark:bg-compBgDark/20 dark:border-black/5 items-start justify-center   px-3 py-2 ${customClass}`">
      <slot class=""></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBox",
  props: {
    customClass: {
      type: String,
    },
  },
};
</script>
