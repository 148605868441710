export const API_LIST = {
  // userApi: "http://192.168.100.9:7002/",
  // userApi:"http://192.168.100.9:7002/",
  userApi: "https://api.condorcash.com/", //staging
  // userApi: "https://apiv1.condorcash.com/", //staging
  // userApi: "http://127.0.0.1:8002/",
  // userApi:"http://localhost/phpmyadmin/"//testing
  // userApi:"https://cb59-103-131-8-31.ngrok-free.app/" //adil
  // userApi:"http://127.0.0.1:7002/"//staging
};
