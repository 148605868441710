<template>
  <CardBox customClass="border border-[#E7E7E7] rounded-3xl w-full !p-5 h-full">
    <div class="w-full h-full flex justify-between items-center">
      <div class="flex flex-col space-y-1">
        <h1 class="text-black dark:text-white font-bold text-[17px]">
          Personal Information
        </h1>
        <span class="text-lightTextColor dark:text-darkTextColor text-[11px]">
          Your account settings
        </span>
      </div>
      <div class="flex items-center space-x-3">
        <CustomButton
          title="Successfully saved"
          buttonType="secondary"
          :disabledVal="true"
          customStyle="px-5"
        />
      </div>
    </div>
    <div class="space-y-4 pt-6">
      <div class="flex flex-col space-y-1">
        <span
          class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
          >First name</span
        >
        <span class="font-semibold dark:text-white">{{ firstName }}</span>
      </div>
      <div class="flex flex-col space-y-1">
        <span
          class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
          >Last name</span
        >
        <span class="font-semibold dark:text-white">{{ lastName }}</span>
      </div>
      <PhoneNumberWithVerification :phone-number="phoneNumber" />
    </div>
  </CardBox>
</template>
<script>
import CardBox from "../../components/SharedComponents/CardBox.vue";
import CustomButton from "../../components/SharedComponents/CustomButton.vue";
import PhoneNumberWithVerification from "@/components/SettingsComp/PhoneNumberWithVerification.vue";

export default {
  name: "ProfileSummaryPage",
  components: { PhoneNumberWithVerification, CardBox, CustomButton },
  props: {
    firstName: {},
    lastName: {},
    phoneNumber: {},
  },
};
</script>
