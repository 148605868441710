<template>
  <BaseModal customClass="dark:bg-[#37383f]">
    <div
      class="xl:w-[38vw] lg:w-[50vw] md:w-[50vw] w-full h-[84vh] overflow-y-auto scrollbar sm:py-4 py-8"
    >
      <div class="flex justify-between items-center sm:px-10 px-2">
        <div class="flex space-x-6 items-center">
          <p class="font-[700] sm:text-[20px] dark:text-[#D1D1D1] text-[17px]">
            {{ title }}
          </p>
        </div>
        <div
          :class="`${
            isLoading ? 'pointer-events-none opacity-50' : ''
          } cursor-pointer hover:bg-gray-200 dark:hover:bg-lightTextColor/60 h-fit rounded-full p-1`"
          @click="onCloseModal"
        >
          <CrossIconSvg />
        </div>
      </div>
      <div class="sm:px-10 px-2 space-y-5">
        <div class="w-full pt-2">
          <p class="text-[#5D5D5D] dark:text-[#D1D1D1] text-[14px] detailText">
            For any inquiries, feedback, or assistance, please use the form
            below. We're committed to providing you with timely and helpful
            support.
          </p>
        </div>
        <div class="w-full space-y-5">
          <div class="space-y-1 relative">
            <label
              class="text-[#5D5D5D] dark:text-[#D1D1D1] text-[13px] font-semibold"
              >Select a category of you message</label
            >
            <div
              :class="isLoading ? 'pointer-events-none opacity-50' : ''"
              @click="openDropdown = !openDropdown"
              class="rounded-md w-full hover:bg-[#e2e2e2] dark:hover:bg-[#40444c] dark:bg-[#4F545F] cursor-pointer bg-[#F2F2F3] flex items-center justify-between px-4 py-3"
            >
              <span
                class="text-[#3D3D3D] dark:text-[#D1D1D1] text-[14px] font-[600]"
                >{{ selectedCategory }}</span
              >
              <span>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  class="text-black dark:text-[#D1D1D1]"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="currentcolor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>

            <div
              v-if="openDropdown"
              class="absolute top-18 left-0 w-full dark:bg-[#4F545F] py-2 px-1 bg-[#F2F2F3] rounded-md z-10"
            >
              <div
                v-for="(item, i) in menuList"
                :key="i"
                @click="handleCategory(item)"
                class="py-2 px-3 cursor-pointer dark:hover:bg-[#40444c] text-lightTextColor dark:text-[#D1D1D1] text-[14px] font-[600] hover:bg-[#e2e2e2] flex items-center space-x-2"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="space-y-1">
            <label
              class="text-[#5D5D5D] dark:text-[#D1D1D1] text-[13px] font-semibold"
              >Name</label
            >
            <TextInput
              v-model="userName"
              :handleChange="handleChange"
              name="userName"
              placeholder="Your Name"
              :readOnly="isUserName ? true : false"
            />
          </div>
          <div class="space-y-1">
            <label
              class="text-[#5D5D5D] dark:text-[#D1D1D1] text-[13px] font-semibold"
              >Email</label
            >
            <TextInput
              v-model="userEmail"
              :handleChange="handleChange"
              name="userEmail"
              placeholder="Your Email"
              :readOnly="isUserEmail ? true : false"
            />
          </div> -->

          <div class="space-y-1">
            <label
              class="text-[#5D5D5D] dark:text-[#D1D1D1] text-[13px] font-semibold"
              >Subject line</label
            >
            <TextInput
              v-model="subjectLine"
              :handleChange="handleChange" 
              value="subjectLine"
              name="subjectLine"
              placeholder="Write a subject of your message"
              :className="
                subjectLineError
                  ? 'border border-red-500'
                  : isLoading
                  ? 'pointer-events-none opacity-50'
                  : ''
              "
            />
            <span v-if="subjectLineError" class="text-red-600 text-[12px]">{{
              subjectLineError
            }}</span>
          </div>

          <div class="space-y-1 flex flex-col">
            <label
              class="text-[#5D5D5D] dark:text-[#D1D1D1] text-[13px] font-semibold"
              >Message/Description</label
            >
            <textarea
              :class="
                descError
                  ? 'border border-red-500'
                  : isLoading
                  ? 'pointer-events-none opacity-50 border-none'
                  : 'border-none'
              "
              @input="updateCharacterCount"
              @keydown.space="handleSpaceKey"
              v-model="desc"
              maxlength="500"
              :rows="screenWidth < 1600 ? '4' : '6'"
              style="resize: none"
              placeholder="Enter a detailed description here..."
              class="rounded-md focus:ring-0 dark:bg-[#4F545F] dark:hover:bg-[#40444c] dark:text-[#D1D1D1] text-[14px] dark:placeholder:text-[#D1D1D1] placeholder:text-[#B0B0B0] w-full hover:bg-[#e2e2e2] bg-[#F2F2F3] px-4 py-3"
            />
            <span v-if="descError" class="text-red-600 text-[12px]">{{
              descError
            }}</span>
            <span class="text-[#888888] dark:text-[#D1D1D1] text-[12px]"
              >Please limit your message to 500 characters.
              <span class="text-[#165D7D] dark:text-[#338cbf] font-[600]"
                >{{ characterCount }} characters left.</span
              ></span
            >
          </div>

          <div class="space-y-4">
            <!-- <div class="py-6">
              <div
                :class="isLoading ? 'pointer-events-none opacity-50' : ''"
                class="w-full hover:bg-[#f2f2f2] dark:hover:bg-[#313238] cursor-pointer rounded-full border-dotted border-4 border-[#cdcdcd] dark:border-[#4F545F] py-4 pl-12"
              >
              

                <label
                  class="flex cursor-pointer items-center space-x-4 w-full"
                >
                  <span>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      class="text-black dark:text-[#D1D1D1]"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5.5V19.5"
                        stroke="currentcolor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12.5H19"
                        stroke="currentcolor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    class="text-[#000000] dark:text-[#D1D1D1] text-[16px] font-[700]"
                    >{{
                      fileData?.name ? fileData?.name : "Attach a file here"
                    }}</span
                  >
                </label>

                <input
                  type="file"
                  id="fileInput"
                  ref="fileInput"
                  style="display: none"
                  @change="handleFileChange"
                />
              </div>
            </div> -->

            <div>
              <vue-recaptcha
                ref="recaptcha"
                @verify="onRecaptchaVerify"
                sitekey="6LcFq1IpAAAAAN5mhlygz82Qm7A7dasCFB_tupbN"
              ></vue-recaptcha>
            </div>
            <div>
              <span
                class="text-[14px] font-[600] text-red-500"
                v-if="responseErrorMessage"
                >{{ truncateText(responseErrorMessage, 40) }}</span
              >
              <span
                class="text-[14px] font-[600] text-[#109636]"
                v-if="responseSuccessMessage"
                >{{ truncateText(responseSuccessMessage, 40) }}</span
              >
            </div>

            <div class="w-full flex items-center space-x-4">
              <CustomButton
                title="Cancel"
                buttonType="secondary"
                @onClick="onCloseModal"
                :customStyle="isLoading ? 'opacity-50' : ''"
                :disabledVal="isLoading"
              />
              <CustomButton
                title="Send"
                buttonType="primary"
                @onClick="handleSave"
                :isLoading="isLoading"
                loaderText="Sending"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../../SharedComponents/BaseModal.vue";
import CrossIconSvg from "../../../assets/svgs/CrossIconSvg.vue";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import { VueRecaptcha } from "vue-recaptcha";
import userApi from "../../../services/userApi";
import TextInput from "@/components/SharedComponents/TextInput.vue";
import { getUserDetailsFromStore } from "@/store";
import "../../../index.css";
export default {
  name: "ContactModal",
  components: {
    BaseModal,
    CrossIconSvg,
    CustomButton,
    VueRecaptcha,
    TextInput,
  },

  props: {
    title: {
      default: "Contact Us",
      type: String,
    },
  },

  data() {
    return {
      isToken: null,
      isLoading: false,
      openDropdown: false,
      subjectLineError: "",
      screenWidth: window.innerWidth,
      descError: "",
      subjectLine: "",
      userName: "",
      userEmail: "",
      characterCount: 500,
      responseSuccessMessage: "",
      responseErrorMessage: "",
      desc: "",
      fileData: "",
      selectedCategory: "Account and Registration",
      menuList: [
        "Account and Registration",
        "Billing and Payments",
        "Technical Support",
        "Product or Service Inquiry",
        "Feedback and Suggestions",
        "Report a Bug or Issue",
        "Privacy and Data Concerns",
        "Content Requests or Suggestions",
        "Partnerships and Collaborations",
        "Other Inquiries",
      ],
    };
  },

  methods: {
    onRecaptchaVerify(response) {
      if (response) {
        this.isToken = response;
        this.responseErrorMessage = "";
      } else {
        this.isToken = null;
        this.responseErrorMessage = "";
      }
    },
    truncateText(text, maxLength) {
      if (text?.length > maxLength) {
        return text?.slice(0, maxLength) + "...";
      } else {
        return text;
      }
    },
    handleChange(event) {
      const inputValue = event.target.value;
      this[event.target.name] = inputValue;
    },

    handleSpaceKey(event) {
      if (!event.target.value.trim()) {
        event.preventDefault();
      }
    },

    async handleSave() {
      const isEmpty = /^\s*$/;
      if (isEmpty.test(this.subjectLine) && isEmpty.test(this.desc)) {
        this.subjectLineError = "Required field!";
        this.descError = "Required field!";
        return;
      }

      if (isEmpty.test(this.subjectLine)) {
        this.subjectLineError = "Required field!";
        console.log("isEmpty", this.subjectLine);
        return;
      }

      if (isEmpty.test(this.desc)) {
        this.descError = "Required field!";
        return;
      }

      if (!this.isToken) {
        this.responseErrorMessage = "Condor Cash is not for robots";
        return;
      }
      try {
        this.isLoading = true;

        const payload = {
          subject: this.subjectLine,
          message: this.desc,
          category: this.selectedCategory,
          fullname: this.userName,
          email: this.userEmail,
        };
        console.log("payload", payload);

        const response = await userApi.contactUs(payload);
        if (response?.data?.status) {
          this.isLoading = false;

          console.log("response", response?.data);
          this.responseSuccessMessage = response?.data?.message;
          this.clearFields();
        } else {
          this.responseErrorMessage = response?.data?.message;
          this.isLoading = false;
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    clearFields() {
      this.subjectLine = "";
      this.desc = "";
      // this.selectedCategory = "";
    },
    updateCharacterCount() {
      this.characterCount = 500 - this.desc?.length;
      if (this.characterCount > 0) {
        this.desc = this.desc.slice(0, 500);
      }
    },
    handleFileChange() {
      console.log("file");
      // const fileInput = this.$refs.fileInput;
      // const selectedFiles = fileInput.files;

      // if (selectedFiles.length > 0) {
      //   const file = selectedFiles[0];
      //   this.fileData = file;
      // }
    },
    handleCategory(val) {
      this.selectedCategory = val;
      this.openDropdown = false;
    },
    onCloseModal() {
      this.$emit("onClose");
    },
  },
  computed: {
    userdetails() {
      return getUserDetailsFromStore();
    },
    isUserName() {
      if (this.userdetails) {
        return this.userdetails?.firstname;
      } else {
        return "";
      }
    },
    isUserEmail() {
      if (this.userdetails) {
        return this.userdetails?.email;
      } else {
        return "";
      }
    },
  },

  watch: {
    desc() {
      this.descError = "";
    },
    subjectLine() {
      this.subjectLineError = "";
    },
    responseSuccessMessage() {
      setTimeout(() => {
        this.responseSuccessMessage = "";
      }, [3000]);
    },
    responseErrorMessage() {
      setTimeout(() => {
        this.responseErrorMessage = "";
      }, [3000]);
    },
    userdetails: {
      immediate: true,
      handler(newVal) {
        if (
          this.userdetails?.firstname &&
          this.userdetails?.lastname &&
          this.userdetails?.email
        ) {
          this.userName = newVal?.firstname;
          this.userEmail = newVal?.email;
        }
      },

      deep: true,
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1600px) {
  .formContainer {
    height: 88vh;
  }
  .bottomDiv {
    padding-top: 0.1rem;
  }
  .header {
    padding: 0.2rem 2.2rem 0.1rem 2.2rem;
  }
  .detailText {
    font-size: 12px;
  }
  .formDiv {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .formContainer {
    height: auto;
    padding-bottom: 1rem;
  }
  .header {
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
  }
  .formDiv {
    margin-top: 20px;
  }
}
</style>
