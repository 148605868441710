import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/home";
import Login from "../pages/userStory/login";
import Chart from "../components/ChartComponent/Chart.vue";
import Settings from "../pages/settings";
import TradeRiskCalculator from "../pages/tradeRiskCalculator";
import TradeHistory from "../pages/tradeHistory";
import CreditReturns from "../pages/creditsReturns";
import ActiveTrades from "../pages/activeTrades";
import SignUp from "../pages/userStory/newSignup";
// import AfterSignUp from "@/pages/userStory/login/AfterSignUp.vue";
import NotFound404 from "@/pages/NotFound404.vue";
import EmailAccess from "@/pages/userStory/emailAccess/index.vue";
import ThirdPartyEmailAccess from "@/pages/userStory/thirdPartyAuth/index.vue";

import SendFeedback from "@/pages/sendFeedback";
import Help from "@/pages/help";
import ForgetPassword from "../pages/userStory/ForgotPassword";
import LayoutPage from "../pages/LayoutPage";
import ResetPassword from "../pages/userStory/ResetPasssword";
import AppStore from "@/pages/AppStore.vue";

Vue.use(VueRouter);
// import AfterSignUp from "../pages/userStory/login/AfterSignUp"

const routes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: { requiresAuth: false, goHome: true },
  },
  {
    name: "AppStore",
    path: "/appStore",
    component: AppStore,
    meta: { requiresAuth: false },
  },
  {
    name: "Dashboard",
    path: "/",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    name: "Layout",
    path: "/layout",
    component: LayoutPage,
    meta: { requiresAuth: false },
  },
  {
    name: "Dashboard",
    path: "/members-area",
    component: Home,
    meta: { requiresAuth: true },
  },

  {
    name: "ForgetPassword",
    path: "/forget-password",
    component: ForgetPassword,
    meta: { requiresAuth: false, goHome: true },
  },
  {
    name: "ResetPassword",
    path: "/reset-password",
    component: ResetPassword,
    meta: { requiresAuth: false, goHome: true },
  },
  {
    name: "Sign up",
    path: "/sign-up",
    component: SignUp,
    meta: { requiresAuth: false, goHome: true },
  },
  {
    name: "Chart",
    path: "/chart",
    component: Chart,
    meta: { requiresAuth: true },
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    name: "Trade Risk Calculator",
    path: "/trade-risk-calculator",
    component: TradeRiskCalculator,
    meta: { requiresAuth: true },
  },
  {
    name: "Trade History",
    path: "/trade-history",
    component: TradeHistory,
    meta: { requiresAuth: true },
  },

  {
    name: "Credit & Returns",
    path: "/credit-returns",
    component: CreditReturns,
    meta: { requiresAuth: true },
  },
  {
    name: "Active Trades",
    path: "/active-trades",
    component: ActiveTrades,
    meta: { requiresAuth: true },
  },
  // {
  //     name: "AfterSignUp",
  //     path: "/after-signup",
  //     component: AfterSignUp,
  //     meta: {requiresAuth: false},
  // },
  {
    name: "Page Not Found",
    path: "/404",
    component: NotFound404,
    meta: { requiresAuth: false },
  },
  {
    name: "Send Feedback",
    path: "/send-feedback",
    component: SendFeedback,
    meta: { requiresAuth: true },
  },
  {
    name: "Help",
    path: "/help",
    component: Help,
    meta: { requiresAuth: true },
  },

  {
    name: "Email Access Link Based Login",
    path: "/email-link-access",
    component: EmailAccess,
    meta: { requiresAuth: false, goHome: true },
  },

  {
    name: "Third PartyEmail Access Link Based Login",
    path: "/third-party-auth",
    component: ThirdPartyEmailAccess,
    meta: { requiresAuth: false, goHome: true },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "is-active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("isUserAuthenticated");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if user is authenticated

    console.log("isAuthenticated", isAuthenticated);
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      next("/login");
    } else {
      if (to.path === "/login") {
        next("/");
      } else {
        next(); // Continue to the requested route
      }
    }
  } else {
    if (isAuthenticated && to.meta.goHome) {
      next("/");
    }
    console.log("to", to.meta);
    next();
  }
});
export default router;
