<template>
  <div class="w-full h-full sm:pt-0 pt-4 pb-12">
    <BaseModal v-if="showModal">
      <div class="w-full flex justify-end px-4">
        <CrossButtonSvg @close="(e) => (showModal = false)"></CrossButtonSvg>
      </div>
      <div v-if="Object.keys(data).length">
        <table class="min-w-full bg-white shadow-md rounded-xl">
          <thead>
            <tr class="bg-blue-gray-100 text-gray-700">
              <th class="py-3 px-4 text-left">Key</th>
              <th class="py-3 px-4 text-left">First</th>
              <th class="py-3 px-4 text-left">Second</th>
            </tr>
          </thead>
          <tbody class="text-blue-gray-900">
            <tr
              class="border-b border-blue-gray-200"
              v-for="d in Object.keys(data)"
              :key="d"
            >
              <td class="py-3 px-4">{{ d }}</td>
              <td class="py-3 px-4">{{ data[d].first }}</td>
              <td class="py-3 px-4">{{ data[d].second }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </BaseModal>

    <div
      class="w-full sm:h-full h-[60vh] space-y-4"
      v-if="!chartLoader && !priceLoader"
      id="chartMainContainer"
    >
      <div class="bg-white dark:bg-compBgDark/20 rounded-xl p-2 w-full">
        <TradingMain
          id="chartTourId"
          :key="mainRef"
          :data="ohlcv"
          :offchart="offchart"
          :style="chartStyle"
        ></TradingMain>
      </div>

      <div
        class="w-full sm:h-fit h-[15vh] sm:flex sm:flex-row flex items-center sm:justify-between sm:space-x-4 space-x-14 sm:overflow-y-hidden overflow-y-auto"
      >
        <div class="flex justify-center" id="creditReturns1">
          <div>
            <BearBullCalc
              calcType="calls"
              indicator="Bear Call"
              :val1="getTradeCalculator?.buy_call_strike_price"
              :val2="getTradeCalculator?.sell_call_strike_price"
              @addValue="addCalls"
              @minusValue="minusCalls"
            />
          </div>
        </div>

        <div
          class="sm:flex sm:flex-row items-center space-x-3 sm:w-6/12 w-full flex justify-center"
          id="creditReturns3"
        >
          <div class="sm:w-4/12 w-6/12">
            <CardBox
              customClass="border border-[#E7E7E7] rounded-3xl w-full  items-center flex justify-center !py-2 !px-3 h-full"
            >
              <div class="flex space-x-2 w-full justify-center items-center">
                <div class="w-2/12">
                  <svg
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      :stroke="`${store.state.theme ? '#4380e6' : '#0B3D90'}`"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 6V12L16 14"
                      :stroke="`${store.state.theme ? '#4380e6' : '#0B3D90'}`"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="flex flex-col whitespace-nowrap w-10/12">
                  <span
                    class="text-[24px] font-bold text-btnPrimary dark:text-[#5174d6]"
                    >{{ getTradeCalculator?.expiry_days }} Days</span
                  >
                  <span
                    class="text-[13px] font-semibold text-[#454545] dark:text-gray-400 tracking-wide"
                    >{{ getTradeCalculator?.expiry_date }}</span
                  >
                </div>
              </div>
            </CardBox>
          </div>
          <div class="sm:w-4/12 w-6/12">
            <CardBox
              :customClass="`${bgColor()} rounded-3xl  !py-1  w-full   h-full`"
            >
              <div class="flex flex-col justify-center text-white pl-2">
                <span class="text-[20px] font-semibold text-center italic"
                  >Return</span
                >
                <span
                  class="text-[24px] text-center font-bold"
                  v-if="calculated_rate_of_return"
                  >{{
                    calculationLoading ? "..." : calculated_rate_of_return + "%"
                  }}</span
                >

                <span v-else class="text-[24px] font-bold text-white">...</span>
              </div>
            </CardBox>
          </div>
          <div class="sm:w-4/12 w-6/12">
            <CardBox customClass=" bg-[#E7E7E7] rounded-3xl w-full h-full">
              <div class="flex flex-col">
                <span
                  class="text-[18px] font-semibold italic text-center text-[#6D6D6D] dark:text-gray-400 whitespace-nowrap"
                  >Credit of IC</span
                >
                <span
                  class="text-[24px] font-bold dark:text-white text-center text-black whitespace-nowrap"
                  v-if="calculated_credit_of_ic"
                  >{{
                    calculationLoading
                      ? "..."
                      : calculated_credit_of_ic + " USD"
                  }}</span
                >
                <span
                  v-else
                  class="text-[27px] text-center font-bold dark:text-white"
                  >...</span
                >
              </div>
            </CardBox>
          </div>
        </div>

        <div class="flex justify-center" id="creditReturns2">
          <BearBullCalc
            calcType="puts"
            indicator="BULL PUT"
            :val1="getTradeCalculator?.sell_put_strike_price"
            :val2="getTradeCalculator?.buy_put_strike_price"
            @addValue="addPuts"
            @minusValue="minusPuts"
          />
        </div>
      </div>
    </div>

    <div v-else class="w-full h-full flex justify-center items-center">
      <div class="">
        <LoaderComponent />
      </div>
    </div>
  </div>
</template>

<script>
// import Navbar from "../../components/NavBar"
import tradeApi from "@/services/tradeApi";
import Data from "@/@trading-vue-js/src/data.json";
import LoaderComponent from "@/components/SharedComponents/LoaderComponent.vue";
import TradingMain from "@/@trading-vue-js/src/TradingMain.vue";
import BearBullCalc from "./BearBullCalc.vue";
import CardBox from "../../components/SharedComponents/CardBox.vue";
import store, {

  getUserDetailsFromStore,
} from "@/store";
import BaseModal from "@/components/SharedComponents/BaseModal.vue";
import CrossButtonSvg from "@/components/Modal/CrossButtonSvg.vue";
  // switch off webtour
// import tourService from "@/services/tourService";
// import moment from "moment";

export default {
  name: "CreditReturns",
  components: {
    CrossButtonSvg,
    BaseModal,
    TradingMain,
    LoaderComponent,
    BearBullCalc,
    CardBox,
    // Navbar,
    // SideBar
  },
  data() {
    return {
      isAuthenticated: localStorage.getItem("token"),
      ohlcv: Data.ohlcv,
      chartStyle: { borderRadius: "20px" },
      offchart: Data.offchart,
      btnDisabled: false,
      chartLoader: false,
      priceLoader: false,
      calculationLoading: false,
      calculated_rate_of_return: "",
      calculated_credit_of_ic: "",
      mainRef: 0,
      showModal: false,
      data: {},
      tourStarted: false,
      tourObj: null,
    };
  },
  setup() {
    return {
      store,
    };
  },
  methods: {
    async calculateCall() {
      this.data = {};
      this.mainRef = this.mainRef + 1;
      try {
        this.calculationLoading = true;
        let first = tradeApi.chartCalcTrade(
          "10",
          this.getTradeCalculator.sell_call_strike_price,
          this.getTradeCalculator.sell_put_strike_price
        );
        let second = tradeApi.chartCalcTrade2(
          "10",
          this.getTradeCalculator.sell_call_strike_price,
          this.getTradeCalculator.sell_put_strike_price
        );
        let resp = await Promise.all([first, second]);
        // console.log("resp",resp)
        first = resp[0];
        second = resp[1];
        let fCal = first?.data.data?.calculation_numbers;
        console.log("first", fCal);
        let sCal = second?.data?.calculation_numbers;
        for (let k in fCal) {
          this.data[k] = { first: fCal[k], second: sCal[k] };
        }
        console.log("second", second?.data);
        this.calculated_rate_of_return = second?.data?.rate_of_return;
        this.calculated_credit_of_ic = second?.data?.ic;

        this.calculationLoading = false;
      } catch (e) {
        console.log("Calculation Error ", e);

        this.calculationLoading = false;
      }
    },

    getObject() {
      // Retrieve the JSON string from localStorage
      const storedObject = localStorage.getItem("appTour");

      // If the storedObject is not null or undefined, parse it into an object
      if (storedObject) {
        this.tourObj = JSON.parse(storedObject);
        console.log(" this.tourObj", this.tourObj);
      } else {
        return;
      }
    },

    onTourEnd() {
      this.tourObj.creditReturn = true;

   localStorage.setItem('appTour', JSON.stringify(this.tourObj));
      // this.$router.push("/active-trades");
    },
    bgColor() {
      if (this.calculated_rate_of_return) {
        if (this.calculated_rate_of_return > 0) {
          return "bg-green-gradient";
        } else {
          return "!bg-[#CC2E3D]";
        }
      } else {
        return "bg-green-gradient";
      }
    },
    addPuts() {
      store.commit("ADD_PUTS", this.calculateCall);
      // setTimeout(() => {
      //   this.calculateCall();
      // })
    },
    minusPuts() {
      store.commit("MINUS_PUTS", this.calculateCall);
    },
    addCalls() {
      store.commit("ADD_CALLS", this.calculateCall);
    },
    minusCalls() {
      store.commit("MINUS_CALLS", this.calculateCall);
    },
    async GetPriceDetails() {
      this.btnDisabled = true;
      this.chartLoader = true;
      try {
        const response = await tradeApi.getChartData();
        // console.log("Stock ", response);
        if (response.status == 200) {
          // const data= response.data.user_detail
          // console.log("Stock RespTrade", response);
          // Data.ohlcv
          // store.commit('SET_USER_DETAIL', data);
          let mainData = response.data.data;
          // console.log("Stock mainData", mainData)
          this.ohlcv = mainData?.ohlcv;
          // Data.ohlcv = mainData?.ohlcv
          let macdData = mainData?.macd;
          let covalData = mainData?.coval;
          let offChartList = [];
          if (covalData) {
            if (covalData) {
              // console.log("covalData", covalData);
              let covalDataMain = covalData;
              // let covalDataCrosses = parseToChartCandleData(crossOverFill);
              offChartList.push({
                name: "Coval Indicators",
                type: "Splines",
                data: covalDataMain,
                meta: covalDataMain,
                settings: {
                  crosses: [],
                },
              });
            }
          }

          if (macdData) {
            // console.log("Stock macdData", macdData);
            let signalLine = macdData.signal_line;
            let histogram = macdData.histogram;
            let macdLine = macdData.macd_line;
            let crossOverMACD = macdData.cross_over_macd;
            console.log(crossOverMACD);
            let finalOutput = [];
            for (let i = 0; i < histogram.length; i++) {
              finalOutput.push([
                histogram[i][0],
                histogram[i][1],
                macdLine[i][1],
                signalLine[i][1],
              ]);
            }
            offChartList.push({
              name: "MACD",
              type: "MACD",
              data: finalOutput,
              settings: {
                macdColor: "#3782f2",
                signalColor: "#f48709",
                histColor: "#9954bb",
                // crosses: parseToChartCandleData(crossOverMACD)
              },
            });
            // console.log("Stock macdData", macdData, Array.isArray(macdData), finalOutput);
            // console.log("Stock offChartList", offChartList);
          }
          this.offchart = offChartList;

          // setTimeout(() => {
          //   // let newDataCube = new DataCube(
          //   //   {chart: {data: mainData?.ohlcv, type: 'Candles',tf:'1D'}}
          //   // )
          //   console.log("ChartUpdate")
          //
          //   // console.log("Stock chartMainData.value", newDataCube)
          // }, 3000)
        } else {
          // Error.value=
          // return;
        }
      } catch (err) {
        console.log("Stock Error", err);
      }
      this.btnDisabled = false;
      this.chartLoader = false;
    },
  },

    // switch off webtour

//   async updated() {
//     // Check if the elements you want to highlight are present in the DOM
//     const elementsExist = document.getElementById("chartMainContainer");
//     if (this.tourObj) {
//       if (
//         this.isAuthenticated &&
//         !this.tourObj.creditReturn &&
//         this.isProfileComplete
//       ) {
//     if (elementsExist) {
//       const steps = [
//         {
//           element: "#chartMainContainer",
//           intro:
//             "This interactive chart shows your $5 call and put values, you can zoom in and out chart to see historical data.",
//         },
//         {
//           element: "#chartTourId",
//           intro:
//             "This interactive chart shows your $5 call and put values, you can zoom in and out chart to see historical data.",
//         },

//         {
//           element: "#creditReturns1",
//           intro:
//             "Use + and - buttons to adjust Bear Calls to calculate returns and credit value.",
//         },
//         {
//           element: "#creditReturns2",
//           intro:
//             "Use + and - buttons to adjust Bull Puts to calculate returns and credit value.",
//         },
//         {
//           element: "#creditReturns3",
//           intro:
//             "See the expiration, returns and credit value for the $5.00 call/put spreads you set on the chart.",
//         },
//       ];

//       // Move the tour start to the next tick to ensure DOM updates are completed
//       this.$nextTick(() => {
//         tourService.startTour(steps, this.onTourEnd);
//       });
//     }
//   }
// }
//   },
  mounted() {
// this.getObject()

    this.GetPriceDetails()
      .then((d) => {
        console.log("GetPriceDetails Success", d);
      })
      .catch((r) => {
        console.log("Error in GetPriceDetails", r);
      });
    this.priceLoader = true;
    tradeApi
      .getIronCondorData()
      .then(() => {
        this.priceLoader = false;
        console.log("tradeApi getIronCondorData Success", this.spxData);
        store.commit("UPDATE_CALCULATOR_DATA", this.spxData);
        this.calculated_rate_of_return = this.spxData.rate_of_return;
        this.calculated_credit_of_ic = this.spxData.ic;
      })
      .catch((r) => {
        this.priceLoader = false;
        console.log("Error in GetPriceDetails", r);
      });

    // tradeApi.get_option_data_async().then(d => console.log("resp",d))
    // .catch(e => console.log("error",e))
  },
  computed: {
    getTradeCalculator() {
      return store.getters.getTradeCalculator;
    },
    spxData() {
      return store.getters.getDefaultSpx;
    },

    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      let user = this.userDetails;
      console.log("userDetails", this.userDetails.profile_status);
      return user?.is_profile_complete ?? false;
    },
  },
};
</script>
