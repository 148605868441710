<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_386_13938)">
      <path
        d="M2 5.33301C2 4.80257 2.21071 4.29387 2.58579 3.91879C2.96086 3.54372 3.46957 3.33301 4 3.33301H12C12.5304 3.33301 13.0391 3.54372 13.4142 3.91879C13.7893 4.29387 14 4.80257 14 5.33301V10.6663C14 11.1968 13.7893 11.7055 13.4142 12.0806C13.0391 12.4556 12.5304 12.6663 12 12.6663H4C3.46957 12.6663 2.96086 12.4556 2.58579 12.0806C2.21071 11.7055 2 11.1968 2 10.6663V5.33301Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 5.33301C2 4.80257 2.21071 4.29387 2.58579 3.91879C2.96086 3.54372 3.46957 3.33301 4 3.33301H12C12.5304 3.33301 13.0391 3.54372 13.4142 3.91879C13.7893 4.29387 14 4.80257 14 5.33301V10.6663C14 11.1968 13.7893 11.7055 13.4142 12.0806C13.0391 12.4556 12.5304 12.6663 12 12.6663H4C3.46957 12.6663 2.96086 12.4556 2.58579 12.0806C2.21071 11.7055 2 11.1968 2 10.6663V5.33301Z"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 5.33301C2 4.80257 2.21071 4.29387 2.58579 3.91879C2.96086 3.54372 3.46957 3.33301 4 3.33301H12C12.5304 3.33301 13.0391 3.54372 13.4142 3.91879C13.7893 4.29387 14 4.80257 14 5.33301V10.6663C14 11.1968 13.7893 11.7055 13.4142 12.0806C13.0391 12.4556 12.5304 12.6663 12 12.6663H4C3.46957 12.6663 2.96086 12.4556 2.58579 12.0806C2.21071 11.7055 2 11.1968 2 10.6663V5.33301Z"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 6.66699H14"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 6.66699H14"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 6.66699H14"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 10H4.67317"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 10H4.67317"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.6665 10H4.67317"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.3335 10H8.66683"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.3335 10H8.66683"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.3335 10H8.66683"
        stroke="black"
        stroke-opacity="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_386_13938">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import store from "@/store";
import { watch, ref } from "vue";

export default {
  name: "AddressBillingSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
