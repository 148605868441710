<template>
  <CardBox customClass="border border-[#E7E7E7] rounded-3xl w-full !p-8 h-full">
    <div class="w-full flex items-center justify-center md:h-[45vh] h-[50vh]">
      <div class="w-full h-full">
        <div class="flex flex-col space-y-3 w-full">
          <div class="flex items-center justify-center">
            <svg
              width="169"
              height="168"
              viewBox="0 0 169 168"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M85.234 24.2783H84.1995C83.215 24.2783 82.4089 23.5392 82.4089 22.6358V1.64292C82.4089 0.739519 83.215 0.000417709 84.1995 0.000417709H85.234C86.2185 0.000417709 87.0239 0.739519 87.0239 1.64292V22.6358C87.0239 23.5392 86.2185 24.2783 85.234 24.2783Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M145.074 46.4275C144.177 45.6036 144.177 44.2566 145.074 43.4328L160.519 29.26C161.416 28.4367 162.885 28.4367 163.782 29.26C164.68 30.0833 164.68 31.4309 163.782 32.2541L148.337 46.4275C147.44 47.2507 145.971 47.2507 145.074 46.4275Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M25.0872 46.8034C24.19 47.6267 22.7214 47.6267 21.8242 46.8034L6.37894 32.6307C5.48111 31.8068 5.48111 30.4599 6.37894 29.636C7.27613 28.8127 8.74467 28.8127 9.64187 29.636L25.0872 43.8093C25.9843 44.6326 25.9843 45.9802 25.0872 46.8034Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M55.0862 38.0765L54.7217 38.2205C53.6556 38.6408 52.4092 38.1845 51.9511 37.2068L47.6391 28.0027C47.181 27.025 47.6783 25.8807 48.7438 25.4603L49.1083 25.3169C50.1744 24.8966 51.4209 25.3523 51.8789 26.3306L56.1909 35.5341C56.649 36.5124 56.1517 37.6562 55.0862 38.0765Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M14.5847 75.0674L13.9576 76.3586C13.6134 77.0675 12.7004 77.3886 11.9285 77.0728L0.910989 72.568C0.13844 72.2527 -0.211457 71.4143 0.132109 70.706L0.759766 69.4142C1.10333 68.7059 2.01698 68.3843 2.78889 68.7001L13.8064 73.2049C14.579 73.5207 14.9289 74.3591 14.5847 75.0674Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M168.232 68.3234L168.88 69.7063C169.203 70.3961 168.852 71.2025 168.101 71.4992L156.928 75.9064C156.176 76.2025 155.297 75.8809 154.974 75.1912L154.326 73.8088C154.003 73.119 154.354 72.312 155.106 72.0159L166.278 67.6087C167.03 67.312 167.909 67.6336 168.232 68.3234Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M118.517 23.7348L119.983 24.4107C120.714 24.7474 121.011 25.5718 120.643 26.2424L115.182 36.2134C114.815 36.8846 113.917 37.1575 113.186 36.8202L111.72 36.1444C110.989 35.807 110.692 34.9826 111.059 34.312L116.52 24.341C116.888 23.6704 117.786 23.3975 118.517 23.7348Z"
                :fill="`${store.state.theme ? 'white' : 'black'}`"
              />
              <path
                d="M70.4992 158.894C77.8166 163.234 84.1818 165.031 88.4836 165.877C95.2613 167.209 100.848 168.168 115.334 167.975C121.997 167.887 126.532 167.167 130.356 166.739C133.391 166.399 136.139 164.857 137.739 162.466C138.041 162.015 138.317 161.521 138.552 160.981C140.857 155.699 137.146 150.941 136.905 150.643C137.407 150.446 142.561 148.329 143.822 143.026C145.202 137.22 140.611 133.086 140.363 132.871C140.363 132.871 151.431 126.523 142.439 115.733C142.864 115.322 147.163 111.025 145.897 104.942C145.874 104.833 145.85 104.725 145.824 104.617C144.623 99.5878 139.677 96.0561 134.065 96.0561H110.354C110.354 96.0561 127.221 66.8583 109.927 56.7026C109.685 56.5604 104.927 53.3637 101.719 54.8796C98.628 56.3404 98.9659 61.3566 96.0927 70.0318C94.7115 74.2027 93.0866 77.4662 91.9427 79.5529C90.459 82.6631 87.0929 88.5566 80.183 93.5172C71.5135 99.7411 61.9506 100.919 57.3564 101.134V157.625C59.1774 157.643 60.62 157.634 61.5064 157.625C65.323 157.587 67.0079 157.467 69.1155 158.26C69.7362 158.493 70.2139 158.738 70.4992 158.894Z"
                fill="#FCC938"
              />
              <path
                d="M32.662 99.8934L32.8411 162.326L57.3564 162.331V97.369L32.662 99.8934Z"
                fill="#FCC938"
              />
              <path
                d="M65.6032 154.989C72.9206 159.329 79.2858 161.126 83.5883 161.971C90.3653 163.304 95.9516 164.263 110.438 164.07C117.101 163.981 121.636 163.262 125.46 162.834C128.495 162.494 131.243 160.952 132.843 158.561C133.145 158.11 133.421 157.616 133.657 157.076C135.961 151.794 132.25 147.036 132.009 146.738C132.511 146.542 137.665 144.424 138.926 139.121C140.306 133.315 135.715 129.181 135.467 128.965C135.467 128.965 146.535 122.618 137.543 111.828C137.968 111.416 142.267 107.12 141.001 101.037C140.978 100.928 140.954 100.82 140.929 100.713C139.727 95.6825 134.781 92.1508 129.169 92.1508H105.458C105.458 92.1508 122.325 62.953 105.032 52.7974C104.789 52.6551 100.031 49.4584 96.8228 50.9743C93.732 52.4351 94.0705 57.4519 91.1967 66.1265C89.8155 70.2975 88.1906 73.5609 87.0467 75.6476C85.563 78.7578 82.1969 84.6514 75.287 89.6119C66.6175 95.8358 57.0546 97.0138 52.4604 97.2286V153.72C54.2814 153.737 55.724 153.729 56.6104 153.72C60.427 153.682 62.1125 153.562 64.2195 154.354C64.8402 154.588 65.3179 154.833 65.6032 154.989Z"
                :stroke="`${store.state.theme ? 'white' : 'black'}`"
                stroke-width="4"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M27.7661 95.9881L27.9451 158.421L52.4604 158.426V93.4637L27.7661 95.9881Z"
                :stroke="`${store.state.theme ? 'white' : 'black'}`"
                stroke-width="4"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="text-[22px] text-center dark:text-white"
            >You are all set!</span
          >
        </div>
        <div class="pt-6 flex items-center space-x-3 justify-center">
          <div>
            <svg
              width="51"
              height="48"
              viewBox="0 0 51 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.9277 47.8555C20.6983 47.8555 17.5645 47.2226 14.6134 45.9744C11.7639 44.7689 9.20514 43.044 7.00829 40.8471C4.8114 38.6502 3.08635 36.0917 1.88111 33.242C0.632881 30.2909 0 27.1573 0 23.9277C0 20.6982 0.632881 17.5645 1.88111 14.6134C3.08635 11.7637 4.8114 9.20523 7.00829 7.00833C9.20514 4.81144 11.7639 3.08652 14.6134 1.88111C17.5645 0.632839 20.6983 0 23.9277 0C27.1573 0 30.291 0.632839 33.2421 1.88111C34.1886 2.28136 34.6313 3.37344 34.231 4.31993C33.8308 5.26643 32.7387 5.70912 31.7922 5.30888C29.3024 4.25584 26.6565 3.7219 23.9277 3.7219C18.5305 3.7219 13.4563 5.82372 9.63994 9.63985C5.82351 13.4564 3.72173 18.5306 3.72173 23.9277C3.72173 29.3249 5.82351 34.3991 9.63994 38.2156C13.4563 42.0317 18.5305 44.1336 23.9277 44.1336C29.3249 44.1336 34.3991 42.0317 38.2157 38.2156C42.0318 34.3991 44.1336 29.3249 44.1336 23.9277C44.1336 20.8582 43.4634 17.9113 42.1421 15.1686C41.6961 14.2429 42.0848 13.1304 43.0105 12.6843C43.9367 12.2383 45.0487 12.6271 45.4948 13.5532C47.0614 16.8035 47.8555 20.2941 47.8555 23.9277C47.8555 27.1573 47.2227 30.2909 45.9744 33.242C44.769 36.0917 43.0441 38.6502 40.8472 40.8471C38.6503 43.044 36.0918 44.7689 33.2421 45.9744C30.291 47.2226 27.1573 47.8555 23.9277 47.8555Z"
                fill="#009245"
              />
              <path
                d="M10.638 20.6554C10.638 20.6554 16.2826 25.1214 20.5626 34.302C20.5626 34.302 22.6096 39.388 24.7806 34.6738C26.9515 29.96 34.147 12.4676 49.2201 2.66685C49.2201 2.66685 50.3987 1.92281 50.0265 1.05398C49.872 0.694057 48.9713 0.483959 47.4833 1.30228C43.7614 3.34935 29.1225 10.9167 22.4235 26.4859C22.4235 26.4859 19.198 19.6631 11.9406 18.4224C11.9406 18.4224 8.65301 18.1122 10.638 20.6554Z"
                fill="#009245"
              />
            </svg>
          </div>
          <span class="text-[22px] text-[#3EAF3F]"
            >Registration completed!</span
          >
        </div>
        <div class="pt-6 w-full">
          <CustomButton
            title="Let’s Get Started!"
            buttonType="success"
            @onClick="goToDashboard"
          />
        </div>
      </div>
    </div>
  </CardBox>
</template>
<script>
import CardBox from "../../components/SharedComponents/CardBox.vue";
import CustomButton from "../SharedComponents/CustomButton.vue";
import router from "../../router";
import store from "../../store";
export default {
  name: "PlansSummaryPage",
  components: { CardBox, CustomButton },
  data() {
    return {
      appTour: {
        dashboard: false,
        activeTradeFinal: false,
        activeTradeModal: false,
        tradeHistory: false,
        riskCalculator: false,
        creditReturn: false,
      },
    };
  },
  setup() {
    return {
      store,
    };
  },
  methods: {
    goToDashboard() {
      localStorage.setItem("appTour", JSON.stringify(this.appTour));
      router.push("/");
      store.commit("SET_NAVBAR_LABEL", "Dashboard");
      store.commit("SET_TOGGLE_SETTINGS", false);
      this.$emit("handleClickStarted", false);
    },
  },
};
</script>
