<template>
  <div class="flex items-center justify-center w-full h-full md:p-0 p-4">
    <div
      :class="`${
        isLoading ? 'pointer-events-none bg-opacity-30' : ''
      } flex flex-col md:w-3/12 w-full bg-white rounded-2xl p-7`"
    >
      <!-- <div class="h-full w-full bg-green-500">asdf</div> -->
      <div class="flex flex-col space-y-4" v-if="!isSuccess">
        <span class="text-center font-bold text-[22px]">Forgot password?</span>
        <span class="text-lightTextColor text-[12px]"
          >Enter the email address you used to create your CondorCash account.
          We'll send a password reset email.</span
        >
        <div class="flex flex-col space-y-3">
          <div class="flex flex-col space-y-1">
            <TextInput
              placeholder="Email Address"
              v-model="email"
              :handleChange="inputChange"
              @keypressEnter="forgetPasswordHandler"
              :className="`${
                errorMessage ? 'border-2 border-danger rounded-md' : ''
              }`"
            />
            <span v-if="errorMessage" class="text-danger text-[13px]">{{
              errorMessage
            }}</span>
          </div>
          <CustomButton
            buttonType="primary"
            title="Send reset email"
            :isLoading="isLoading"
            loaderText="Verifying email address"
            @onClick="forgetPasswordHandler"
          />
          <div class="w-full flex items-center justify-center">
            <span
              @click="backToLogin"
              class="text-[13px] text-btnPrimary hover:underline cursor-pointer"
              >Back to Login</span
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col space-y-4">
          <div class="flex items-center justify-center space-x-3">
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                  fill="#3EAF3F"
                />
              </svg>
            </div>
            <div>
              <span class="text-[23px] font-bold text-green">Success!</span>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <span class="text-center text-lightTextColor text-[14px]"
              >Your password reset link is on its way to the email address
              linked to your CondorCash account.</span
            >
          </div>
          <div class="flex items-center justify-center">
            <img src="../../../assets/svgs/successforget.svg" />
          </div>
          <div class="pt-2">
            <CustomButton
              title="Back to Login"
              buttonType="primary"
              customStyle="h-[5vh]"
              @onClick="backToLogin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import userApi from "@/services/userApi";
// import router from "@/router";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import TextInput from "@/components/SharedComponents/TextInput.vue";
import router from "@/router";
import userApi from "@/services/userApi";
// import store from '@/store';

export default {
  name: "ForgetPassword",
  data() {
    return {
      isSuccess: false,
      email: "",
      isLoading: false,
      errorMessage: "",
    };
  },
  props: {
    contactEmail: {},
  },
  methods: {
    backToLogin() {
      router.push("/login");
    },

    async forgetPasswordHandler() {
      if (this.email === "") {
        this.errorMessage = "Please enter email address";
      } else {
        try {
          this.isLoading = true;
          let payload = {
            email: this.email,
          };
          const response = await userApi.forgetPassword(payload);
          if (response?.data?.status === true) {
            console.log("response status", response.status);
            localStorage.setItem("email", this.email);
            this.isLoading = false;
            this.isSuccess = true;
          } else {
            console.log("response err", response);
            this.errorMessage = response?.data?.message;
            this.isLoading = false;
          }
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      }
    },
    inputChange(e) {
      this.email = e.target.value;
    },
  },
  components: {
    CustomButton,
    TextInput,
    // CustomButton
  },
};
</script>
