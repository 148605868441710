<template>
  <footer
    class="w-full h-full flex items-center justify-center border-t-[#888888]/30 border-t"
  >
    <!-- Web Footer -->
    <div
      v-if="!isLoading"
      class="w-full mx-auto h-full px-10 md:flex hidden items-center justify-between bg-transparent"
    >
      <span
        class="lg:text-[13px] text-[10px] text-[#959595] font-[300] sm:text-center dark:text-white/70"
        >Copyright© {{ currentYear }}
        <a class="hover:underline" href="https://www.condorcash.com/" target="_blank">CondorCash.com</a>. All rights reserved.
      </span>

      <span
        class="lg:text-[13px] text-[11px] text-[#6D6D6D] font-[300] sm:text-center dark:text-white/70"
      >
        Design and Developed by
        <a
          :href="store?.state?.siteSettings?.app_developer_label_link?.value"
          target="_blank"
          class="hover:underline font-[400] text-[#0B3D90] dark:text-yellow-400"
        >
          {{
            store?.state?.siteSettings?.app_developer_label_link?.label ||
            "Dimensional Sys"
          }}
        </a>
      </span>

      <ul
        class="flex flex-wrap items-center mt-3 lg:text-[12px] text-[11px] font-[400] text-[#3D3D3D] dark:text-white/70 sm:mt-0"
      >
        <li>
          <a
            href="https://staging.condorcash.com/"
            target="_blank"
            class="hover:underline cursor-pointer me-4 md:me-6"
            >Home</a
          >
        </li>
        <li>
          <a
            href="https://staging.condorcash.com/privacy-policy"
            target="_blank"
            class="hover:underline cursor-pointer me-4 md:me-6"
            >Privacy Policy</a
          >
        </li>

        <li>
          <a class="hover:underline cursor-pointer" @click="handleContactUs"
            >Contact Us</a
          >
        </li>

        <div class="space-x-2 flex ml-4">
          <li>
            <a
              :title="
                store?.state?.siteSettings?.app_facebook_label_link?.label
              "
              :href="
                store?.state?.siteSettings?.app_facebook_label_link?.value ||
                '#'
              "
              target="_blank"
            >
              <img
                src="../../assets/svgs/social-icons/facebook-icon.svg"
                width="20"
              />
            </a>
          </li>
          <li>
            <a
              :title="store?.state?.siteSettings?.app_youtube_label_link?.label"
              :href="
                store?.state?.siteSettings?.app_youtube_label_link?.value || '#'
              "
              target="_blank"
            >
              <img
                src="../../assets/svgs/social-icons/youtube.svg"
                width="22"
              />
            </a>
          </li>
          <li>
            <a
              :title="
                store?.state?.siteSettings?.app_linkedin_label_link?.label
              "
              :href="
                store?.state?.siteSettings?.app_linkedin_label_link?.value ||
                '#'
              "
              target="_blank"
            >
              <img
                src="../../assets/svgs/social-icons/linkedin-icon.svg"
                width="20"
              />
            </a>
          </li>
        </div>
      </ul>
    </div>
    <div class="w-full flex h-full items-center justify-center pl-4" v-else>
      <div class="flex items-center justify-between w-full h-full">
        <SkeletonLoader :loadingLines="2" />
        <SkeletonLoader :loadingLines="2" />
        <SkeletonLoader :loadingLines="2" />
      </div>
    </div>
    <!-- Web Footer -->

    <!-- Mobile Tablet Footer -->

    <div
      class="w-full mx-auto h-full  md:hidden flex items-center justify-center "
    >
      <div class="flex-col h-full w-full space-y-0.5 py-2 ">
        <div class="flex w-full items-center">
          <ul
            class="flex  flex-wrap w-full justify-center space-x-8 sm:justify-evenly items-center sm:text-[15px] text-[11px] font-bold cursor-pointer text-black/70 dark:text-gray-400"
          >
            <li>
              <a
                href="https://staging.condorcash.com/"
                target="_blank"
                class="hover:underline w-full flex "
                >Home</a
              >
            </li>
            <li>
              <a
                href="https://staging.condorcash.com/privacy-policy"
                target="_blank"
                class="hover:underline w-full flex"
                >Privacy Policy</a
              >
            </li>

            <li>
              <a class="hover:underline w-full flex" @click="handleContactUs"
                >Contact Us</a
              >
            </li>
          </ul>
        </div>

        <div class="flex space-x-1 items-center justify-center">
          <span
            class="text-end sm:text-[16px] text-[11px] text-[#959595] font-[300] dark:text-gray-400"
          >
            Copyright© {{ currentYear }} CondorCash.com.
          </span>
          <span
            class="text-end sm:text-[16px] text-[11px] text-[#959595] font-[300] dark:text-gray-400"
          >
            {{ "All rights reserved." }}
          </span>
        </div>

        <div class="flex space-x-1 items-center justify-center">
          <span
            class="text-end sm:text-[16px] text-[11px] text-[#959595] font-[300] dark:text-gray-400"
          >
            {{ "Design and Developed by " }}
          </span>
          <a
            :href="store?.state?.siteSettings?.app_developer_label_link?.value"
            target="_blank"
            class="hover:underline font-[400] sm:text-[16px] text-[11px] text-btnPrimary dark:text-yellow-400"
          >
            {{
              store?.state?.siteSettings?.app_developer_label_link?.label ||
              "DimensionalSys"
            }}
          </a>
        </div>

        <ul>
          <div class="space-x-2 flex items-center justify-center">
            <li>
              <a
                :href="
                  store?.state?.siteSettings?.app_facebook_label_link?.value ||
                  '#'
                "
                target="_blank"
              >
                <img
                  src="../../assets/svgs/social-icons/facebook-icon.svg"
                  width="20"
                />
              </a>
            </li>
            <li>
              <a
                :href="
                  store?.state?.siteSettings?.app_youtube_label_link?.value ||
                  '#'
                "
                target="_blank"
              >
                <img
                  src="../../assets/svgs/social-icons/youtube.svg"
                  width="22"
                />
              </a>
            </li>
            <li>
              <a
                :href="
                  store?.state?.siteSettings?.app_linkedin_label_link?.value ||
                  '#'
                "
                target="_blank"
              >
                <img
                  src="../../assets/svgs/social-icons/linkedin-icon.svg"
                  width="20"
                />
              </a>
            </li>
          </div>
        </ul>

        <!-- Mobile Tablet Footer -->
      </div>
    </div>
  </footer>
</template>

<script>
import store from "@/store";
import userApi from "@/services/userApi";
import SkeletonLoader from "@/components/SharedComponents/SkeletonLoader.vue";

export default {
  name: "FooterPage",
  setup() {
    return {
      store,
    };
  },
  components: {
    SkeletonLoader,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isLoading: false,
    };
  },
  methods: {
    handleContactUs() {
      this.$emit("toggleModal", true);
    },
    async getSiteSettings() {
      this.isLoading = true;
      try {
        const response = await userApi.getSiteSettings();

        if (response?.data?.status) {
          console.log("response Get", response.data.data);
          store.commit("SET_SITE_SETTING", response.data.data);
          this.isLoading = false;
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getSiteSettings();
  },
};
</script>
