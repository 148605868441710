<template>
  <BaseModal>
    <div class="xl:w-[40vw] lg:w-[50vw] md:w-[50vw] w-full">
      <div
        class="flex justify-between items-center border-b-[#E7E7E7] dark:border-[#888888]/30 border-b sm:px-10 px-2 sm:py-3 py-5"
      >
        <p class="font-[700] sm:text-[20px] text-[17px] dark:text-[#DCDCDC]">
          Coval Trend Indicator Usage
        </p>
        <div
          :class="`${
            isLoading ? 'pointer-events-none' : ''
          } cursor-pointer hover:bg-gray-200 dark:hover:bg-lightTextColor/60 h-fit rounded-full p-1`"
          @click="onCloseModal"
        >
          <CrossIconSvg />
        </div>
      </div>
      <div class="sm:px-10 px-2">
        <div
          class="border-b-[#E7E7E7] dark:border-[#888888]/30 border-b py-5 pb-6"
        >
          <p class="text-[#008780] sm:text-[16px]  font-[700] pb-1">
            Bullish Market
          </p>
          <p class="text-[14px] font-[700] dark:text-[#DCDCDC]">
            Coval Trend Indicator above the 30% area.
          </p>
          <div class="flex items-center space-x-1 pt-1">
            <img src="../../../assets/svgs/circleCheck.svg" />
            <p
              class="sm:text-[14px] text-[12px] font-[500] text-[#3D3D3D] dark:text-darkTextColor"
            >
              Trade Action - Trade Iron Condors or Vertical Puts
            </p>
          </div>
        </div>
        <div
          class="border-b-[#E7E7E7] border-b dark:text-[#DCDCDC] dark:border-[#888888]/30 py-5 pb-6"
        >
          <p class="text-black text-[16px] dark:text-[#DCDCDC] font-[700] pb-1">
            Neutral Market
          </p>
          <p class="text-[14px] font-[700]">
            Coval Trend Indicator below falls below the 70% area.
          </p>
          <div class="flex items-center space-x-1 pt-1">
            <img src="../../../assets/svgs/circleCheck.svg" />
            <p
              class="sm:text-[14px] text-[12px] font-[500] text-[#3D3D3D] dark:text-darkTextColor"
            >
              Trade Action - Trade Iron Condors or Vertical Calls.
            </p>
          </div>
          <p class="text-[14px] font-[700] pt-3">
            Coval Trend Indicator climbs above the 30% area.
          </p>
          <div class="flex items-center space-x-1 pt-1">
            <img src="../../../assets/svgs/circleCheck.svg" />
            <p
              class="sm:text-[14px] text-[12px] font-[500] text-[#3D3D3D] dark:text-darkTextColor"
            >
              Trade Action - Trade Iron Condors or Vertical Calls.
            </p>
          </div>
        </div>
        <div class="py-5 pb-6">
          <p class="text-[#CC2E3D] text-[16px] font-[700] pb-1">
            Bearish Market
          </p>
          <p class="text-[14px] font-[700] dark:text-[#DCDCDC]">
            Coval Trend Indicator below falls below the 70% area.
          </p>
          <div class="">
            <div class="flex items-center space-x-6 pt-2">
              <p
                class="sm:text-[14px] text-[13px] font-[400] text-[#3D3D3D] dark:text-darkTextColor whitespace-nowrap"
              >
                Option #1
              </p>
              <p
                class="sm:text-[14px] text-[12px] font-[400] text-[#3D3D3D] dark:text-darkTextColor"
              >
                Close out all positions. Don't start any new trades.
              </p>
            </div>
            <div class="flex pt-2 space-x-6 dark:text-darkTextColor">
              <p
                class="sm:text-[14px] text-[13px] font-[400] text-[#3D3D3D] dark:text-darkTextColor whitespace-nowrap"
              >
                Option #2
              </p>
              <p
                class="sm:text-[14px] text-[12px] font-[400] text-[#3D3D3D] dark:text-darkTextColor"
              >
                Place conditional orders on all open positions. Don't start any
                new trades.
              </p>
            </div>

            <div
              class="flex items-start pt-2 justify-between space-x-6 dark:text-darkTextColor"
            >
              <p
                class="sm:text-[14px] text-[13px] font-[400] text-[#3D3D3D] dark:text-darkTextColor whitespace-nowrap"
              >
                Option #3
              </p>
              <p
                class="sm:text-[14px] text-[12px] font-[400] text-[#3D3D3D] dark:text-darkTextColor flex"
              >
                If you can watch the market (and your account size allows),
                <br />
                plan to close out the short Put option to profit on the
                accelerated move lower from the long Put option. This is the
                most aggressive option. Don't start any new trades.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../../SharedComponents/BaseModal.vue";

import CrossIconSvg from "../../../assets/svgs/CrossIconSvg.vue";
export default {
  name: "calculatorValueModal",
  components: {
    BaseModal,

    CrossIconSvg,
  },

  mounted() {},

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    onCloseModal() {
      this.$emit("onClose");
    },
  },
};
</script>
