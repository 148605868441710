import axios from "@/api/axios";
// import {get as oldGet}  from "axios";
import {
  TRADE_DETAIL,
  CALCULATE_RISK_FACTOR,
  ADD_TRADE,
  DELETE_TRADE,
  GET_IRON_CONDOR,
  GET_CHART_DATA,
  CHART_CALC,
  get_option_data,
  TRADE_HISTORY,
  
} from "@/api/apiRoutes";
import store from "@/store";

let cancelToken;

export default {
  getTradeHistory: async (payload) => {
    const response = await axios.get(`${TRADE_HISTORY}/${payload}`);
    return response;
  },

  getChartData: async () => {
    // console.log("payload", payload);
    const response = await axios.get(GET_CHART_DATA);
    return response;
  },

  tradeDetails: async () => {
    // console.log("payload", payload);
    const response = await axios.get(TRADE_DETAIL);
    return response;
  },

  condorIron: async () => {
    // console.log("payload", payload);
    const response = await axios.get(GET_IRON_CONDOR);
    return response;
  },
  getIronCondorData: async () => {
    let condorIronData = {};
    if (store?.state?.spxData?.five) {
      return store.state.spxData;
    } else {
      const response = await axios.get(GET_IRON_CONDOR);
      if (response.status == 200) {
        condorIronData = response.data.data;
        console.log("tradeApi condorIronData", condorIronData);
        store.commit("SET_SPX_DATA", condorIronData);
      }
      return condorIronData;
    }
  },
  calculateRiskFactor: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(CALCULATE_RISK_FACTOR, payload);
    return response;
  },
  calculateRiskFactorOld: async (payload) => {
    console.log("payload", payload);
    let url = `https://incometrader.com/get_ic_returns2.php?spx_current_price=${payload.spx_price}`;
    url = `${url}&current_iv=${payload.iv}`;
    url = `${url}&exp_date=${payload.expiry}`;
    url = `${url}&short_call_strike=${payload.call_strike_price}`;
    url = `${url}&short_put_strike=${payload.put_strike_price}`;

    const response = await axios.get(url);
    return response;
  },

  addTrade: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(ADD_TRADE, payload);
    return response;
  },

  deleteTrade: async (payload) => {
    console.log("payload", payload);
    const response = await axios.get(`${DELETE_TRADE}/${payload}`);
    return response;
  },

  chartCalcTrade: async (
    spread_type,
    sell_call_strike_price,
    sell_put_strike_price
  ) => {
    if (cancelToken) {
      cancelToken.cancel("New request generated");
      console.log("Request canceled: New request generated");
    }
    cancelToken = axios.CancelToken.source();
    try {
      const response = await axios.get(
        `${CHART_CALC}?spread_type=${spread_type}&sell_call_strike_price=${sell_call_strike_price}&sell_put_strike_price=${sell_put_strike_price}`,
        { cancelToken: cancelToken.token }
      );
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Request error:", error);
      }
    }
    // console.log("payload", payload);
  },

  chartCalcTrade2: async (
    spread_type,
    sell_call_strike_price,
    sell_put_strike_price
  ) => {
    if (cancelToken) {
      cancelToken.cancel("New request generated");
      console.log("Request canceled: New request generated");
    }
    cancelToken = axios.CancelToken.source();
    try {
      const url = `https://incometrader.com/get_ic_returns_test.php?spread_type=${spread_type}&sell_call_strike_price=${sell_call_strike_price}&sell_put_strike_price=${sell_put_strike_price}`;
      const response = await axios.get(url, { cancelToken: cancelToken.token });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Request error:", error);
      }
    }
    // console.log("payload", payload);
  },
  get_option_data_async: async () => {
    // console.log("payload", payload);
    const response = await axios.get(`${get_option_data}`);
    return response;
  },

  getSpxPrice: async () => {
    try {
      const url = "https://incometrader.com/get_price_quote.php?symbol=SPX";
      const response = await fetch(url);

      if (response.status === 200) {
        const newQuotePrice = await response.json();

        // SPX
        let dataSpx = false;
        if ("SPX" in newQuotePrice) {
          dataSpx = newQuotePrice["SPX"];
        } else if ("^SPX" in newQuotePrice) {
          dataSpx = newQuotePrice["^SPX"];
        }

        let price = false;
        let time = false;
        if (typeof dataSpx === "object") {
          price = parseFloat(dataSpx["last"]);
          time = dataSpx["lasttradedatetime"];
        }
        // dataSpxPrice.value = spxClosing;
        let data = { price, time };
        console.log("spxClosing response", data);
        return data;
      } else {
        console.log(
          `Failed to fetch SPX price. Status code: ${response.status}`
        );
        return false;
      }
    } catch (error) {
      console.log(`Error in getSpxPrice: ${error}`);
      return false;
    }
  },
};
