<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_335_11784)">
      <path
        d="M11.334 2.0015C11.5091 1.82641 11.7169 1.68751 11.9457 1.59275C12.1745 1.49799 12.4197 1.44922 12.6673 1.44922C12.9149 1.44922 13.1601 1.49799 13.3889 1.59275C13.6177 1.68751 13.8256 1.82641 14.0007 2.0015C14.1757 2.1766 14.3146 2.38447 14.4094 2.61324C14.5042 2.84202 14.5529 3.08721 14.5529 3.33484C14.5529 3.58246 14.5042 3.82766 14.4094 4.05643C14.3146 4.28521 14.1757 4.49307 14.0007 4.66817L5.00065 13.6682L1.33398 14.6682L2.33398 11.0015L11.334 2.0015Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_335_11784">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import { watch, ref } from "vue";
import store from "@/store";
export default {
  name: "EditSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
