<template>
  <svg
    class="sm:w-5 sm:h-5 w-4 h-4"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0912 0.146751C4.59125 0.175474 0.114809 4.69891 0.143532 10.1988C0.172256 15.6988 4.69569 20.1752 10.1956 20.1465C15.6955 20.1178 20.172 15.5943 20.1433 10.0944C20.1145 4.59447 15.5911 0.118028 10.0912 0.146751ZM8.16954 15.157L3.14349 10.1832L4.54611 8.76583L8.15476 12.327L15.705 4.6975L17.1224 6.11011L8.16954 15.157Z"
      :fill="activeColor"
    />
  </svg>
</template>
<script>
import store from "@/store";
import { watch, ref } from "vue";

export default {
  name: "GrayCheckbox",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "gray" : "#E7E7E7");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "gray" : "#E7E7E7";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
