<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_224_1191)">
      <path
        d="M8 0.702148V15.3688"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3333 3.36816H6.33333C5.71449 3.36816 5.121 3.614 4.68342 4.05158C4.24583 4.48917 4 5.08266 4 5.7015C4 6.32034 4.24583 6.91383 4.68342 7.35141C5.121 7.789 5.71449 8.03483 6.33333 8.03483H9.66667C10.2855 8.03483 10.879 8.28066 11.3166 8.71825C11.7542 9.15583 12 9.74933 12 10.3682C12 10.987 11.7542 11.5805 11.3166 12.0181C10.879 12.4557 10.2855 12.7015 9.66667 12.7015H4"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_224_1191">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0351562)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import { watch, ref } from "vue";
import store from "@/store";
export default {
  name: "CreditReturnsSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
