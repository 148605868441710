<template>
  <!-- <div> -->
  <div
    v-if="!showLoginScreen"
    class="flex  lg:flex-row flex-col h-full w-full sm:justify-center sm:items-center lg:space-x-40 sm:space-x-0 lg:space-y-0 sm:space-y-10 space-y-0"
  >
    <div class="lg:flex hidden">
      <!--      <img class="" src="../../../assets/svgs/login.svg" />-->
      <LoginPageBannerSvg></LoginPageBannerSvg>
    </div>

    <!-- <div v-if="!openEmail" class="flex justify-center w-full h-32 lg:hidden">
      <img
        class="w-[70vw] h-32"
        src="../../../assets/NewSvgCollection/Logo.svg"
      />
    </div> -->
    <img
      class="sm:w-[44vw] w-[36vw] sm:h-36 h-28 pb-6 md:hidden self-center"
      src="../../../assets/svgs/sidebar/CCLightLogo.svg"
    />
    <div
      class="flex justify-center items-center h-[60vh] lg:w-3/12 sm:w-4/6 w-full px-2 py-3"
    >
      <div
        :class="`${
          isLoader ? 'pointer-events-none bg-opacity-30' : ''
        } bg-white rounded-3xl space-y-3 w-full flex flex-col lg:p-6 sm:p-3 p-3`"
      >
        <div class="flex flex-col lg:space-y-1 sm:space-y-3 space-y-1.5">
          <span class="text-center text-[20px] font-semibold">
            Sign In
            <!-- <span class="font-bold"> CondorCash </span> -->
          </span>

          <span
            class="text-center text-lightTextColor text-[14px] lg:text-[15px]"
          >
            Create a new account?
            <router-link to="/sign-up">
              <span
                class="text-btnPrimary hover:text-blue-500 lg:text-[15px] text-[14px] font-semibold"
              >
                Sign Up
              </span>
            </router-link>
          </span>
        </div>

        <!-- <div class="flex flex-col lg:space-y-3 sm:space-y-4 space-y-1.5">
          <LoginButtons
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Continue with Google"
            svgType="google"
            @onClick="handleThirdPartyIntegration('google')"
          />

          <LoginButtons
            v-if="!openEmail"
            @onClick="enableForm"
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Continue with Email"
            svgType="email"
            buttonType="primary"
          />

          <LoginButtons
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Continue with Facebook"
            svgType="facebook"
            @onClick="handleThirdPartyIntegration('facebook')"
          />

          <LoginButtons
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Continue with LinkedIn"
            svgType="linkedin"
            @onClick="handleThirdPartyIntegration('linkedin')"
          />
        </div> -->

        <!-- <div
          :class="openEmail ? 'block' : 'hidden'"
          class="flex-row flex justify-center items-center"
        >
          <div class="border-t border-t-lightTextColor/20 w-full" />
          <p :class="` pl-3 pr-3  text-lightTextColor  text-[15px]`">or</p>
          <div class="border-t border-t-lightTextColor/20 w-full" />
        </div> -->

        <div v-if="openEmail">
          <div class="sm:space-y-3 space-y-1.5">
            <div
              class=""
              :class="
                validationObject.email.status
                  ? 'border-danger border-2 rounded-md'
                  : ' '
              "
            >
              <input
                @focus="
                  (isInputFocused = false),
                    (validationObject.email.status = false),
                    (messages = '')
                "
                type="email"
                id="email"
                v-model="email"
                class="pl-5 lg:h-12 sm:h-14 h-14 text-[14px] placeholder:text-lightTextColor bg-[#EFEFEF]/50 hover:bg-lightTextColor/10 border-none text-lightTextColor focus:border-none focus:ring-2 focus:outline-none rounded-lg ring-none block w-full p-2.5"
                :class="validationObject.email.status ? '' : ''"
                placeholder="Email Address "
                required
                @input="validInput"
                @keypress.enter="login"
              />
            </div>

            <!-- :class="{
                'border-red-500 border-2': validationObject.password.status,
                'border-blue-600 border-2': isInputFocused,
              }" -->
            <div
              class="hover:bg-[#EFEFEF]/80 bg-[#EFEFEF]/50 flex items-center space-x-1 flex-row rounded-lg lg:h-12 sm:h-14 h-14 w-full lg:p-2.5 sm:p-2 p-2"
              :class="`${
                validationObject.password.status
                  ? 'border-red-500 border-2'
                  : ''
              } ${isInputFocused ? 'border-blue-600 border-2' : ''}`"
            >
              <input
                @focus="
                  (isInputFocused = true),
                    (ReInputFocused = false),
                    (messages = ''),
                    (validationObject.password.status = '')
                "
                :type="!showPassword ? 'text' : 'password'"
                id="password"
                v-model="password"
                class="w-full  focus:ring-0 bg-transparent text-[14px] pl-3 border-none text-lightTextColor placeholder:text-lightTextColor"
                placeholder="Password"
                required
                @keypress.enter="login"
              />
              <svg
                v-if="showPassword"
                @click="enablePassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#000000"
                :class="'pr-2 hover:cursor-pointer h-7 w-7'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
              <svg
                v-else
                @click="enablePassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#000000"
                :class="'pr-2 hover:cursor-pointer h-7 w-7'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>

            <!-- <div class="lg:h-5 h-5 bg-green-50"> -->
            <span
              class=""
              :class="
                messages
                  ? 'text-danger  h-5 text-[12px] '
                  : 'text-[12px]  h-5 text-danger mb-20 bg-green-500'
              "
            >
              {{ messages }}
              <!-- invalid email -->
            </span>
            <!-- </div> -->
            <LoginButtons
              @onClick="login"
              customStyle="lg:h-12 sm:h-14 h-12 w-full"
              title="Login"
              buttonType=""
              :isLoading="isLoader"
              loaderText="Please wait"
              :message="messages ? messages : ''"
            />

            <!-- <CustomButton title="Create account" @onClick="signUpRequest" buttonType="" :isLoading="isLoader"
          :loaderText="'Please wait'" /> -->
          </div>

          <div class="w-full flex pt-2 justify-center">
            <p
              class="text-lightTextColor text-[12px] text-center px-4"
              @click="gotToForgetPassword"
            >
              <a
                class="hover:text-blue-500 hover:underline cursor-pointer text-btnPrimary"
                >Forgot password?</a
              >
            </p>
          </div>
        </div>
        <BaseModal v-if="toggleFeatureAvailable">
          <div class="h-full lg:w-[40vw] sm:w-[80vw] w-full overflow-hidden">
            <div class="w-full flex justify-end">
              <div
                class="w-fit cursor-pointer pt-2 pr-2"
                @click="toggleFeatureAvailable = false"
              >
                <CrossIconSvg stroke="#8a8a8a" />
              </div>
            </div>
            <div class="h-full w-full flex items-center justify-center">
              <div class="h-full w-full rounded-3xl sm:!p-8 p-4">
                <div
                  class="h-full w-full flex items-center flex-row justify-around sm:space-x-2 space-x-8"
                >
                  <div class="h-full w-full flex flex-col -whitespace-nowrap">
                    <span class="sm:text-[22px] text-[18px]"
                      >This page is under development</span
                    >
                    <span class="sm:text-[22px] text-[18px] font-bold"
                      >It will be available soon.</span
                    >
                  </div>
                  <div class="h-full w-2/6 justify-end flex">
                    <!-- width="120"
                    height="120" -->
                    <LoginBannerUstad />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex items-center justify-end px-4 pb-2">
              <div>
                <CustomButton
                  title="OK"
                  @onClick="toggleFeatureAvailable = false"
                  buttonType="primary"
                  customStyle="!px-6 !py-1.5"
                />
              </div>
            </div>
          </div>
        </BaseModal>
      </div>
    </div>
  </div>

  <div v-else>
    <AfterSignInWarning :isModal="showLoginScreen" @updateValue="handleUpdateValue"/>
  </div>
</template>

<script>
import userApi from "@/services/userApi";
import { afterSigninActivty } from "@/pages/userStory/login/afterSigninActivty";
import CustomButton from "../../../components/SharedComponents/CustomButton";
import LoginButtons from "../../../components/SharedComponents/LoginButtons";
// import router from "@/router";
// import store from "@/store";
import AfterSignInWarning from "@/pages/userStory/AfterSignInWarning.vue";
import store from "@/store";
import BaseModal from "@/components/SharedComponents/BaseModal.vue";
import CrossIconSvg from "../../../assets/svgs/CrossIconSvg.vue";
import LoginPageBannerSvg from "@/pages/userStory/login/LoginPageBannerSvg.vue";
import LoginBannerUstad from "@/pages/userStory/login/LoginBannerUstad.vue";
// import router from "@/router";
import { FACEBOOK_LOGIN, GOOGLE_LOGIN, LINKDIN_LOGIN } from "@/api/apiRoutes";
import tradeApi from "@/services/tradeApi";
export default {
  name: "LogIn",
  data() {
    return {
      isMobileDevice: /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone/i.test(
        navigator.userAgent
      ),
      // email: "mobeci6446@mcenb.com",
      email: "",
      password: "",
      toggleFeatureAvailable: false,
      isLoader: false,
      messages: "",
      openEmail: true,
      showPassword: true,
      alertType: "",
      validatedEmail: null,
      isInputFocused: false,
      showLoginScreen: false,
      validatedPassword: null,
      validationObject: {
        email: {
          status: false,
          message: "Please enter a valid email address",
          borderColorChnage: false,
        },
        password: {
          status: false,
          message: "Please enter a password",
          borderColorChnage: false,
        },
      },
    };
  },
  components: {
    LoginBannerUstad,
    LoginPageBannerSvg,
    AfterSignInWarning,
    BaseModal,
    CrossIconSvg,
    CustomButton,

    LoginButtons,
  },
  methods: {
    handleUpdateValue(value) {
      // This method will be called when the child emits an event
      console.log('Received value from child:', value);
      this.showLoginScreen = value
    },
    handleThirdPartyIntegration(val) {
      let redirectUrl;

      if (val === "google") {
        redirectUrl = GOOGLE_LOGIN;
      } else if (val === "facebook") {
        redirectUrl = FACEBOOK_LOGIN;
      } else if (val === "linkedin") {
        redirectUrl = LINKDIN_LOGIN;
      } else {
        console.error("Invalid integration type");
        return;
      }
      // Redirect to the specified URL
      window.location.href = redirectUrl;
    },
    hideLoginWarning() {
      this.showLoginScreen = false;
    },
    showErrors(message, alertType, alertStatus) {
      this.messages = message;
      this.alertStatus = alertStatus;
      this.alertType = alertType;
    },
    async loadingTradingData() {
      tradeApi
        .tradeDetails()
        .then((d) => {
          console.log("D.dta 3", d.data);
          store.commit("SET_TRADE_DATA_LIST", d.data.trades);
          // this.tradeData = d.data.trades
        })
        .catch((e) => {
          console.log(e);
        });
    },
    gotToForgetPassword() {
      this.$router.push("/forget-password");
    },
    enablePassword() {
      console.log("login click");
      this.showPassword = !this.showPassword;
    },

    async handleCheckIn(email){
      try {
        const checkInPayload = { email: email };
        const checkInResponse = await userApi.checkIn(checkInPayload);
        const userId = checkInResponse.data.user_id;
        localStorage.setItem("userId", userId);
        console.log("Check-in successful:", checkInResponse?.data);
      } catch (error) {
        console.log("Check-in failed:", error);
      }
     // this.$router.push({ path: "/", query: { ts: Date.now() } });

    },

    async login() {
      let isNotValid = false;

      if (!this.email && !this.passowrd) {
        this.showErrors("Please enter a correct email & password");
        isNotValid = true;
        this.validationObject.email.status = true;
        this.validationObject.password.status = true;
      } else if (!this.email && this.passowrd) {
        this.showErrors("Please enter your email");
        this.validationObject.email.status = true;
      } else if (!this.validatedEmail) {
        this.showErrors("Please enter a correct email");
        this.validationObject.email.status = true;
        console.log("validate email chcek", this.validatedEmail);
      } else if (!this.password) {
        this.showErrors("Please enter a password");
        this.validationObject.password.status = true;
        console.log("validate email chcek", this.validatedEmail);
      }

      if (isNotValid) {
        return;
      }

      let payload = {
        email: this.email,
        password: this.password,
      };
      console.log(payload);
      if (this.validatedEmail === true && this.password) {
        this.isLoader = true;
        try {
          const resp = await userApi.userSignIn(payload);
          this.isLoader = false;
          if (resp.data.status) {
            let userDetail = resp?.data?.user_detail;
            console.log("activation_time", userDetail?.activation_time);
            if (userDetail?.activation_time === 0) {
              store.state.emailBeforeSignup = userDetail?.email;
              this.showLoginScreen = true;
            } else {
              // Check-in API call after successful login
              this.handleCheckIn(this.email)
              // Proceed to the dashboard after successful login and check-in
              store.commit("SET_PROFILE_LOADING", true);
              const afterCall = () => {
                store.commit("SET_PROFILE_LOADING", false);
                afterSigninActivty(resp, false, afterCall);
                window.history.replaceState({}, document.title, "/");
                this.$router.push({path: "/", query: {ts: Date.now()}});
                this.$router.replace("/"); // Navigate to dashboard
                window.history.pushState({}, document.title, "/");
              };

              store.commit("SET_PROFILE_LOADING", true);
              afterSigninActivty(resp, false, afterCall);
              window.history.replaceState({}, document.title, "/");
              this.$router.push({path: "/", query: {ts: Date.now()}});
              this.$router.replace("/"); // Navigate to dashboard
              window.history.pushState({}, document.title, "/");
              this.loadingTradingData();

            }
          } else {
            this.messages = resp.data.message;
            this.validationObject.email.status = true;
            this.validationObject.password.status = true;
          }
        } catch (e) {
          console.log("Error: " + e.message)
        }
      }
    },
    enableForm() {
      console.log("login click");
      this.openEmail = true;
    },
    validateEmail(value) {
      if (
        /^[a-zA-Z0-9.'{|}~+-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/.test(
          value
        )
      ) {
        this.validatedEmail = true;
      } else {
        console.log("email not true");
        this.validatedEmail = false;
      }
    },
    validInput(event) {
      this.validationObject.email.status = "";
      // this.validationObject.password.status = "";
      // this.messages = "";
      this.email = event.target.value;
      this.validateEmail(event.target.value);
    },
    validatePassword() {
      if (this.$refs.password.value == 0) {
        this.$refs.password.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
      }
    },
  },
};
</script>
