// import axios from 'axios'
import router from "@/router";

import axios from "axios";
import store from "@/store";

// Add a request interceptor

axios.interceptors.request.use(
  (req) => {
    let headers = {
      ...req.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    if (headers) {
      req.headers = headers;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      console.log("success");
    }
    return res;
  },
  (err) => {
    if (
      err.response.status === 401
      // || err.response.status === 404
    ) {
      console.log("hello==>");
      localStorage.clear();
      console.log(
        "router.currentRoute",
        router.currentRoute.path,
        window.location.pathname
      );
      if (router.currentRoute?.path != "/login") {
        router.push("/login");
        localStorage.clear();
        store.commit("SET_TOGGLE_THEME", false);
      }
      //
      //  router.push("/signin");
    }
    return Promise.reject(err);
  }
);

export default axios;
