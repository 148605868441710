<template>
  <div></div>
</template>
<script>
import userApi from "@/services/userApi";
import router from "@/router";
import {afterSigninActivty} from "@/pages/userStory/login/afterSigninActivty";
import store from "@/store";

export default {
  name: "EmailLinkAccess",
  mounted() {
    const queryParams = this.$route.query;
    let token = queryParams.token;
    localStorage.setItem("token", token);
    userApi
        .verifyUserEmail(token)
        .then((d) => {
          console.log("verifyUserEmail", d?.data);
          // afterSigninActivty(d,token);
          if (d?.data.status) {
            const afterCall = () => {
              console.log("access token complete")
              store.commit("SET_PROFILE_LOADING", false)
              store.commit('SET_INITIAL_SUCCESS', true)
              userApi
                  .userDetails()
                  .then((resp) => {
                    console.log("login resp", resp);

                    store.commit("SET_USER_DETAIL", resp.data.user_detail);
                    store.commit("SET_PROFILE_LOADING", false);
                  })
                  .catch((e) => {
                    console.log(e);
                    store.commit("SET_PROFILE_LOADING", false);
                  });
            }
            store.commit("SET_PROFILE_LOADING", true);
            afterSigninActivty(d, true, afterCall);


          } else {
            router.push("/404");
          }
        })
        .catch((e) => {
          console.log(e);
          router.push("/404");
          // alert(`somewhat ${e}`)
        });
    console.log("queryParams", queryParams);
  },
};
</script>
