<template>
  <div class="w-full flex justify-center sm:pb-20">
    <div v-if="!store.getters.getTradeHistoryLoader" class="w-full flex justify-center">
      <div v-if="getTradeHistory.length" class="w-full space-y-10 pt-2">
        <!-- Trade History -->
        <div class="flex w-full justify-center">
          <div class="sm:w-8/12 w-full">
            <CardBox customClass=" rounded-lg space-x-3 w-full flex items-center">
              <div :class="typeView == 'chart'
      ? ' bg-btnPrimary/10 dark:bg-[#87A1CC1A] dark:text-[#A9C9FF] -hover:opacity-80 w-full sm:text-[14px] text-[13px] p-2 flex justify-center text-btnPrimary font-[600] rounded-md'
      : 'bg-[#ECECED] dark:bg-[#87A1CC1A] w-full p-2 hover:opacity-70 sm:text-[14px] text-[13px] cursor-pointer flex justify-center text-[#B0B0B0] font-[600] rounded-md'
      " @click="getValue('chart')">
                Chart View
              </div>
              <div :class="typeView == 'tabular'
      ? 'bg-btnPrimary/10 dark:bg-[#87A1CC1A] dark:text-[#A9C9FF] -hover:opacity-80 w-full sm:text-[14px] text-[13px]  p-2 flex justify-center text-btnPrimary font-[600] rounded-md'
      : 'bg-[#ECECED] dark:bg-[#87A1CC1A] w-full p-2 hover:opacity-70 sm:text-[14px] text-[13px] cursor-pointer flex justify-center text-[#B0B0B0] font-[600] rounded-md'
      " @click="getValue('tabular')">
                Tabular View
              </div>
            </CardBox>
          </div>
        </div>
        <CardBox v-if="typeView == 'chart'"
          customClass=" rounded-2xl w-full sm:!p-6 !p-1 !py-4 sm:!py-7  items-center  ">
          <div class="sm:border-b-2 border-b mb-3">
            <div class="w-full flex sm:pb-3 pb-2 space-x-2 items-center pl-4">
              <img src="../../assets/svgs/calendar.svg" />
              <p class="font-[700] dark:text-white">{{ formattedTimeFrame }}</p>
            </div>
          </div>
          <ApexChart :options="chartOptions" :series="chartSeries" type="line" height="350" />
          <div class="flex justify-center items-center -mt-2 text-[#6D6D6D] text-[12px]">
            <p>Date</p>
          </div>
        </CardBox>
        <div class="flex justify-center w-full" v-else>
          <div class="sm:w-8/12 w-full">
            <CardBox customClass=" rounded-2xl  w-full   items-center  ">
              <div class="space-y-0">
                <div class="flex space-x-0 w-full h-14" :class="store.getters.getTheme ? 'space-x-1' : ''">
                  <div
                    class="text-white text-[14px] dark:border-btnPrimary w-full font-bold border p-2 flex justify-center items-center rounded-tl-xl border-[#E7E7E7] bg-btnPrimary dark::bg-[#0853CE]">
                    Date
                  </div>
                  <div
                    class="text-white text-[14px] dark:border-btnPrimary w-full font-bold border p-2 flex justify-center items-center rounded-tr-xl border-[#E7E7E7] bg-btnPrimary dark::bg-[#0853CE]">
                    Risk Factor
                  </div>
                </div>
                <div class="relative">
                  <div class="flex space-x-0 w-full " v-for="(items, index) in getTradeHistory" :key="index">
                    <div class="flex w-full border border-b-gray-300 dark:border-lightTextColor hover:bg-btnPrimary/10">
                      <div :class="`${dataFile.calculation_date == items.calculation_date
      ? ''
      : ''
      }  text-[14px] font-medium dark:text-white flex items-center w-full justify-center p-3`">
                      {{ formattedDate(items.calculation_date) }}
                    </div>
                    <div :class="`${dataFile.calculation_date == items.calculation_date
      ? ''
      : ''
      }  w-full py-3 flex justify-between `">
                      <div class="sm:px-0 py-0 h-10 relative flex justify-between items-end w-full space-x-3">
                        <p class="text-[23px] font-medium ml-2 "
                          :class="items.score > 30 ? 'text-red-500' : (items.score > 10 && items.score <= 30) ? 'text-[#E1A325]' : 'text-green'">
                          {{ items.score.toFixed(2) }}%
                        </p>
                        <div class="flex w-full h-full flex-col justify-end mb-1.5">
                          <div class="move-image" :style="{ left: `${angleToPercentage(items.score)}%` }">
                            <!-- {{ showImage(items.score) }} -->
                            <svg width="25" height="25" viewBox="0 0 37 37" :fill="items.score <= 10 ? '#3EAF3F' : items.score > 30 ? '#E14942' : '#E1A325' "
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_340_11789)">
                                <path
                                  d="M19.5896 25.7555C19.3241 26.021 18.971 26.1804 18.5963 26.204C18.2216 26.2276 17.8512 26.1136 17.5546 25.8835L17.4097 25.7555L8.15969 16.5055L8.03173 16.3606L7.94849 16.2419L7.86524 16.0939L7.83903 16.0384L7.7974 15.9351L7.74807 15.7686L7.73265 15.6869L7.71724 15.5944L7.71107 15.5065L7.70799 15.4156L7.71107 15.3246L7.71878 15.2352L7.73265 15.1427L7.74807 15.0625L7.7974 14.896L7.83903 14.7927L7.94694 14.5892L8.04715 14.4505L8.15969 14.3256L8.30461 14.1977L8.42332 14.1144L8.57132 14.0312L8.62682 14.005L8.73011 13.9633L8.89661 13.914L8.97832 13.8986L9.07082 13.8832L9.15869 13.877L9.24965 13.8739L27.7497 13.8739C29.0632 13.8739 29.7492 15.394 28.9568 16.376L28.8396 16.5055L19.5896 25.7555Z"
                                  />
                              </g>
                              <defs>
                                <clipPath id="clip0_340_11789">
                                  <rect width="37" height="37" fill="white" transform="translate(37 37) rotate(180)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <!-- <div class="w-full flex h-1.5 rounded-full">
                            <div class="h-full w-[15%] bg-green rounded-l-full " />
                            <div class="h-full w-[25%] bg-[#E1A325] " />
                            <div class="h-full w-[60%] bg-red-500 rounded-r-full " />
                          </div> -->
                          <!-- <div :class="`flex w-100%  justify-between ${!Svg ? 'mt-5' : ''}`"> -->
                          <div :class="`flex w-100% h-1.5  justify-between rounded-full`">
                            <div :class="items.score <= 10 ? 'w-[25%]' : 'w-[20%]'"
                              class="rounded-l-full  bg-[#3EAF3F]" />
                            <div
                              :class="items.score > 10 && items.score <= 30 ? 'w-[40%]' : 'w-[35%]'"
                              class="bg-[#E1A325]" />
                            <div :class="items.score > 30 ? 'w-[50%]' : 'w-[40%]'"
                              class="bg-[#E14942] rounded-r-full " />
                          </div>
                        </div>
                        <div class="cursor-pointer mb-0.5 hover:bg-gray-200 hover:dark:bg-gray-600 h-fit rounded-full p-0 pr-2"
                          @click="getFileData(items)">
                          <!-- <img src="../../assets/svgs/sidebar/info.svg" class="h-3 w-3" /> -->
                          <scgCompSelect v-if="store.getters.getTradeHistory && dataFile.calculation_date == items.calculation_date" />
                          <div v-else>
                            <svgCompDark v-if="store.getters.getTheme" />
                          <svgComp v-else />
                          </div>
                        </div>
                        <div class="absolute z-50 sm:w-[21vw] w-[52vw] sm:left-[21vw] -left-[13vw]"
                          v-if="store.getters.getTradeHistory">
                          <TradeCard v-if="dataFile.calculation_date ==
      items.calculation_date
      " :data="dataFile" :Svg="showSvg" />
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBox>
          </div>
        </div>
      </div>
      <div v-else class="w-full flex justify-center mt-52 items-center">
        <div class="space-y-3">
          <img src="../../assets/svgs/slateDarkmode.svg"  v-if="store.getters.getTheme"  />
          <img src="../../assets/svgs/clipBoard.svg" v-else />
          <div class="text-center">
            <p class="text-[#5D5D5D] dark:text-white text-[12px]">
              No open trades history at the moment
            </p>

            <!-- <p class="text-[#0B3D90] font-bold cursor-pointer hover:underline" >Add a New
              Trade</p> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full h-full flex justify-center mt-60 items-center">
      <div>
        <LoaderComponent />
      </div>
    </div>
  </div>
</template>

<script>
import CardBox from "@/components/SharedComponents/CardBox.vue";
import TradeCard from "@/components/TradeCard.vue";
import store, { getUserDetailsFromStore } from "@/store";
import LoaderComponent from "@/components/SharedComponents/LoaderComponent.vue";
import tourService from "@/services/tourService";
import svgComp from "./svgComp.vue"
import svgCompDark from "./svgCompDark.vue"
import scgCompSelect from "./scgCompSelect.vue"
export default {
  name: "TradeHistory",
  components: {
    CardBox,
    TradeCard,
    LoaderComponent,
    svgComp,
    svgCompDark,
    scgCompSelect
  },
  setup() {
    return {
      store,
    };
  },

  data() {
    return {
      arrowColor: "",
      isAuthenticated: localStorage.getItem("token"),
      options: [
        { value: "volvo", text: "Volvo" },
        { value: "saab", text: "Saab" },
        { value: "opel", text: "Opel" },
        { value: "audi", text: "Audi" },
      ],
      selectedValue: "volvo",
      formattedTimeFrame: "",
      chartOptions: {
        // ApexCharts options go here
        // Example:
        chart: {
          id: "apexchart-example",
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          enabled: true,
        },
      },
      chartSeries: [
        // ApexCharts data series go here
        // Example:
        {
          name: "SPX",
          data: [],
        },
      ],
      typeView: "chart",
      dataFile: "",
      showSvg: false,
      showModal: false,
      // tourObj: {tradeHistory:false},
      tourObj: { tradeHistory: false },
    };
  },
  mounted() {
    let stockData = this.getTradeHistory;
    
    if (stockData.length > 0) {
      console.log("stockData", store.getters.getTradeHistoryData);
      this.getTradeHistory.forEach((item)=>
    // this.arrowColor = item.score
    console.log("item.schore", item)
    )
      this.setTradeHistoryInComponent(stockData);
    } else {
      // Get the current date
      const currentDate = new Date();

      // Calculate the start and end dates one month ago
      const startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 1);

      const endDate = new Date(currentDate);

      // Format the dates
      this.formatEndStartDates(startDate, endDate);
    }
  },

  methods: {
    moveImage(number) {
      // Increment position value (0 to 100) to move the image
      console.log('hello percentage', number)
      // Reset position after reaching 100 (optional)
      if (
        number <= 10
      ) {
        this.arrowColor = "#3EAF3F";
      } else if (
        number > 10 &&
        number <= 30
      ) {
        this.arrowColor = "#E1A325";
      } else {
        this.arrowColor = "#E14942";
      }
    },
    formatEndStartDates: function (startDate, endDate) {

      // Log the formatted date range
      let formattedTimeRange = (`${endDate.toLocaleDateString()} - ${startDate.toLocaleDateString()}`);
      this.formattedTimeFrame = formattedTimeRange;
    },
    getValue(item) {
      this.typeView = item;
      console.log("BTN", item);
    },
    formattedDate(d) {
      const dateObj = new Date(d);
      const day = dateObj.getDate();
      const month = this.getMonthName(dateObj.getMonth());
      const year = dateObj.getFullYear();
      return `${day} ${month}, ${year}`;
    },
    getMonthName(monthIndex) {
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      return months[monthIndex];
    },
    angleToPercentage(angle) {
      console.log("angle==>", angle);
      // this.moveImage(angle)
      

      const maxAngleSemiCircle = angle < 30.1 ? 50 : 65;
      const percentage = (angle / maxAngleSemiCircle) * 100;
      console.log("percentage 1", angle.toFixed(2));
      return Number(percentage);
      // return Number(percentage);
    },
    setTradeHistoryInComponent(stockData) {
      if (stockData.length > 0) {
        // Extract closing prices and dates
        const closingPrices = stockData.map((item) => item?.score);
        const dates = stockData.map((item) =>
          new Date(item?.calculation_date)
        );
        console.log("closingPrices", closingPrices);
        console.log("dates", dates);

        // Set min and max values dynamically
        let minimumDate = Math.min(...dates);
        this.chartOptions.xaxis.min = minimumDate;
        let maximumDate = Math.max(...dates);
        this.chartOptions.xaxis.max = maximumDate;
        this.formatEndStartDates(new Date(minimumDate), new Date(maximumDate));
        // Update chartSeries with the closing prices and dates
        this.chartSeries = [
          {
            name: "Score",
            data: dates.map((date, index) => [date, closingPrices[index]]),
          },
        ];
      }
    },
    onTourEnd() {
      this.tourObj.tradeHistory = true;

      localStorage.setItem("appTour", JSON.stringify(this.tourObj.tradeHistory = true));
      // this.$router.push("/active-trades");
    },

    getFileData(item) {
      this.dataFile = item;
      this.showModal = true;
      store.commit("SET_TRADE_HISTORY", true);
    },
    startTradeHistoryTour() {
      setTimeout(() => {
        const steps = [
          {
            element: "#historyElement1",
            intro:
              "Click on the 'Add a New Trade' link to start adding your trades here and benefit from our proprietary risk management tool.",
          },
          {
            element: "#historyElement2",
            intro:
              "Every night we store your trade management number and display them here for reporting purpose. You can see them in charting and tabular view.\n Currently, it is empty because you just added up a trade, and it has no historic data.",
          },
        ];
        tourService.startTour(steps, this.onTourEnd);
      }, 600);
    },
  },
  computed: {
    getTradeHistory() {
      return store.getters?.getTradeHistoryData ?? [];
    },
    tradeData() {
      return store.getters.getTradeData;
    },
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      let user = this.userDetails;
      console.log("userDetails", this.userDetails.profile_status);
      return user?.is_profile_complete ?? false;
    },
  },
  watch: {
    getTradeHistory() {
      let stockData = this.getTradeHistory;

      this.setTradeHistoryInComponent(stockData);
    },
    getTradeHistoryData() {
      console.log("this.getTradeHistoryData", this.getTradeHistoryData);
    },
    tradeData: {
      immediate: true, // Run the handler immediately
      handler(newValue) {
        console.log("Noicee", this.getTradeHistory);
        if (
          this.isAuthenticated &&
          !this.tourObj?.tradeHistory &&
          this.isProfileComplete
        ) {
          if (newValue && newValue.length > 0) {
            console.log("this.tradeData", newValue);
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.progressLeft {
  border-bottom-left-radius: 20px;
}

.progressRight {
  border-bottom-right-radius: 20px;
}

.move-image {
  position: relative;
  /* To allow positioning relative to this element */
  /* transition: left 0.3s ease;  */
  /* Transition effect for smooth movement */
}
</style>