<template>
  <div
    :class="error ? 'border-2 rounded-md border-red-700' : 'border-0'"
    class="flex h-[5vh] rounded-md bg-black/20 dark:bg-textInputDark dark:bg-opacity-70"
  >
    <div
      v-if="firstimageSrc"
      class="flex px-2 items-center justify-center bg-gray-300 dark:bg-gray-700 rounded-l-md"
    >
      <img :src="firstimageSrc" :class="imageClass" />
    </div>
    <input
      ref="input"
      v-bind="inputProps"
      :v-model="value"
      :type="type"
      :value="value"
      :name="name"
      :autocomplete="false"
      :readonly="readOnly"
      @input="handleChange"
      :v-mask="mask"
      @click="onClickHandler"
      @keypress.enter="onKeyPressEnter"
      @keydown.space="handleSpaceKey"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :class="`pl-3 h-full no-spinners no-focus-outline ${
        secondImageSrc || firstimageSrc ? '' : 'rounded-md'
      } focus:ring-0 dark:text-white dark:bg-textInputDark dark:bg-opacity-70 border border-none focus:border-none outline-none w-full xl:placeholder:text-[13px] lg:placeholder:text-[11px] sm:placeholder:text-[10px] placeholder:text-[10px] placeholder:text-[#888] dark:placeholder:text-darkTextColor xl:text-[15px] lg:text-[12px] sm:text-[11px] text-[13px] text-black opacity-80 ${mainClass}`"
    />
    <div
      @click="secondImageClicked"
      v-if="secondImageSrc"
      class="flex px-2 items-center justify-center bg-gray-300 dark:bg-gray-700 rounded-r-md"
    >
      <img :src="secondImageSrc" :class="imageClass" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",

  props: {
    id: String,
    name: {
      type: String,
      default: "",
    },
    firstimageSrc: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: "",
    },
    secondImageSrc: {
      type: String,
      default: "",
    },

    imageClass: {
      type: String,
      default: "w-6 h-6",
    },
    className: String,
    onClick: Function,
    value: {
      type: String,
      default: "",
    },
    // numbPattern: {
    //   type: String,
    //   default: "",
    // },
    handleChange: {
      type: Function,
      default: () => {},
    },
    placeholder: String,
    secondSvg: String,
    mainClass: String,
    type: {
      type: String,
      default: "text",
    },
    maxLength: {
      type: Number,
      default: 300,
    },
    disabled: Boolean,
    onkeyDownhandler: Function,
    onInputClick: {
      type: Function,
      default: () => {},
    },
    onKeyPress: Function,
    autoFocus: Boolean,
    inputProps: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onKeyPressEnter(event) {
      if (event.key === "Enter") {
        console.log("Enter");
        // Trigger the sendOTPHandler function when Enter key is pressed
        this.$emit("keypressEnter");
      }
    },
    secondImageClicked() {
      this.$emit("onSecondImageClicked");
    },
    onClickHandler(){
      this.$emit("onClickHandler")
    },
    handleSpaceKey(event) {
      if (!this.value.trim()) {
        event.preventDefault();
      }
    }
  },
  computed: {},
};
</script>

<style scoped>
/* Add your styles here */
.no-spinners {
  /* For Firefox */
  -moz-appearance: textfield;

  /* For Chrome, Safari, and Opera */
  -webkit-appearance: none;
  margin: 0;

  /* Hide the arrows in Chrome and Safari */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .no-focus-outline:focus {
    outline: none !important;
  }
}
</style>
