<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_224_1186)">
      <path
        d="M14.1401 10.6285C13.7159 11.6315 13.0526 12.5154 12.208 13.2027C11.3634 13.8901 10.3632 14.3602 9.29502 14.5717C8.2268 14.7833 7.12301 14.73 6.08016 14.4164C5.03731 14.1028 4.08715 13.5386 3.31274 12.773C2.53834 12.0074 1.96327 11.0637 1.63782 10.0245C1.31237 8.98531 1.24644 7.8822 1.4458 6.81163C1.64515 5.74106 2.10373 4.73563 2.78143 3.88323C3.45913 3.03083 4.33532 2.35742 5.3334 1.92188"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6667 8.03483C14.6667 7.15935 14.4942 6.29244 14.1592 5.48361C13.8242 4.67477 13.3331 3.93984 12.714 3.32079C12.095 2.70173 11.3601 2.21067 10.5512 1.87563C9.74239 1.5406 8.87548 1.36816 8 1.36816V8.03483H14.6667Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_224_1186">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0351562)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import store from "@/store";
import { watch, ref } from "vue";

export default {
  name: "TradeRiskCalculatorSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
