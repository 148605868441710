<template>
  <div class="w-full relative bg-white dark:bg-compBgDark/20 rounded-3xl">
    <div class="flex flex-row items-center justify-between">
      <p
      class="font-bold sm:text-[22px] text-[20px] pt-5 pl-8 pb-3 dark:text-white/80"
    >
      ${{ spreadType }} SPX Iron Condor
    </p>
    <div @click="modalOpenHandler" :class=" spreadType === '10.00' && (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'blur-[3px] select-none  mr-5'  : 'cursor-pointer mr-5'">
      <img v-if="!get_theme"  src="../../assets/images/thinkorswim.png" class="h-6"/>
      <img v-else src="../../assets/images/dashboard/CC-LogosDark/thinkSwim.svg" class="h-6"/>
    </div>
  </div>
  <div v-if="spreadType === '10.00' && (userDetails.subscription === 4 || userDetails.subscription === 5 )" 
  class="absolute w-full h-[25vh] flex items-center space-y-6 flex-col pt-[5rem] justify-center">
    <div class="bg-white z-10 border p-3 border-none shadow-lg rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>
    </div>
    <div>
      <button @click="gotoSetting" class="custom-button">Upgrade Plan</button>
</div>    
    

  </div>
    <CardBox
      :customClass="`rounded-b-3xl relative w-full sm:px-0 sm:py-0 space-y-2 ${
        !isCompleteProfile
          ? 'blur-[3px] select-none'
          : spreadType === '10.00' && (userDetails.subscription === 4 || userDetails.subscription === 5 )
          ? 'blur-[3px] select-none'
          : ''
      } items-center `"
    >
       
      <div
        class="px-2 py-2 sm:px-0 sm:py-0"
        v-for="(items, index) in rows"
        :key="index"
      >
        <div class="w-full sm:px-8 sm:pt-6">
          <div
            class="border-b border-b-borderColorLight dark:border-b-compBgDark/40 sm:pb-5 pb-1 w-full flex justify-between"
          >
            <div
              class="sm:space-y-0 space-y-1 flex flex-col justify-between sm:py-0 py-2"
            >
              <Badge :name="items.side" />

              <p
                class="text-[14px] mt-1 text-[#454545] font-semibold dark:text-white/70"
              >
                {{ spreadType === '10.00' &&  (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'hidden' :  items.date }}
              </p>

              <div class="sm:hidden flex items-center space-x-1">
                <img
                  src="../../assets/svgs/alaram.svg"
                  class="fill:green-400"
                />
                <p class="text-[14px] text-[#888888]">{{ spreadType === '10.00' &&  (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'hidden' :  items.days }}</p>
              </div>
            </div>

            <div class="sm:flex hidden items-center space-x-1">
              <img src="../../assets/svgs/alaram.svg" />
              <p class="text-[14px] text-[#888888] dark:text-white/70">
                {{spreadType === '10.00' &&  (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'hidden' :  items.days }}
              </p>
              <!--              <p class="text-[14px] text-[#888888] dark:text-white/70">Days</p>-->
            </div>

            <div>
              <img />
              <div
                class="flex items-start space-x-3 sm:py-0 py-1 -bg-yellow-500"
              >
                <img
                  v-if="items.type === 'CALLS'"
                  src="../../assets/svgs/calls.svg"
                  class="mt-1"
                />
                <img
                  v-if="items.type === 'PUTS'"
                  src="../../assets/svgs/puts.svg"
                  class="mt-1"
                />
                <div>
                  <div class="flex">
                    <p class="text-[10px] font-bold dark:text-white/70">$</p>
                    <p class="text-[16px] font-bold dark:text-white/70">
                      <!-- {{ items?.price }} -->
                      {{spreadType === '10.00' && (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'hidden' :  typeof items?.price === "number"
                          ? Number(items.price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "hidden"
                      }}
                    </p>
                  </div>
                  <p
                    class="text-[12px] text-[#888888] font-bold dark:text-white/70"
                  >
                    {{ items?.type }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="rounded-b-3xl border-t-borderColorLight w-full px-6 py-4 bg-btnPrimary/10 flex justify-between items-center"
      >
        <div>
          <p class="font-semibold text-[17px] dark:text-white">
            Credit Amount for IC
          </p>
          <p class="text-[11px] text-[#888888]">
            Last Updated: {{ spreadType === '10.00' &&  (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'hidden' :  lastUpdate }}
          </p>
        </div>
        <div class="flex">
          <p class="dark:text-white/70">$</p>
          <p class="text-[22px] font-bold dark:text-white/70"
          :class="spreadType === '10.00' && (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'blur-sm' : ''  "
          >
            <!-- {{ ic }} -->
            {{spreadType === '10.00' && (userDetails.subscription === 4 || userDetails.subscription === 5 ) ? 'hidden' :  typeof ic === "number" ? ic?.toFixed(2) : "hidden" }}
          </p>
        </div>
      </div>
    </CardBox>
  </div>
</template>
<script>
import router from '@/router';
import Badge from "../../components/SharedComponents/Badge.vue";
import CardBox from "../../components/SharedComponents/CardBox.vue";
import store, {getUserDetailsFromStore} from "../../store";
export default {
  name: "SpxCondorCard",
  components: { Badge, CardBox },
  props: {
    rows: {},
    spreadType: {
      type: String,
      default: "5.00",
    },
    ic: {
      type: Number,
      default: 0.0,
    },
    lastUpdate: {
      type: String,
      default: "",
    },
    isCompleteProfile: {
      type: Boolean,
    },
  },
  mounted() {
    console.log("spxCondor", this.userDetails);
    console.log("store.getters.isStandardProduct", store.getters.isStandardProduct);
  },
  computed: {
    isStandardPlan() {
      return store.getters.isStandardProduct;
    },
    userDetails() {
      return getUserDetailsFromStore();
    },
    get_theme(){
      return store.getters.getTheme
    }
  },
  methods:{
    gotoSetting(){
      store.commit("SET_TOGGLE_SETTINGS", true);
      router.push('/settings');
      store.commit("SET_NAVBAR_LABEL", "Subscription Plans");
      store.commit("SET_SETTINGS_TAB", "Subscription Plans");
      store.commit("SET_SIDEBAR_TOGGLE", false);
    },
    modalOpenHandler() {
      if(this.spreadType === '10.00' && ( this.userDetails.subscription === 4 || this.userDetails.subscription === 5 )){
        store.commit("SET_BROOKER_MODAL", false);
        // store.commit("SET_IRON_CONDOR", this.spreadType);
      } else{
        store.commit("SET_BROOKER_MODAL", true);
        store.commit("SET_IRON_CONDOR", this.spreadType);
      }
    },
  }
};
</script>


<style scoped>
.custom-button {
    background-color: #1e4598; /* Blue background color */
    color: white; /* White text color */
    font-size: 18px; /* Font size */
    padding: 10px 20px; /* Padding around the text */
    border: none; /* Remove default border */
    border-radius: 25px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    position: relative; /* Position relative for the dashed border */
    outline: none; /* Remove outline */
    z-index: 2;
}
.custom-button::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px dashed #1E4598; /* Dashed border with blue color */
    border-radius: 30px; /* Rounded corners for the border */
    pointer-events: none; /* Make sure the border doesn't interfere with button interactions */
}

.custom-button:hover{
  background-color: hsl(221, 67%, 30%);
}

.custom-button:active{
  background-color: hsl(221, 67%, 40%);
}
</style>