<template>
  <div class="w-full h-full">
    <div class="w-full justify-center h-full items-center flex">
      <div
        class="h-full flex items-center justify-center md:w-7/12 w-full"
        v-if="isProfileNotComplete && !isBillingAndAddressHave"
      >
        <AddressComponent
          :isProfileNotComplete="isProfileNotComplete"
          v-if="showAddress"
        />
        <CreditCardComponent
          :isProfileNotComplete="isProfileNotComplete"
          v-if="showCard"
        />
      </div>
      <div
        class="h-full flex items-center justify-center md:w-7/12 w-full"
        v-if="isProfileNotComplete && isBillingAndAddressHave"
      >
        <div class="flex flex-col space-y-6 w-full">
          <NotificationComp
            desc="Redirecting to Subscription and plans section."
            :isSuccess="true"
            :bgColor="`${store.state.theme ? 'dark' : 'white'}`"
            :textColor="`${store.state.theme ? 'white' : 'black'}`"
            :onClick="handleClickNext"
            title="Step 3 of 4 completed!"
          />
          <CardBox
            customClass="border border-[#E7E7E7] rounded-3xl w-full !p-5 w-full flex justify-center items-center h-[50vh]"
          >
            <div class="w-full h-full flex justify-center items-center">
              <div class="flex flex-col space-y-3">
                <div class="flex justify-center">
                  <svg
                    width="50"
                    height="68"
                    viewBox="0 0 50 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M39.9324 28.9336V19.9778C39.9324 16.0052 38.3494 12.236 35.5439 9.4297C32.7387 6.623 28.971 5.03926 25 5.03926C21.0291 5.03926 17.2615 6.623 14.4561 9.4297C11.6506 12.236 10.0676 16.0052 10.0676 19.9778V28.9336H5.04309V19.9778C5.04309 14.6711 7.1548 9.62536 10.9034 5.8753C14.6518 2.12542 19.6955 0.0128498 25 0.0128498C30.3046 0.0128498 35.3481 2.12542 39.0966 5.87549C42.8452 9.62536 44.9569 14.6711 44.9569 19.9778V28.9336H39.9324Z"
                      :fill="`${store.state.theme ? 'gray' : '#32322D'}`"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M42.5921 26.7397H3.1436C2.33127 26.7397 1.59364 27.0714 1.05962 27.6056C0.525594 28.1398 0.19397 28.8779 0.19397 29.6906V65.0366C0.19397 65.8491 0.525594 66.5871 1.05962 67.1214C1.59364 67.6556 2.33127 67.9873 3.1436 67.9873H46.8566C47.6687 67.9873 48.4066 67.6556 48.9405 67.1214C49.4745 66.5871 49.806 65.8491 49.806 65.0366V29.6906C49.806 28.8779 49.4745 28.1398 48.9405 27.6056C48.4066 27.0714 47.6687 26.7397 46.8566 26.7397H44.7119H42.5921Z"
                      fill="#F9AA00"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25 49.3281C27.8208 49.3281 30.1213 47.0265 30.1213 44.2047C30.1213 41.3829 27.8208 39.0815 25 39.0815C22.1793 39.0815 19.8788 41.3829 19.8788 44.2047C19.8788 47.0265 22.1793 49.3281 25 49.3281Z"
                      fill="#946500"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24.9488 45.9031H25.0511C26.0274 45.9031 26.8262 46.7022 26.8262 47.6788V53.8707C26.8262 54.8473 26.0274 55.6465 25.0511 55.6465H24.9488C23.9726 55.6465 23.1738 54.8473 23.1738 53.8707V47.6788C23.1738 46.7022 23.9726 45.9031 24.9488 45.9031Z"
                      fill="#946500"
                    />
                  </svg>
                </div>
                <div class="flex items-center space-x-2">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2.9873C6.5 2.9873 2 7.4873 2 12.9873C2 18.4873 6.5 22.9873 12 22.9873C17.5 22.9873 22 18.4873 22 12.9873C22 7.4873 17.5 2.9873 12 2.9873ZM10 17.9873L5 12.9873L6.41 11.5773L10 15.1573L17.59 7.5673L19 8.9873L10 17.9873Z"
                      fill="#3EAF3F"
                    />
                  </svg>
                  <span class="text-[14px] dark:text-white font-semibold"
                    >Credit card information successfully validated.</span
                  >
                </div>
              </div>
            </div>
          </CardBox>
        </div>
      </div>
    </div>
    <div
      class="h-full flex items-center justify-center w-full pt-10"
      v-if="!isProfileNotComplete"
    >
      <div class="md:w-7/12 w-full h-full">
        <BillingComp />
      </div>
    </div>

    <!-- <BaseModal v-if="isModalOpen" @close="closeModal">
      <div class="w-[35vw] h-full">
        <div class="flex flex-col space-y-6 pt-3">
          <div class="w-full flex justify-end px-4">
            <CrossButtonSvg></CrossButtonSvg>
          </div>
          <div class="flex items-center justify-center w-full h-full">
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <h1 class="text-[22px] font-bold text-black text-center">
                  Congratulations!
                </h1>
                <p class="text-[14px] text-black text-center">
                  Your account has been successfully created.
                </p>
              </div>
              <div>
                <img src="@/assets/images/shakinghands.png" alt=""/>
              </div>
              <div class="pb-3">
                <CustomButton
                    button-type="success"
                    title="Complete your profile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal> -->
  </div>
</template>

<script>
import CreditCardComponent from "@/components/SettingsComp/CreditCardComponent.vue";
import AddressComponent from "@/components/SettingsComp/AddressComponent.vue";
import store, { getUserDetailsFromStore } from "@/store";
import BillingComp from "../SettingsComp/BillingComp.vue";
import NotificationComp from "../../components/SharedComponents/Notification.vue";
import CardBox from "@/components/SharedComponents/CardBox.vue";
// import BaseModal from "@/components/SharedComponents/BaseModal.vue";
// import CrossButtonSvg from "@/components/Modal/CrossButtonSvg.vue";
// import USFlag from "../../assets/images/USFlag.png"

export default {
  name: "AccountPage",
  setup() {
    return {
      store,
    };
  },
  components: {
    AddressComponent,
    CreditCardComponent,
    BillingComp,
    NotificationComp,
    CardBox,
    // CrossButtonSvg,

    // BaseModal,
    // USFlag
  },
  data() {
    return {
      isModalOpen: false,
      isAddressVerified: false,
      isVerificationFailed: false,
      profileUpdates: false,
      appTour: {
        dashboard: false,
        activeTrade: false,
        tradeHistory: false,
        riskCalculator: false,
        creditReturn: false,
      },
    };
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileNotComplete() {
      let user = getUserDetailsFromStore();
      console.log("userDetails", user);
      if (user?.is_profile_complete) {
        return false;
      }
      return true;
    },
    showAddress() {
      let user = getUserDetailsFromStore();
      if (this.isProfileNotComplete) {
        return user?.next_step == "address";
      } else {
        return true;
      }
    },
    showCard() {
      let user = getUserDetailsFromStore();
      if (this.isProfileNotComplete) {
        return user?.next_step == "billing";
      } else {
        return true;
      }
    },
    isBillingAndAddressHave() {
      let userDetails = getUserDetailsFromStore();
      console.log("userDetails", userDetails);
      let profile_status = userDetails["profile_status"];
      if (profile_status?.["billing"] && profile_status?.["address"]) {
        return true;
      }
      return false;
    },
  },
  methods: {
    handleClick() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleClickNext() {
      store.commit("SET_NAVBAR_LABEL", "Subscription Plans");
      store.commit("SET_SETTINGS_TAB", "Subscription Plans");
    },
  },
  watch: {
    isProfileComplete(n, o) {
      console.log(n, o);
      this.profileUpdates = !this.profileUpdates;
    },
    isBillingAndAddressHave(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.handleClickNext();
        }, 3000);
      }
    },
  },
};
</script>
