<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3334 4.66699H2.66671C1.93033 4.66699 1.33337 5.26395 1.33337 6.00033V12.667C1.33337 13.4034 1.93033 14.0003 2.66671 14.0003H13.3334C14.0698 14.0003 14.6667 13.4034 14.6667 12.667V6.00033C14.6667 5.26395 14.0698 4.66699 13.3334 4.66699Z"
      :stroke="isActive ? activeColor : disableColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6667 14V3.33333C10.6667 2.97971 10.5262 2.64057 10.2762 2.39052C10.0261 2.14048 9.687 2 9.33337 2H6.66671C6.31309 2 5.97395 2.14048 5.7239 2.39052C5.47385 2.64057 5.33337 2.97971 5.33337 3.33333V14"
      :stroke="isActive ? activeColor : disableColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import store from "@/store";
import { watch, ref } from "vue";

export default {
  name: "ActiveTradeSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
