var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center w-full h-full md:p-0 p-4"},[_c('div',{class:`${
      _vm.isLoading ? 'pointer-events-none bg-opacity-30' : ''
    } md:w-[30vw] w-full bg-white rounded-2xl p-7`},[(!_vm.isSuccess)?_c('div',{staticClass:"flex flex-col space-y-4"},[_c('span',{staticClass:"font-bold text-[22px] text-center"},[_vm._v("Reset your password")]),_c('div',{staticClass:"flex flex-col space-y-4"},[_c('span',{staticClass:"text-lightTextColor text-[13px]"},[_vm._v(" Use the form below to create a new password for the following email: ")]),_c('span',{staticClass:"font-bold text-btnPrimary text-[15px]"},[_vm._v(_vm._s(_vm.email))])]),_c('div',{staticClass:"flex flex-col space-y-1 pt-2"},[_c('span',{staticClass:"font-semibold text-[14px] text-lightTextColor"},[_vm._v("Create password")]),_c('span',{staticClass:"text-[13px] text-lightTextColor"},[_vm._v("To confirm with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.")]),_c('div',{staticClass:"flex flex-col space-y-1"},[_c('div',{staticClass:"flex flex-col space-y-3"},[_c('PasswordStrengthComponent',{attrs:{"password":_vm.newPassword},on:{"password-strength":_vm.passwordStrengthHandler}}),_c('TextInput',{attrs:{"value":_vm.newPassword,"name":"newPassword","handleChange":_vm.inputChange,"placeholder":"Choose a Strong Password","className":` h-[6vh] ${
                _vm.errorMessage ? 'border-2 border-danger rounded-md' : ''
              }`}}),_c('div',{staticClass:"flex flex-col space-y-1"},[_c('TextInput',{attrs:{"value":_vm.newPasswordConfirm,"name":"newPasswordConfirm","placeholder":"Confirm Password","handleChange":_vm.inputChange,"className":` h-[6vh] ${
                  _vm.errorMessage ? 'border-2 border-danger rounded-md' : ''
                }`},on:{"keypressEnter":_vm.resetPasswordHandler}}),(_vm.errorMessage)?_c('span',{staticClass:"text-danger text-[13px]"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1),_c('CustomButton',{attrs:{"title":"Confirm Reset Password","isLoading":_vm.isLoading,"loaderText":"Resetting your password","buttonType":"primary","customStyle":"h-[6vh]"},on:{"onClick":_vm.resetPasswordHandler}}),_c('div',{staticClass:"w-full flex items-center justify-center"},[_c('span',{staticClass:"text-[13px] text-btnPrimary hover:underline cursor-pointer",on:{"click":_vm.backToLogin}},[_vm._v("Back to Login")])])],1)])])]):_c('div',[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',{staticClass:"flex items-center justify-center space-x-3"},[_c('div',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z","fill":"#3EAF3F"}})])]),_vm._m(0)]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"pt-2"},[_c('CustomButton',{attrs:{"title":"Back to Login","buttonType":"primary","customStyle":"h-[5vh]"},on:{"onClick":_vm.backToLogin}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"text-[23px] font-bold text-green"},[_vm._v("Success!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center"},[_c('span',{staticClass:"text-center text-lightTextColor text-[14px]"},[_vm._v("Your password has been reset successfully.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center"},[_c('img',{attrs:{"src":require("../../../assets/svgs/resetSuccess.svg")}})])
}]

export { render, staticRenderFns }