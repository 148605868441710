<template>
  <div class="w-full space-y-6 " :class="!isProfileComplete ? ' sm:mt-0 -mt-4 sm:h-full h-[100vh] sm:overflow-y-hidden overflow-y-auto':''">
    <NotificationComp
      v-if="isProfileNotComplete"
      title="Enter a payment method"
      desc="Choose a plan to start using CondorCash website."
      :bgColor="`${store.state.theme ? 'darkWarning' : '#FFF9E3'}`"
      :textColor="`${store.state.theme ? 'textDark' : '#C27500'}`"
    />
    <NotificationComp
      v-if="seeDetailError"
      title="Payment verification failed."
      desc="Click here to see the details. Please try again, but if the problem persists, contact us immediately to get instant help from your account manager."
      bgColor="#FFE3E3"
      textColor="#C20000"
      fill="#C20000"
    />
    <CardBox
      :customClass="`${
        isModalForm
          ? '!bg-transparent'
          : 'border border-[#E7E7E7] rounded-3xl w-full !p-6'
      }`"
    >
      <div class="flex flex-col space-y-1 b ">
        <h1 class="text-black font-bold text-[17px] dark:text-white">
          Add a New Card 
        </h1>
        <span class="text-lightTextColor dark:text-darkTextColor text-[12px]">
          Enter your credit card information to get started your subscription.
        </span>
      </div>
      <div class="space-y-6 pt-6">
        <div v-if="validationError">
          <span class="text-[14px] text-[#FD3232]">{{ validationError }}</span>
        </div>
        <div v-if="successMessage">
          <span class="text-[14px] text-green">{{ successMessage }}</span>
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Card Number*</span
          >

          <TextInput
            v-if="isProfileComplete"
            placeholder="1234 1234 1234 1234"
            :value="formattedCardNumber"
            className="h-[5vh]"
            :class="{'cardNumber-border-cs': isCardEmpty}"
            name="cardNumber"
            :secondImageSrc="require(`../../assets/svgs/${showCardNumber ? 'eyeOpen' : 'eyeClose'}.svg`)"
            @keypressEnter="verifyCardHandler"
            @onClickHandler="resetCardNumber"
            @onSecondImageClicked="() => (this.showCardNumber = !this.showCardNumber)"
            :imageClass="`w-5 h-5 cursor-pointer`"
            type="tel"
            :maxLength="19"
            :handleChange="onChangeHandler"
          />
          <TextInput
            v-else
            placeholder="1234 1234 1234 1234"
            :value="formattedCardNumber"
            className="h-[5vh]"
            :class="{'cardNumber-border-cs': isCardEmpty}"
            name="cardNumber"
            :secondImageSrc="require(`../../assets/svgs/${showCardNumber ? 'eyeOpen' : 'eyeClose'}.svg`)"
            @keypressEnter="verifyCardHandler"
            @onClickHandler="resetCardNumber"
            @onSecondImageClicked="() => (this.showCardNumber = !this.showCardNumber)"
            type="tel"
            :maxLength="19"
            :handleChange="onChangeHandler"
            :imageClass="`w-5 h-5 cursor-pointer`"
            
          />
        </div>
        <div class="flex items-center space-x-4">
          <div class="flex flex-col space-y-1 w-6/12">
            <span
              class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >
              Expiration*
            </span>
            <div
              class="w-full flex items-center text-[#000000] h-[5vh] border-none rounded-md focus:outline-none space-x-3"
            >
              <div>
                <TextInput
                  placeholder="12"
                  v-model="month"
                  :maxLength="2"
                  id="cardMonth"
                  ref="cardMonth"
                  @keypressEnter="verifyCardHandler"
                  className="h-[5vh]"
                  name="month"
                  :class="{ 'month-border-cs': monthInvalid }"
                  type="tel"
                  :handleChange="onChangeHandler"
                />
              </div>
              <div>
                <TextInput
                  placeholder="2024"
                  v-model="year"
                  ref="expiryYear"
                  :maxLength="4"
                  className="h-[5vh]"
                  @keypressEnter="verifyCardHandler"
                  name="year"
                  type="tel"
                  :class="{ 'year-border-cs': yearInvalid }"
                  :handleChange="onChangeHandler"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-1 w-6/12">
            <span
              class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >
              CVC*
            </span>
            <TextInput
              placeholder="CVC"
              v-model="cvc"
              ref="cvcFocus"
              id="cardCC"
              name="cvc"
              type="tel"
              :maxLength="3"
              :handleChange="onChangeHandler"
              @keypressEnter="verifyCardHandler"
              className="h-[5vh]"
              :class="{'cvc-border-cs': cvcInvalid}"
              :secondImageSrc="require('../../assets/svgs/cvc.svg')"
            />
          </div>
        </div>
        <div class="">
          <div class="flex items-center space-x-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 9V7C17 5.67392 16.4732 4.40215 15.5355 3.46447C14.5979 2.52678 13.3261 2 12 2C10.6739 2 9.40215 2.52678 8.46447 3.46447C7.52678 4.40215 7 5.67392 7 7V9C6.20435 9 5.44129 9.31607 4.87868 9.87868C4.31607 10.4413 4 11.2044 4 12V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V12C20 11.2044 19.6839 10.4413 19.1213 9.87868C18.5587 9.31607 17.7956 9 17 9ZM9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V9H9V7ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12V19Z"
                fill="#00C802"
              />
            </svg>
            <span class="text-[14px] font-semibold text-[#3EAF3F]"
              >This is a secure 256-bit SSL encrypted</span
            >
          </div>
        </div>
        <div v-if="isModalForm" class="flex items-center space-x-4">
          <CustomButton
            v-if="!isLoading && isEditable"
            buttonType="secondary"
            title="Discard Changes"
            @onClick="discardInfo"
            customStyle="dark:bg-lightTextColor/70 dark:hover:bg-lightTextColor/60"
          />
          <CustomButton
          v-if="isEditable"
          buttonClasses="text-[40px]"
            @onClick="verifyCardHandler"
            buttonType="primary"
            title="Update Card"
            :isLoading="isLoading"
            loaderText="Verifying credit card information"
          />
        </div>
        <div v-else>
          <CustomButton
            @onClick="verifyCardHandler"
            buttonType="primary"
            title="Verify and continue"
            :isLoading="isLoading"
            loaderText="Verifying credit card information"
          />
        </div>
      </div>
    </CardBox>
  </div>
</template>

<script>

import CardBox from "@/components/SharedComponents/CardBox.vue";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
// import FlatPickr from "vue-flatpickr-component";
import NotificationComp from "../../components/SharedComponents/Notification.vue";
import TextInput from "@/components/SharedComponents/TextInput.vue";
import userApi from "@/services/userApi";
import store, { getUserDetailsFromStore } from "@/store";
import moment from "moment";
// import moment from "moment";

export default {
  name: "CreditCardComponent",
  components: {
    CardBox,
    CustomButton,
    // FlatPickr,
    NotificationComp,
    TextInput,
  },
  setup() {
    return {
      store,
    };
  },
  props: {
    isProfileNotComplete: {},
    isModalForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expiryDate: "",
      cardNumber: "",
      cvc: "",
      month: "",
      year: "",
      paymentFailed: false,
      seeDetailError: false,
      isLoading: false,
      showCardNumber: false,
      isEditable:false,
      validationError: null,
      monthInvalid: false,
      yearInvalid: false,
      flatpickrConfig: {
        dateFormat: "d/m/Y", // Set the desired date format
      },
      successMessage: "",
      isCardEmpty: false,
      cvcInvalidfalse: false,
    };
  },
  methods: {
    resetCardNumber(){
      this.cardNumber = '';
      this.showCardNumber = true
    },
    discardInfo() {
      this.year = "";
      this.cardNumber = "";
      this.month = "";
      this.expiryDate = "";
      this.cvc = "";
      this.$emit("onClose", false);
      this.isEditable = false
    },

    isValidCreditCardNumber(cardNumber) {
      // this.isEditable=true
      // Remove any non-digit characters
      const cleanedCardNumber = cardNumber.replace(/\D/g, "");

      // Convert the card number to an array of digits
      const cardDigits = cleanedCardNumber.split("").map(Number);

      // Reverse the array for processing
      cardDigits.reverse();

      // Double every second digit
      for (let i = 1; i < cardDigits.length; i += 2) {
        cardDigits[i] *= 2;
        if (cardDigits[i] > 9) {
          cardDigits[i] -= 9;
        }
      }

      // Sum all the digits
      const sum = cardDigits.reduce((acc, digit) => acc + digit, 0);

      // Check if the sum is a multiple of 10
      return sum % 10 === 0;
    },

    checkExpiry(m, y) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; 
    const currentYear = currentDate.getFullYear();
    
    const conMonth = parseInt(m);
    const conYear = parseInt(y);

    if (conYear < currentYear) {
        return false;
    }

    if (conYear === currentYear && conMonth < currentMonth) {
        return false;
    }

    return true;
},
    verifyCardHandler() {

      this.isEditable = true;
      this.isCardEmpty = false;
      this.monthInvalid = false;
      this.yearInvalid = false;
      this.cvcInvalid = false;
   
      let checkNumber = this.cardNumber.replace(/\D/g, "");

      if(!this.cardNumber || checkNumber.length < 16){
        this.isCardEmpty = true;
      }
      if(!this.month){
        this.monthInvalid = true
      }
      if(!this.year ){
        this.yearInvalid = true
      }
      if(!this.cvc){
        console.log("cvc");
        this.cvcInvalid = true;
        console.log("cvc boolean", this.cvcInvalid);
      }

      const showCardValidationError = (msg = "Invalid Expiry Date") => {
        this.validationError = msg;
        setTimeout(() => {
          this.validationError = "";
        }, 2500);
      };

      const showErrorAndReturn = (msg) => {
        showCardValidationError(msg);
        return;
      };
      let monthValid = this.month > 0 && this.month < 13;
      // let yearValid = this.year > 23;
      let validCardDate = this.checkExpiry(this.month,this.year)
      console.log("valid date",validCardDate)
      let trim =  this.cardNumber.replace(/\D/g, "");
     if(trim.length === 16){
      if (this.month && this.year && monthValid && this.cvc && this.cardNumber) {
        if(validCardDate){
        try {
          const monthValidation = `${this.month}/${this.year}`;
          // const inputDate = moment(monthValidation, "MM/YYYY", true);
          const currentYear = moment().year() % 100;
          const enteredYear = parseInt(this.year, 10);

          if (!this.year || enteredYear < currentYear) {
            showErrorAndReturn("It appears that the credit card number you entered is invalid or has expired");
          } else {
            if (this.isValidCreditCardNumber(this.cardNumber)) {
              // if (inputDate.isValid() && inputDate.isAfter(moment())) {
              //   console.log("The date is in the future.");
              //   showCardValidationError();
              // } 
              // else {
                this.validationError = "";
                this.isLoading = true;
                userApi
                  .updateBillingInfo({
                    cc_number: this.cardNumber,
                    cc_exp: monthValidation,
                    cc_cvc: this.cvc,
                    default: true,
                  })
                  .then((response) => {
                    if (response.data?.status) {
                      this.isLoading = false;
                      this.successMessage =
                        "Your Card has been added successfully";
                      let userDetail = response.data?.user_detail;
                      store.commit("SET_USER_DETAIL", userDetail);
                      setTimeout(() => {
                        this.$emit("onClose", false);
                        this.discardInfo();
                      }, [2000]);
                      console.log("response", userDetail);
                    } else {
                      this.isLoading = false;
                      console.log("response err", response.data);
                    }
                  })
                  .catch((e) => {
                    showErrorAndReturn("Card Adding Failed");
                    console.log(e);
                    this.isLoading = false;
                  });
              }
            // } 
            else {
              showErrorAndReturn(
                "It appears that the credit card number you entered is invalid"
              );
            }
          }
        } catch (e) {
          console.log(e);
          showErrorAndReturn();
        }
      }else{
        this.validationError = "Card Expired";
      }

      } else {
        this.validationError = "Valid inputs are required.";
      }
    }
    // else{
    // this.validationError = "All fields with * are required.";
    // }
    },

    onChangeHandler(event){

      console.log("cvc", this.cvc);

      this.isEditable = true;

      const {name, value} = event.target;
      console.log("name=>", name, "value=>", value);
      if (name === 'month') {
        this.month = value;
        console.log("month value", this.month);
        this.monthInvalid = value > 12;
      }
      if(name === 'year'){
        this.year = value;
       if(this.year){
        this.yearInvalid = value < 24;
       }else{
        this.yearInvalid = false;
       }
      }
      
      if(name === 'cvc'){
        this.cvc = value;
        if(this.cvc.length !== ""){
          this.cvcInvalid = false;
        }
      }
      if(name === "cardNumber"){
        if(value.length === 0){
          this.showCardNumber = true
        }else{
          this.isCardEmpty = false;
          const cleanedValue = value.replace(/\D/g, "");
          // Add a space every four digits, or set an empty string if no digits
          const formattedValue = cleanedValue.length > 0 ? cleanedValue.replace(/(\d{4})/g, "$1 ").trim() : "";
          this.cardNumber = formattedValue;
        }
      }

      this.validationError = null;
      console.log("this", this.monthInvalid);
    },
    ExpiryFocus() {
      this.$refs.expiryYear.focus();
    },
    cvcFocus(){
      this.$refs.cvcFocus.focus()
    },
    monthFocus(){
      this.$refs.cardMonth.focus()
    }
   
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    maskedCardNumber() {
      const lastFourDigits = this.cardNumber.slice(-4);
      const asterisks = "*".repeat(15);
      return asterisks + lastFourDigits;
    },
    formattedCardNumber() {
      if (this.cardNumber.length > 0) {
        return this.showCardNumber ? this.cardNumber : this.maskedCardNumber;
      } else {
        return this.cardNumber;
      }
      // return this.showCardNumber ? this.cardNumber:  this.maskedCardNumber;
    },
    isProfileComplete() {
      let user = this.userDetails;
      return user?.is_profile_complete ?? false;
    },
  },
  watch: {
    month(newMonth){
      let parseMonth = parseInt(newMonth)
      if(parseMonth > 0 && parseMonth < 13){
        if(newMonth?.length == 2){
          this.ExpiryFocus();
        }
      }
    },
    year(newYear){
      console.log("year", newYear);
      let parseYear = parseInt(newYear);
      if(newYear > 0 && parseYear >= 24){
        if(newYear?.length === 2){
          this.cvcFocus();
        }
      }
    },
    cardNumber(newCardNumber){
      if(newCardNumber.length - 3 === 16){
        if(newCardNumber){
          this.monthFocus()
        }
      }
      // if(newCardNumber.length > 0){
      //   this.showCardNumber = true
      // }else{
      //   this.showCardNumber = false;
      // }
    },
    userDetails: {
      handler(newVal) {
        if (
          this.userDetails?.cc_number &&
          this.userDetails?.cc_exp &&
          this.userDetails?.cc_cvc
        ) {
          this.cardNumber = newVal.cc_number;
          this.month = newVal?.cc_exp?.split("/")[0];
          this.year = newVal?.cc_exp?.split("/")[1];
          this.cvc = newVal?.cc_cvc;
        }
      },
    },
  },
  mounted() {
    if (this.userDetails) {
      this.cardNumber = this.userDetails.cc_number;
      this.month = this.userDetails.cc_exp.split("/")[0];
      this.year = this.userDetails.cc_exp.split("/")[1];
      this.cvc = this.userDetails.cc_cvc;
    }
  },
};
</script>


<style scoped>

.month-border-cs{
  border: 1px solid red !important;
}
.year-border-cs{
  border: 1px solid red !important;
}
.cardNumber-border-cs{
  border: 1px solid red !important;
}
.cvc-border-cs{
  border: 1px solid red !important;
}

</style>