<template>
  <div class="h-full w-full">
    <div class="h-full w-full justify-center items-center flex sm:p-0 p-6">
      <div
        class="lg:w-5/12 w-full h-fit flex justify-center items-center bg-white dark:bg-[#24252A] rounded-lg"
      >
        <div class="flex flex-col space-y-7 p-6">
          <div class="flex justify-center items-center space-x-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9 15.6445L10.5 12.0445L14.1 15.6445L15.5 14.2445L11.9 10.6445L15.5 7.04453L14.1 5.64453L10.5 9.24453L6.9 5.64453L5.5 7.04453L9.1 10.6445L5.5 14.2445L6.9 15.6445ZM10.5 20.6445C9.11667 20.6445 7.81667 20.3819 6.6 19.8565C5.38333 19.3312 4.325 18.6189 3.425 17.7195C2.525 16.8195 1.81267 15.7612 1.288 14.5445C0.763333 13.3279 0.500667 12.0279 0.5 10.6445C0.5 9.2612 0.762667 7.9612 1.288 6.74453C1.81333 5.52786 2.52567 4.46953 3.425 3.56953C4.325 2.66953 5.38333 1.9572 6.6 1.43253C7.81667 0.907865 9.11667 0.645198 10.5 0.644531C11.8833 0.644531 13.1833 0.907198 14.4 1.43253C15.6167 1.95786 16.675 2.6702 17.575 3.56953C18.475 4.46953 19.1877 5.52786 19.713 6.74453C20.2383 7.9612 20.5007 9.2612 20.5 10.6445C20.5 12.0279 20.2373 13.3279 19.712 14.5445C19.1867 15.7612 18.4743 16.8195 17.575 17.7195C16.675 18.6195 15.6167 19.3322 14.4 19.8575C13.1833 20.3829 11.8833 20.6452 10.5 20.6445Z"
                fill="#E14942"
              />
            </svg>

            <span class="text-danger font-bold text-[20px]">Uh-oh!</span>
          </div>
          <span class="text-[14px] text-center text-danger">
            It seems there's an issue. We couldn't find any account linked to
            the information provided.
          </span>
          <!-- <span class=" text-btnPrimary font-semibold text-center text-[14px]">abc@email.com</span>
      <span class="text-[9px] text-center text-gray-600">Click the blue button in the email to access your
          account.</span> -->
          <div class="flex justify-center items-center">
            <img src="../assets//images/404.png" />
            <!-- <img src="../assets//images/404.png" height="20px" width="40px" /> -->
          </div>
          <CustomButton
            @onClick="autoRout"
            buttonType="danger"
            title="Try again"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import userApi from "@/services/userApi";
import router from "@/router";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
// import CustomButton from "@/components/SharedComponents/CustomButton.vue";
export default {
  name: "404Page",
  data() {
    return {
      isAuthentic: localStorage.getItem("isUserAuthenticated"),
      // isAuthentic: localStorage.getItem("isUserAuthenticated")
    };
  },
  methods: {
    autoRout() {
      // location.replace("/");

      // let isAuthentic= localStorage.getItem("isUserAuthenticated");
      // console.log('isAuth', this.isAuthentic)
      if (this.isAuthentic) {
        router.push("/");
      } else {
        router.push("/login");
      }
    },
  },
  components: {
    CustomButton,

    // CustomButton
  },
};
</script>
