<template>
  <div class="w-full h-full -mt-8 sm:pb-16 pb-44">
    <div
      class="flex flex-col space-y-5"
      v-click-outside="isMobileOrTabletDevice ? null : handleCloseOutside"
    >
      <div>
        <AddressComponent />
      </div>
      <div>
        <CardBox customClass="border border-[#E7E7E7] rounded-3xl w-full !p-6">
          <div class="w-full h-full">
            <div class="flex flex-col">
              <h1 class="text-black dark:text-white font-bold text-[17px]">
                Billing
              </h1>
              <span
                class="text-lightTextColor dark:text-darkTextColor text-[12px]"
                >Manage your credit cards for billing</span
              >
            </div>
            <div class="pt-4">
              <!-- <---Previous Work------>
              <!-- <div
                class="border-2 border-dashed border-[#CDCDCD] dark:border-[#5f5d5d] rounded-2xl flex items-center justify-center p-8 cursor-pointer"
                @click="addNewCardToggle = true"
              > -->
              <!-- <div
                class="border-2 border-dashed border-[#CDCDCD] dark:border-[#5f5d5d] rounded-2xl flex items-center justify-center p-8 cursor-pointer"
                @click="toggleFeatureAvailable = true"
              >
                <div class="flex items-center space-x-3">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5 5.04785V19.0479"
                      :stroke="`${store.getters.getTheme ? 'white' : 'black'}`"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.5 12.0479H19.5"
                      :stroke="`${store.getters.getTheme ? 'white' : 'black'}`"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h1
                    class="text-black dark:text-white font-semibold text-[16px]"
                  >
                    Add a new Card
                  </h1>
                </div>
              </div> -->
            </div>
            <div class="pt-4">
              <h1 class="text-black dark:text-white font-bold text-[14px]">
                Available Cards
              </h1>
              <CardBox
                customClass="border border-[#E7E7E7] dark:bg-lightTextColor/50 rounded-3xl w-full !p-4 mt-4"
                v-for="card in availableCards"
                :key="card"
              >
                <div
                  class="flex sm:flex-row flex-col sm:space-y-0 space-y-2 items-center justify-between"
                >
                  <div class="flex items-center space-x-3">
                    <img src="" />
                    <div class="flex flex-col">
                      <div class="flex flex-row items-center space-x-2">
                        <span class="text-[13px] font-semibold dark:text-white">
                          <!-- {{ getCardInfo?.cc_number }} -->
                          {{ formattedCardNumber }}
                        </span>
                        <div
                          class="bg-[#CAFFD9] px-2 py-1 flex items-center justify-center rounded-xl"
                          v-if="card.isActive"
                        >
                          <span class="text-[11px] font-semibold text-[#007922]"
                            >Primary</span
                          >
                        </div>
                      </div>
                      <span
                        class="text-[11px] text-lightTextColor dark:text-darkTextColor tracking-wider"
                        >{{ getCardInfo?.cc_exp }}</span
                      >
                    </div>
                  </div>
                  <div class="sm:w-fit w-full">
                    <CustomButton
                      buttonType="secondary"
                      title="Edit"
                      customStyle="dark:bg-lightTextColor/70 dark:hover:bg-lightTextColor/20"
                      @onClick="addNewCardToggle = true"
                    />
                  </div>
                </div>
              </CardBox>
            </div>
          </div>
        </CardBox>
      </div>
      <div>
        <InvoicesComponent />
      </div>
    </div>
    <BaseModal v-if="toggleFeatureAvailable">
      <div class="h-full lg:w-[40vw] sm:w-[80vw] w-full overflow-hidden">
        <div class="w-full flex justify-end">
          <div
            class="w-fit cursor-pointer pt-2 pr-2"
            @click="toggleFeatureAvailable = false"
          >
            <CrossIconSvg stroke="#8a8a8a" />
          </div>
        </div>
        <div class="h-full w-full flex items-center justify-center">
          <div class="h-full w-full rounded-3xl sm:!p-8 p-4">
            <div
              class="h-full w-full flex items-center flex-row justify-around sm:space-x-2 space-x-8"
            >
              <div
                class="h-full w-full flex flex-col -whitespace-nowrap dark:text-white"
              >
                <span class="sm:text-[22px] text-[18px]"
                  >This feature is under development</span
                >
                <span class="sm:text-[22px] text-[18px] font-bold"
                  >It will be available soon.</span
                >
              </div>
              <div class="h-full w-2/6 justify-end flex">
                <ComingSoon />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex items-center justify-end px-4 pb-2">
          <div>
            <CustomButton
              title="OK"
              @onClick="toggleFeatureAvailable = false"
              buttonType="primary"
              customStyle="!px-6 !py-1.5"
            />
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal v-if="addNewCardToggle">
      <div class="p-2 ">
        <div class="flex w-full justify-end">
          <CreditCardComponent :isModalForm="true" @onClose="onCloseHandler" />
          <div
            class="p-1 rounded-full h-8 hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
            @click="addNewCardToggle = false"
          >
            <CrossIconSvg />
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import CardBox from "../SharedComponents/CardBox.vue";
import CreditCardComponent from "../../components/SettingsComp/CreditCardComponent.vue";
import BaseModal from "../SharedComponents/BaseModal.vue";
import CustomButton from "../SharedComponents/CustomButton.vue";
import AddressComponent from "../../components/SettingsComp/AddressComponent.vue";
import InvoicesComponent from "../../components/SettingsComp/Invoices.vue";
import store, { getUserDetailsFromStore } from "../../store";
import CrossIconSvg from "@/assets/svgs/CrossIconSvg.vue";
import ComingSoon from "../../assets/svgs/comingSoon.vue";
import vClickOutside from "vue-click-outside";

export default {
  name: "BillingComp",
  directives: {
    "click-outside": vClickOutside,
  },
  setup() {
    return {
      store,
    };
  },
  data() {
    return {
      addNewCardToggle: false,
      toggleFeatureAvailable: false,
      availableCards: [
        {
          id: 1,
          name: "Visa/Master Card **4544",
          date: "08/24",
          isActive: true,
          img: "../../assets/svgs/visa.svg",
        },
      ],
      isMobileOrTabletDevice:
        /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|Tablet|Kindle/i.test(
          navigator.userAgent
        ),
    };
  },
  methods: {
    onCloseHandler(val) {
      this.addNewCardToggle = val;
    },
    handleCloseOutside() {
      this.addNewCardToggle = false;
    },
  },
  computed: {
    userdetails() {
      return getUserDetailsFromStore();
    },
    formattedCardNumber() {
      const cardNumber = this.getCardInfo?.cc_number || "";
      const numericOnly = cardNumber.replace(/\D/g, "");
      const lastFourDigits = numericOnly.slice(-4);
      return "**** **** **** " + lastFourDigits;
    },
    getCardInfo() {
      return this.userdetails;
    },
  },
  components: {
    CardBox,
    CreditCardComponent,
    BaseModal,
    CustomButton,
    AddressComponent,
    InvoicesComponent,
    CrossIconSvg,
    ComingSoon,
  },
};
</script>
