<template>
  <!-- <div> -->
  <div
    v-if="!showWaring"
    class="flex lg:flex-row flex-col h-full w-full justify-center items-center lg:space-x-40 sm:space-x-0 lg:space-y-0 sm:space-y-10 space-y-0"
  >
    <div class="lg:flex hidden">
      <!--      <img class="" src="../../../assets/NewSvgCollection/SignUp.svg" />-->
      <SignupImage />
    </div>

    <div v-if="!openEmail" class="flex justify-center w-full h-32 lg:hidden">
      <img
        class="w-[70vw] h-32"
        src="../../../assets/NewSvgCollection/Logo.svg"
      />
    </div>

    <div
      class="flex justify-center items-center lg:w-3/12 sm:w-4/6 w-full px-2 sm:py-3 py-0 sm:mt-0 !-mt-10"
    >
      <div
        :class="`${
          isLoader ? 'pointer-events-none bg-opacity-30' : ''
        } bg-white rounded-3xl space-y-3 w-full flex flex-col lg:p-6 sm:p-3 p-3`"
      >
        <div class="flex flex-col lg:space-y-1 sm:space-y-3 space-y-1.5">
          <span class="text-center text-[20px] font-semibold">
            Sign Up
            <!-- <span class="font-bold"> CondorCash </span> -->
          </span>

          <span
            class="text-center text-lightTextColor dark:text-darkTextColor text-[14px] lg:text-[15px]"
          >
            Already have an account?
            <router-link to="/login">
              <span
                class="text-btnPrimary hover:text-blue-500 lg:text-[15px] text-[14px] font-semibold"
              >
                Login
              </span>
            </router-link>
          </span>
        </div>

        <!-- <div class="flex flex-col lg:space-y-3 sm:space-y-4 space-y-1.5">
          <LoginButtons
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Register using Google"
            svgType="google"
            @onClick="handleThirdPartyIntegration('google')"
          />

          <LoginButtons
            v-if="!openEmail"
            @onClick="enableForm"
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Register using Email"
            svgType="email"
            buttonType="primary"
          />

          <LoginButtons
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Register using Facebook"
            svgType="facebook"
            @onClick="handleThirdPartyIntegration('facebook')"
          />

          <LoginButtons
            customStyle="lg:h-12 sm:h-14 h-12 w-full"
            title="Register using LinkedIn"
            svgType="linkedin"
            @onClick="handleThirdPartyIntegration('linkedin')"
          />
        </div> -->

        <!-- <div
          :class="openEmail ? 'block' : 'hidden'"
          class="flex-row flex justify-center items-center"
        >
          <div class="border-t border-t-lightTextColor/20 w-full" />
          <p
            :class="` pl-3 pr-3  text-lightTextColor dark:text-darkTextColor/60 text-[15px]`"
          >
            or
          </p>
          <div class="border-t border-t-lightTextColor/20 w-full" />
        </div> -->

        <div v-if="openEmail">
          <div class="sm:space-y-3 space-y-1.5">
            <div
              class=""
              :class="
                validationObject.email.status
                  ? 'border-danger border-2 rounded-md'
                  : ''
              "
            >
              <input
                @focus="
                  (isInputFocused = false),
                    (validationObject.email.status = ''),
                    (ReInputFocused = false)
                "
                type="email"
                id="email"
                v-model="email"
                class="bg-lightTextColor/5 pl-5 lg:h-12 sm:h-14 h-14 text-[14px] placeholder:text-lightTextColor dark:text-darkTextColor/60 hover:bg-lightTextColor/10 border-none text-lightTextColor dark:text-darkTextColor rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                :class="validationObject.email.status ? 'border' : ''"
                placeholder="Email Address "
                required
                @input="validInput"
                v-on:keyup.enter="signUpRequest"
              />
            </div>

            <div
              :class="{
                'border-red-500 border-2': validationObject.password.status,
                'border-blue-500 border-2': isInputFocused,
              }"
              class="hover:bg-[#EFEFEF]/80 bg-[#EFEFEF]/50 flex items-center space-x-1 flex-row rounded-lg lg:h-12 sm:h-14 h-14 w-full lg:p-2.5 sm:p-2 p-2"
            >
              <input
                @focus="(isInputFocused = true), (ReInputFocused = false)"
                :type="!showPassword ? 'text' : 'password'"
                id="password"
                v-model="password"
                class="w-full focus:ring-0 bg-transparent text-[14px] pl-3 border-none text-lightTextColor dark:text-darkTextColor placeholder:text-lightTextColor dark:text-darkTextColor/60"
                placeholder="Password"
                required
                v-on:keyup.enter="signUpRequest"
              />
              <svg
                v-if="showPassword"
                @click="enablePassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#000000"
                :class="'pr-2 hover:cursor-pointer h-7 w-7'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
              <svg
                v-else
                @click="enablePassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#000000"
                :class="'pr-2 hover:cursor-pointer h-7 w-7'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <PasswordStrengthComponent
              :password="password"
              @password-strength="passwordStrengthHandler"
            />

            <div
              :class="{
                'border-red-500 border-2': validationObject.password.status,
                'border-blue-500 border-2': ReInputFocused,
              }"
              class="hover:bg-[#EFEFEF]/80 bg-[#EFEFEF]/50 flex items-center space-x-1 flex-row rounded-lg h-12 w-full p-2.5 mt-2.5"
            >
              <input
                @focus="
                  (ReInputFocused = true),
                    (isInputFocused = false),
                    (validationObject.password.status = '')
                "
                :type="!showRePassword ? 'text' : 'password'"
                id="password"
                v-on:keyup.enter="signUpRequest"
                v-model="confirm_password"
                class="w-full focus:ring-0 bg-transparent text-[14px] pl-3 border-none text-lightTextColor dark:text-darkTextColor placeholder:text-lightTextColor dark:text-darkTextColor/60"
                placeholder="Confirm Password"
                required
              />
              <svg
                v-if="showRePassword"
                @click="enableConfirmPassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#000000"
                :class="'pr-2 hover:cursor-pointer h-7 w-7'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
              <svg
                v-else
                @click="enableConfirmPassword"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#000000"
                :class="'pr-2 hover:cursor-pointer h-7 w-7'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <!-- <div class="lg:h-5 h-5 bg-green-50"> -->
            <span
              class=""
              :class="
                messages
                  ? 'text-danger  h-5 text-[12px] '
                  : 'text-[12px]  h-5 text-danger mb-20 bg-green-500'
              "
            >
              {{ messages }}
              <!-- invalid email -->
            </span>
            <!-- </div> -->
            <LoginButtons
              @onClick="signUpRequest"
              customStyle="lg:h-12 sm:h-14 h-12 w-full"
              title="Create account"
              buttonType=""
              :isLoading="isLoader"
              loaderText="Creating your account"
              :message="messages ? messages : ''"
            />

            <!-- <CustomButton title="Create account" @onClick="signUpRequest" buttonType="" :isLoading="isLoader"
          :loaderText="'Please wait'" /> -->
          </div>

          <div class="w-full flex pt-2 justify-center">
            <p
              class="text-lightTextColor dark:text-darkTextColor/90 text-[12px] text-center px-4"
            >
              By creating an account, you agree to the
              <a
                class="hover:text-blue-500 cursor-pointer hover:underline text-btnPrimary"
                >Terms of Service</a
              >
              and
              <a
                class="hover:text-blue-500 hover:underline cursor-pointer text-btnPrimary"
                >Privacy Policy</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <SingUpWarning />
  </div>
</template>

<script>
import userApi from "@/services/userApi";
// import SigupSVG from "../../../assets/NewSvgCollection/SignUp.svg"
// import router from "@/router";
// import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import LoginButtons from "../../../components/SharedComponents/LoginButtons";
import SingUpWarning from "../login/AfterSignUp.vue";
import store from "@/store";
import SignupImage from "@/pages/userStory/newSignup/SignupImage.vue";
import PasswordStrengthComponent from "@/pages/userStory/newSignup/PasswordStrengthComponent.vue";
import { FACEBOOK_LOGIN, GOOGLE_LOGIN, LINKDIN_LOGIN } from "@/api/apiRoutes";

export default {
  name: "SignUp",
  data() {
    return {
      // email: "adil.rajput@dimensionalsys.com",
      // password: "123456",
      isMobileDevice: /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone/i.test(
        navigator.userAgent
      ),
      email: "",
      password: "",
      passwordStrength: "",
      confirm_password: "",
      isLoader: false,
      showWaring: false,
      messages: "",
      openEmail: true,
      showPassword: true,
      showRePassword: true,
      alertType: "",
      validatedEmail: null,
      validatedPassword: null,
      isInputFocused: false,
      ReInputFocused: false,
      validationObject: {
        email: {
          status: false,
          message: "Please enter a correct email address",
          borderColorChnage: false,
        },
        password: {
          status: false,
          message: "Please enter a password",
          borderColorChnage: false,
        },
      },
      svgObj: {
        name: "facebook",
        className: "bg-green-400 h-10 w-10",
        fill: "red",
        stroke: "yellow",
      },
    };
  },
  methods: {
    handleThirdPartyIntegration(val) {
      let redirectUrl;

      if (val === "google") {
        redirectUrl = GOOGLE_LOGIN;
      } else if (val === "facebook") {
        redirectUrl = FACEBOOK_LOGIN;
      } else if (val === "linkedin") {
        redirectUrl = LINKDIN_LOGIN;
      } else {
        console.error("Invalid integration type");
        return;
      }
      // Redirect to the specified URL
      window.location.href = redirectUrl;
    },
    async signUpRequest() {
      this.messages = "";
      let isNotValid = false;

      if (!this.email && !this.password) {
        this.showErrors("Please enter a correct email & password");
        isNotValid = true;
        this.validationObject.password.status = true;
        this.validationObject.email.status = true;
      } else if (!this.email && this.password !== "") {
        this.showErrors("Please enter your email");
        this.validationObject.email.status = true;
      } else if (!this.validatedEmail) {
        this.showErrors("Please enter a correct email");
        this.validationObject.email.status = true;
        console.log("validate email chcek", this.validatedEmail);
      } else if (this.password !== this.confirm_password) {
        this.validationObject.password.status = true;
        this.showErrors("Passwords doesn't match");
      } else if (this.password === "" || this.confirm_password === "") {
        this.validationObject.password.status = true;
        this.showErrors("Please enter password");
      }

      if (isNotValid) {
        return;
      }
      let payload = {
        email: this.email,
        password: this.password,
        confirm_password: this.confirm_password,
      };
      console.log("payload", this.passwordStrength);

      if (
        this.password.length > 7 && (this.passwordStrength === "Medium" || this.passwordStrength === "Strong")
      ) {
        if (
          this.validatedEmail === true &&
          this.password !== "" &&
          this.password !== "" &&
          this.password === this.confirm_password
        ) {
          this.isLoader = true;
          const resp = await userApi.userSignUp(payload);
          this.messages = resp.data.message;
          this.isLoader = resp.data.status;
          console.log("Updated Response from new signup", resp);
          if (resp?.data?.status) {
            this.messages = "";
            this.isLoader = false;
            store.state.emailBeforeSignup = this.email;
            // location.replace("/after-signup");
            this.showWaring = true;
          }
        }
      } else {
        this.showErrors("Password should be atleast 8 characters long, 1 uppercase letter and/or 1 special character");
      }
    },
    backToLogin() {
      this.openEmail = false;
      this.showWaring = false;
      location.replace("/login");
    },
    enablePassword() {
      console.log("login click");
      this.showPassword = !this.showPassword;
    },
    enableConfirmPassword() {
      console.log("login click");
      this.showRePassword = !this.showRePassword;
    },

    enableForm() {
      console.log("login click");
      this.openEmail = true;
    },
    showErrors(message, alertType, alertStatus) {
      this.messages = message;
      this.alertStatus = alertStatus;
      this.alertType = alertType;
    },
    passwordStrengthHandler(strength) {
      this.passwordStrength = strength;
    },
    validateEmail(value) {
      if (
        /^[a-zA-Z0-9.'{|}~+-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/.test(
          value
        )
      ) {
        this.validatedEmail = true;
      } else {
        console.log("email not true");
        this.validatedEmail = false;
      }
    },
    validInput(event) {
      this.validationObject.email.status = "";
      this.email = event.target.value;
      this.validateEmail(event.target.value);
    },
    validatePassword() {
      if (this.$refs.password.value == 0) {
        this.$refs.password.style.border = `0.1px solid rgba(233, 233, 233, 0.3)`;
      }
    },
  },
  components: {
    PasswordStrengthComponent,
    SignupImage,
    // SigupSVG,
    // CustomButton,
    LoginButtons,
    SingUpWarning,
  },
};
</script>
