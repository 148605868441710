<template>
  <CardBox
    customClass="border  border-[#E7E7E7] rounded-3xl w-full !py-3 !px-3 h-full"
  >
    <div class="w-full h-full">
      <div
        class="flex items-center sm:space-x-3 sm:justify-start justify-between"
      >
        <div
          :class="`
          bg-[#00A79E] hover:bg-[#02857E]
          text-white sm:py-2 text-center sm:px-2 px-1 py-1 !rounded-[12px]  cursor-pointer`"
          @click="addValue"
        >
          <svg
            class="sm:h-7 sm:w-7 h-6 w-6"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0156 5.94336V19.9434"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.01562 12.9434H19.0156"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-if="calcType === 'calls'">
          <img
            src="../../assets/svgs/calls.svg"
            class="sm:w-8 sm:m-0 m-1 w-4 h-4 sm:h-8"
          />
        </div>
        <div v-else>
          <img
            src="../../assets/svgs/puts.svg"
            class="sm:w-8 sm:m-0 m-1 w-4 h-4 sm:h-8"
          />
        </div>
        <div class="flex flex-col text-black sm:pr-0 pr-2 dark:text-white">
          <span
            class="sm:text-[12px] text-[11px] font-medium whitespace-nowrap"
            >{{ indicator?.toUpperCase() }}</span
          >
          <span class="font-bold text-[13px] sm:text-[14px]"
            >${{
              val1?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}</span
          >
          <span class="font-bold text-[13px] sm:text-[14px]"
            >${{
              val2?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}</span
          >
        </div>
        <div
          :class="`sm:py-2 text-center sm:px-2 px-1 py-1 !rounded-[12px]  cursor-pointer text-white ${'bg-[#CC2E3D] hover:bg-[#A00D1B]'} `"
          @click="minusValue"
        >
          <svg
            class="sm:h-7 sm:w-7 h-6 w-6"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.39404 12H19.394"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </CardBox>
</template>

<script>
import CardBox from "../../components/SharedComponents/CardBox.vue";
export default {
  name: "BearBullCalc",
  components: {
    CardBox,
  },
  props: {
    calcType: {
      type: String,
      default: "",
    },
    indicator: {
      type: String,
      default: "",
    },
    val1: {
      type: Number,
      default: 0,
    },
    val2: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    addValue() {
      this.$emit("addValue");
    },
    minusValue() {
      this.$emit("minusValue");
    },
  },
};
</script>
