<template>
  <div class=""
  :class="!isProfileComplete ? 'sm:h-full h-[125vh]':'w-full h-full pb-10'"
  >
    <div
      v-click-outside="isMobileOrTabletDevice ? null : closeModal"
      class="w-full justify-center h-full flex items-center"
    >
      <div class="h-full flex items-center justify-center md:w-8/12 w-full"
      >
        <div class="w-full space-y-6 h-full sm:pb-0 pb-10 sm:mt-0 -mt-8 "
        >
          <NotificationComp
            title="Step 4 of 4: Select a plan and enjoy 3-steps process."
            desc="Click on “Subscribe” button to enable your subscription."
            v-if="!isPlanGiven"
            :bgColor="`${store.state.theme ? 'darkWarning' : '#FFF9E3'}`"
            :textColor="`${store.state.theme ? 'textDark' : '#C27500'}`"
          />
          <div v-if="isShowComplete" class="w-full h-full">
            <PlansSummaryPage @handleClickStarted="startedClickHandler" />
          </div>
          <div
            class="w-full flex items-center justify-center h-full "
            v-if="
              isProfileNotComplete || (isProfileComplete && !isShowComplete)
            "
          >
            <div
              class="w-full h-full space-y-6 pb-24 sm:pb-8 "
              
              
              v-if="!isLoading"
            >
            <!-- Subcription Status Start -->
              <CardBox
                customClass="border border-[#E7E7E7] rounded-3xl w-full h-full space-y-2 !p-4"
                v-if="!isProfileNotComplete"
              >
                <div>
                  <div class="flex flex-col">
                    <h1
                      class="text-black dark:text-white font-bold text-[19px]"
                    >
                      Subscription Status
                      <span
                        class="text-black dark:text-white font-[500] text-[12px] italic"
                      >
                        ({{ currentPlan?.description }})</span
                      >
                    </h1>
                    <span
                      class="text-lightTextColor dark:text-darkTextColor sm:w-full -mt-1 w-9/12 sm:text-[12px] text-[10px]"
                    >
                      Review your current plan and its features below.
                    </span>

                    <div class="flex items-center space-x-2 pt-2">
                      <CheckBox
                        inputType="switch"
                        @toggleSwitch="toggleAutoHandler"
                        :isActive="isAutoToggle"
                      />
                      <div class="flex flex-col space-y-0.5">
                        <div>
                          <p
                            v-if="isAutoToggle"
                            class="text-black dark:text-white font-[600] text-[15.5px]"
                          >
                            Auto Renewal Enabled
                          </p>
                          <p
                            v-else
                            class="text-black dark:text-white font-[600] text-[15.5px]"
                          >
                            Auto Renewal Disabled
                          </p>
                        </div>
                        <p
                          class="text-lightTextColor dark:text-darkTextColor sm:text-[12px] text-[10px] !-mt-1"
                        >
                          Enable auto renew to ensure uninterrupted service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <CardBox
                :customClass="isAutoToggle ? 'h-[15vh] hover:bg-gray-50 rounded-3xl w-full !p-4 border-2 border-[#E7E7E7]':'h-auto hover:bg-gray-50 rounded-3xl w-full !p-4 border-2 border-[#E7E7E7]'"
                >
                  <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-4">
                      <img
                        :src="getPlanImage(currentPlanSubs.db_name)"
                        alt=""
                      />

                      <div class="flex flex-col space-y-2">
                        <div class="flex items-center space-x-2">
                          <div v-if="isCurrentPlan(currentPlanSubs)">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                fill="#3EAF3F"
                              />
                            </svg>
                          </div>
                          <span
                            class="text-black dark:text-white text-[15px] font-semibold"
                            >{{ currentPlanSubs.title }}</span
                          >
                          <div
                            v-if="isCurrentPlan(currentPlanSubs)"
                            class="bg-[#CAFFD9] px-2 py-1 flex items-center justify-center rounded-xl"
                          >
                            <span
                              class="text-[11px] text-[#007922] font-semibold"
                            >
                              Active
                            </span>
                          </div>
                        </div>
                        <span
                          class="text-black dark:text-white text-[14px]"
                          v-if="!isCurrentPlan(currentPlanSubs)"
                          >${{ items?.amount }}/yr</span
                        >
                        <span
                          class="text-lightTextColor dark:text-white text-[12px] font-semibold"
                          v-else
                          >{{
                            `${
                              plansInfo?.subscription_period_number === 12
                                ? "Yearly"
                                : "Monthly"
                            } Billing Cycle`
                          }}</span
                        >
                        <span
                        v-if="!isAutoToggle"
                              class="text-[#ff8080] text-[12px]"
                              >Subscription will expire on {{ subscriptionDate }}</span
                            >
                        <span
                        v-else
                              class=" text-[12px] dark:text-gray-400 text-gray-500"
                              >Subscription will renew on {{ subscriptionDate }}</span
                            >
                      </div>
                    </div>
                    <div class="md:flex hidden space-x-3 items-center">
                      <!-- <CustomButton
                        title="Details"
                        buttonType="secondary"
                        @onClick="showDetailsYearly(currentPlanSubs)"
                        customStyle="dark:bg-lightTextColor/40 dark:hover:bg-lightTextColor/20"
                      /> -->
                      <CustomButton
                        :disabled-val="isCurrentPlan(currentPlanSubs)"
                        title="Cancel Subscription"
                        :buttonType="
                          isCurrentPlan(currentPlanSubs)
                            ? 'secondary'
                            : 'primary'
                        "
                        @onClick="
                          setSubs(currentPlanSubs, getTitle(currentPlanSubs))
                        "
                        :is-loading="subsLoading"
                      />
                    </div>
                  </div>
                </CardBox>
              </CardBox>
            <!-- Subcription Status End -->

              <CardBox
                customClass="border border-[#E7E7E7]   rounded-3xl w-full h-full !p-4"
              >
              <!-- Plans header & toggle start -->
                <div class="flex items-center justify-between">
                  <div class="flex flex-col">
                    <h1
                      class="text-black dark:text-white font-bold text-[19px]"
                    >
                      Plans
                      <!-- <span
                    
                        class="text-black dark:text-white font-[500] text-[12px] italic"
                      >
                        ({{ currentPlan.description }})</span
                      > -->
                    </h1>
                    <span
                      class="text-lightTextColor dark:text-darkTextColor sm:w-full -mt-1 w-9/12 sm:text-[13px] text-[11px]"
                    >
                      Unleash your true investing power with us
                    </span>
                    <span
                      class="text-red-600 pt-1 sm:w-full w-9/12 sm:text-[13px] text-[11px]"
                    >
                      {{ subscriptionCancelError }}
                    </span>
                  </div>
                  <!-- <div class="flex flex-col" v-else>
                    <h1
                      class="text-black dark:text-white font-bold text-[19px]"
                    >
                      Subscription Status
                      <span
                        class="text-black dark:text-white font-[500] text-[12px] italic"
                      >
                        ({{ currentPlan.description }})</span
                      >
                    </h1>
                    <span
                      class="text-lightTextColor dark:text-darkTextColor sm:w-full -mt-1 w-9/12 sm:text-[12px] text-[10px]"
                    >
                      Review your current plan and its features below.
                    </span>

                    <div class="flex items-center space-x-2 pt-4">
                      <CheckBox
                        inputType="switch"
                        @toggleSwitch="toggleAutoHandler"
                        :isActive="isAutoToggle"
                      />
                      <div class="flex flex-col space-y-0.5">
                        <div>
                          <p
                            v-if="isAutoToggle"
                            class="text-black dark:text-white font-[600] text-[15.5px]"
                          >
                            Auto Renewal Enabled
                          </p>
                          <p
                            v-else
                            class="text-[#CA0000] font-[600] text-[15.5px]"
                          >
                            Auto Renewal Disabled
                          </p>
                        </div>
                        <p
                          class="text-lightTextColor dark:text-darkTextColor sm:text-[13px] text-[10px] -mt-1"
                        >
                          Enable auto renew to ensure uninterrupted service.
                        </p>
                      </div>
                    </div>
                  </div> -->

                  <div class="flex items-center space-x-2 text-[14px]">
                    <span
                      :class="`sm:text-[14px] text-[12px] ${
                        isActiveToggle
                          ? ' text-lightTextColor dark:text-darkTextColor'
                          : 'text-btnPrimary dark:text-[#a4cafe]'
                      }`"
                      >Monthly</span
                    >
                    <CheckBox
                      inputType="switch"
                      @toggleSwitch="toggleSwitchHandler"
                      :isActive="isActiveToggle"
                    />
                    <span
                      :class="`sm:text-[14px] text-[12px] ${
                        !isActiveToggle
                          ? ' text-lightTextColor dark:text-darkTextColor'
                          : 'text-btnPrimary dark:text-[#a4cafe]'
                      }`"
                      >Yearly</span
                    >
                  </div>
                </div>
                <!-- Plans header & toggle End -->

                <!-- Subscription Plans for New User Start -->
                <div
                  class="flex items-center space-x-2 pt-4"
                  v-if="isProfileNotComplete"
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.86148 3.07347L1.21481 12.5001C1.09839 12.7018 1.03679 12.9303 1.03614 13.1631C1.03549 13.396 1.09581 13.6249 1.2111 13.8272C1.32639 14.0294 1.49263 14.198 1.69328 14.316C1.89394 14.4341 2.12201 14.4976 2.35481 14.5001H13.6481C13.8809 14.4976 14.109 14.4341 14.3097 14.316C14.5103 14.198 14.6766 14.0294 14.7919 13.8272C14.9072 13.6249 14.9675 13.396 14.9668 13.1631C14.9662 12.9303 14.9046 12.7018 14.7881 12.5001L9.14148 3.07347C9.02263 2.87754 8.85529 2.71555 8.65561 2.60313C8.45592 2.4907 8.23064 2.43164 8.00148 2.43164C7.77232 2.43164 7.54703 2.4907 7.34735 2.60313C7.14766 2.71555 6.98033 2.87754 6.86148 3.07347Z"
                      stroke="#C27500"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00098 6.5V9.16667"
                      stroke="#C27500"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00098 11.833H8.00764"
                      stroke="#C27500"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span class="text-[#DC6900] text-[13px]"
                    >Please select a plan</span
                  >
                </div>
                <!-- Subscription Plans for New User End -->

                <!-- Monthly Subscription Plans Start -->
                <div v-if="!isActiveToggle">
                  <div
                    v-for="(items, index) in subscriptionPlans.monthly"
                    :key="index"
                    class="flex flex-col space-y-4 pt-4"
                  >
                    <CardBox :customClass="getClasses(items)">
                      <div class="flex items-center justify-between">
                        <div class="flex items-center space-x-4">
                          <img :src="getPlanImage(items.db_name)" alt="" />
                          <!-- {{ renderPlanImages(items.db_name) }} -->
                          <!-- <component :is="renderPlanImages(items.db_name)" /> -->

                          <div class="flex flex-col space-y-2">
                            <div class="flex items-center space-x-2">
                              <div v-if="isCurrentPlan(items)">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                    fill="#3EAF3F"
                                  />
                                </svg>
                              </div>
                              <span
                                class="text-black dark:text-white text-[15px] font-semibold"
                                >{{ items.title }}</span
                              >
                              <div
                                v-if="isCurrentPlan(items)"
                                class="bg-[#CAFFD9] px-2 py-1 flex items-center justify-center rounded-xl"
                              >
                                <span
                                  class="text-[11px] text-[#007922] font-semibold"
                                >
                                  Active
                                </span>
                              </div>
                            </div>
                            <span class="text-black dark:text-white text-[14px]"
                              >${{ items?.amount }}/mo</span
                            >
                            <span
                            v-if="isCurrentPlan(items)"
                              class="text-lightTextColor dark:text-darkTextColor text-[12px]"
                              >Renew on {{ subscriptionDate }}</span
                            >
                          </div>
                        </div>
                        <div v-if="!isPendingPlan(items)" class="md:flex hidden space-x-3 items-center">
                          <CustomButton
                            title="Details"
                            buttonType="secondary"
                            @onClick="showDetailsMonthly(items)"
                            customStyle="dark:bg-lightTextColor/40 dark:hover:bg-lightTextColor/20"
                          />
                          <CustomButton
                            :disabled-val="isCurrentPlan(items)"
                            :title="getTitle(items)"
                            :buttonType="
                              isCurrentPlan(items) ? 'secondary' : 'primary'
                            "
                            @onClick="setSubs(items, getTitle(items))"
                            :is-loading="subsLoading"
                            :customStyle="
                              isCurrentPlan(items)
                                ? 'dark:bg-lightTextColor/70'
                                : ''
                            "
                          />
                        </div>
                        <div v-else class="md:flex hidden space-x-3 items-end space-y-2 -bg-red-200 flex-col">
                              <span class="text-[13px] text-[#666666] dark:text-[#c4c4c5] text-right">Your subscription will be active on {{subscriptionDate}}</span>
                            <div class="flex items-center space-x-2">
                              <span class="text-[12px] rounded-[12px] bg-[#f0f0f1] text-[#666666] font-normal cursor-default dark:bg-[#747579] dark:text-[#c4c4c5] font-semibold px-3 py-1">Pending</span>
                              <span @click="cancelSubscription()" class="text-[12px] rounded-[12px] cursor-pointer -text-white text-[red] hover:bg-[#ed9a9a] font-semibold bg-[#f4b5b5]  -hover:bg-[#2959a5] -bg-[#22509a]  px-3 py-1">Cancel</span>
                            </div>

                        </div>
                      </div>
                      <div v-if="!isPendingPlan(items)" class="md:hidden flex space-x-3 items-center pt-4">
                        <CustomButton
                          title="Details"
                          buttonType="secondary"
                          @onClick="showDetailsMonthly(items)"
                          customStyle="dark:bg-lightTextColor/40"
                        />
                        <CustomButton
                          :disabled-val="isCurrentPlan(items)"
                          :title="getTitle(items)"
                          :buttonType="
                            isCurrentPlan(items) ? 'secondary' : 'primary'
                          "
                          @onClick="setSubs(items, getTitle(items))"
                          :is-loading="subsLoading"
                        />
                      </div>
                      <div v-else class="md:hidden flex space-x-3 items-end space-y-2 -bg-red-200 flex-col">
                              <span class="text-[13px] text-[#666666] dark:text-[#c4c4c5] text-right">Your subscription will be active on {{subscriptionDate}}</span>
                            <div class="flex items-center space-x-2">
                              <span class="text-[12px] rounded-[12px] bg-[#f0f0f1] text-[#666666] font-normal cursor-default dark:bg-[#747579] dark:text-[#c4c4c5] font-semibold px-3 py-1">Pending</span>
                              <span @click="cancelSubscription()" class="text-[12px] rounded-[12px] cursor-pointer -text-white text-[red] hover:bg-[#ed9a9a] font-semibold bg-[#f4b5b5]  -hover:bg-[#2959a5] -bg-[#22509a]  px-3 py-1">Cancel</span>
                            </div>

                        </div>
                    </CardBox>
                  </div>
                </div>
                <!-- Monthly Subscription Plans End -->

                <!-- Yearly Subscription Plans Strat -->
                <div v-else>
                  <div
                    v-for="(items, index) in subscriptionPlans.yearly"
                    :key="index"
                    class="flex flex-col space-y-4 pt-4"
                  >
                    <CardBox :customClass="getClasses(items)">
                      <div class="flex items-center justify-between">
                        <div class="flex items-center space-x-4">
                          <img :src="getPlanImage(items.db_name)" alt="" />

                          <div class="flex flex-col space-y-2">
                            <div class="flex items-center space-x-2">
                              <div v-if="isCurrentPlan(items)">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                    fill="#3EAF3F"
                                  />
                                </svg>
                              </div>
                              <span
                                class="text-black dark:text-white text-[15px] font-semibold"
                                >{{ items.title }}</span
                              >
                              <div
                                v-if="isCurrentPlan(items)"
                                class="bg-[#CAFFD9] px-2 py-1 flex items-center justify-center rounded-xl"
                              >
                                <span
                                  class="text-[11px] text-[#007922] font-semibold"
                                >
                                  Active
                                </span>
                              </div>
                            </div>
                            <span
                              class="text-black dark:text-white text-[14px]"
                              v-if="!isCurrentPlan(items)"
                              >${{ items?.amount === 319 ? '39.99' : items?.amount === 539 ? '49.99' : '89.99' }}/mo</span
                            >
                            <span
                              class="text-lightTextColor dark:text-white text-[12px] font-semibold"
                              v-else
                              >{{
                                `${
                                  plansInfo?.subscription_period_number === 12
                                    ? "Yearly"
                                    : "Monthly"
                                } Billing Cycle`
                              }}</span
                            >
                            <span
                            v-if="isCurrentPlan(items)"
                              class="text-lightTextColor dark:text-darkTextColor text-[12px]"
                              >Renew on {{ subscriptionDate }}</span
                            >
                          </div>
                        </div>
                        <div v-if="!isPendingPlan(items)" class="md:flex hidden space-x-3 items-center">
                          <CustomButton
                            title="Details"
                            buttonType="secondary"
                            @onClick="showDetailsYearly(items)"
                            customStyle="dark:bg-lightTextColor/40 dark:hover:bg-lightTextColor/20"
                          />
                          <CustomButton
                            :disabled-val="isCurrentPlan(items)"
                            :title="getTitle(items)"
                            :buttonType="
                              isCurrentPlan(items) ? 'secondary' : 'primary'
                            "
                            @onClick="setSubs(items, getTitle(items))"
                            :is-loading="subsLoading"
                            :customStyle="
                              isCurrentPlan(items)
                                ? 'dark:bg-lightTextColor/70'
                                : ''
                            "
                          />
                        </div>
                        <div v-else class="md:flex hidden space-x-3 items-end space-y-2 -bg-red-200 flex-col">
                              <span class="text-[13px] text-[#666666] dark:text-[#c4c4c5] text-right">Your subscription will be active on {{subscriptionDate}}</span>
                            <div class="flex items-center space-x-2">
                              <span class="text-[12px] rounded-[12px] bg-[#f0f0f1] text-[#666666] font-normal cursor-default dark:bg-[#747579] dark:text-[#c4c4c5] font-semibold px-3 py-1">Pending</span>
                              <span @click="cancelSubscription()" class="text-[12px] rounded-[12px] cursor-pointer -text-white text-[red] hover:bg-[#ed9a9a] font-semibold bg-[#f4b5b5]  -hover:bg-[#2959a5] -bg-[#22509a]  px-3 py-1">Cancel</span>
                            </div>

                        </div>
                      </div>
                      <div v-if="!isPendingPlan(items)" class="md:hidden flex space-x-3 items-center pt-4">
                        <CustomButton
                          title="Details"
                          @onClick="showDetailsYearly(items)"
                          buttonType="secondary"
                          customStyle="dark:bg-lightTextColor/40"
                        />
                        <CustomButton
                          :disabled-val="isCurrentPlan(items)"
                          :title="getTitle(items)"
                          :buttonType="
                            isCurrentPlan(items) ? 'secondary' : 'primary'
                          "
                          @onClick="setSubs(items, getTitle(items))"
                          :is-loading="subsLoading"
                        />
                      </div>
                      <div v-else class="md:hidden flex space-x-3 items-end space-y-2 -bg-red-200 flex-col">
                              <span class="text-[13px] text-[#666666] dark:text-[#c4c4c5] text-right">Your subscription will be active on {{subscriptionDate}}</span>
                            <div class="flex items-center space-x-2">
                              <span class="text-[12px] rounded-[12px] bg-[#f0f0f1] text-[#666666] font-normal cursor-default dark:bg-[#747579] dark:text-[#c4c4c5] font-semibold px-3 py-1">Pending</span>
                              <span @click="cancelSubscription()" class="text-[12px] rounded-[12px] cursor-pointer -text-white text-[red] hover:bg-[#ed9a9a] font-semibold bg-[#f4b5b5]  -hover:bg-[#2959a5] -bg-[#22509a]  px-3 py-1">Cancel</span>
                            </div>

                        </div>
                    </CardBox>
                  </div>
                </div>
                <!-- Yearly Subscription Plans End -->

                <!-- legacy users  -->
                <div v-if="subscriptionPlans?.legacy?.length > 0">
                  <!-- <div class="pt-4 text-black dark:text-white font-bold text-[19px]">
                    <span>Legacy User</span>
                  </div> -->
                  <div
                    v-for="(items, index) in subscriptionPlans?.legacy"
                    :key="index"
                    class="flex flex-col space-y-4 pt-4"
                  >
                    <CardBox :customClass="getClasses(items) +' bg-[#f4f5f7] hover:bg-[#f4f5f7] dark:hover:bg-[#38393d] dark:bg-[#38393d]'" v-if="isCurrentPlan(items)">
                      <div class="flex items-center justify-between">
                        <div class="flex items-center space-x-4">
                          <img :src="getPlanImage(items.db_name)" alt="" />
                          <div class="flex flex-col space-y-2">
                            <div class="flex items-center space-x-2">
                              <span
                                class="text-black dark:text-white text-[15px] font-semibold"
                                >{{ items.title }}</span
                              >
                            </div>
                            <span
                              class="text-black dark:text-white text-[14px]"
                              
                              >${{ items?.amount }}/mo</span
                            >
                          </div>
                        </div>
                        <div class="md:flex hidden space-x-3 items-center">
                          <CustomButton
                            :disabled-val="isCurrentPlan(items)"
                            title="Details"
                            buttonType="secondary"
                            customStyle="dark:bg-lightTextColor/70 pointer-events-none bg-[#dedfe0]"
                          />
                          <CustomButton
                            :disabled-val="isCurrentPlan(items)"
                            :title="getTitle(items)"
                            buttonType="secondary"
                            customStyle="dark:bg-lightTextColor/70 pointer-events-none bg-[#dedfe0]"
                          />
                        </div>
                      </div>
                   
                    </CardBox>
                  </div>
                </div>
                
              </CardBox>
            </div>
            <div v-else>
              <LoaderComponent />
            </div>
          </div>
        </div>
      </div>
    </div>

    <SubscribePlanModal
      v-if="isShowModal"
      :planInfo="planInfo"
      @onClose="closeModal"
      :updateStatus="updateStatus"
      @showProfileCompleted="showProfileCompletedHandler"
      @isDetailLoading="isDetailLoadingHandler"
      @isUpdateCard="isUpdateCardHandler"
    />
    <PlanDetailModal
      :planInfo="setDetails"
      v-if="showDetailToggle"
      @onClose="closeDetailModal"
    />

    <BaseModal v-if="updateCardToggle">
      <div class="p-2">
        <div class="flex w-full justify-end">
          <div
            class="p-1 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
            @click="addNewCardToggle = false"
          >
            <CrossIconSvg />
          </div>
        </div>

        <CreditCardComponent :isModalForm="true" @onClose="onCloseHandler" />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import CardBox from "@/components/SharedComponents/CardBox.vue";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import PlansSummaryPage from "@/components/SettingsComp/PlansSummaryPage.vue";
import NotificationComp from "../SharedComponents/Notification.vue";
import store, { getUserDetailsFromStore } from "@/store";
import SubscribePlanModal from "./subscribePlanModal.vue";
import userApi from "@/services/userApi";
import LoaderComponent from "@/components/SharedComponents/LoaderComponent.vue";
import CheckBox from "../SharedComponents/CheckBox.vue";
import vClickOutside from "vue-click-outside";
import PlanDetailModal from "../PlanDetailModal/index.vue";
import CreditCardComponent from "../SettingsComp/CreditCardComponent.vue";
// import moment from "moment";
// import StandardPlanSvg from "../../assets/svgs/standardSvg.vue";
// import GoldPlanSvg from "../../assets/svgs/goldSvg.vue";
// import PlatinumPlanSvg from "../../assets/svgs/platinumSvg.vue";

// import PlanIcon from "@/components/PlanIcon";
import BaseModal from "../SharedComponents/BaseModal.vue";
export default {
  name: "subscriptionBilling",
  setup() {
    return {
      store,
    };
  },
  directives: {
    "click-outside": vClickOutside,
  },
  data() {
    return {
      subscriptionPlans: {},
      initialPlan: "Standard Plan",
      subscriptionCancelError:'',
      subsLoading: false,
      isShowModal: false,
      isShowComplete: false,
      planInfo: {},
      updateCardToggle: false,
      updateStatus: "",
      isLoading: false,
      isActiveToggle: false,
      isAutoToggle: false,
      showDetailToggle: false,
      currentPlanSubs: "",
      subscriptionDate:"",
      monthlyDetails: [
        {
          title: "Standard Plan",
          price: "44.99",
          planName: "Monthly",
          image: require("../../assets/svgs/standard.svg"),
          details: ["$5 Spreads", "2 Trades Allowed"],
        },
        {
          title: "Gold Plan",
          planName: "Monthly",
          price: "54.99",

          image: require("../../assets/svgs/goldplan.svg"),
          details: [
            "$5 and $10 Spreads",
            "4 Trades Allowed",
            "Monthly Newsletter",
            "Webinar",
            "Priority Support",
          ],
        },
        {
          title: "Platinum Plan",
          planName: "Monthly",
          price: "94.99",

          image: require("../../assets/svgs/platinumplan.svg"),
          details: [
            "$5 and $10 Spreads",
            "Unlimited Trades Allowed",
            "Weekly & Monthly Newsletter",
            "Webinar",
            "Trade Risk History",
            "Trade Assistance Pro (Direct Call)",
            "Priority Support",
          ],
        },
      ],
      yearlyDetails: [
        {
          title: "Standard Plan",
          price: "39.99",
          planName: "Yearly",
          image: require("../../assets/svgs/standard.svg"),
          details: ["$5 Spreads", "2 Trades Allowed"],
        },
        {
          title: "Gold Plan",
          planName: "Yearly",
          price: "49.99",

          image: require("../../assets/svgs/goldplan.svg"),
          details: [
            "$5 and $10 Spreads",
            "4 Trades Allowed",
            "Monthly Newsletter",
            "Webinar",
            "Priority Support",
          ],
        },
        {
          title: "Platinum Plan",
          planName: "Yearly",
          price: "89.99",

          image: require("../../assets/svgs/platinumplan.svg"),
          details: [
            "$5 and $10 Spreads",
            "Unlimited Trades Allowed",
            "Weekly & Monthly Newsletter",
            "Webinar",
            "Trade Risk History",
            "Trade Assistance Pro (Direct Call)",
            "Priority Support",
          ],
        },
      ],
      images: {
        new_monthly_standard: require("../../assets/svgs/standard.svg"),
        new_monthly_gold: require("../../assets/svgs/goldplan.svg"),
        new_monthly_platinum: require("../../assets/svgs/platinumplan.svg"),
        new_yearly_standard: require("../../assets/svgs/standard.svg"),
        new_yearly_gold: require("../../assets/svgs/goldplan.svg"),
        new_yearly_platinum: require("../../assets/svgs/platinumplan.svg"),
      },
      isMobileOrTabletDevice:
        /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|Tablet|Kindle/i.test(
          navigator.userAgent
        ),
      setDetails: {},
    };
  },
  components: {
    CardBox,
    CustomButton,
    PlansSummaryPage,
    NotificationComp,
    SubscribePlanModal,
    LoaderComponent,
    CheckBox,
    PlanDetailModal,
    CreditCardComponent,
    BaseModal,
    // StandardPlanSvg,
    // GoldPlanSvg,
    // PlatinumPlanSvg,
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    currentPlan() {
      return store.getters.getCurrentPricing;
    },
    isProfileNotComplete() {
      let user = getUserDetailsFromStore();
      console.log("userDetails", user);
      if (user?.is_profile_complete) {
        return false;
      }
      return true;
    },

    plansInfo() {
      return store.getters.getCurrentPricing;
    },
    isPlanGiven() {
      let userDetails = getUserDetailsFromStore();

      let profile_status = userDetails["profile_status"];
      return profile_status?.["plan"] ?? false;
    },
    isProfileComplete() {
      let user = this.userDetails;
      return user?.is_profile_complete ?? false;
    },
  },
  methods: {
    async cancelSubscription(){
      this.isLoading = true;
      try{
        const response = await userApi.cancelSubscription();
        if(response?.data?.status){
          await this.getUserDetails()
          this.isLoading = false;
        }else{
          this.subscriptionCancelError = "Failded to cancel subscription"
          setTimeout(() => {
            this.subscriptionCancelError = ""
          }, 3000);
        }
      }catch(e){
        console.log("e",e)
        this.isLoading = false;
        this.subscriptionCancelError = "Failded to cancel subscription"
          setTimeout(() => {
            this.subscriptionCancelError = ""
          }, 3000);
      }
      this.isLoading = false;
    },
    async getUserDetails() {
      try {
        const response = await userApi.userDetails();
        if (response?.data?.status) {
          let userDetail = response.data.user_detail;
          store.commit("SET_USER_DETAIL", userDetail);
          this.$emit("isDetailLoading", true);
        }
      } catch (err) {
        console.log(err);

      }
    },

    getTitle(item) {
      if (this.isProfileComplete) {
        let isCurrentVal = this.isCurrentPlan(item);

        if (isCurrentVal) {
          return "Current";
        } else {
          const currentSubs = [
            ...this.subscriptionPlans.yearly,
            ...this.subscriptionPlans.monthly,
          ].find((item) => item.id === this.userDetails?.subscription);
          store.commit("SET_CURRENT_PRICING", currentSubs);

          console.log("current plan",this.currentPlan?.id)

          if (this.currentPlan?.id > item?.id ) {
            return "Downgrade";
          } else if (this.currentPlan?.id < item?.id) {
            return "Upgrade";
          } else {
            return 'Upgrade'
            // let isInit = item?.amount == currentSubs?.amount;
            // return isInit ? "Upgrade" : "Downgrade";
          }
        }
      }
      return "Subscribe";
    },
    closeDetailModal() {
      this.showDetailToggle = false;
    },
    isUpdateCardHandler(val) {
      console.log("update", val);
      this.updateCardToggle = val;
    },
    showDetailsMonthly(items) {
      if (items.db_name === "new_monthly_standard") {
        this.setDetails = this.monthlyDetails[0];
      } else if (items.db_name === "new_monthly_gold") {
        this.setDetails = this.monthlyDetails[1];
      } else {
        this.setDetails = this.monthlyDetails[2];
      }
      this.showDetailToggle = true;
    },
    showDetailsYearly(items) {
      if (items.db_name === "new_yearly_standard") {
        this.setDetails = this.yearlyDetails[0];
      } else if (items.db_name === "new_yearly_gold") {
        this.setDetails = this.yearlyDetails[1];
      } else {
        this.setDetails = this.yearlyDetails[2];
      }
      this.showDetailToggle = true;
    },
    isDetailLoadingHandler(val) {
      if (val) {
        this.getSubscriptions();
      }
    },
    getPlanImage(dbName) {
      return this.images[dbName];
    },
    async getSubscriptions() {
      this.isLoading = true;
      try {
        const response = await userApi.getSubscriptionPlans();
        if (response?.data?.status) {
          console.log("response?.data?.list", response);
          this.subscriptionPlans = response?.data?.list;
          const currentSubs = [
            ...response?.data?.list.yearly,
            ...response?.data?.list.monthly,
          ].find((item) => item.id === this.userDetails?.subscription);
          console.log("currentSubs", currentSubs.subscription_period_number);
          if(currentSubs.subscription_period_number === 1){
            // this.subscriptionDate =  moment(this.userDetails.activation_time).add(1,'month').format("MM/DD/YYYY")
            const newDate = new Date(this.userDetails.activation_time * 1000)
            let day = newDate.getDate();
            let month = newDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 for January)
            let year = newDate.getFullYear()+1;
            if (month === 12) {
                month = 1; // Reset month to January
                year++;    // Increment year
                this.subscriptionDate =  month + "/" + day + "/" + year
            } else {
                month++;   // Increment month
                this.subscriptionDate =  month + "/" + day + "/" + year
            }
          } else {
            const newDate = new Date(this.userDetails.activation_time * 1000)
            let day = newDate.getDate();
            let month = newDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month (0 for January)
            let year = newDate.getFullYear()+1;
            this.subscriptionDate =  month + "/" + day + "/" + year
            console.log("newDate 2", month + "/" + day + "/" + year);
          }
      
          this.currentPlanSubs = currentSubs;

          this.isActiveToggle =
            currentSubs?.subscription_period_number == 12 ? true : false;
          this.isLoading = false;
        } else {
          console.log("error", response?.data);
          this.isLoading = false;
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    isCurrentPlan(item) {
      return item.id == this.userDetails?.subscription;
    },


    isPendingPlan(item){
      return item?.id == this.userDetails?.pending_subscription;
    },

    toggleSwitchHandler(val) {
      this.isActiveToggle = val;
    },
    toggleAutoHandler(val) {
      this.isAutoToggle = val;
    },

    startedClickHandler(val) {
      this.isShowComplete = val;
    },
    showProfileCompletedHandler(val) {
      this.isShowComplete = val;
    },
    setSubs(items, status) {
      this.planInfo = items;
      this.updateStatus = status;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    onCloseHandler() {
      this.updateCardToggle = false;
    },
    getClasses(item) {
      let borderColor = this.isCurrentPlan(item)
        ? "border-[#E7E7E7]"
        : "border-[#E7E7E7]";
      return `border-2 ${borderColor} rounded-3xl w-full !p-4 hover:bg-gray-50`;
    },
  },
  mounted() {
    this.getSubscriptions();
  },
};
</script>
