// tourService.js
import introJs from "intro.js";

const intro = introJs();

export default {
  startTour(steps, onTourEnd) {
    intro.setOptions({
      steps,
    });
    intro.start();

    intro.onexit(() => {
      // Perform any actions before or after the tour ends

      // Call the provided onTourEnd callback function if it's a function
      if (typeof onTourEnd === "function") {
        onTourEnd();
      }
    });
  },
};
