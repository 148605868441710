<template>
  <div :class="notificationClasses" class="rounded-3xl px-4 py-6">
    <div class="flex space-x-3">
      <div class="pt-1">
        <svg
          v-if="isSuccess"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
            fill="#3EAF3F"
          />
        </svg>

        <WarningIconSvg :fill="fill" v-else />
      </div>
      <div class="flex items-center justify-between w-full">
        <div class="flex flex-col space-y-1">
          <p :class="`text-[${textColor}] text-[16px] font-semibold`">
            {{ title }}
          </p>
          <p
            :class="`text-[${textColor}] opacity-70 text-[12px] font-semibold`"
          >
            {{ desc }}
          </p>
        </div>
        <div v-if="isSuccess">
          <CustomButton
            buttonType="success"
            title="Continue"
            @onClick="onClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarningIconSvg from "../../assets/svgs/WarningIconSvg.vue";
import CustomButton from "../SharedComponents/CustomButton.vue";
export default {
  name: "NotificationComp",
  components: {
    WarningIconSvg,
    CustomButton,
  },
  props: {
    bgColor: {
      type: String,
      default: "#FFF9E3",
    },
    title: {
      type: String,
      default: "Notification",
    },
    desc: {
      type: String,
      default: "Some Notification",
    },
    textColor: {
      type: String,
      default: "#C27500",
    },
    fill: {
      type: String,
      default: "#C27500",
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    notificationClasses() {
      return {
        "bg-[#FFF9E3]": this.bgColor === "#FFF9E3",
        "bg-[#FFE3E3]": this.bgColor === "#FFE3E3",
        "bg-[#FFC0411A]": this.bgColor === "darkWarning",
        "bg-white": this.bgColor === "white",
        "bg-[#5d5d5d40]": this.bgColor === "dark",
        // Add other background color classes based on conditions

        "text-[#C27500]": this.textColor === "#C27500",
        "text-[#C20000]": this.textColor === "#C20000",
        "text-black": this.textColor === "#000000",
        "text-white": this.textColor === "white",
        "text-[#FFC041]": this.textColor === "textDark",

        // Add other text color classes based on conditions
      };
    },
  },
};
</script>
