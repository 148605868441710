<template>
  <div class="flex items-center justify-center -bg-red-900 -bg-[#f5f5f5] w-full h-screen md:px-0 px-2  ">
    
    <div class="lg:h-[80vh] sm:h-[55vh] h-[70vh] md:w-fit w-full bg-white rounded-3xl md:px-9 px-5 md:py-7 py-5 md:-mt-16 -mt-28">
      <div class="flex flex-col items-center h-full w-full sm:space-y-7 space-y-2">

        <div class="flex justify-center items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9F580B"
          class="lg:h-7 sm:h-10 h-9 lg:w-8 lg:mt-2 sm:mt-1 mt-0 sm:w-8 w-7 fill-nones">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
          </svg>
          <span class=" text-yellow-500 font-bold lg:text-[29px] sm:text-[35px] text-[25px] ">Warning!</span>

        </div>


        <div class="flex flex-col justify-center items-center sm:ml-16 sm:mr-16 lg:space-y-5 sm:space-y-8 space-y-3">
          <span class="lg:text-[17px] sm:text-[20px] text-[19px] text-center text-yellow-500">Please verify your email to access your account.
</span>

          <span class="lg:text-[17px] sm:text-[20px] text-[19px] text-center text-gray-600">We have already sent you an account verification link
          to your email address </span>

 
          <!-- <span class=" text-btnPrimary font-semibold text-center text-[14px]">abc@xyz.com</span> -->
        <span class="lg:text-[20px] lg:h-20 lg:flex lg:items-center sm:text-[25px] text-[19px] text-center text-btnPrimary font-bold ">
          {{ contactEmail }}
          <!-- ubaid.rehman@dimensionalasdfasdfasdfasdfassys.com -->
        </span>


        <span class="lg:text-[17px] sm:text-[20px] text-[19px] text-center text-gray-600">
          Click the blue button in the email to access
          your
          account. </span>


          <svg class="sm:w-40 w-28 fill-none" viewBox="0 0 156 141" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_404_19519)">
              <path
                d="M154.337 54.0976C154.282 54.0977 154.227 54.0806 154.182 54.0488L78.7823 1.53848C78.5529 1.37943 78.2802 1.29442 78.001 1.2949C77.7218 1.29538 77.4494 1.38131 77.2205 1.54114L2.36427 54.0482C2.30506 54.0898 2.23178 54.1061 2.16055 54.0936C2.08932 54.0811 2.02597 54.0408 1.98444 53.9816C1.94291 53.9224 1.9266 53.8491 1.9391 53.7779C1.9516 53.7066 1.99189 53.6433 2.0511 53.6017L76.9074 1.09465C77.2279 0.870914 77.6092 0.750645 78.0001 0.750003C78.391 0.74936 78.7727 0.868375 79.0939 1.09106L154.493 53.6012C154.541 53.6344 154.577 53.6819 154.595 53.7369C154.614 53.7918 154.615 53.8513 154.598 53.9067C154.58 53.9621 154.546 54.0105 154.499 54.0448C154.452 54.0791 154.395 54.0976 154.337 54.0976Z"
                fill="#3F3D56" />
              <path d="M6.64178 55.959L78.0752 3.01807L150.051 59.726L81.7557 100.212L44.6775 91.7605L6.64178 55.959Z"
                fill="#E6E6E6" />
              <path
                d="M47.8073 125.218H12.5174C12.3007 125.219 12.0861 125.176 11.8858 125.094C11.6856 125.011 11.5035 124.889 11.3502 124.736C11.1969 124.583 11.0753 124.401 10.9923 124.201C10.9093 124.001 10.8666 123.786 10.8666 123.57C10.8666 123.353 10.9093 123.138 10.9923 122.938C11.0753 122.738 11.1969 122.556 11.3502 122.403C11.5035 122.25 11.6856 122.129 11.8858 122.046C12.0861 121.963 12.3007 121.921 12.5174 121.921H47.8073C48.0239 121.921 48.2386 121.963 48.4388 122.046C48.6391 122.129 48.8211 122.25 48.9744 122.403C49.1278 122.556 49.2494 122.738 49.3324 122.938C49.4154 123.138 49.4581 123.353 49.4581 123.57C49.4581 123.786 49.4154 124.001 49.3324 124.201C49.2494 124.401 49.1278 124.583 48.9744 124.736C48.8211 124.889 48.6391 125.011 48.4388 125.094C48.2386 125.176 48.0239 125.219 47.8073 125.218Z"
                fill="#00CE5E" />
              <path
                d="M25.1786 118.131H12.5174C12.3007 118.131 12.0861 118.089 11.8858 118.006C11.6856 117.923 11.5035 117.802 11.3502 117.649C11.1969 117.496 11.0753 117.314 10.9923 117.114C10.9093 116.913 10.8666 116.699 10.8666 116.482C10.8666 116.266 10.9093 116.051 10.9923 115.851C11.0753 115.651 11.1969 115.469 11.3502 115.316C11.5035 115.163 11.6856 115.041 11.8858 114.958C12.0861 114.876 12.3007 114.833 12.5174 114.833H25.1786C25.3953 114.833 25.6099 114.876 25.8102 114.958C26.0105 115.041 26.1925 115.163 26.3458 115.316C26.4991 115.469 26.6208 115.651 26.7038 115.851C26.7868 116.051 26.8295 116.266 26.8295 116.482C26.8295 116.699 26.7868 116.913 26.7038 117.114C26.6208 117.314 26.4991 117.496 26.3458 117.649C26.1925 117.802 26.0105 117.923 25.8102 118.006C25.6099 118.089 25.3953 118.131 25.1786 118.131Z"
                fill="#00CE5E" />
              <path
                d="M79.0797 85.4456C78.8115 85.4459 78.5458 85.3939 78.2975 85.2924L34.2423 67.0126V9.79381C34.2429 9.28785 34.4441 8.80277 34.8019 8.445C35.1597 8.08722 35.6448 7.88597 36.1507 7.88538H120.667C121.173 7.88597 121.658 8.08722 122.016 8.445C122.374 8.80277 122.575 9.28785 122.576 9.79381V67.0526L122.493 67.088L79.8856 85.2835C79.6305 85.3907 79.3565 85.4458 79.0797 85.4456Z"
                fill="white" />
              <path
                d="M79.0797 85.5819C78.7936 85.5822 78.5103 85.5268 78.2455 85.4187L34.106 67.1038V9.7939C34.1066 9.25179 34.3222 8.73205 34.7055 8.34872C35.0889 7.96539 35.6086 7.74976 36.1507 7.74915H120.667C121.209 7.74976 121.729 7.96539 122.112 8.34872C122.496 8.73205 122.711 9.25179 122.712 9.7939V67.1426L79.9391 85.4089C79.667 85.5233 79.3748 85.5822 79.0797 85.5819ZM34.6512 66.7396L78.4534 84.9146C78.862 85.0803 79.3196 85.0776 79.7261 84.907L122.167 66.7827V9.7939C122.166 9.39636 122.008 9.01523 121.727 8.73412C121.446 8.45302 121.065 8.29488 120.667 8.29441H36.1507C35.7532 8.29488 35.3721 8.45302 35.0909 8.73412C34.8098 9.01523 34.6517 9.39636 34.6512 9.7939L34.6512 66.7396Z"
                fill="#3F3D56" />
              <path
                d="M153.792 53.5525H153.738L122.439 66.917L79.6167 85.2026C79.4494 85.273 79.2699 85.3097 79.0883 85.3106C78.9068 85.3116 78.7269 85.2767 78.5588 85.208L34.3785 66.8788L2.31126 53.5743L2.26227 53.5525H2.20769C1.70172 53.5531 1.21662 53.7543 0.858842 54.1121C0.501062 54.4699 0.299817 54.955 0.299255 55.4609V138.342C0.299818 138.848 0.501063 139.333 0.858842 139.691C1.21662 140.048 1.70172 140.25 2.20769 140.25H153.792C154.298 140.25 154.783 140.048 155.141 139.691C155.499 139.333 155.7 138.848 155.701 138.342V55.4609C155.7 54.955 155.499 54.4699 155.141 54.1121C154.783 53.7543 154.298 53.5531 153.792 53.5525ZM155.155 138.342C155.155 138.703 155.012 139.05 154.756 139.305C154.5 139.561 154.154 139.705 153.792 139.705H2.20769C1.8462 139.705 1.49956 139.561 1.24395 139.305C0.988335 139.05 0.844668 138.703 0.844524 138.342V55.4609C0.845018 55.1084 0.981757 54.7697 1.22615 54.5157C1.47055 54.2616 1.80368 54.1119 2.15591 54.0978L34.3785 67.4677L78.349 85.7124C78.825 85.9061 79.3583 85.9031 79.8321 85.7042L122.439 67.5086L153.847 54.0978C154.198 54.1129 154.531 54.2631 154.774 54.517C155.018 54.7709 155.155 55.109 155.155 55.4609V138.342Z"
                fill="#3F3D56" />
              <path
                d="M78.6394 69.2214C77.013 69.2244 75.4297 68.6985 74.1284 67.723L74.0477 67.6624L57.0596 54.6557C56.2729 54.0529 55.6125 53.301 55.1164 52.443C54.6202 51.585 54.2979 50.6377 54.1678 49.6551C54.0377 48.6725 54.1025 47.674 54.3583 46.7164C54.6142 45.7589 55.0561 44.8611 55.6589 44.0743C56.2618 43.2876 57.0136 42.6273 57.8716 42.1311C58.7296 41.6349 59.677 41.3126 60.6595 41.1825C61.6421 41.0525 62.6407 41.1172 63.5982 41.373C64.5558 41.6289 65.4536 42.0708 66.2403 42.6737L77.2439 51.1111L103.247 17.199C103.85 16.4125 104.602 15.7524 105.461 15.2565C106.319 14.7606 107.266 14.4386 108.249 14.3089C109.231 14.1792 110.23 14.2443 111.187 14.5005C112.145 14.7567 113.042 15.199 113.829 15.8022L113.667 16.0217L113.833 15.8054C115.42 17.0247 116.458 18.8237 116.72 20.8075C116.982 22.7913 116.446 24.7979 115.23 26.3872L84.644 66.2759C83.9366 67.1951 83.0269 67.939 81.9856 68.4498C80.9443 68.9606 79.7992 69.2246 78.6394 69.2214Z"
                fill="#00CE5E" />
            </g>
            <defs>
              <clipPath id="clip0_404_19519">
                <rect width="155.401" height="139.5" fill="white" transform="translate(0.299316 0.75)" />
              </clipPath>
            </defs>
          </svg>
        </div>

          <CustomButton buttonType="primary" customStyle="w-80 sm:mt-0 mt-3  lg:h-full sm:h-14 h-18" title="Back to login" @onClick="backToLogin">
        </CustomButton>
          

      </div>
    </div>

  </div>
</template>


<script>
// import userApi from "@/services/userApi";
// import router from "@/router";
import CustomButton from "@/components/SharedComponents/LoginButtons.vue";
// import store from '@/store';
// import CustomButton from "@/components/SharedComponents/CustomButton.vue";
export default {
  name: "AfterSignInWarning",
  props: {
    contactEmail: {},
    isModal:Boolean
  },
  methods: {
    backToLogin() {
      // location.replace('/login')
      // router.push('/login')
      const newValue = !this.isModal;
      // Emit an event to the parent component with the updated value
      this.$emit('updateValue', newValue);
      // this.$emit('backLogin')
    }
  },
  components: {
    CustomButton

    // CustomButton
  }
};
</script>