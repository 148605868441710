<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_224_1215)">
      <path
        d="M8.00004 14.7015C11.6819 14.7015 14.6667 11.7167 14.6667 8.03483C14.6667 4.35293 11.6819 1.36816 8.00004 1.36816C4.31814 1.36816 1.33337 4.35293 1.33337 8.03483C1.33337 11.7167 4.31814 14.7015 8.00004 14.7015Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.06006 6.03554C6.21679 5.58998 6.52616 5.21427 6.93336 4.97496C7.34056 4.73564 7.81932 4.64816 8.28484 4.72801C8.75036 4.80786 9.1726 5.04989 9.47678 5.41122C9.78095 5.77256 9.94743 6.22988 9.94673 6.7022C9.94673 8.03554 7.94673 8.7022 7.94673 8.7022"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11.3682H8.00667"
        :stroke="isActive ? activeColor : disableColor"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_224_1215">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.0351562)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import { watch, ref } from "vue";
import store from "@/store";
export default {
  name: "HelpSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
