<template>
  <div>
    <div v-if="inputType === 'checkbox'" class="w-full h-full">
      <input
        id="default-checkbox"
        @click="handleClick"
        type="checkbox"
        :disabled="isDisable"
        :checked="isChecked"
        :class="`${isDisable ? 'cursor-not-allowed' : 'cursor-pointer'}`"
        class="w-5 h-5 text-btnPrimary bg-gray-100 border-gray-400 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-darkTextColor dark:border-gray-600"
      />
    </div>

    <div
      v-if="inputType === 'switch'"
      :class="`${
        isActive ? 'bg-btnPrimary' : 'bg-gray-300'
      } w-9 h-6 flex items-center  rounded-full border cursor-pointer border-gray-400 transition duration-300 ease-in-out`"
      @click="toggleSwitch"
    >
      <div
        :class="{ 'translate-x-full': isActive }"
        class="w-4 h-4 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out"
      ></div>
    </div>
  </div>
</template>

<script>
import LoaderSvg from "../../assets/svgs/loader.svg";

export default {
  setup() {
    return {
      LoaderSvg,
      // isActive: false,
    };
  },
  name: "CheckBox",
  data() {
    return {};
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    inputType: String,
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("checkbox-clicked");
    },
    toggleSwitch() {
      this.$emit("toggleSwitch", !this.isActive);
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
