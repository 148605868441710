<template>
  <BaseModal>
    <div class=" h-[55vh] sm:w-[20vw]  py-5 sm:px-3 px-3 border dark:border-darkBackground rounded-2xl m-1">
      <div class="w-full flex justify-between ">
        <div class="flex items-center space-x-2">
          <div>
            <img :src="planInfo?.image" />
          </div>
          <div class="flex flex-col">
            <div
              class="flex items-center space-x-1 text-[17px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >
              <p>{{ planInfo.title }}</p>
              <p>({{ planInfo.planName }})</p>
            </div>
            <div>
              <p class="text-[#3EAF3F] font-semibold text-[16px]">
                ${{ planInfo?.price }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="hover:bg-gray-200 dark:hover:bg-gray-800 h-full cursor-pointer rounded-full p-2"
          @click="onCloseModal"
        >
          <CrossIconSvg />
        </div>
      </div>
      <div class="flex flex-col space-y-4 pt-6">
        <!-- <p class="text-[14px] text-btnPrimary">
          Things you'll get in this package:
        </p> -->
        <div v-for="(item, index) in planInfo?.details" :key="index">
          <div class="flex items-center space-x-2">
            <img class="h-4 w-4" src="../../assets/svgs/checkPlans.svg" />
            <span
              class="text-lightTextColor dark:text-darkTextColor text-[14px]"
              >{{ item }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/SharedComponents/BaseModal.vue";
import CrossIconSvg from "@/assets/svgs/CrossIconSvg.vue";
export default {
  name: "PlanDetailModal",
  components: {
    BaseModal,
    CrossIconSvg,
  },
  props: {
    planInfo: Object,
  },
  methods: {
    onCloseModal() {
      this.$emit("onClose");
    },
  },
};
</script>
