<template>
  <div class="w-full h-full">
    <div class="w-full justify-center h-full flex items-center pb-6">
      <div
        class="h-full flex items-center justify-center sm:w-7/12 w-full dark:text-white"
      >
        <div class="w-full space-y-6">
          <CardBox
            customClass="border border-[#E7E7E7] h-full w-full rounded-3xl !px-8 py-6"
          >
            <div class="h-full w-full flex flex-row justify-center space-x-2">
              <div
                class="h-full sm:text-[25px] text-[17px] w-9/12 flex flex-col"
              >
                <span class="">We are creating Some</span>
                <span class="font-bold">exciting new features,</span>
                <span class="font-bold">they will be available soon</span>
              </div>
              <div class="h-full w-3/12">
                <img
                  src="../../assets/svgs/vector-icon.svg"
                  v-if="!store.getters.getTheme"
                />
                <img src="../../assets/svgs/vector-icon-dark.svg" v-else />
              </div>
            </div>
          </CardBox>
          <!-- <EmailNotifications />
          <BrowserNotifications />
          <AppNotifications />
          <NotificationInterval /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardBox from "../SharedComponents/CardBox.vue";
import userApi from "@/services/userApi";
// import BrowserNotifications from "../Notifications/BrowserNotifications.vue";
// import EmailNotifications from "../Notifications/EmailNotifications.vue";
// import AppNotifications from "../Notifications/AppNotifications.vue";
// import NotificationInterval from "../Notifications/NotificationInterval.vue";
import store, { getUserDetailsFromStore } from "@/store";
export default {
  name: "NotificationsPage",
  data() {
    return {
      firstName: "",
      lastName: "",
      isPhoneModal: false,
      // OTPCode: "",
      phoneNumber: "",
      phoneMessage: "",
      // isOTPLoader: false,
      isSuccessPhone: false,
      // isValidOTP: "123456",
      // otpSuccess: "",
      isAddressSaving: false,
      // otpFailed: "",
      isVerified: true,
      isActive: false,
      notificationObject: [
        {
          name: "Browser",
          key: "browser",
        },
        {
          name: "Email",
          key: "email",
        },
      ],
    };
  },
  components: {
    CardBox,
    // EmailNotifications,
    // BrowserNotifications,
    // AppNotifications,
    // NotificationInterval,
  },
  methods: {
    handleSave() {
      if (
        this.firstName != "" &&
        this.lastName != "" &&
        this.phoneNumber != ""
      ) {
        let basicPayload = {
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phoneNumber,
        };
        this.isAddressSaving = true;
        userApi
          .updateBasicInfo(basicPayload)
          .then((d) => {
            console.log(d);
            this.isAddressSaving = false;
          })
          .catch((e) => {
            console.log(e);
            this.isAddressSaving = false;
          });
      }
      console.log("firstName", this.firstName, this.lastName, this.phoneNumber);
    },
    onChangeHandler(event) {
      this[event.target.name] = event.target.value;
      if (event.target.name === "phoneNumber") {
        if (this.phoneNumber.length === 3 && !this.phoneNumber.includes("(")) {
          this.phoneNumber = `(${this.phoneNumber}) `;
        }
      }
    },

    toggle() {
      this.isActive = !this.isActive;
    },
  },
  computed: {
    userDetails() {
      let user = getUserDetailsFromStore();
      console.log("notifications", user);
      return user;
    },
  },
  setup() {
    return {
      store,
    };
  },
};
</script>
