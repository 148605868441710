<template>
  <div class="lg:h-full sm:h-[83vh] h-[81vh] w-full overflow-y-auto">
    <img src="../../assets//images//DummyImages/1.png" />
    <img src="../../assets//images//DummyImages/2.png" />
    <img src="../../assets//images//DummyImages/3.png" />
    <img src="../../assets//images//DummyImages/4.png" />
    <img src="../../assets//images//DummyImages/5.png" />
    <img src="../../assets//images//DummyImages/6.png" />
    <img src="../../assets//images//DummyImages/7.png" />
    <img src="../../assets//images//DummyImages/8.png" />
    <img src="../../assets//images//DummyImages/9.png" />
    <img src="../../assets//images//DummyImages/10.png" />
    <img src="../../assets//images//DummyImages/11.png" />
  </div>
  </template>
  
  <script>
  
  export default {
    name: "LayoutPage",

    data() {
    return {
    };
  }
  };
  </script>