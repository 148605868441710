<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <div
      role="status"
      class="w-full flex-col space-y-2 flex justify-center animate-pulse"
    >
      <!-- Use a loop to generate loading lines -->
      <div
        v-for="index in loadingLines"
        :key="index"
        class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"
        :style="{ width: `${70 + index * 80}px` }"
      ></div>

      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    loadingLines: {
      type: Number,
      default: 3,
    },
  },
};
</script>
