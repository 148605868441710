<template>
  <div></div>
</template>

<script>

import userApi from "@/services/userApi";
import store from "@/store";
import {afterSigninActivty} from "@/pages/userStory/login/afterSigninActivty";

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'token' parameter
    const token = urlParams.get("token");
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem("isUserAuthenticated", true);
      // Optionally, store user details as well
      // localStorage.setItem('user', JSON.stringify(userDetails));

      userApi.userDetails()
          .then((resp) => {
            store.commit("SET_PROFILE_LOADING", true);
            setTimeout(() => {
              const afterCall = () => {
                console.log("access token complete")
                store.commit("SET_PROFILE_LOADING", false)
               }
              afterSigninActivty(resp, true, afterCall, token);
            }, 10);
          })
          .catch((e) => {
            console.log(e);
            store.commit("SET_PROFILE_LOADING", false);
          });
    }

  }
}
</script>

<style scoped>

</style>