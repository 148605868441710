<template>
  <BaseModal>
    <!-- <div><button @click="testFunction">button</button></div> -->
    <div class="md:w-[30vw] w-full h-full p-4">
      <div class="flex flex-col space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-2">
            <div v-if="updateStatus === 'Downgrade'">
              <WarningIconSvg fill="#E14942" />
            </div>
            <div v-else>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_404_18147)">
                  <path
                    d="M6 21.5L21 6.5L18 3.5L3 18.5L6 21.5Z"
                    stroke="#3EAF3F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 6.5L18 9.5"
                    stroke="#3EAF3F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 3.5C9 4.03043 9.21071 4.53914 9.58579 4.91421C9.96086 5.28929 10.4696 5.5 11 5.5C10.4696 5.5 9.96086 5.71071 9.58579 6.08579C9.21071 6.46086 9 6.96957 9 7.5C9 6.96957 8.78929 6.46086 8.41421 6.08579C8.03914 5.71071 7.53043 5.5 7 5.5C7.53043 5.5 8.03914 5.28929 8.41421 4.91421C8.78929 4.53914 9 4.03043 9 3.5Z"
                    stroke="#3EAF3F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13.5C19 14.0304 19.2107 14.5391 19.5858 14.9142C19.9609 15.2893 20.4696 15.5 21 15.5C20.4696 15.5 19.9609 15.7107 19.5858 16.0858C19.2107 16.4609 19 16.9696 19 17.5C19 16.9696 18.7893 16.4609 18.4142 16.0858C18.0391 15.7107 17.5304 15.5 17 15.5C17.5304 15.5 18.0391 15.2893 18.4142 14.9142C18.7893 14.5391 19 14.0304 19 13.5Z"
                    stroke="#3EAF3F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_404_18147">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span
              class="md:text-[20px] text-[17px] font-bold dark:text-white"
              v-if="!isProfileComplete"
            >
              Subscribe to {{ planInfo?.title }}
            </span>
            <div v-else>
              <span
                class="md:text-[20px] text-[17px] font-bold text-[#E14942]"
                v-if="updateStatus === 'Downgrade'"
              >
                {{ updateStatus }} to {{ planInfo?.title }}
              </span>
              <span
                class="md:text-[20px] dark:text-white text-[17px] font-bold"
                v-else
              >
                {{ updateStatus }} to {{ planInfo?.title }}
              </span>
            </div>
          </div>
          <div
            :class="`${
              isLoading ? 'pointer-events-none' : 'cursor-pointer'
            } hover:bg-gray-200 dark:hover:bg-gray-800 h-fit rounded-full p-2`"
            @click="cancelModal"
          >
            <CrossIconSvg />
          </div>
        </div>
        <div class="">
          <div class="space-y-4">
            <div class="w-full h-full" v-if="subscriptionFailed">
              <NotificationComp
                title="Oh no! Payment Failed"
                :desc="
                  subscriptionError
                    ? subscriptionError
                    : 'Something went wrong.Please try again.'
                "
                bgColor="#FFE3E3"
                textColor="#C20000"
                fill="#C20000"
              />
            </div>

            <p
              class="text-[14px] font-semibold text-[#3D3D3D] dark:text-darkTextColor"
              v-if="!isProfileComplete"
            >
              Are you sure you want to subscribe to
              {{ planInfo?.title }}?
            </p>
            <p
              class="text-[14px] font-semibold text-[#3D3D3D] dark:text-darkTextColor"
              v-else
            >
              Are you sure you want to {{ updateStatus }} your plan?
            </p>

            <div>
              <p
                class="text-[13px] text-[#3D3D3D] dark:text-darkTextColor"
                v-if="updateStatus === 'Downgrade'"
              >
                {{ planInfo?.title }} will be enabled after current billing
                cycle of
                {{ isCurrentPlan.title }}
              </p>
              <p
                class="text-[13px] text-[#3D3D3D] dark:text-darkTextColor"
                v-else
              >
                We will adjust current billing amount to new subscription plan.
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center space-x-4">
          <CustomButton
            v-if="!isLoading"
            buttonType="secondary"
            :title="`${
              isProfileComplete && subscriptionFailed
                ? 'Update Card' // it's working
                : subscriptionFailed && !isProfileComplete ? 
                'Update Card'
                :
                'No, Keep running'
            }`"
            @onClick="onCloseModal"
          />
          <div v-if="!isProfileComplete" class="w-full h-full">
            <CustomButton
              buttonType="success"
              title="Yes, Subscribe Now"
              :isLoading="isLoading"
              @onClick="onSubscribeHandler"
              :loaderText="'Setting up your subscription'"
            />
          </div>
          <div v-else class="w-full h-full">
            <CustomButton
              v-if="updateStatus === 'Downgrade'"
              buttonType="danger"
              title="Yes, Downgrade Now"
              :isLoading="isLoading"
              @onClick="onSubscribeHandler"
              :loaderText="'Setting up your subscription'"
            />
            <CustomButton
              v-else
              buttonType="success"
              title="Yes, Upgrade Now"
              :isLoading="isLoading"
              @onClick="onSubscribeHandler"
              :loaderText="'Setting up your subscription'"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../SharedComponents/BaseModal.vue";
import CustomButton from "../SharedComponents/CustomButton.vue";
import CrossIconSvg from "../../assets/svgs/CrossIconSvg.vue";
import userApi from "@/services/userApi";
import WarningIconSvg from "../../assets/svgs/WarningIconSvg.vue";

import store, { getUserDetailsFromStore } from "@/store";

import NotificationComp from "../SharedComponents/Notification.vue";
export default {
  name: "SubscribePlanModal",
  data() {
    return {
      isLoading: false,
      subscriptionFailed: false,
      subscriptionError: "",
    };
  },
  components: {
    BaseModal,
    CustomButton,
    CrossIconSvg,
    NotificationComp,
    WarningIconSvg,
  },
  props: {
    planInfo: {
      type: Object,
      default: null,
    },
    updateStatus: {
      type: String,
      default: "",
    },
  },
  methods: {
    onCloseModal() {
      if (this.subscriptionFailed) {
        this.$emit("isUpdateCard", true);
      } else {
        this.$emit("onClose");
      }
    },
    cancelModal() {
      this.$emit("onClose");
    },
    async getUserDetails() {
      try {
        const response = await userApi.userDetails();
        console.log("resp detail", response?.data?.status);
        if (response?.data?.status) {
          let userDetail = response.data.user_detail;
          store.commit("SET_USER_DETAIL", userDetail);
          this.$emit("isDetailLoading", true);
          this.onCloseModal();
        }
      } catch (err) {
        console.log(err);

        this.onCloseModal();
      }
    },

    async onSubscribeHandler() {
      this.isLoading = true;

      let payload = {
        plan_name: this.planInfo?.db_name,
      };
      try {
        const response = await userApi.updatePlan(payload);
        if (response?.data?.status) {
          this.isLoading = false;
          this.subscriptionFailed = false;
          if (!this.isProfileComplete) {
            this.$emit("showProfileCompleted", true);
          }

          console.log("resp", response.data);
          // let userDetail = response.data.user_detail;
          // store.commit("SET_USER_DETAIL", userDetail);
          store.commit("SET_CURRENT_PRICING", this.planInfo);

          // this.profileUpdates = this.profileUpdates + 1;
          console.log("initial", response);

          this.getUserDetails();
        } else {
          console.log("this.subscription block", this.subscriptionFailed)
          this.subscriptionFailed = true;
          console.log("this.subscription block", this.subscriptionFailed)
          this.subscriptionError = response?.data?.message;
          this.isLoading = false;
          console.log("err in billing", response);
          // console.log("User detail =", this.getUserDetails())
        }
      } catch (e) {
        console.log("err in billing", e);
        // this.onCloseModal();
        console.log("this.subscription block", this.subscriptionFailed)
        this.subscriptionFailed = true;
        console.log("this.subscription block", this.subscriptionFailed)
        this.subscriptionError = e?.response?.data?.message;
        this.isLoading = false;
      }
    },
  },
  mounted(){
      let user = getUserDetailsFromStore()
      console.log("user checking==>", user.is_profile_complete)
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      let user = this.userDetails
      // console.log("user==>", user)
      return user?.is_profile_complete ?? false
    },
    isCurrentPlan() {
      return store.getters.getCurrentPricing;
    },
  },
};
</script>
