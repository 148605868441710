import store, {updateSideBarByUserDetails} from "@/store";
import router from "@/router";

export function findSettingTabFromUserSettings(nextStep) {
    let settingsTab = "Profile"
    if (nextStep == "address" || nextStep == "billing") {
        settingsTab = "Address & Billing"
    } else if (nextStep == "plan") {
        settingsTab = "Subscription Plans"
    }
    return settingsTab;
}

export function afterSigninActivty(resp, takeToWelcomePage = false,afterCall = null,token=null) {
    let profile = resp.data.user_detail;
    // let nextStep = profile?.next_step;
    let is_profile_complete = profile?.is_profile_complete;
    console.log("token==>",resp.data.token)
    const tokenVal = token ? token : resp?.data?.token
    localStorage.setItem("token", tokenVal);
    store.commit("SET_USER_DETAIL", profile)
    localStorage.setItem("isUserAuthenticated", true);
    if (is_profile_complete || takeToWelcomePage) {
        let location1 = "/members-area";
        router.push(location1,afterCall);

    } else {
        updateSideBarByUserDetails(profile)
        router.push('/settings',afterCall);
    }


    // location.reload()
    // location.replace(location1)
}