<template>
  <BaseModal customClass="dark:bg-[#37383f]">
    <div
      class="xl:w-[60vw] lg:w-[50vw] md:w-[50vw] w-full h-[50vh] overflow-y-auto scrollbar sm:py-3 sm:px-3 py-3"
    >
      <div class="flex flex-col h-full w-full rounded-3xl sm:px-0 sm:py-0 px-3 py-0">
        <div
          class="flex justify-between items-center border-b border-b-borderColorLight"
        >
          <div class="flex items-center space-x-4 sm:flex-row flex-col">
            <p class="font-bold sm:text-[22px] text-[20px] dark:text-white/80">
            ${{ spxPrice }} Iron Condor Spread
          </p>
          <span class="dark:text-white/80 text-[13px]"> Link:  ${{ linkPrice.toFixed(2) }}</span>
          </div>
          <div
            class="hover:bg-gray-200 dark:hover:bg-gray-800 h-full cursor-pointer rounded-full p-2"
            @click="onCloseModal"
          >
            <CrossIconSvg />
          </div>
        </div>
        <!-- <div>{{ modatData }}</div> -->
        <table class="flex w-full flex-col mt-5">
          <tr class="flex w-full">
            <div
              class="flex w-full border items-center justify-center dark:text-white"
              v-for="(item, index) in tableHead"
              :key="index"
            >
              <th>{{ item.toUpperCase() }}</th>
              <!-- <th>H1</th> -->
            </div>
          </tr>
          <div
            class="flex w-full"
            v-for="(items, index) in modatData?.rows"
            :key="index"
          >
            <tr class="flex w-full flex-col border items-center justify-center">
              <td class="text-lightTextColor dark:text-darkTextColor">
                {{ items?.date }}
              </td>
            </tr>
            <tr class="flex w-full flex-col border items-center justify-center">
              <td class="text-lightTextColor dark:text-darkTextColor">
                {{ items?.price }}
              </td>
            </tr>
            <tr class="flex w-full flex-col border items-center justify-center">
              <td class="text-lightTextColor dark:text-darkTextColor">
                {{ items?.days }}
              </td>
            </tr>
            <tr class="flex w-full flex-col border items-center justify-center">
              <td class="text-lightTextColor dark:text-darkTextColor">
                {{ items?.side }}
              </td>
            </tr>
            <tr class="flex w-full flex-col border items-center justify-center">
              <td class="text-lightTextColor dark:text-darkTextColor">
                {{ items?.type }}
              </td>
            </tr>
          </div>
          <tr>
            <!-- <td>Centro comercial Moctezuma</td>
            <td>Francisco Chang</td>
            <td>Mexico</td> -->
          </tr>
        </table>
        <div class="flex mt-5">
          <p class="text-[12px] text-gray-400 dark:text-gray-500">Disclaimer: </p>
          <p class="text-[13px] text-gray-600 dark:text-gray-400 text-justify">
            Stock market, and or options investments are risky and past
            performance doesn't guarantee future results. All securities
            investments entail the risk of great and sudden financial loss. No
            assurance is given that anything described here will be successsful.
            CovalCondor.com contains neither recommendations nor solicitations
            to buy or sell securities or option on securities or indexes.
            Documents are published solely for information and educational
            purpose and do not form the basis of any contract or commitment
            whatsoever.
          </p>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../../SharedComponents/BaseModal.vue";
import store from "@/store";
import CrossIconSvg from "@/assets/svgs/CrossIconSvg.vue";
import "../../../index.css";
export default {
  name: "ContactModal",
  components: {
    BaseModal,
    CrossIconSvg,
    // CrossIconSvg,
    // CustomButton,
    // VueRecaptcha,
    // TextInput,
  },

  props: {
    title: {
      default: "BrookerModal",
      type: String,
    },
  },

  data() {
    return {
      spxPrice: null,
      modatData: null,
      tableHead: {},
      linkPrice:null,
    };
  },

  mounted() {
    this.ironCondor;
    this.spxPrice = this.ironCondor;
    this.getIronData;
    if (this.ironCondor === "10.00") {
      console.log("this.getIronData[0]", this.getIronData[0]);
      this.linkPrice = this.getIronData[0].ic
      console.log("Object.kyspx[0]", Object.keys(this.getIronData[0].rows[0]));
      this.tableHead = Object.keys(this.getIronData[0].rows[0]);
      this.modatData = this.getIronData[0];
    } else {
      console.log("this.getIronData[1]", this.getIronData[1]);
      console.log("Object.kyspx[1]", Object.keys(this.getIronData[0].rows[0]));
      this.modatData = this.getIronData[1];
      this.linkPrice = this.getIronData[1].ic
      this.tableHead = Object.keys(this.getIronData[1].rows[0]);
    }
  },
  methods: {
    onCloseModal() {
      store.commit("SET_BROOKER_MODAL", false);
    },
  },
  computed: {
    ironCondor() {
      return store.getters.getIronCondor;
    },
    getIronData() {
      return store.getters.getIronCondorData;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1600px) {
  .formContainer {
    height: 88vh;
  }

  .bottomDiv {
    padding-top: 0.1rem;
  }

  .header {
    padding: 0.2rem 2.2rem 0.1rem 2.2rem;
  }

  .detailText {
    font-size: 12px;
  }

  .formDiv {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .formContainer {
    height: auto;
    padding-bottom: 1rem;
  }

  .header {
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
  }

  .formDiv {
    margin-top: 20px;
  }
}
</style>
