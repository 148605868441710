import { render, staticRenderFns } from "./LoginButtons.vue?vue&type=template&id=474f92fe&scoped=true"
import script from "./LoginButtons.vue?vue&type=script&lang=js"
export * from "./LoginButtons.vue?vue&type=script&lang=js"
import style0 from "./LoginButtons.vue?vue&type=style&index=0&id=474f92fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f92fe",
  null
  
)

export default component.exports