var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex bg-[#D1D1D1] dark:bg-compBgDark space-x-1"},[_c('div',{staticClass:"w-[33%] h-0.5 text-gray-500 items-center",class:{
      'bg-red-500':
        _vm.passwordStrength === 'Weak' ||
        _vm.passwordStrength === 'Medium' ||
        _vm.passwordStrength === 'Strong',
      // 'bg-yellow-300': passwordStrength === 'Medium',
      // 'bg-yellow-300': passwordStrength === 'Strong',
    }}),_c('div',{staticClass:"w-[33%] h-0.5 text-gray-500 items-center",class:{
      'bg-[#ef950e]/90':
        _vm.passwordStrength === 'Medium' || _vm.passwordStrength === 'Strong',
    }}),_c('div',{staticClass:"w-[33%] h-0.5 text-gray-500 items-center",class:{
      'bg-green': _vm.passwordStrength === 'Strong',
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }