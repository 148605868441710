import Vue from 'vue'
import App from './App.vue'
import './assets/css/tailwind.css'
import router from './router'
import store from './store'

// import 'vue2-datepicker/index.css';
import 'flatpickr/dist/flatpickr.css';
import VueApexCharts from 'vue-apexcharts';

Vue.config.productionTip = false

Vue.component('ApexChart', VueApexCharts);
Vue.use(VueApexCharts);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
