var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center w-full h-full md:p-0 p-4"},[_c('div',{class:`${
      _vm.isLoading ? 'pointer-events-none bg-opacity-30' : ''
    } flex flex-col md:w-3/12 w-full bg-white rounded-2xl p-7`},[(!_vm.isSuccess)?_c('div',{staticClass:"flex flex-col space-y-4"},[_c('span',{staticClass:"text-center font-bold text-[22px]"},[_vm._v("Forgot password?")]),_c('span',{staticClass:"text-lightTextColor text-[12px]"},[_vm._v("Enter the email address you used to create your CondorCash account. We'll send a password reset email.")]),_c('div',{staticClass:"flex flex-col space-y-3"},[_c('div',{staticClass:"flex flex-col space-y-1"},[_c('TextInput',{attrs:{"placeholder":"Email Address","handleChange":_vm.inputChange,"className":`${
              _vm.errorMessage ? 'border-2 border-danger rounded-md' : ''
            }`},on:{"keypressEnter":_vm.forgetPasswordHandler},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.errorMessage)?_c('span',{staticClass:"text-danger text-[13px]"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1),_c('CustomButton',{attrs:{"buttonType":"primary","title":"Send reset email","isLoading":_vm.isLoading,"loaderText":"Verifying email address"},on:{"onClick":_vm.forgetPasswordHandler}}),_c('div',{staticClass:"w-full flex items-center justify-center"},[_c('span',{staticClass:"text-[13px] text-btnPrimary hover:underline cursor-pointer",on:{"click":_vm.backToLogin}},[_vm._v("Back to Login")])])],1)]):_c('div',[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',{staticClass:"flex items-center justify-center space-x-3"},[_c('div',[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z","fill":"#3EAF3F"}})])]),_vm._m(0)]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"pt-2"},[_c('CustomButton',{attrs:{"title":"Back to Login","buttonType":"primary","customStyle":"h-[5vh]"},on:{"onClick":_vm.backToLogin}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"text-[23px] font-bold text-green"},[_vm._v("Success!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center"},[_c('span',{staticClass:"text-center text-lightTextColor text-[14px]"},[_vm._v("Your password reset link is on its way to the email address linked to your CondorCash account.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center"},[_c('img',{attrs:{"src":require("../../../assets/svgs/successforget.svg")}})])
}]

export { render, staticRenderFns }