<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5.69727C12 4.6364 11.5786 3.61898 10.8284 2.86884C10.0783 2.11869 9.06087 1.69727 8 1.69727C6.93913 1.69727 5.92172 2.11869 5.17157 2.86884C4.42143 3.61898 4 4.6364 4 5.69727C4 10.3639 2 11.6973 2 11.6973H14C14 11.6973 12 10.3639 12 5.69727Z"
      :stroke="isActive ? activeColor : disableColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.15335 14.3643C9.03614 14.5663 8.86791 14.734 8.6655 14.8506C8.46309 14.9672 8.2336 15.0286 8.00001 15.0286C7.76643 15.0286 7.53694 14.9672 7.33453 14.8506C7.13212 14.734 6.96389 14.5663 6.84668 14.3643"
      :stroke="isActive ? activeColor : disableColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import store from "@/store";
import { watch, ref } from "vue";

export default {
  name: "NotificationsSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
