<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 8.89909H6.66667C6.84348 8.89909 7.01305 8.82885 7.13807 8.70383C7.2631 8.5788 7.33333 8.40923 7.33333 8.23242V2.89909C7.33333 2.72228 7.2631 2.55271 7.13807 2.42768C7.01305 2.30266 6.84348 2.23242 6.66667 2.23242H2.66667C2.48986 2.23242 2.32029 2.30266 2.19526 2.42768C2.07024 2.55271 2 2.72228 2 2.89909V8.23242C2 8.40923 2.07024 8.5788 2.19526 8.70383C2.32029 8.82885 2.48986 8.89909 2.66667 8.89909ZM2 13.5658C2 13.7426 2.07024 13.9121 2.19526 14.0372C2.32029 14.1622 2.48986 14.2324 2.66667 14.2324H6.66667C6.84348 14.2324 7.01305 14.1622 7.13807 14.0372C7.2631 13.9121 7.33333 13.7426 7.33333 13.5658V10.8991C7.33333 10.7223 7.2631 10.5527 7.13807 10.4277C7.01305 10.3027 6.84348 10.2324 6.66667 10.2324H2.66667C2.48986 10.2324 2.32029 10.3027 2.19526 10.4277C2.07024 10.5527 2 10.7223 2 10.8991V13.5658ZM8.66667 13.5658C8.66667 13.7426 8.73691 13.9121 8.86193 14.0372C8.98695 14.1622 9.15652 14.2324 9.33333 14.2324H13.3333C13.5101 14.2324 13.6797 14.1622 13.8047 14.0372C13.9298 13.9121 14 13.7426 14 13.5658V8.89909C14 8.72228 13.9298 8.55271 13.8047 8.42768C13.6797 8.30266 13.5101 8.23242 13.3333 8.23242H9.33333C9.15652 8.23242 8.98695 8.30266 8.86193 8.42768C8.73691 8.55271 8.66667 8.72228 8.66667 8.89909V13.5658ZM9.33333 6.89909H13.3333C13.5101 6.89909 13.6797 6.82885 13.8047 6.70383C13.9298 6.5788 14 6.40923 14 6.23242V2.89909C14 2.72228 13.9298 2.55271 13.8047 2.42768C13.6797 2.30266 13.5101 2.23242 13.3333 2.23242H9.33333C9.15652 2.23242 8.98695 2.30266 8.86193 2.42768C8.73691 2.55271 8.66667 2.72228 8.66667 2.89909V6.23242C8.66667 6.40923 8.73691 6.5788 8.86193 6.70383C8.98695 6.82885 9.15652 6.89909 9.33333 6.89909Z"
      :fill="isActive ? activeColor : disableColor"
    />
  </svg>
</template>
<script>
import { watch, ref } from "vue";
import store from "@/store";

export default {
  name: "DashboardSvg",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
