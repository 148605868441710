<template>
  <trading-vue
    :data="chart"
    :width="width"
    :height="height"
    :color-back="store.getters.getTheme? '#38393E' : colors.colorBack"
    :color-grid="store.getters.getTheme? '#5b5a5a': colors.colorGrid"
    :color-text="store.getters.getTheme? 'white' :colors.colorText"
    title-txt="SPX"
    :overlays="[MACD]"
    :index-based="true"
  />
</template>

<script>
//:overlays="[MACD]"
import TradingVue from "@/@trading-vue-js/src/TradingVue.vue";
import store from "@/store"
// import Data from '../data/data.json';
import DataCube from "./helpers/datacube.js";
import MACD from "@/@trading-vue-js/src/components/overlays/MACD.vue";

export default {
  name: "TradingMain",
setup(){

return{
store
}

},

  computed: {
    MACD() {
      return MACD;
    },
  },
  components: {
    TradingVue,
  },
  props: {
    data: {
      type: [Array],
      default() {
        return [];
      },
    },
    onchart: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    offchart: {
      type: [Array],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chart: new DataCube({
        ohlcv: this.data,
        type: "Candles",
        onchart: this.onchart,
        offchart: this.offchart,
      }),
      width: window.innerWidth * 0.8,
      height: window.innerHeight * 0.7,

      colors: {
        colorBack: "#FFF",
        colorGrid: "#EEE",
        colorText: "#333",
      },
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.dc = this.chart;
    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const chartMainContainer = document.getElementById("chartMainContainer");
      // const parentDiv = this.$el.parentNode;
      const parentHeight = chartMainContainer?.offsetHeight - 130;
      const parentWidth = chartMainContainer?.offsetWidth;

      // Now you can use parentHeight and parentWidth as needed
      console.log("Parent div height:", parentHeight);
      this.height = parentHeight;
      console.log("Parent div width:", parentWidth);
      this.width = parentWidth;
    },
  },
  watch: {
    data: {
      async handler(val) {
        // let newD = JSON.parse(JSON.stringify(val))
        // let newD = JSON.stringify(val)
        // console.log(val.value,typeof val, newD,typeof newD)

        // console.log(val)
        let newData = { data: val, tf: "1D" };
        console.log("Candle Watch Updated", newData);
        // this.chart.set('chart.tf', this.timeFrame)
        this.chart.merge("chart", newData);

        this.$emit("chartUpdate");
      },
      deep: true,
    },
  },
};
</script>
