<template>
    <div class="" :class="!isProfileComplete ? 'sm:h-full h-[107vh] sm:mt-0 -mt-8 sm:overflow-y-hidden overflow-y-auto':'w-full h-full pb-16'">
      <BaseModal v-if="isPhoneModal">
        <div class="md:w-[35vw] w-[85vw] h-full px-6 py-4">
          <div class="flex justify-between">
            <div class="flex flex-col space-y-1">
              <span class="text-[17px] text-black dark:text-white font-semibold">
                Add a phone number
              </span>
              <span
                class="text-[12px] text-lightTextColor dark:text-darkTextColor"
              >
                Link your phone number to your account
              </span>
            </div>
            <div
              :class="`cursor-pointer ${
                this.disableButton ? 'pointer-events-none opacity-50' : ''
              } hover:bg-gray-200 dark:hover:bg-gray-800 h-fit rounded-full md:p-2`"
              @click="onCloseHandler"
            >
              <CrossIconSvg />
            </div>
          </div>
          <div v-if="!isVerified">
            <div class="pt-4">
              <div
                class="flex h-[5vh] rounded-md bg-black/40 dark:bg-textInputDark dark:bg-opacity-70"
              >
                <div
                  class="flex px-2 items-center justify-center bg-gray-300 dark:bg-gray-700 rounded-l-md"
                >
                  <img src="../../assets/images/USFlag.png" class="w-8 h-6" />
                </div>
                <input
                  type="text"
                  @input="inputHandler"
                  :value="phoneNumber"
                  @keydown.enter="!isButtonDisable && verifyOTPHandler"
                  ref="input"
                  name="phoneNumber"
                  placeholder="+1 (209) 934-9895"
                  v-mask="mask"
                  class="pl-3 h-full no-spinners no-focus-outline focus:outline-none focus:ring-0 dark:text-white dark:rounded-md dark:bg-textInputDark dark:bg-opacity-70 focus:border-none outline-none border border-none w-full xl:placeholder:text-[13px] lg:placeholder:text-[11px] sm:placeholder:text-[10px] placeholder:text-[10px] placeholder:text-[#888] dark:placeholder:text-darkTextColor xl:text-[15px] lg:text-[12px] sm:text-[11px] text-[13px] text-black opacity-80 "
                  :class="{'error-ph': isInValidPhoneNumber}"
                />
              </div>
              <!-- <TextInput
                @keypressEnter="sendOTPHandler"
                placeholder="_ _ _ _ _ _ _ _ _ _ _"
                v-model="phoneNumber"
                :firstimageSrc="require('../../assets/images/USFlag.png')"
                imageClass="w-8 h-6"
                :maxLength="13"
                :name="'phoneNumber'"
                :mask="phoneMask"
                :handleChange="onChangeHandler"
                :onInputClick="
                  () => {
                    this.phoneMessage = '';
                  }
                "
              /> -->
              <!-- <button id="send" @click.prevent="unmaskedValue">
                Log Unmasked Value
              </button> -->
              <div
                class="flex items-center space-x-2 pt-2"
                v-if="phoneMessage.length"
              >
                <WarningIconSvg fill="#FF3030" v-if="!isSuccessPhone" />
                <span
                  :class="`${
                    isSuccessPhone ? 'text-[#3EAF3F]' : 'text-[#FF3030]'
                  } text-[13px]`"
                  >{{phoneNumber}}</span
                >
              </div>
            </div>
            <div class="pt-3 md:w-4/12 w-full">
              <p v-if="isInValidPhoneNumber" class="mb-2 text-[12px]  text-red-600" >Invalid Phone Number</p>
              <CustomButton
                :class="[isButtonDisable ? 'cursor' : '']"
                title="Save phone number"
                buttonType='primary'
                :isLoading="isLoader"
                loaderText="Sending"
                @onClick="verifyOTPHandler"
                :disabledVal="isButtonDisable ? true : disableHandler()"
              />
            </div>
            <div v-if="isSuccessPhone">
              <div class="flex flex-col space-y-1 pt-4">
                <span class="text-[#5D5D5D] dark:text-darkTextColor text-[13px]"
                  >OTP Code
                </span>
                <TextInput
                  placeholder="Enter 6 digit OTP code here"
                  @keypressEnter="verifyOTPHandler"
                  :maxLength="12"
                  v-model="OTPCode"
                  :name="'OTPCode'"
                  type="number"
                  :handleChange="onChangeHandler"
                  :onInputClick="
                    () => {
                      this.otpFailed = '';
                    }
                  "
                />
                <div class="pt-2 flex items-center space-x-2" v-if="otpFailed">
                  <WarningIconSvg fill="#FF3030" />
                  <span :class="`text-[13px] text-[#FF3030]`">{{
                    this.otpFailed
                  }}</span>
                </div>
              </div>
              <div class="pt-6 sm:w-5/12 w-full">
                <CustomButton
                  title="Verify phone number"
                  buttonType="primary"
                  @onClick="verifyOTPHandler"
                  :isLoading="isOTPLoader"
                  loaderText="Verifying OTP"
                />
              </div>
            </div>
          </div>
          <div v-else class="w-full h-full">
            <div class="pt-4">
              <div
                class="flex sm:flex-row sm:space-x-4 space-x-0 sm:space-y-0 flex-col space-y-2 pt-2"
              >
                <div class="flex flex-col space-y-1">
                  <span
                    class="text-lightTextColor dark:text-darkTextColor text-[13px]"
                    >Phone Number</span
                  >
                  <span
                    v-if="isProfileComplete"
                    class="text-[16px] dark:text-white font-semibold tracking-wider"
                    >{{ this.phoneNumber }}</span
                  >
                  <span
                    v-else
                    class="text-[16px] dark:text-white font-semibold tracking-wider"
                  >
                    {{ this.phoneNumber }}
                  </span>
                </div>
                <div class="flex items-center mt-9 pt-[24px] space-x-2"  >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                      fill="#3EAF3F"
                    />
                  </svg>
  
                  <span class="text-[#3EAF3F] text-[13px]">{{
                    this.otpSuccess
                  }}</span>
                </div>
              </div>
              <div class="pt-6 w-4/12">
                <CustomButton
                  buttonType="primary"
                  @onClick="onCloseHandler"
                  title="Close"
                />
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
      <div
        class="w-full justify-center h-full flex items-center"
        v-click-outside="isMobileOrTabletDevice ? null : handleClose"
      >
        <!-- After user completes this change step  -->
        <div class="h-full flex items-center justify-center lg:w-7/12 w-full">
          <div class="w-full h-full flex flex-col space-y-6" >
            <NotificationComp
              title="Step 2 of 4: Complete your profile"
              desc="Enter first name, last name, and phone number"
              v-if="!isPersonalInfoHave && !isProfileComplete"
              :bgColor="`${store.state.theme ? 'darkWarning' : '#FFF9E3'}`"
              :textColor="`${store.state.theme ? 'textDark' : '#C27500'}`"
            />
            <NotificationComp
              title="Step 2 of 4: completed"
              desc="Redirecting to Address & Billing information section."
              :isSuccess="true"
              :bgColor="`${store.state.theme ? 'dark' : 'white'}`"
              :textColor="`${store.state.theme ? 'white' : 'black'}`"
              :onClick="handleClickNext"
              v-if="isPersonalInfoHave && !isProfileComplete"
            />
            <div
              :class="`w-full h-full ${
                !isProfileComplete ? 'overflow-y-hidden' : ''
              }`"
            >
              <ProfileSummaryPage
                :first-name="firstName"
                :last-name="lastName"
                :phone-number="phoneNumber"
                v-if="!isProfileComplete && isPersonalInfoHave"
              />
  
              <div class="w-full h-full">
                <CardBox
                  v-if="!isPersonalInfoHave || isProfileComplete"
                  customClass="border border-[#E7E7E7] rounded-3xl w-full !p-5 h-full"
                >
                  <div class="w-full h-full flex justify-between items-center">
                    <div class="flex flex-col space-y-1">
                      <h1
                        class="text-black dark:text-white font-bold lg:text-[17px] text-[14px] whitespace-nowrap"
                      >
                        Personal Information
                      </h1>
                      <span
                        class="text-lightTextColor   dark:text-darkTextColor text-[11px]"
                      >
                        Update your account settings
                      </span>
                    </div>
  
                    <div v-if="!isProfileComplete" class="md:flex hidden">
                      <CustomButton
                        @onClick="handleSave"
                        title="Save and Continue"
                        buttonType="primary"
                        customStyle="px-5"
                        :isLoading="isPersonalInfoSaving"
                        :loaderText="'Saving'"
                      />
                    </div>
                    <!-- for web button personal information element -->
                    <div class="md:flex hidden  items-center space-x-3" v-else>
                      <CustomButton
                      @onClick="handleDiscard"
                      title="Discard Changes"
                      buttonType="secondary"
                      customStyle="px-5  dark:bg-lightTextColor/40 dark:hover:bg-lightTextColor/20"
                      :disabledVal="isDisable"
                      />
                      <CustomButton
                        @onClick="handleSave"
                        title="Save"
                        buttonType="primary"
                        customStyle="px-5"
                        :isLoading="isPersonalInfoSaving"
                        :loaderText="'Saving'"
                        :disabledVal="isDisable"
                      />
                    </div>
                  </div>
                  <div class="space-y-6 pt-6">
                    <span v-if="nameError" class="text-[14px] text-[#FD3232]">{{
                      nameError
                    }}</span>
                    <div class="flex flex-col space-y-1">
                      <span
                        class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
                        >First name*</span
                      >
                      <TextInput
                        placeholder="For eg: John"
                        v-model="firstName"
                        :value="firstName"
                        :handleChange="onChangeHandler"
                        :onInputClick="clearError"
                        :name="'firstName'"
                        @keypressEnter="keypressEnter"
                        :class="{'border-cs': isShowBorderClass}"
                        :mainClass="`rounded-md ${
                          firstName
                            ? 'border border-black'
                            : 'myborderCls'
                        }
                        `"
                      />
                    </div>
                    <div class="flex flex-col space-y-1">
                      <span
                        class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
                        >Last name*</span
                      >
                      <TextInput
                        placeholder="For eg: Doe"
                        name="lastName"
                        v-model="lastName"
                        :onInputClick="clearError"
                        :handleChange="onChangeHandler"
                        @keypressEnter="keypressEnter"
                        :class="{'borderLast': isShBorLast}"
                        :mainClass="`rounded-md ${
                          lastName
                            ? 'border border-black'
                            : 'border  border-red-600'
                        }`"
                      />
                    </div>
                    <!--              Add Number Tab Tak aye gaa Jab tak otp verify nahi hugaa -->
                    <div
                      class="md:w-4/12 w-full flex items-center space-x-3"
                      v-if="!havePhoneNumberInfo"
                    >
                      <CustomButton
                        :buttonType="`${
                          store.state.theme ? 'primary' : 'rounded'
                        }`"
                        title="Add a phone number"
                        :customStyle="`${
                          firstName && lastName ? 'border border-red-600' : ''
                        } py-2`"
                        @onClick="isPhoneModal = true"
                      />
                      <div class="iframe-container">
                        <iframe
                          v-if="firstName && lastName"
                          src="https://giphy.com/embed/VIPUNs0YY0IRpb6SWD"
                          width="70"
                          height="70"
                          frameBorder="0"
                          class="giphy-embed"
                          allowFullScreen
                          sandbox="allow-scripts"
                        ></iframe>
                      </div>
                    </div>
                    <PhoneNumberWithVerification
                      :phone-number="phoneNumber"
                      :showEdit="true"
                      @onClick="showPhoneModel(payload)"
                      v-else
                    ></PhoneNumberWithVerification>
                  </div>
                  <div class="pt-3" v-if="!isPersonalInfoHave">
                    <p
                      class="text-lightTextColor/70 dark:text-darkTextColor text-[12px] italic"
                    >
                      We'll use your phone number to send nightly trade updates,
                      including risk factors. Rest assured, we never share your
                      information with any third party or organization.
                    </p>
                  </div>
                  <div class="pt-4">
                    <div v-if="!isProfileComplete" class="md:hidden block">
                      <CustomButton
                        @onClick="handleSave"
                        title="Save and Continue"
                        buttonType="primary"
                        customStyle="px-5 "
                        :isLoading="isPersonalInfoSaving"
                        :loaderText="'Saving'"
                        
                      />
                    </div>
                    <div class="md:hidden flex items-center space-x-3" v-else>
                      <CustomButton
                        title="Discard Changes"
                        buttonType="secondary"
                        customStyle="px-5"
                        @onClick="handleDiscard"
                        :disabledVal="isDisable"
                      />
                      <CustomButton
                        @onClick="handleSave"
                        title="Save"
                        buttonType="primary"
                        customStyle="px-5"
                        :isLoading="isPersonalInfoSaving"
                        :loaderText="'Saving'"
                        :disabledVal="isDisable"
                      />
                    </div>
                  </div>
                </CardBox>
              </div>
            </div>
            <!--          Account information-->
            <ProfileEmailInformationComponent
              :is-profile-complete="isProfileComplete"
              :user-email="userEmail"
            />
  
          </div>
        </div>
        <!-- Before user completes the step -->
      </div>
    </div>
  </template>

<script>

import { VueMaskDirective } from 'v-mask';
import vClickOutside from "vue-click-outside";

import CardBox from "../../components/SharedComponents/CardBox.vue";
import CustomButton from "../../components/SharedComponents/CustomButton.vue";
import TextInput from "../../components/SharedComponents/TextInput.vue";
import NotificationComp from "../../components/SharedComponents/Notification.vue";
import BaseModal from "../../components/SharedComponents/BaseModal.vue";
import CrossIconSvg from "../../assets/svgs/CrossIconSvg.vue";
import WarningIconSvg from "../../assets/svgs/WarningIconSvg.vue";
import userApi from "@/services/userApi";
// import store, {getUserDetailsFromStore, updateSideBarByUserDetails} from "@/store";
import store, {
  getUserDetailsFromStore,
  updateSideBarByUserDetails,
} from "@/store";
import { FACEBOOK_LOGIN, GOOGLE_LOGIN, LINKDIN_LOGIN } from "@/api/apiRoutes";
import ProfileSummaryPage from "@/components/SettingsComp/ProfileSummaryPage.vue";
import PhoneNumberWithVerification from "@/components/SettingsComp/PhoneNumberWithVerification.vue";
import ProfileEmailInformationComponent from "@/components/SettingsComp/ProfileEmailInformationComponent.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {


  name: "ProfilePage",
  setup() {
    return {
      // Other variables from your setup
      store,
    };
  },
  directives: {
    "click-outside": vClickOutside,
    mask: VueMaskDirective
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      isPhoneModal: false,
      isEditable:false,
      isDisable:true,
      OTPCode: "",
      phoneMessage: "",
      isOTPLoader: false,
      isSuccessPhone: false,
      isValidOTP: "123456",
      otpSuccess: "",
      isPersonalInfoSaving: false,
      otpFailed: "",
      isVerified: false,
      profileUpdates: 0,
      userEmail: "",
      isLoader: false,
      nameError: "",
      disableButton: false,
      indexes: [],
      isMobileOrTabletDevice:
      /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|Tablet|Kindle/i.test(
          navigator.userAgent
          ),
        phoneNumber: "",
        isInValidPhoneNumber: false,
        isButtonDisable: true,
        currencyMask :  createNumberMask({
        prefix: '+1',
        integerLimit: 10,
        allowNegative: false,
        }),
        // mask: ['(', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        mask: ['+', '1', ' ', '(', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        isShowBorFirst: false,
        isShBorLast: false,
        isShowBorderClass: false,
        

    };
  },
  components: {
    ProfileEmailInformationComponent,
    PhoneNumberWithVerification,
    ProfileSummaryPage,
    CardBox,
    CustomButton,
    TextInput,
    NotificationComp,
    BaseModal,
    CrossIconSvg,
    WarningIconSvg,
  },
  methods: {
    keypressEnter(){
      !this.isDisable && this.handleSave()
    },
    cleanedPhoneNumber() {
      return this.phoneNumber.replace(/[^\d]/g, '');
    },
    unmaskedValue() {
      var val = this.$refs.input.clean;
      console.log(val);
    },
    showPhoneModel(payload) {
      console.log("payload",payload);
      this.isPhoneModal = true;
      
    },
    inputHandler(event){
      this.phoneNumber = event.target.value;
      this.isButtonDisable = false;
      console.log("event==>", this.phoneNumber)
      this.isInValidPhoneNumber = false;
    },

    formatPhoneNumber(phoneNumber) {
      console.log('slice phone 1', phoneNumber)
      const areaCode = phoneNumber.slice(0, 3);
      const firstPart = phoneNumber.slice(3, 6);
      const secondPart = phoneNumber.slice(6);
      console.log('slice phone 2', areaCode, firstPart, secondPart)
      return `(${areaCode}) ${firstPart}${secondPart}`;
    },


    handleThirdPartyIntegration(val) {
      console.log("third party", val);

      let redirectUrl;

      if (val === "google") {
        redirectUrl = GOOGLE_LOGIN;
      } else if (val === "facebook") {
        redirectUrl = FACEBOOK_LOGIN;
      } else if (val === "linkedin") {
        redirectUrl = LINKDIN_LOGIN;
      } else {
        console.error("Invalid integration type");
        return;
      }
      window.location.href = redirectUrl;
    },
    
    handleClose() {
      this.isPhoneModal = false;
      this.phoneMessage = "";
      this.otpFailed = "";
      this.isInValidPhoneNumber = false;
      this.isButtonDisable = true;
      // this.phoneNumber = "";
    },
    onCloseHandler() {
      this.isPhoneModal = false;
      this.phoneMessage = "";
      this.otpSuccess = "";
      this.isSuccessPhone = false;
      this.isVerified = false;
      this.otpFailed = "";
      this.OTPCode = "";
    },
    disableHandler() {
      return this.disableButton;
    },
    handleSave() {
      // this.isEditable=true
      if(this.firstName.trim() === "") {
        this.isShowBorderClass = true;
      }
      if(this.lastName.trim() === ""){
        this.isShBorLast = true;
      }
      // console.log("ph 1", this.userDetails?.phone);
      // console.log("ph 1", this.userDetails?.phone, "firstname==>", this.firstName, this.lastName);
      if (
        this.firstName.trim() != "" &&
        this.lastName.trim() != "" &&
        // this.phoneNumber != "" &&
        this.userDetails?.phone
      ) {
        this.isShowBorderClass = false;
        this.isShBorLast = false;
        let basicPayload = {
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phoneNumber,
        };
        this.isPersonalInfoSaving = true;
        userApi
          .updateBasicInfo(basicPayload)
          .then((resp) => {
            console.log(resp);
            let userDetail = resp.data.user_detail;
            store.commit("SET_USER_DETAIL", userDetail);
            this.isEditable= false;
            this.isDisable = false;
            this.isPersonalInfoSaving = false;
            this.profileUpdates = this.profileUpdates + 1;
          })
          .catch((e) => {
            console.log(e);
            this.isPersonalInfoSaving = false;
          });
      } else {
        if (this.firstName.trim() !== "" && this.lastName.trim() !== "") {
          if (!this.userDetails?.phone) {
            // Phone number is not verified
            this.nameError =
              "Phone number is not verified. Please verify your phone number.";
          } else {
            this.nameError = null;
          }
        } else {
          this.nameError = "All fields with * are required";
        }
      }
    },

    clearError() {
      this.nameError = "";
    },

    onChangeHandler(event) {
      this.isEditable = true;
      this.isDisable = false;
      this.isShowBorderClass = false;
      this.isShBorLast = false;
      this.nameError = "";
      const inputValue = event.target.value;

      this[event.target.name] = inputValue;
    },
    handleDiscard() {
      this.isEditable = false;
      this.isDisable = true;
      this.firstName = this.userDetails?.firstname;
      this.lastName = this.userDetails?.lastname;
    },

    verifyOTPHandler() {

      console.log("phone number",this.phoneNumber, this.phoneNumber.length)
      console.log("this.cleanedNumber=>", this.cleanedPhoneNumber());

      const clenedNumber = this.cleanedPhoneNumber();
      console.log("clean", clenedNumber)

      // let parseNumber = parseInt(clenedNumber);
    if(clenedNumber.length === 11){
      if( this.phoneNumber){
        console.log("ph 2", this.phoneNumber);
        this.isOTPLoader = true;
        this.isLoader = true;
        console.log('phoene', this.phoneNumber)
        // this.isOTPLoader = true;
        userApi
            .verifyMobileOTP({
            otp: this.phoneNumber,
            })
            .then((d) => {
            console.log('d profile',d);
            if (d?.data?.status) {
                this.isLoader=false
                this.isVerified = true;
                this.otpSuccess = "Successfully verified";
                this.disableButton = false;
                // this.isVerified = 
                let userDetail = d.data.user_detail;
                store.commit("SET_USER_DETAIL", userDetail);
            }
            //  else {
            //   this.otpFailed = "You have entered an incorrect OTP code.";
            //   this.disableButton = false;
            // }
            this.isOTPLoader = false;
            this.isLoader = false;
            })
            .catch((e) => {
            this.isOTPLoader = false;
            this.isLoader = false;
            this.otpFailed = "You have entered an incorrect OTP code.";
            this.disableButton = false;

            console.log(e);
            });
             setTimeout(() => {
            if (this.OTPCode.length === 6 && this.OTPCode === this.isValidOTP) {
            this.isVerified = true;
            this.otpSuccess = "Successfully verified";
            } else {
            this.otpFailed = "You have entered an incorrect OTP code.";
            }
            this.isOTPLoader = false;
            this.isLoader = false;
        }, 500);
      } else{
            this.isInValidPhoneNumber = true;
      }
      
    }
    else{
        this.isInValidPhoneNumber = true;
      }
    },
    handleClickNext() {
      updateSideBarByUserDetails(this.userDetails);
    },
    updatedButtonVal(value){
      if(value){
        this.isButtonDisable = true;
      } if(!value){
        this.isButtonDisable = false;
      }
    }
  },

  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      // return this.proComp
      let user = this.userDetails;
      console.log("userDetails", user);
      return user?.is_profile_complete ?? false;
    },
    isPersonalInfoHave() {
      let userDetails = getUserDetailsFromStore();
      console.log("userDetails", userDetails);
      let profile_status = userDetails["profile_status"];
      return profile_status?.["profile"] ?? false;
    },
    isAccountConnected() {
      let user = this.userDetails;
      return (
        user &&
        user.accounts &&
        (user.accounts.facebook ||
          user.accounts.google ||
          user.accounts.linkedin)
      );
    },
    havePhoneNumberInfo() {
      let userDetails = getUserDetailsFromStore();
      console.log("userDetails", userDetails);
      let profile_status = userDetails["phone"];
      return profile_status?.length > 0 ?? false;
      // return this.phoneHave
    },
  },
  watch: {
    userDetails: {
      handler(newVal) {
        if (this.isAccountConnected) {
          console.log("newVal", this.isAccountConnected);

          this.firstName = newVal?.firstname;
          this.lastName = newVal?.lastname;
          this.phoneNumber = newVal?.phone;
          this.userEmail = newVal?.email;
          this.state = newVal?.lastname;
          this.zip = newVal?.zip;
          this.country = newVal?.country;
          this.city = newVal?.city;
        } else {
          if (this.isPersonalInfoHave) {
            this.firstName = newVal?.firstname;
            this.lastName = newVal?.lastname;
            this.phoneNumber = newVal?.phone;
            this.userEmail = newVal?.email;
            this.state = newVal?.lastname;
            this.zip = newVal?.zip;
            this.country = newVal?.country;
            this.city = newVal?.city;
          }
        }
      },
      isAccountConnected: {
        handler(newVal) {
          if (this.isAccountConnected) {
            console.log("newVal", newVal);
          }
        },
      },
      immediate: true,
      deep: true,
    },
    isPersonalInfoHave(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.handleClickNext();
        }, 3000);
      }
    },
    phoneNumber(newVal){
      if(newVal.length >= 16){
        this.isButtonDisable = true;
      }if(newVal.length >= 17){
        this.isButtonDisable = false;
      }
      // if(newVal.length < 15){
      //   console.log("cmd", newVal.length)
      //   this.isButtonDisable = true;
      // }
      else{
        this.isButtonDisable = false;
      }
    }
  },

};
</script>

<style scoped>
.iframe-container {
  position: relative;
  z-index: 1; /* Ensure the iframe is on top */
}
.disabled {
  position: relative;
  opacity: 0.5; /* You can adjust the opacity to your preference */
  pointer-events: none; /* Disallow pointer events */
}
.error-ph{
  border: 1px solid red !important;
}

.cursor{
  pointer-events: none !important;
}

.border-cs{
  border: 1px solid red !important;
}

.borderLast{
  border: 1px solid red !important;
}
</style>