<template>
   <svg width="154" height="150" viewBox="0 0 154 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_360_16644)">
<path d="M97.5546 33.7345H56.1478C55.2042 33.7356 54.2996 34.111 53.6324 34.7782C52.9652 35.4454 52.5899 36.35 52.5887 37.2936V129.321L52.1142 129.465L41.9564 132.576C41.475 132.723 40.955 132.673 40.5106 132.436C40.0662 132.2 39.7337 131.797 39.5861 131.316L9.3714 32.6216C9.22436 32.1402 9.27448 31.6201 9.51073 31.1757C9.74699 30.7312 10.1501 30.3987 10.6313 30.2513L26.2844 25.4583L71.6633 11.5682L87.3163 6.77527C87.5545 6.70196 87.8048 6.67634 88.0529 6.69988C88.3011 6.72342 88.5421 6.79567 88.7623 6.91247C88.9824 7.02928 89.1774 7.18835 89.3361 7.3806C89.4947 7.57284 89.6138 7.79448 89.6867 8.03283L97.41 33.2599L97.5546 33.7345Z" fill="#F2F2F2"/>
<path d="M106.59 33.2598L97.2819 2.85535C97.1271 2.34876 96.8739 1.87764 96.5369 1.46893C96.1999 1.06022 95.7857 0.721918 95.3178 0.473363C94.85 0.224807 94.3378 0.0708642 93.8105 0.0203367C93.2832 -0.0301907 92.7511 0.0236875 92.2446 0.178887L70.2373 6.91513L24.8609 20.8076L2.85366 27.5462C1.83132 27.8601 0.9753 28.5669 0.473496 29.5113C-0.0283076 30.4557 -0.134898 31.5606 0.177127 32.5835L31.991 136.493C32.2445 137.319 32.7559 138.042 33.4503 138.556C34.1447 139.069 34.9854 139.347 35.8491 139.348C36.2489 139.348 36.6464 139.288 37.0283 139.17L52.1143 134.553L52.5888 134.405V133.91L52.1143 134.054L36.8884 138.717C35.986 138.992 35.0113 138.898 34.1782 138.455C33.345 138.013 32.7215 137.258 32.4442 136.356L0.632792 32.4436C0.495431 31.9968 0.447557 31.5272 0.49191 31.0619C0.536263 30.5966 0.671967 30.1445 0.891262 29.7317C1.11056 29.3189 1.40913 28.9534 1.76988 28.6562C2.13064 28.3589 2.54648 28.1357 2.99361 27.9994L25.0009 21.2608L70.3774 7.3707L92.3847 0.632087C92.7238 0.528587 93.0764 0.475815 93.431 0.475484C94.192 0.477193 94.9324 0.722525 95.5438 1.17555C96.1553 1.62857 96.6056 2.26547 96.8288 2.99297L106.094 33.2598L106.242 33.7343H106.735L106.59 33.2598Z" fill="#3F3D56"/>
<path d="M29.1091 30.3288C28.6517 30.3285 28.2066 30.1816 27.8388 29.9097C27.4711 29.6378 27.2002 29.2553 27.0658 28.8181L24.0096 18.8355C23.9275 18.5674 23.899 18.2857 23.9258 18.0065C23.9525 17.7274 24.034 17.4562 24.1656 17.2086C24.2971 16.9609 24.4762 16.7416 24.6926 16.5631C24.9089 16.3847 25.1583 16.2506 25.4264 16.1685L67.1724 3.38799C67.7139 3.22274 68.2989 3.2791 68.799 3.54471C69.299 3.81031 69.6732 4.26346 69.8395 4.80469L72.8957 14.7874C73.0609 15.3289 73.0044 15.9139 72.7389 16.4139C72.4733 16.9139 72.0202 17.2882 71.479 17.4545L29.733 30.2351C29.5308 30.2971 29.3205 30.3287 29.1091 30.3288Z" fill="#00C874"/>
<path d="M45.1185 10.6668C47.7394 10.6668 49.864 8.54215 49.864 5.92128C49.864 3.30041 47.7394 1.17578 45.1185 1.17578C42.4977 1.17578 40.373 3.30041 40.373 5.92128C40.373 8.54215 42.4977 10.6668 45.1185 10.6668Z" fill="#00C874"/>
<path d="M45.1188 8.92797C46.7784 8.92797 48.1238 7.58258 48.1238 5.92297C48.1238 4.26335 46.7784 2.91797 45.1188 2.91797C43.4592 2.91797 42.1138 4.26335 42.1138 5.92297C42.1138 7.58258 43.4592 8.92797 45.1188 8.92797Z" fill="white"/>
<path d="M142.991 138.134H62.7917C62.257 138.133 61.7444 137.921 61.3663 137.543C60.9882 137.164 60.7755 136.652 60.7749 136.117V40.0207C60.7755 39.486 60.9882 38.9734 61.3663 38.5953C61.7444 38.2172 62.257 38.0045 62.7917 38.0039H142.991C143.525 38.0045 144.038 38.2172 144.416 38.5953C144.794 38.9734 145.007 39.486 145.008 40.0207V136.117C145.007 136.652 144.794 137.164 144.416 137.543C144.038 137.921 143.525 138.133 142.991 138.134Z" fill="#E6E6E6"/>
<path d="M106.094 33.2598H56.1479C55.0786 33.2613 54.0535 33.6867 53.2974 34.4429C52.5412 35.199 52.1158 36.2241 52.1143 37.2934V134.054L52.5888 133.909V37.2934C52.59 36.3499 52.9653 35.4452 53.6325 34.778C54.2997 34.1108 55.2043 33.7355 56.1479 33.7343H106.241L106.094 33.2598ZM149.634 33.2598H56.1479C55.0786 33.2613 54.0535 33.6867 53.2974 34.4429C52.5412 35.199 52.1158 36.2241 52.1143 37.2934V145.965C52.1158 147.035 52.5412 148.06 53.2974 148.816C54.0535 149.572 55.0786 149.998 56.1479 149.999H149.634C150.704 149.998 151.729 149.572 152.485 148.816C153.241 148.06 153.666 147.035 153.668 145.965V37.2934C153.666 36.2241 153.241 35.199 152.485 34.4429C151.729 33.6867 150.704 33.2613 149.634 33.2598ZM153.193 145.965C153.192 146.909 152.817 147.814 152.15 148.481C151.483 149.148 150.578 149.523 149.634 149.525H56.1479C55.2043 149.523 54.2997 149.148 53.6325 148.481C52.9653 147.814 52.59 146.909 52.5888 145.965V37.2934C52.59 36.3499 52.9653 35.4452 53.6325 34.778C54.2997 34.1108 55.2043 33.7355 56.1479 33.7343H149.634C150.578 33.7355 151.483 34.1108 152.15 34.778C152.817 35.4452 153.192 36.3499 153.193 37.2934V145.965Z" fill="#3F3D56"/>
<path d="M124.72 43.7013H81.0617C80.4956 43.7007 79.9528 43.4755 79.5524 43.0751C79.1521 42.6748 78.9269 42.132 78.9263 41.5658V31.1257C78.9269 30.5595 79.1521 30.0167 79.5524 29.6164C79.9528 29.2161 80.4956 28.9909 81.0617 28.9902H124.72C125.287 28.9909 125.829 29.2161 126.23 29.6164C126.63 30.0167 126.855 30.5595 126.856 31.1257V41.5658C126.855 42.132 126.63 42.6748 126.23 43.0751C125.829 43.4755 125.287 43.7007 124.72 43.7013Z" fill="#E14942"/>
<path d="M102.891 29.7C105.512 29.7 107.637 27.5754 107.637 24.9545C107.637 22.3336 105.512 20.209 102.891 20.209C100.27 20.209 98.1455 22.3336 98.1455 24.9545C98.1455 27.5754 100.27 29.7 102.891 29.7Z" fill="#E14942"/>
<path d="M102.891 27.8454C104.487 27.8454 105.781 26.5513 105.781 24.9549C105.781 23.3586 104.487 22.0645 102.891 22.0645C101.295 22.0645 100 23.3586 100 24.9549C100 26.5513 101.295 27.8454 102.891 27.8454Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_360_16644">
<rect width="153.668" height="149.999" fill="white"/>
</clipPath>
</defs>
</svg>


</template>
<script>
export default {
  name: 'BoardSVGLight'
}
</script>