<template>
  <div
    :class="`w-full space-y-6  ${isProfileNotComplete ? 'sm:pb-16 pb-44' : ''}`"
  >
    <NotificationComp
      v-if="isProfileNotComplete"
      title="Step 3 of 4: Complete your profile"
      desc="Please enter your address information below."
      :bgColor="`${store.state.theme ? 'darkWarning' : '#FFF9E3'}`"
      :textColor="`${store.state.theme ? 'textDark' : '#C27500'}`"
    />
    <CardBox customClass="border border-[#E7E7E7] rounded-3xl w-full !p-6">
      <div class="w-full flex justify-between items-center">
        <div class="flex flex-col space-y-1">
          <h1 class="text-black dark:text-white font-bold text-[17px]">
            Address Information
          </h1>
          <span class="text-lightTextColor dark:text-darkTextColor text-[12px]">
            Update your address information
          </span>
        </div>

        <div v-if="!isProfileComplete" class="md:block hidden">
          <CustomButton
            @onClick="handleAddress"
            title="Save and Continue"
            buttonType="primary"
            customStyle="px-5"
            :isLoading="isAddressSaving"
          />
        </div>
        <!-- button for web -->
        <div class="md:flex items-center space-x-3 hidden" v-else>
          <CustomButton
            title="Discard Changes"
            buttonType="secondary"
            @onClick="handleDiscard"
            customStyle="px-5 dark:bg-lightTextColor/70 dark:hover:bg-lightTextColor/20"
            :disabledVal="isDisable"
          />
          <CustomButton
            title="Save"
            buttonType="primary"
            customStyle="px-5"
            @onClick="handleAddress('update')"
            :isLoading="isAddressSaving"
            :loaderText="'Saving'"
            :disabledVal="isDisable"
          />
        </div>
      </div>

      <div class="space-y-6 pt-6">
        <div v-if="validationError">
          <span class="text-[14px] text-[#FD3232]">{{ validationError }}</span>
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Country*</span
          >

          <TextInput
            placeholder=""
            v-model="countryS"
            :readOnly="true"
            :firstimageSrc="require('../../assets/svgs/usaFlag.svg')"
            imageClass="h-5 w-5"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Address*</span
          >
          <TextInput
            placeholder="Eg: 123 Main Street, Anytown, CA 98765, United States"
            v-model="address"
            :value="address"
            :handleChange="onChangeHandler"
            :name="'address'"
            :className="`${!address.trim() && validationError ? 'border border-danger rounded-md' : ''}`"
            @keypressEnter="handleAddress('update')"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >City*</span
          >

          <TextInput
            placeholder="Eg: Springfield"
            v-model="city"
            :value="city"
            :handleChange="onChangeHandler"
            :name="'city'"
            :className="`${!city.trim()  && validationError ? 'border border-danger rounded-md' : ''}`"
            @keypressEnter="handleAddress('update')"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >State*</span
          >
          <TextInput
            placeholder="Eg: California"
            v-model="state"
            :value="state"
            :handleChange="onChangeHandler"
            :name="'state'"
            :className="`${!state.trim() && validationError ? 'border border-danger rounded-md' : ''}`"
            @keypressEnter="handleAddress('update')"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Zip Code*</span
          >
          <TextInput
            placeholder="Eg: 90210 "
            v-model="zip"
            type="number"
            :value="zip"
            :className="`${!zip.trim() && validationError ? 'border border-danger rounded-md' : ''}`"
            :handleChange="onChangeHandler"
            :name="'zip'"
            @keypressEnter="handleAddress('update')"
          />
        </div>
        <div v-if="!isProfileComplete" class="md:hidden block w-full">
          <div>
            <CustomButton
              @onClick="handleAddress"
              title="Save and Continue"
              buttonType="primary"
              customStyle="px-5"
              :isLoading="isAddressSaving"
            />
          </div>
        </div>

        <div class="md:hidden flex items-center space-x-3 pt-4" v-else>
          <CustomButton
            title="Discard Changes"
            @onClick="handleDiscard"
            buttonType="secondary"
            customStyle="px-5"
          />
          <CustomButton
            title="Save"
            buttonType="primary"
            customStyle="px-5"
            @onClick="handleAddress('update')"
            :isLoading="isAddressSaving"
          />
        </div>
      </div>
    </CardBox>
  </div>
</template>
<script>
import CardBox from "@/components/SharedComponents/CardBox.vue";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import NotificationComp from "../../components/SharedComponents/Notification.vue";
import TextInput from "@/components/SharedComponents/TextInput.vue";
import userApi from "@/services/userApi";
import store, {
  updateSideBarByUserDetails,
  getUserDetailsFromStore,
} from "@/store";

export default {
  name: "AddressComponent",
  // eslint-disable-next-line vue/no-unused-components
  components: { CardBox, CustomButton, NotificationComp, TextInput },
  props: {
    isProfileNotComplete: {},
  },
  setup() {
    return {
      store,
    };
  },
  data() {
    return {
      number: "",
      address: "",
      state: "",
      zip: "",
      countryS: "United States",
      city: "",
      isAddressSaving: false,
      validationError: "",
      isEditable: false,
      isDisable: true,
    };
  },
  methods: {
    handleAddress(val) {
      if (this.address?.trim() === "" || this.state?.trim() === "" || this.city?.trim() === "" || this.zip?.trim() === "") {
        this.validationError = "All fields with * are required";
        return;
      }else{
        if (this.address.trim() !== "" && this.state?.trim() !== "" && this.zip !== "" && this.countryS && this.city?.trim() !== "") {
        this.isAddressSaving = true;
        userApi
          .updateAddressInfo({
            address: this.address,
            state: this.state,
            zip: this.zip,
            country: this.countryS,
            city: this.city,
          })
          .then((response) => {
            console.log("response======>", response?.data?.status);
            if (response.data?.status) {
              console.log("inside if")
              this.isAddressSaving = false;
              let userDetail = response.data?.user_detail;
              store.commit("SET_USER_DETAIL", userDetail);
              this.isEditable = false;
              if (val !== "update") {
                updateSideBarByUserDetails(userDetail);
              } else {
                return;
              }

            } else {
              console.log("inside else")
              console.log("response err", response.data);
            }
            this.isAddressSaving = false;
            console.log(response);
          })
          .catch((e) => {
            console.log("in catch")
            this.isAddressSaving = false;
            console.log(e);
          });
      } else {
        this.validationError = "All fields with * are required";
      }
      }
     
    },
    onChangeHandler(event) {
      this.isDisable = false;
      this.isEditable = true;
      const inputValue = event.target.value;

      // Check for empty value
      // if (inputValue.trim() === "") {
      //   return;
      // }
        this.validationError = "";
      // Update the state with the cleaned input value
      this[event.target.name] = inputValue;
    },
    handleDiscard() {
      this.isEditable = false;
      this.address = this.userDetails?.address;
      this.state = this.userDetails?.state;
      this.zip = this.userDetails?.zip;
      this.countryS = this.userDetails?.country;
      this.city = this.userDetails?.city;
    },
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      // return this.proComp
      let user = this.userDetails;
      console.log("userDetails", user);
      return user?.is_profile_complete ?? false;
    },
  },
  mounted() {},
  watch: {
    userDetails: {
      handler(newVal) {
        this.address = newVal?.address;
        this.state = newVal?.state;
        this.zip = newVal?.zip;
        this.country = newVal?.country;
        this.city = newVal?.city;
      },
      immediate: true, // Trigger the handler immediately after establishing the watcher
      deep: true, // Watch changes deeply within the userDetails object
    },
  },
};
</script>
