<template>
     <div :class="`${name=='BUY' || name=='Buy' ?'bg-[#F0FFF0] dark:bg-[#285b28] ':'bg-[#FF2E2E]/10 dark:bg-[#FF2E2E]/20'} w-6/12 rounded-2xl flex justify-center`">
    <p :class="`font-bold text-[14px] ${name=='BUY' || name=='Buy' ?'text-[#3EAF3F] ':' text-[#FF2E2E]'} `">{{ name }}</p>
</div>   
</template>

<script>
export default {
  name: "BadgeComp",
  props: {
    name: {
      type: String,
    },
  },
};
</script>
