<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_408_16040)">
      <path
        d="M11.9999 1.66992C12.9549 1.66992 13.8449 2.13692 14.3899 2.91692L14.4949 3.07692L22.6089 16.6249C22.8555 17.052 22.9897 17.5347 22.9989 18.0277C23.0081 18.5208 22.8919 19.0081 22.6614 19.4441C22.4309 19.8801 22.0935 20.2504 21.6808 20.5204C21.2682 20.7904 20.7937 20.9513 20.3019 20.9879L20.1069 20.9959H3.88194C3.38965 20.9904 2.90678 20.8602 2.47841 20.6175C2.05005 20.3749 1.69015 20.0276 1.43231 19.6082C1.17447 19.1888 1.0271 18.7109 1.00396 18.2191C0.980808 17.7273 1.08264 17.2377 1.29994 16.7959L1.39894 16.6109L9.50894 3.07292C9.76861 2.64473 10.1342 2.29069 10.5706 2.04494C11.0069 1.79919 11.4992 1.67003 11.9999 1.66992ZM12.0099 14.9999L11.8829 15.0069C11.6399 15.0358 11.4159 15.1529 11.2534 15.3359C11.0908 15.5189 11.0011 15.7552 11.0011 15.9999C11.0011 16.2447 11.0908 16.4809 11.2534 16.664C11.4159 16.847 11.6399 16.964 11.8829 16.9929L11.9999 16.9999L12.1269 16.9929C12.37 16.964 12.594 16.847 12.7565 16.664C12.9191 16.4809 13.0088 16.2447 13.0088 15.9999C13.0088 15.7552 12.9191 15.5189 12.7565 15.3359C12.594 15.1529 12.37 15.0358 12.1269 15.0069L12.0099 14.9999ZM11.9999 7.99992C11.755 7.99995 11.5186 8.08988 11.3356 8.25264C11.1525 8.4154 11.0356 8.63967 11.0069 8.88292L10.9999 8.99992V12.9999L11.0069 13.1169C11.0359 13.36 11.1529 13.584 11.3359 13.7465C11.5189 13.909 11.7552 13.9988 11.9999 13.9988C12.2447 13.9988 12.481 13.909 12.664 13.7465C12.847 13.584 12.964 13.36 12.9929 13.1169L12.9999 12.9999V8.99992L12.9929 8.88292C12.9643 8.63967 12.8474 8.4154 12.6643 8.25264C12.4813 8.08988 12.2449 7.99995 11.9999 7.99992Z"
        :fill="fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_408_16040">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "WarningIconSvg",
  props: {
    fill: {
      type: String,
      default: "#C27500",
    },
  },
};
</script>
