<template>
  <div class="w-full h-full space-y-2">
    <div
      class="text-[#5D5D5D] dark:text-darkTextColor text-[14.5px] font-semibold"
    >
      {{ heading }}
    </div>
    <div class="space-y-0.5">
      <div
        :class="error ? 'border border-[#E14942]' : ''"
        class="flex space-x-2 bg-black/5 dark:bg-textInputDark hover:bg-[#e0e0e0] items-center px-4 rounded-[10px] py-2 w-full"
      >
        <div class="w-1/12 text-center">
          <button @click="decrement"   class="hover:bg-white/80 dark:hover:bg-white/10 p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 text-[#000000] dark:text-[#DCDCDC] h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 12h-15"
              />
            </svg>
          </button>
        </div>
        <div class="w-10/12">
          <input
            v-model="inputValue"
            @input="handleInput"
            step="0.5"
            type="number"
            class="w-full h-full dark:text-[#DCDCDC] text-[#000000] border-none focus:outline-none text-center bg-transparent"
          />
        </div>
        <div class="w-1/12 text-center text-md mr-4">
          <button @click="increment"  class="hover:bg-white/80 dark:hover:bg-white/10 p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 text-[#000000] dark:text-[#DCDCDC]  h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        </div>
      </div>
      <!-- <div class="text-[1.3vh] text-[#E14942]" v-if="error">{{ error }}</div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    priceValue: {
      type: Number,
      default: 0,
    },
    heading: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputValue: this.priceValue.toString(),
    };
  },
  watch: {
    inputValue(newVal) {
      this.$emit("input", parseFloat(newVal)); // Convert to number
    },
    priceValue(newVal) {
      this.inputValue = newVal.toString();
    },
  },
  methods: {
    keyDownEvent(eventFire){
      if(eventFire.code === 'ArrowUp'){
        // this.increment()
      }
      if(eventFire.code === 'ArrowDown'){
        // this.decrement()
      }
    },
    increment() {
      const currentValue = parseFloat(this.inputValue);
      if (!isNaN(currentValue)) {
        const incrementedValue = (currentValue + 0.5).toFixed(2);
        this.inputValue = String(incrementedValue);
      }
    },
    decrement() {
      const currentValue = parseFloat(this.inputValue);
      if (!isNaN(currentValue) && currentValue > 0) {
        const decrementedValue = (currentValue - 0.5).toFixed(2);
        this.inputValue = String(decrementedValue);
      }
    },

    handleInput(event) {
      this.inputValue = event.target.value;
    },
  },
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
