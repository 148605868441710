<template>
  <BaseModal>
    <div class="w-fit p-4 py-6">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <div class="flex items-center space-x-2">
            <img src="../../../assets/svgs/redWarning.svg" />
            <span class="text-[22px] text-[#E14942] font-bold">
              Remove '{{ store.getters.getDeleteTradeData.reference_name }}'
            </span>
          </div>
        </div>
        <div
          :class="`${
            isLoading ? 'pointer-events-none' : ''
          } cursor-pointer hover:bg-gray-200 dark:hover:bg-lightTextColor/60 h-fit rounded-full p-2`"
          @click="onCloseModal"
        >
          <CrossIconSvg />
        </div>
      </div>
      <div class="space-y-5 pb-5 pt-3">
        <p class="text-[14px] font-semibold dark:text-gray-500 text-[#454545]">
          Removing this trade is irreversible and cannot be recovered.
        </p>

        <p class="text-[13px] font-[200] dark:text-white">
          Are you sure you want to remove '{{
            store.getters.getDeleteTradeData.reference_name
          }}'?
        </p>
      </div>

      <div class="flex items-center space-x-4">
        <CustomButton
          v-if="!isLoading"
          buttonType="secondary"
          title="No, Keep Running"
          @onClick="onCloseModal"
          customStyle="dark:bg-lightTextColor/70 dark:hover:bg-lightTextColor/60"
        />
        <CustomButton
          buttonType="danger"
          title="Yes, Remove"
          :isLoading="isLoading"
          @onClick="deleteTrade"
          :loaderText="'Loading'"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../../SharedComponents/BaseModal.vue";
import store from "@/store";
import CrossIconSvg from "../../../assets/svgs/CrossIconSvg.vue";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import tradeApi from "@/services/tradeApi";
export default {
  name: "DeleteTradeModal",
  components: {
    BaseModal,
    CustomButton,
    CrossIconSvg,
  },

  setup() {
    return {
      store,
    };
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    onCloseModal() {
      this.$emit("onClose");
    },
    loadingTradingData: function () {
      tradeApi
        .tradeDetails()
        .then((d) => {
          store.commit("SET_TRADE_DATA_LIST", d.data.trades);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async deleteTrade() {
      const id = store.getters.getDeleteTradeData.id;

      this.isLoading = true;
      const res = await tradeApi.deleteTrade(id);
      if (res.data.status) {
        store.commit("SET_PROFILE_LOADING", true);
        this.loadingTradingData();

        this.onCloseModal();
      }

      // setTimeout(() => {
      //         this.error = "";
      //         }, 1500);

      // this.error="All fields are required"

      this.isLoading = false;
    },
  },
};
</script>
