<template>
  <div class="w-full sm:h-full h-[190vh] flex justify-center">
    <div
      class="h-full w-full space-y-8 pt-2"
      v-click-outside="closeCalculatorValueModal"
    >
      <div
        class="w-full md:space-y-0 space-y-6 md:flex justify-between"
        id="calculatorElement1"
      >
        <div
          v-for="(item, i) in tradeDetail"
          :key="i"
          :style="{ borderColor: item.color }"
          class="md:w-[32%] md:p-4 p-6 bg-[#FFFFFF] dark:bg-compBgDark/20 px-8 flex flex-col justify-center border-b-4 h-full rounded-[24px]"
        >
          <div
            class="flex text-[22px] items-center text-center text-[#000000] dark:text-white font-bold space-x-2"
          >
            <span class="text-center">{{ item?.from }}</span>
            <span class="text-center">{{ i < 2 ? "to" : "and" }}</span>
            <span class="text-center">{{ item?.to }}</span>
          </div>
          <div>
            <span
              class="text-[14px] line-clamp-1 font-semibold text-[#5D5D5D] dark:text-darkTextColor"
              >{{ item?.detail }}</span
            >
          </div>
        </div>
      </div>
      <!--This Element for Risk factor-->
      <div class="w-full  space-y-6 md:pb-0 pb-24">
        <div class="w-full h-full">
          <span class="text-[21px] font-bold text-[#000000] dark:text-white"
            >Calculated Risk Factor</span
          >
        </div>
        <div class="h-full w-full">
          <div
            class="w-full h-full md:space-y-0 space-y-3 md:space-x-8 md:flex justify-between"
          >
            <div
              class="md:w-6/12 w-full rounded-[24px] p-8 bg-white dark:bg-compBgDark/20"
            >
              <div class="w-full h-full">
                <div class="w-full flex justify-end">
                  <span
                    @click="openCalculatorValueModal"
                    class="underline text-[#3D3D3D] dark:text-white/40 cursor-pointer font-semibold text-[13px]"
                    >How this works?</span
                  >
                </div>
                <div
                  class="relative flex flex-col overflow-hidden w-full items-center justify-center"
                  id="calculatorElement4"
                >
                  <!-- <div class="absolute left-[5vh] bottom-[6vh]">
                  <span class="text-[#000000] font-bold text-[1.7vh]">Low</span>
                  </div>
                  <div class="absolute top-[13vh] left-[11vh]">
                  <span class="text-[#000000] font-bold text-[1.7vh]">Normal</span>
                  </div> -->
                  <div class="w-full absolute top-0 flex justify-center">
                    <div class="w-full flex items-center justify-center">
                      <span
                        :class="isLoading ? 'text-black' : 'text-[#E1A325]'"
                        class="font-bold sm:text-[14px] text-[10px]"
                        >Normal</span
                      >
                    </div>
                  </div>
                  <div class="sm:-mt-44 -mt-64">
                    <div>
                      <Guage
                        :value="resultValue?.sigma1move"
                        :loading="isLoading"
                        :options="options"
                      />
                    </div>
                  </div>
                  <div class="w-full -mt-40">
                    <div
                      class="w-full flex sm:pl-[1.5vh] pl-0 -pr-10 items-center justify-between sm:-mt-10 -mt-12"
                    >
                      <span
                        :class="isLoading ? 'text-black' : 'text-[#3EAF3F]'"
                        class="font-bold sm:text-[14px] text-[10px]"
                        >Low</span
                      >
                      <span
                        :class="isLoading ? 'text-black' : 'text-[#E14942]'"
                        class="font-bold sm:text-[14px] text-[10px]"
                        >Action Needed</span
                      >
                    </div>
                  </div>
                  <div class="w-full bottom-[1vh] absolute">
                    <div class="w-full flex items-center justify-center">
                      <span
                        v-if="isLoading"
                        class="bg-[#E7E7E7] dark:bg-darkBackground h-[4vh] rounded-[12px] w-2/12"
                      >
                      </span>
                      <span
                        v-else
                        :class="priceColor"
                        class="text-[#045bdd] dark:text-[#045bdd] font-bold pl-4 text-[26.5px]"
                      >
                        {{ percentageValue ? percentageValue : "0.0" }}%</span
                      >
                    </div>
                  </div>
                  <!-- <div class="absolute right-0 bottom-[6vh]">
               
                  </div> -->
                </div>
              </div>
            </div>
            <div
              class="md:w-6/12 w-full h-[90%] space-y-7 !p-4 !rounded-[24px] 2xl:h-[46.5vh] lg:h-[51vh] dark:bg-compBgDark/20 bg-white"
              v-if="showForm"
            >
              <div class="w-full h-[20%]" id="calculatorElement2">
                <RiskFactorInput
                  v-model="currentSpx"
                  :error="showCurrentSpxPriceError"
                  :priceValue="currentSpx"
                  v-on:increment="handleIncrement('spx')"
                  v-on:decrement="handleDecrement('spx')"
                  heading="Current Spx Price"
                />
              </div>
              <div class="w-full h-[20%]">
                <div class="w-full h-full space-y-2" id="calculatorElement3">
                  <div
                    class="text-[#5D5D5D] dark:text-darkTextColor text-[14.5px] font-semibold"
                  >
                    Trade Expiration
                  </div>
                  <div class="space-y-1">
                    <div
                      :class="showDateError ? 'border border-[#E14942]' : ''"
                      class="flex space-x-2 hover:bg-[#e0e0e0] bg-[#F2F2F3] dark:bg-textInputDark items-center justify-between px-4 rounded-[10px] py-2 w-full"
                    >
                      <div class="w-full flex items-center relative">
                        <flat-pickr
                          v-model="selectedDate"
                          placeholder="MM/DD/YYYY"
                          class="w-full text-[#000000] dark:text-[#DCDCDC] font-medium h-full border-none focus:outline-none bg-transparent"
                          :config="flatpickrConfig"
                        ></flat-pickr>

                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 text-[#3D3D3D] dark:text-darkTextColor h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showDateError"
                  class="text-[13px] text-[#E14942] pt-3"
                >
                  {{ showDateError }}
                </div>
              </div>
              <div
                :class="`flex items-crenter w-full h-[20%] justify-between space-x-4 ${
                  showDateError ? 'pt-3' : ''
                }`"
              >
                <!-- This is Call Strike Price -->
                <RiskFactorInput
                  v-model="callStrikePrice"
                  :priceValue="callStrikePrice"
                  :error="showCallStrikeError"
                  v-on:increment="handleIncrement('callstrike')"
                  v-on:decrement="handleDecrement('callstrike')"
                  heading="Call Strike Price"
                />
                <!-- This is Putstrike Price -->
                <RiskFactorInput
                  v-model="putStrikePrice"
                  :priceValue="putStrikePrice"
                  :error="showPutStrikeError"
                  v-on:increment="handleIncrement('putstrike')"
                  v-on:decrement="handleDecrement('putstrike')"
                  heading="Put Strike Price"
                />
              </div>
              <div class="flex items-center justify-center h-[12%] w-full">
                <CustomButton
                  :isLoading="isLoading"
                  title="Calculate Risk Factor"
                  buttonType="primary"
                  @onClick="handleRiskFactor"
                />
              </div>
            </div>
            <div v-else class="sm:w-6/12 w-full h-full">
              <div
                v-if="isLoading"
                class="w-full h-full rounded-[24px] space-y-8 bg-white dark:bg-compBgDark/20 p-8"
              >
                <div
                  class="h-full flex-col w-full space-y-3 flex items-center justify-center"
                >
                  <div>
                    <img
                      v-if="store.getters.getTheme"
                      src="../../assets/svgs/sidebar/CCDarkLogo.svg"
                      width="130"
                    />
                    <img
                      v-else
                      src="../../assets/svgs/sidebar/CCLightLogo.svg"
                      width="130"
                    />
                    <!-- <img src="../../assets/images/Logo.png" /> -->
                  </div>
                  <div class="w-4/12 text-center">
                    <span
                      class="text-[#000000] dark:text-white text-[13px] font-semibold"
                      >We are working on your request</span
                    >
                  </div>
                  <div class="mt-2 pt-6">
                    <svg
                      aria-hidden="true"
                      class="w-10 h-10 text-white animate-spin fill-[#0B3D90]"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                </div>
                <div class="w-full flex items-center h-[12%]">
                  <div class="w-full">
                    <CustomButton
                      :isLoading="isLoading"
                      loaderText="Please wait"
                      :isLoaderSvg="false"
                    />
                  </div>
                </div>
              </div>
              <div
                v-else
                class="w-full h-[100%] rounded-[24px] space-y-4 bg-[#FFFFFF] dark:bg-compBgDark/20 p-8"
              >
                <div class="h-[100%] w-full">
                  <div
                    v-if="showMessage"
                    class="w-full h-full flex items-center justify-center text-[#E14942] sm:text-[20px] text-[16px] font-semibold"
                  >
                    {{ showMessage }}
                  </div>
                  <div v-else class="w-full h-full space-y-4">
                    <div
                      class="h-[30%] flex xl:flex-row flex-col xl:items-center xl:justify-between xl:space-x-4 space-x-0 xl:space-y-0 space-y-4"
                    >
                      <div class="flex flex-col -space-y-2">
                        <span :class="priceColor" class="text-[32px] font-bold"
                          >{{ percentageValue }}%</span
                        >

                        <span
                          class="text-[#3D3D3D] dark:text-white text-[17px] font-semibold"
                          >Calculated Risk Factor</span
                        >
                      </div>
                      <!-- <div class="flex flex-col space-y-2 whitespace-nowrap">
                        <div class="flex flex-col space-y-1 dark:text-white/70">
                          <div
                            class="flex items-center space-x-2 sm:text-[12px] text-[10px] font-semibold"
                          >
                            <span>High Strike Price :</span>
                            <span>{{ resultValue?.high_price_strike }}</span>
                          </div>
                          <div
                            class="flex items-center space-x-2 sm:text-[12px] text-[10px] font-semibold"
                          >
                            <span>Low Strike Price :</span>
                            <span>{{ resultValue?.low_price_strike }}</span>
                          </div>
                        </div>
                        <div class="flex flex-col space-y-1 dark:text-white/70">
                          <div
                            class="flex items-center space-x-2 sm:text-[12px] text-[10px] font-semibold"
                          >
                            <span>High Strike Old :</span>
                            <span>{{ resultValueOld?.high_price_strike }}</span>
                          </div>
                          <div
                            class="flex items-center space-x-2 sm:text-[12px] text-[10px] font-semibold"
                          >
                            <span>Low Strike Old :</span>
                            <span>{{ resultValueOld?.low_price_strike }}</span>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <!-- <div class="h-[.5%] w-full bg-[#E7E7E7]" /> -->
                    <div
                      class="h-[55%] py-5 border-t border-b border-[#E7E7E7] flex flex-col justify-center space-y-2"
                    >
                      <div>
                        <span
                          class="text-[#3D3D3D] dark:text-white text-[18px] font-semibold"
                          >{{ resultHeading }}</span
                        >
                      </div>
                      <div>
                        <span
                          class="text-[#000000] dark:text-white sm:text-[28px] text-[22px] font-bold"
                        >
                          {{
                            resultHeading == "Action Needed"
                              ? "The trade should be "
                              : "Trade is in "
                          }}
                          <span :class="priceColor">{{ priceResultText }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="h-[12%] w-full">
                  <div class="h-full flex items-end w-full">
                    <div class="w-full">
                      <CustomButton
                        :isLoading="isLoading"
                        title="Calculate Again"
                        buttonType="primary"
                        @onClick="handleCalculateAgain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Ending Rish Factor-->
    </div>
    <div class="sm:pb-0 pb-16">
      <calculatorValueModal
        v-if="store.getters.getCovalTrendModal"
        @onClose="closeCalculatorValueModal"
      />
    </div>
    <BaseModal v-if="toggleFeatureAvailable">
      <div
        class="h-full lg:w-[40vw] bg-red-400 sm:w-[80vw] w-full overflow-hidden"
      >
        <div class="w-full flex justify-end">
          <div
            class="w-fit cursor-pointer pt-2 pr-2"
            @click="toggleFeatureAvailable = false"
          >
            <CrossIconSvg stroke="#8a8a8a" />
          </div>
        </div>
        <div class="h-full w-full flex items-center justify-center">
          <div class="h-full w-full rounded-3xl sm:!p-8 p-4">
            <div
              class="h-full w-full flex items-center flex-row justify-around sm:space-x-2 space-x-8"
            >
              <div class="h-full w-full flex flex-col -whitespace-nowrap">
                <span class="sm:text-[22px] text-[18px]"
                  >Please complete your profile to start using the</span
                >
                <span class="sm:text-[22px] text-[18px] font-bold">
                  Trade Risk Factor Calculator.</span
                >
              </div>
              <div class="h-full w-2/6 justify-end flex">
                <!-- width="120"
                  height="120" -->
                <LoginBannerUstad />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex items-center justify-end px-4 pb-2">
          <div>
            <CustomButton
              title="OK"
              @onClick="toggleFeatureAvailable = false"
              buttonType="primary"
              customStyle="!px-6 !py-1.5"
            />
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
// import Navbar from "../../components/NavBar"
import calculatorValueModal from "../../components/Modal/calculatorValueModal";
import RiskFactorInput from "../../components/input/RiskFactorInput.vue";
import service from "../../services/tradeApi";
import CustomButton from "../../components/SharedComponents/CustomButton.vue";
import store from "../../store";
import flatPickr from "vue-flatpickr-component";
import Guage from "../../components/Guage/radialGuage.vue";
import moment from "moment";
import BaseModal from "@/components/SharedComponents/BaseModal.vue";
import CrossIconSvg from "../../assets/svgs/CrossIconSvg.vue";
import LoginBannerUstad from "@/pages/userStory/login/LoginBannerUstad.vue";
// import CardBox from "../../components/SharedComponents/CardBox.vue";
import { getUserDetailsFromStore } from "../../store";
import vClickOutside from "vue-click-outside";
import tourService from "@/services/tourService";

export default {
  name: "TradeRisk",
  directives: {
    "click-outside": vClickOutside,
  },
  components: {
    RiskFactorInput,
    CustomButton,
    calculatorValueModal,
    LoginBannerUstad,
    BaseModal,
    CrossIconSvg,
    // DatePicker
    // CardBox,
    flatPickr,
    Guage,
  },
  setup() {
    return {
      store,
    };
  },
  data() {
    return {
      isAuthenticated: localStorage.getItem("token"),
      screenWidth: window.innerWidth,
      toggleFeatureAvailable: false,
      options: {
        needleType: "arrow",
        needleStart: 0,
        needleEnd: 55,
        needleWidth: 5,
        needleCircleSize: 7,
        needleCircleInner: true,
        needleCircleOuter: false,
        needleShadow: false,
        colorNeedle: "#0853CE",
        colorNeedleShadowUp: "#0853CE",
        colorNeedleCircleInner: "#0853CE",
        colorNeedleCircleInnerEnd: "#0853CE",
        colorNeedleEnd: "#0853CE",
        colorNeedleCircleOuter: "#0853CE",
        colorNeedleCircleOuterEnd: "#0853CE",
        animationDuration: 1500,
        animationRule: "linear",
        strokeTicks: false,
        highlights: "transparent",
        colorPlate: "transparent",
        borderShadowWidth: 0,
        borders: false,
        // colorMinorTicks: "gray",
        colorMinorTicks: [
          "#3EAF3F",
          "#3EAF3F",
          "#3EAF3F",
          "#3EAF3F",
          "#3EAF3F",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E1A325",
          "#E14942",
          "#E14942",
          "#E14942",
          "#E14942",
        ],
        colorNumbers: "transparent",
        valueBox: false,
        colorUnits: "white",
        units: "",
        width: window.screen.width > 994 ? 430 : 275,
        height: 450,
        minValue: 0,
        startAngle: 90,
        ticksAngle: 180,
        isMobileOrTabletDevice:
          /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|Tablet|Kindle/i.test(
            navigator.userAgent
          ),
        maxValue: 100,
        minorTicks: 10,
        // minorTicks: 0,
        colorMajorTicks: ["#3EAF3F", "#E1A325", "#E14942"],
        // colorMajorTicks: [
        //   "#3EAF3F",
        //   "#3EAF3F",
        //   "#E1A325",
        //   "#E1A325",
        //   "#E14942",
        //   "#E14942",
        //   "#E14942",
        //   "#E14942",
        //   "#E14942",
        //   "#E14942",
        //   "#E14942",
        // ],
        majorTicks: [0, 30, 100],
        tickThickness: 6,
        // majorTicks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },

      tradeDetail: [
        {
          from: "0.0%",
          to: "10.0%",
          detail: "Trade is in excellent shape",
          color: "#3EAF3F",
        },
        {
          from: "10.1%",
          to: "30.0%",
          detail: "Trade is in normal ranges",
          color: "#E1A325",
        },
        {
          from: "31%",
          to: "above",
          detail: `The trade should be altered or closed`,
          color: "#E14942",
        },
      ],
      currentSpx: 0,
      callStrikePrice: 0,
      putStrikePrice: 0,
      isLoading: false,
      showMessage: null,
      selectedDate: "",
      resultValue: null,
      percentageValue: null,
      resultValueOld: null,
      showForm: true,
      showCurrentSpxPriceError: null,
      showDateError: null,
      showCallStrikeError: null,
      showPutStrikeError: null,
      priceColor: "text-[#0B3D90]",
      priceResultText: "Normal",
      resultHeading: "Stable Trade",
      flatpickrConfig: {
        enable: [
          function (date) {
            return date.getDay() === 5;
          },
        ],
        dateFormat: "m/d/Y",
        minDate: "today",
      },
      tourObj: null,
    };
  },
  methods: {
    startTradeRiskTour() {
      const steps = [
        {
          element: "#calculatorElement1",
          intro:
            "These percentages are showing the meaning of dial’s number.\nThe resultant percentage represents the status of your trade.",
        },
        {
          element: "#calculatorElement2",
          intro:
            "This is the current SPX 500 price, we will use this price as an input to compute the trade risk factor.",
        },
        {
          element: "#calculatorElement3",
          intro: "Select the expiry of your trade here.",
        },
        {
          element: "#calculatorElement4",
          intro:
            "Here you go! It is showing the trade risk management number for the call, put, and expiry date of the trade you just entered.",
        },

        // Add more steps as needed
      ];
      tourService.startTour(steps, this.onTourEnd);
    },
    getObject() {
      // Retrieve the JSON string from localStorage
      const storedObject = localStorage.getItem("appTour");

      // If the storedObject is not null or undefined, parse it into an object
      if (storedObject) {
        this.tourObj = JSON.parse(storedObject);
        console.log(" RisktourObj", this.tourObj);
      } else {
        return;
      }
    },
    onTourEnd() {
      this.tourObj.riskCalculator = true;

      localStorage.setItem("appTour", JSON.stringify(this.tourObj));
    },
    handleIncrement(val) {
      console.log("value==>", val);
      if (val == "spx") {
        this.currentSpx = this.currentSpx + 0.1;
        console.log("val", this.currentSpx);
      } else if (val == "callstrike") {
        this.callStrikePrice = this.callStrikePrice + 0.1;
      } else if (val == "putstrike") {
        this.putStrikePrice = this.putStrikePrice + 0.1;
      }
    },
    closeCalculatorValueModal() {
      store.commit("SET_CovalTrendModal", false);
    },

    openCalculatorValueModal() {
      store.commit("SET_CovalTrendModal", true);
    },
    handleDecrement(val) {
      if (val == "spx") {
        if (this.currentSpx > 0) {
          this.currentSpx = this.currentSpx - 0.1;
          console.log("value", this.currentSpx);
        }
      } else if (val == "callstrike") {
        if (this.callStrikePrice > 0) {
          this.callStrikePrice = this.callStrikePrice - 0.1;
        }
      } else if (val == "putstrike") {
        if (this.putStrikePrice > 0) {
          this.putStrikePrice = this.putStrikePrice - 0.1;
        }
      } else {
        return;
      }
    },

    async handleRiskFactor() {
      if (!this.isProfileComplete) {
        this.toggleFeatureAvailable = true;
        console.log("Please Complete this Profile");
      } else {
        if (
          this.currentSpx == 0 &&
          this.callStrikePrice == 0 &&
          this.putStrikePrice == 0 &&
          !this.selectedDate
        ) {
          this.showCallStrikeError = "Field is required";
          this.showCurrentSpxPriceError = "Field is required";
          this.showPutStrikeError = "Field is required";
          this.showDateError = "Field is required";
          return;
        }

        if (this.currentSpx == 0) {
          this.showCurrentSpxPriceError = "Field is required";
          return;
        }
        if (!this.selectedDate) {
          this.showDateError = "Field is required";
          return;
        }

        if (this.callStrikePrice == 0) {
          this.showCallStrikeError = "Field is required";
          return;
        }
        if (!Number.isInteger(this.callStrikePrice)) {
          this.showCallStrikeError = "Please enter a valid numeric value";
          return;
        }
        if (this.putStrikePrice == 0) {
          this.showPutStrikeError = "Field is required";
          return;
        }
        if (!Number.isInteger(this.putStrikePrice)) {
          this.showPutStrikeError = "Please enter a valid numeric value";
          return;
        }

        this.isLoading = true;
        this.options = {
          ...this.options,
          colorMinorTicks: [
            "#B0B0B0",
            "#B0B0B0",
            "#B0B0B0",
            "#B0B0B0",
            "#B0B0B0",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#D1D1D1",
            "#6D6D6D",
            "#6D6D6D",
            "#6D6D6D",
            "#6D6D6D",
          ],
          colorMajorTicks: ["#B0B0B0", "#D1D1D1", "#6D6D6D"],
        };
        this.showForm = false;
        try {
          const formattedDate = moment(this.selectedDate, "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          );
          const payload = {
            iv: store?.getters?.getSpxData?.five?.iv
              ? store?.getters?.getSpxData?.five?.iv
              : 11.14,
            spx_price: this.currentSpx,
            expiry: formattedDate,
            call_strike_price: this.callStrikePrice,
            put_strike_price: this.putStrikePrice,
          };
          const res = await service.calculateRiskFactor(payload);
          const resOld = await service.calculateRiskFactorOld(payload);
          console.log("resOld", resOld);
          if (resOld?.data) {
            this.resultValueOld = resOld?.data;
            this.isLoading = false;
          }
          if (res?.data?.status) {
            this.isLoading = false;

            let resultValue = res?.data?.result;
            console.log("resultValue", resultValue);
            //high_price_strike
            //low_price_strike
            //sigma1move
            const [sigma1move, low_strike_price] = this.CalculateAngle(
              resultValue?.low_price_strike,
              resultValue?.high_price_strike
            );
            resultValue.sigma1move = sigma1move;
            resultValue.low_strike_price = low_strike_price;
            this.resultValue = resultValue;
          } else {
            this.showMessage = res?.data?.message;
            this.isLoading = false;
          }
          console.log("response", res);
        } catch (err) {
          console.log("err", err);
          this.showMessage = "Something went wrong. Please try again.";
          this.isLoading = false;
        }
      }
    },
    handleCalculateAgain() {
      this.showForm = true;
      this.resultValue = null;
      this.percentageValue = null;
      this.priceColor = "text-[#0B3D90]";
      // this.selectedDate = null;
      this.showMessage = null;
      // this.getCurrentSPx();
    },
    CalculateAngle(low_price_strike, high_price_strike) {
      /*if (high_price_strike < 10) {
        $(".pricestrike").css({"background": "#dff0d8", "color": "#3c763d"});
      } else if (high_price_strike >= 10 && high_price_strike < 31) {
        $(".pricestrike").css({"background": "#fcf8e3", "color": "#8a6d3b"});
      } else {
        $(".pricestrike").css({"background": "#f2dede", "color": "#a94442"});
      }
      if (low_price_strike < 10) {
        $(".highpricestrike").css({"background": "#dff0d8", "color": "#3c763d"});
      } else if (low_price_strike >= 10 && low_price_strike < 31) {
        $(".highpricestrike").css({"background": "#fcf8e3", "color": "#8a6d3b"});
      } else {
        $(".highpricestrike").css({"background": "#f2dede", "color": "#a94442"});
      }*/

      if (low_price_strike < high_price_strike) {
        low_price_strike = high_price_strike;
        console.log("low_price_strike", low_price_strike, high_price_strike);
      }
      this.percentageValue = low_price_strike;

      if (low_price_strike > 100 || low_price_strike < 0) {
        alert("please enter value between 0 and 100");
      } else {
        if (low_price_strike < 10) {
          let angle1 = (low_price_strike / 10) * 52;
          console.log("angle 1", angle1);
          // $('#hand').css({
          //   '-webkit-transform': 'rotate(' + angle1 + 'deg)',
          //   '-moz-transform': 'rotate(' + angle1 + 'deg)',
          //   '-o-transform': 'rotate(' + angle1 + 'deg)',
          //   '-ms-transform': 'rotate(' + angle1 + 'deg)'
          // });
          return [this.angleToPercentage(angle1), low_price_strike];
        } //<30
        if (low_price_strike >= 10 && low_price_strike < 31) {
          let angle2 = low_price_strike - 10;
          let temp = (angle2 / 20) * (128 - 52);
          temp += 52;
          angle2 = temp;
          console.log("angle 2", angle2);

          // $('#hand').css({
          //   '-webkit-transform': 'rotate(' + angle2 + 'deg)',
          //   '-moz-transform': 'rotate(' + angle2 + 'deg)',
          //   '-o-transform': 'rotate(' + angle2 + 'deg)',
          //   '-ms-transform': 'rotate(' + angle2 + 'deg)'
          // });
          return [this.angleToPercentage(angle2), low_price_strike];
        } //<128

        if (low_price_strike >= 31 && low_price_strike <= 100) {
          let angle3 = low_price_strike - 31;
          let temp = (angle3 / 70) * (180 - 128);
          temp += 128;
          angle3 = temp;
          console.log("angle 3", angle3);
          // $('#hand').css({
          //   '-webkit-transform': 'rotate(' + angle3 + 'deg)',
          //   '-moz-transform': 'rotate(' + angle3 + 'deg)',
          //   '-o-transform': 'rotate(' + angle3 + 'deg)',
          //   '-ms-transform': 'rotate(' + angle3 + 'deg)'
          // });
          return [this.angleToPercentage(angle3), low_price_strike];
        }
      }
    },
    angleToPercentage(angle) {
      const maxAngleSemiCircle = 180;
      const percentage = (angle / maxAngleSemiCircle) * 100;
      console.log("percentage", percentage.toFixed(2));
      return Number(percentage.toFixed(2));
    },
    async getCurrentSPx() {
      const res = await service.getSpxPrice();
      console.log("res==>",res)
      if (res && res?.price) {
        this.currentSpx = res.price;
        this.callStrikePrice = Math.round(this.currentSpx) + 5;
        this.putStrikePrice = Math.round(this.currentSpx) - 5;
      } else {
        this.cuurentSpx = 0;
        this.callStrikePrice = 0;
        this.putStrikePrice = 0;
      }
    },
  },

  mounted() {
    this.getObject();

    this.getCurrentSPx();
    console.log("windowWidth", this.screenWidth);
    if (store.getters.getTheme) {
      this.colorNeedle = "#0853CE";
      this.colorNeedleShadowUp = "#0853CE";
      this.colorNeedleCircleInner = "#0853CE";
      this.colorNeedleCircleInnerEnd = "#0853CE";
      this.colorNeedleEnd = "#0853CE";
      this.colorNeedleCircleOuter = "#0853CE";
      this.colorNeedleCircleOuterEnd = "#0853CE";
      //   colorNeedle: store.getters.getTheme ? "#0853CE" : "#0B3D90",
      //   colorNeedleShadowUp: store.getters.getTheme ? "#0853CE" : "#0B3D90",
      //   colorNeedleCircleInner: store.getters.getTheme ? "#0853CE" : "#0B3D90",
      //   colorNeedleCircleInnerEnd: store.getters.getTheme ? "#0853CE" : "#0B3D90",
      //   colorNeedleEnd: store.getters.getTheme ? "#0853CE" : "#0B3D90",
      //   colorNeedleCircleOuter: store.getters.getTheme ? "#0853CE" : "#0B3D90",
      //   colorNeedleCircleOuterEnd: store.getters.getTheme ? "#0853CE" : "#0B3D90",
    } else {
      this.colorNeedle = "#0B3D90";
      this.colorNeedleShadowUp = "#0B3D90";
      this.colorNeedleCircleInner = "#0B3D90";
      this.colorNeedleCircleInnerEnd = "#0B3D90";
      this.colorNeedleEnd = "#0B3D90";
      this.colorNeedleCircleOuter = "#0B3D90";
      this.colorNeedleCircleOuterEnd = "#0B3D90";
    }
    if (this.screenWidth < 1600) {
      this.options = {
        ...this.options,
        tickThickness: 12,
      };
    }

    // switch off webtour
    
    // if (this.tourObj) {
    //   if (
    //     this.isAuthenticated &&
    //     !this.tourObj.riskCalculator &&
    //     this.isProfileComplete
    //   ) {
    //     console.log("Helloo");
    //     this.startTradeRiskTour();
    //   }
    // }
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      let user = this.userDetails;
      return user?.is_profile_complete ?? false;
    },
  },
  watch: {
    isLoading: function (val) {
      if (!val) {
        setTimeout(() => {
          this.options = {
            ...this.options,
            colorMinorTicks: [
              "#3EAF3F",
              "#3EAF3F",
              "#3EAF3F",
              "#3EAF3F",
              "#3EAF3F",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E1A325",
              "#E14942",
              "#E14942",
              "#E14942",
              "#E14942",
            ],
            colorMajorTicks: ["#3EAF3F", "#E1A325", "#E14942"],
          };
        }, 1500);
      }
    },
    currentSpx: function () {
      this.showCurrentSpxPriceError = null;
    },
    callStrikePrice: function () {
      this.showCallStrikeError = null;
    },
    putStrikePrice: function () {
      this.showPutStrikeError = null;
    },
    selectedDate: function () {
      this.showDateError = null;
    },
    priceColor: function (val) {
      console.log("priceColor", val);
    },

    resultValue: function () {
      if (this.percentageValue !== null) {
        if (this.percentageValue <= 10) {
          this.priceColor = "text-[#3EAF3F]";
          this.resultHeading = "Optimal Trade";
          this.priceResultText = "excellent shape";
        } else if (this.percentageValue > 10 && this.percentageValue <= 30) {
          this.priceColor = "text-[#E1A325]";
          this.priceResultText = "normal ranges";
          this.resultHeading = "Stable Trade";
        } else if (this.percentageValue > 30 && this.percentageValue <= 100) {
          this.priceColor = "text-[#E14942]";
          this.priceResultText = "altered or closed";
          this.resultHeading = "Action Needed";
        } else {
          this.priceColor = "text-[#0B3D90]";
          this.resultHeading = "Action Needed";
          this.priceResultText = "altered or closed";
        }
      }
    },
  },
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>

<!-- <style>
.dark .flatpickr-innerContainer{
background: red !important;
background-color: aqua !important;

}
.dark .flatpickr-months{

background-color: aqua !important;
}
.flatpickr-rContainer {
  background-color: aqua !important;
  color:black
}
.dark.flatpickr-rContainer {
  background-color: rgb(29, 41, 41) !important;
  color:black
}
</style> -->
