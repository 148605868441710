var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full py-3 flex items-center justify-center"},[_c('div',[_vm._m(0),_c('div',{staticClass:"w-full flex justify-center pt-2"},[_c('div',{staticClass:"lg:w-[500px] md:w-[46vw] sm:w-[72vw] w-full"},[_c('div',{staticClass:"w-full flex justify-between rounded-3xl p-1.5 space-x-2 sm:text-[14px] text-[12px] shadow-lg"},[_c('div',{class:`rounded-3xl p-2 cursor-pointer w-full text-center flex items-center justify-center space-x-1 text-white ${_vm.isActiveToggle ? 'bg-[#7B7B7B66]' : 'bg-[#3EAF3F]'
            }`,on:{"click":function($event){return _vm.toggleSwitchHandler(false)}}},[_c('p',[_vm._v("Yearly Plan")]),_c('p'),_c('div',{staticClass:"bg-[#CAFFD9] rounded-3xl sm:text-[11px] text-[9px] px-1 font-[600] p-0.5 text-[#007922]"},[_vm._v(" Save $200 ")])]),_c('div',{class:`rounded-3xl cursor-pointer w-full justify-center flex items-center text-center text-white  p-2 ${_vm.isActiveToggle ? 'bg-[#3EAF3F]' : 'bg-[#7B7B7B66]'
            }`,on:{"click":function($event){return _vm.toggleSwitchHandler(true)}}},[_c('p',[_vm._v("Monthly Plan")])])])])]),_c('div',{staticClass:"sm:px-0 sm:h-full h-[60vh] sm:mt-0 mt-1 overflow-y-auto px-8"},[(_vm.isActiveToggle)?_c('div',{staticClass:"w-full sm:flex pt-6 pb-4 sm:space-x-4 sm:space-y-0 space-y-4 justify-center items-center"},_vm._l((_vm.subscriptionPlans.monthly),function(items,index){return _c('div',{key:index,class:` ${_vm.store?.getters?.getUserDetails?.is_profile_complete &&
            items.id == _vm.store?.getters?.getUserDetails?.subscription
            ? 'module-border-wrap'
            : ''
            }   rounded-[35px]`},[_c('CardBox',{class:`dark:bg-[#050505]  rounded-[25px] `,attrs:{"customClass":_vm.getClasses(items)}},[_c('div',{staticClass:"flex items-center justify-between w-full h-full"},[_c('div',{staticClass:"flex-col w-full justify-between flex items-start space-y-3 relative h-full"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{attrs:{"src":_vm.getPlanImage(items.db_name),"alt":""}}),_c('div',{},[_c('div',{staticClass:"text-black dark:text-white text-[17px] font-semibold"},[_vm._v(" "+_vm._s(items.title)+" ")]),_c('div',{staticClass:"text-[#3EAF3F] -mt-1 font-semibold text-[14px]"},[_vm._v(" $"+_vm._s(items.amount)+"/mo ")])])]),_c('div',{staticClass:"flex flex-col h-full items-start space-y-1"},_vm._l((items.title === 'Standard Plan' ? _vm.subscriptionPlans.detail[0] : items.title === 'Gold Plan' ? _vm.subscriptionPlans.detail[1] : _vm.subscriptionPlans.detail[2]),function(item,index){return _c('div',{key:index,staticClass:"pt-3"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{staticClass:"h-4 w-4",attrs:{"src":require("../../assets/svgs/checkPlans.svg")}}),_c('p',{staticClass:"text-black dark:text-white font-[600] text-[12px]"},[_vm._v(" "+_vm._s(item)+" ")])])])}),0)])])])],1)}),0):_c('div',{staticClass:"w-full sm:flex pt-6 pb-4 sm:space-x-4 sm:space-y-0 space-y-4 justify-center items-center"},_vm._l((_vm.subscriptionPlans.yearly),function(items,index){return _c('div',{key:index,class:`${_vm.store?.getters?.getUserDetails?.is_profile_complete &&
            items.id == _vm.store?.getters?.getUserDetails?.subscription
            ? 'module-border-wrap'
            : ''
            }   rounded-[35px]`},[_c('CardBox',{class:`dark:bg-[#050505]  rounded-[25px] `,attrs:{"customClass":_vm.getClasses(items)}},[_c('div',{staticClass:"flex items-center justify-between w-full h-full"},[_c('div',{staticClass:"flex-col w-full justify-between flex items-start space-y-3 relative h-full"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{attrs:{"src":_vm.getPlanImage(items.db_name),"alt":""}}),_c('div',{},[_c('div',{staticClass:"text-black dark:text-white text-[17px] font-semibold"},[_vm._v(" "+_vm._s(items.title)+" ")]),_c('div',{staticClass:"text-[#3EAF3F] -mt-1 font-semibold text-[14px]"},[_vm._v(" $"+_vm._s(items.myPrice)+"/mo ")])])]),_c('div',{staticClass:"flex flex-col h-full items-start space-y-1"},_vm._l((items.title === 'Standard Plan' ? _vm.subscriptionPlans.detail[0] : items.title === 'Gold Plan' ? _vm.subscriptionPlans.detail[1] : _vm.subscriptionPlans.detail[2]),function(item,index){return _c('div',{key:index,staticClass:"pt-3"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{staticClass:"h-4 w-4",attrs:{"src":require("../../assets/svgs/checkPlans.svg")}}),_c('p',{staticClass:"text-black dark:text-white font-[600] text-[12px]"},[_vm._v(" "+_vm._s(item)+" ")])])])}),0)])])])],1)}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center justify-center space-x-4"},[_c('p',{staticClass:"font-[600] text-[28px] dark:text-white text-center"},[_vm._v(" Pricing ")])]),_c('p',{staticClass:"font-[400] sm:text-[14px] text-[12px] text-[#3D3D3D] dark:text-gray-400 text-center pt-2 pb-2"},[_vm._v(" Elevate your experience and savings with our versatile pricing plans tailored to your needs. ")])])
}]

export { render, staticRenderFns }