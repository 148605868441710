var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.showLoginScreen)?_c('div',{staticClass:"flex lg:flex-row flex-col h-full w-full sm:justify-center sm:items-center lg:space-x-40 sm:space-x-0 lg:space-y-0 sm:space-y-10 space-y-0"},[_c('div',{staticClass:"lg:flex hidden"},[_c('LoginPageBannerSvg')],1),_c('img',{staticClass:"sm:w-[44vw] w-[36vw] sm:h-36 h-28 pb-6 md:hidden self-center",attrs:{"src":require("../../../assets/svgs/sidebar/CCLightLogo.svg")}}),_c('div',{staticClass:"flex justify-center items-center h-[60vh] lg:w-3/12 sm:w-4/6 w-full px-2 py-3"},[_c('div',{class:`${
        _vm.isLoader ? 'pointer-events-none bg-opacity-30' : ''
      } bg-white rounded-3xl space-y-3 w-full flex flex-col lg:p-6 sm:p-3 p-3`},[_c('div',{staticClass:"flex flex-col lg:space-y-1 sm:space-y-3 space-y-1.5"},[_c('span',{staticClass:"text-center text-[20px] font-semibold"},[_vm._v(" Sign In ")]),_c('span',{staticClass:"text-center text-lightTextColor text-[14px] lg:text-[15px]"},[_vm._v(" Create a new account? "),_c('router-link',{attrs:{"to":"/sign-up"}},[_c('span',{staticClass:"text-btnPrimary hover:text-blue-500 lg:text-[15px] text-[14px] font-semibold"},[_vm._v(" Sign Up ")])])],1)]),(_vm.openEmail)?_c('div',[_c('div',{staticClass:"sm:space-y-3 space-y-1.5"},[_c('div',{class:_vm.validationObject.email.status
                ? 'border-danger border-2 rounded-md'
                : ' '},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"pl-5 lg:h-12 sm:h-14 h-14 text-[14px] placeholder:text-lightTextColor bg-[#EFEFEF]/50 hover:bg-lightTextColor/10 border-none text-lightTextColor focus:border-none focus:ring-2 focus:outline-none rounded-lg ring-none block w-full p-2.5",class:_vm.validationObject.email.status ? '' : '',attrs:{"type":"email","id":"email","placeholder":"Email Address ","required":""},domProps:{"value":(_vm.email)},on:{"focus":function($event){(_vm.isInputFocused = false),
                  (_vm.validationObject.email.status = false),
                  (_vm.messages = '')},"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value},_vm.validInput],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}}})]),_c('div',{staticClass:"hover:bg-[#EFEFEF]/80 bg-[#EFEFEF]/50 flex items-center space-x-1 flex-row rounded-lg lg:h-12 sm:h-14 h-14 w-full lg:p-2.5 sm:p-2 p-2",class:`${
              _vm.validationObject.password.status
                ? 'border-red-500 border-2'
                : ''
            } ${_vm.isInputFocused ? 'border-blue-600 border-2' : ''}`},[((!_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full focus:ring-0 bg-transparent text-[14px] pl-3 border-none text-lightTextColor placeholder:text-lightTextColor",attrs:{"id":"password","placeholder":"Password","required":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"focus":function($event){(_vm.isInputFocused = true),
                  (_vm.ReInputFocused = false),
                  (_vm.messages = ''),
                  (_vm.validationObject.password.status = '')},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)},"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((!_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full focus:ring-0 bg-transparent text-[14px] pl-3 border-none text-lightTextColor placeholder:text-lightTextColor",attrs:{"id":"password","placeholder":"Password","required":"","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"focus":function($event){(_vm.isInputFocused = true),
                  (_vm.ReInputFocused = false),
                  (_vm.messages = ''),
                  (_vm.validationObject.password.status = '')},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)},"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full focus:ring-0 bg-transparent text-[14px] pl-3 border-none text-lightTextColor placeholder:text-lightTextColor",attrs:{"id":"password","placeholder":"Password","required":"","type":!_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.password)},on:{"focus":function($event){(_vm.isInputFocused = true),
                  (_vm.ReInputFocused = false),
                  (_vm.messages = ''),
                  (_vm.validationObject.password.status = '')},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.showPassword)?_c('svg',{class:'pr-2 hover:cursor-pointer h-7 w-7',attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"#000000"},on:{"click":_vm.enablePassword}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"}})]):_c('svg',{class:'pr-2 hover:cursor-pointer h-7 w-7',attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"#000000"},on:{"click":_vm.enablePassword}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M15 12a3 3 0 11-6 0 3 3 0 016 0z"}})])]),_c('span',{class:_vm.messages
                ? 'text-danger  h-5 text-[12px] '
                : 'text-[12px]  h-5 text-danger mb-20 bg-green-500'},[_vm._v(" "+_vm._s(_vm.messages)+" ")]),_c('LoginButtons',{attrs:{"customStyle":"lg:h-12 sm:h-14 h-12 w-full","title":"Login","buttonType":"","isLoading":_vm.isLoader,"loaderText":"Please wait","message":_vm.messages ? _vm.messages : ''},on:{"onClick":_vm.login}})],1),_c('div',{staticClass:"w-full flex pt-2 justify-center"},[_c('p',{staticClass:"text-lightTextColor text-[12px] text-center px-4",on:{"click":_vm.gotToForgetPassword}},[_c('a',{staticClass:"hover:text-blue-500 hover:underline cursor-pointer text-btnPrimary"},[_vm._v("Forgot password?")])])])]):_vm._e(),(_vm.toggleFeatureAvailable)?_c('BaseModal',[_c('div',{staticClass:"h-full lg:w-[40vw] sm:w-[80vw] w-full overflow-hidden"},[_c('div',{staticClass:"w-full flex justify-end"},[_c('div',{staticClass:"w-fit cursor-pointer pt-2 pr-2",on:{"click":function($event){_vm.toggleFeatureAvailable = false}}},[_c('CrossIconSvg',{attrs:{"stroke":"#8a8a8a"}})],1)]),_c('div',{staticClass:"h-full w-full flex items-center justify-center"},[_c('div',{staticClass:"h-full w-full rounded-3xl sm:!p-8 p-4"},[_c('div',{staticClass:"h-full w-full flex items-center flex-row justify-around sm:space-x-2 space-x-8"},[_c('div',{staticClass:"h-full w-full flex flex-col -whitespace-nowrap"},[_c('span',{staticClass:"sm:text-[22px] text-[18px]"},[_vm._v("This page is under development")]),_c('span',{staticClass:"sm:text-[22px] text-[18px] font-bold"},[_vm._v("It will be available soon.")])]),_c('div',{staticClass:"h-full w-2/6 justify-end flex"},[_c('LoginBannerUstad')],1)])])]),_c('div',{staticClass:"w-full flex items-center justify-end px-4 pb-2"},[_c('div',[_c('CustomButton',{attrs:{"title":"OK","buttonType":"primary","customStyle":"!px-6 !py-1.5"},on:{"onClick":function($event){_vm.toggleFeatureAvailable = false}}})],1)])])]):_vm._e()],1)])]):_c('div',[_c('AfterSignInWarning',{attrs:{"isModal":_vm.showLoginScreen},on:{"updateValue":_vm.handleUpdateValue}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }