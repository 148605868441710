<template>
  <div class="flex flex-col space-y-1">
    <span
      class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
      >Phone number</span
    >
    <div class="flex items-center space-x-3">
      <span class="font-semibold dark:text-darkTextColor"
        >{{ formatPhoneNumber(this.updatedPhoneNumber) }}</span
      >
      <div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
            fill="#3EAF3F"
          />
        </svg>
      </div>
      <div v-if="showEdit && isProfileComplete">
        <CustomButton
          @onClick="onClickHandler"
          title="Edit"
          :buttonType="`${store.state.theme ? 'primary' : 'rounded'}`"
          customStyle="!px-5 !py-2"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "../../components/SharedComponents/CustomButton.vue";
import store, { getUserDetailsFromStore } from "../../store";

export default {
  name: "PhoneNumberWithVerification",
  components: { CustomButton },
  setup() {
    return {
      store,
    };
  },
  methods: {
    onClickHandler(){
      this.$emit('onClick',this.phoneNumber)
    },
    formatPhoneNumber(phoneNumber) {
      console.log("phoneNumber=>",phoneNumber);
      // Assuming the phone number format is always (XXX)XXXXXXX
      const formattedNumber =
        phoneNumber.slice(0, 8) +
        " " +
        phoneNumber.slice(8, phoneNumber.length);

      // Add a space after the closing bracket
      return formattedNumber.replace(")", ") ");
    },
  },
  props: {
    phoneNumber: {},
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isPersonalInfoHave() {
      let userDetails = getUserDetailsFromStore();
      let profile_status = userDetails["profile_status"];
      console.log("profile_status", profile_status?.["profile"]);
      return profile_status?.["profile"] ?? false;
    },
    updatedPhoneNumber() {
      let userPhoneNumber = this.userDetails.phone;
      return userPhoneNumber;
    },
    isProfileComplete() {
      // return this.proComp
      let user = this.userDetails;
      console.log("userDetails", user);
      return user?.is_profile_complete ?? false;
    },
  },
};
</script>
