<template>
  <div class="w-full h-full py-3 flex items-center justify-center">
    <div>
      <div>
        <div class="flex items-center justify-center space-x-4">
          <p class="font-[600] text-[28px] dark:text-white text-center">
            Pricing
          </p>
          <!--  -->
        </div>
        <p class="font-[400] sm:text-[14px] text-[12px] text-[#3D3D3D] dark:text-gray-400 text-center pt-2 pb-2">
          Elevate your experience and savings with our versatile pricing plans
          tailored to your needs.
        </p>
      </div>

      <div class="w-full flex justify-center pt-2">
        <div class="lg:w-[500px] md:w-[46vw] sm:w-[72vw] w-full">
          <div class="w-full flex justify-between rounded-3xl p-1.5 space-x-2 sm:text-[14px] text-[12px] shadow-lg">
            <div :class="`rounded-3xl p-2 cursor-pointer w-full text-center flex items-center justify-center space-x-1 text-white ${isActiveToggle ? 'bg-[#7B7B7B66]' : 'bg-[#3EAF3F]'
              }`" @click="toggleSwitchHandler(false)">
              <p>Yearly Plan</p>
              <p></p>
              <div class="bg-[#CAFFD9] rounded-3xl sm:text-[11px] text-[9px] px-1 font-[600] p-0.5 text-[#007922]">
                Save $200
              </div>
            </div>
            <div :class="`rounded-3xl cursor-pointer w-full justify-center flex items-center text-center text-white  p-2 ${isActiveToggle ? 'bg-[#3EAF3F]' : 'bg-[#7B7B7B66]'
              }`" @click="toggleSwitchHandler(true)">
              <p>Monthly Plan</p>
            </div>
          </div>
        </div>
      </div>

      <div class="sm:px-0 sm:h-full h-[60vh] sm:mt-0 mt-1 overflow-y-auto px-8">
        <div class="w-full sm:flex pt-6 pb-4 sm:space-x-4 sm:space-y-0 space-y-4 justify-center items-center"
          v-if="isActiveToggle">
          <div v-for="(items, index) in subscriptionPlans.monthly" :key="index" :class="` ${store?.getters?.getUserDetails?.is_profile_complete &&
              items.id == store?.getters?.getUserDetails?.subscription
              ? 'module-border-wrap'
              : ''
              }   rounded-[35px]`">
            <CardBox :customClass="getClasses(items)" :class="`dark:bg-[#050505]  rounded-[25px] `">
              <div class="flex items-center justify-between w-full h-full">
                <div class="flex-col w-full justify-between flex items-start space-y-3 relative h-full">
                  <div class="flex items-center space-x-2">
                    <img :src="getPlanImage(items.db_name)" alt="" />
                    <div class="">
                      <div class="text-black dark:text-white text-[17px] font-semibold">
                        {{ items.title }}
                      </div>
                      <div class="text-[#3EAF3F] -mt-1 font-semibold text-[14px]">
                        ${{ items.amount }}/mo
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col h-full items-start space-y-1">
                    <div class="pt-3"
                      v-for="(item, index) in items.title === 'Standard Plan' ? subscriptionPlans.detail[0] : items.title === 'Gold Plan' ? subscriptionPlans.detail[1] : subscriptionPlans.detail[2]"
                      :key="index">
                      <div class="flex items-center space-x-2">
                        <img class="h-4 w-4" src="../../assets/svgs/checkPlans.svg" />

                        <p class="text-black dark:text-white font-[600] text-[12px]">
                          {{ item }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBox>
          </div>
        </div>

        <div class="w-full sm:flex pt-6 pb-4 sm:space-x-4 sm:space-y-0 space-y-4 justify-center items-center" v-else>
          <div v-for="(items, index) in subscriptionPlans.yearly" :key="index" :class="`${store?.getters?.getUserDetails?.is_profile_complete &&
              items.id == store?.getters?.getUserDetails?.subscription
              ? 'module-border-wrap'
              : ''
              }   rounded-[35px]`">
            <CardBox :customClass="getClasses(items)" :class="`dark:bg-[#050505]  rounded-[25px] `">
              <div class="flex items-center justify-between w-full h-full">
                <div class="flex-col w-full justify-between flex items-start space-y-3 relative h-full">
                  <div class="flex items-center space-x-2">
                    <img :src="getPlanImage(items.db_name)" alt="" />
                    <div class="">
                      <div class="text-black dark:text-white text-[17px] font-semibold">
                        {{ items.title }}
                      </div>
                      <div class="text-[#3EAF3F] -mt-1 font-semibold text-[14px]">
                        <!-- ${{ items.amount }}/mo -->
                        ${{ items.myPrice }}/mo
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col h-full items-start space-y-1">
                    <div class="pt-3"
                      v-for="(item, index) in items.title === 'Standard Plan' ? subscriptionPlans.detail[0] : items.title === 'Gold Plan' ? subscriptionPlans.detail[1] : subscriptionPlans.detail[2]"
                      :key="index">
                      <div class="flex items-center space-x-2">
                        <img class="h-4 w-4" src="../../assets/svgs/checkPlans.svg" />

                        <p class="text-black dark:text-white font-[600] text-[12px]">
                          {{ item }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store, { getUserDetailsFromStore, getPackages } from "@/store";
import CardBox from "@/components/SharedComponents/CardBox.vue";
import userApi from "@/services/userApi";

export default {
  name: "ActiveTrades",
  components: {
    CardBox,
  },

  setup() {
    return {
      store,
    };
  },
  mounted() {
    this.defaultPlan();
  },
  data() {
    return {
      tradeLoading: true,
      isActiveToggle: null,
      showModal: false,
      images: {
        new_monthly_standard: require("../../assets/svgs/standard.svg"),
        new_monthly_gold: require("../../assets/svgs/goldplan.svg"),
        new_monthly_platinum: require("../../assets/svgs/platinumplan.svg"),
        new_yearly_standard: require("../../assets/svgs/standard.svg"),
        new_yearly_gold: require("../../assets/svgs/goldplan.svg"),
        new_yearly_platinum: require("../../assets/svgs/platinumplan.svg"),
      },

      subscriptionPlans: {
        status: "monthly",
        monthly:{},
        yearly:{},
        detail: {
          0: ["$5 Spreads", "2 Trades Allowed"],
          1: ["$5 and $10 Spreads", "4 Trades Allowed", "Monthly Newsletter", "Webinar", "Priority Support"],
          2: ["$5 and $10 Spreads", "Unlimited Trades Allowed", "Webinar", "Trade Risk History", "Trade Assistance Pro (Direct Call)", "Priority Support"]
        }

      },
    };
  },

  computed: {
    userDetails() {
      console.log('user detail', getUserDetailsFromStore())

      this.getPlansDetail()

      return getUserDetailsFromStore();
    },
    PackagePrice() {
      this.getSubs()
      return getPackages();
    },

  },
  methods: {
    getSubs() {
      if (this.userDetails.subscription === 4 || this.userDetails.subscription === 6 || this.userDetails.subscription === 8) {
        this.isActiveToggle = true
      } else this.isActiveToggle = false
    },
    async getPlansDetail() {
      const getPrice = await userApi.getSubscriptionPlans()
      console.log('prices 22', getPrice?.data?.list)
    },
    toggleSwitchHandler(val) {
      this.isActiveToggle = val;
    },

    defaultPlan() {
      if(this.userDetails.subscription==4 || this.userDetails.subscription==6 || this.userDetails.subscription==8 ){
        this.toggleSwitchHandler(true)
      } else this.toggleSwitchHandler(false)
      this.subscriptionPlans.monthly[0] = this?.PackagePrice?.monthly[0]
      this.subscriptionPlans.monthly[1] = this?.PackagePrice?.monthly[2]
      this.subscriptionPlans.monthly[2] = this?.PackagePrice?.monthly[1]
      this.subscriptionPlans.yearly[0] = this.PackagePrice.yearly[0]
      this.subscriptionPlans.yearly[1] = this.PackagePrice.yearly[2]
      this.subscriptionPlans.yearly[2] = this.PackagePrice.yearly[1]
      this.subscriptionPlans.yearly[0]['myPrice'] = "39.99"
      this.subscriptionPlans.yearly[1]['myPrice'] = "89.99"
      this.subscriptionPlans.yearly[2]['myPrice'] = "49.99"
    },

    isCurrentPlan(item) {
      return item.id == this.userDetails?.subscription;
    },
    getHeight(item) {

      if (item === "new_monthly_platinum" || item === "new_yearly_platinum") {
        return "h-[50vh]";
      } else {
        return "h-[45vh]";
      }
    },
    getPlanImage(dbName) {
      return this.images[dbName];
    },
    getClasses(item) {
      console.log("class item", item?.title, "===>" ,this.initialPlan, "===>" ,item);
      let borderColor = this.isCurrentPlan(item)
        ? "bg-green"
        : "border-[#E7E7E7]";
      return `border ${borderColor} ${this.getHeight(
        item.db_name
      )}  rounded-3xl h-[40vh]  lg:w-[17vw] md:w-[24vw] sm:w-[24vw] !py-4 !px-3`;
    },
  },
};
</script>

<style scoped>
.module-border-wrap {
  /* Gradient border */
  border: 2px solid transparent;
  background: linear-gradient(to right top, #ff5f55, #335add);

  padding: 0px 0px 0px 0px;
  /* Adjust padding as needed */
  border-radius: 25px;
}
</style>
