<template>
  <img src="@/assets/svgs/vector-icon.svg" v-if="!store.state.theme" />
  <img v-else src="@/assets/svgs/vector-icon-dark.svg" />
</template>

<script>
import store from "@/store";

export default {
  name: "ComingSoon",
  props: {
    stroke: {
      type: String,
      default: "gray",
    },
  },
  setup() {
    return {
      store,
    };
  },
};
</script>
