<template>
  <nav
    class="w-full flex lg:px-6 sm:px-6 px-4 justify-center h-full border-b border-[#888888]/30"
  >
    <div
      class="px-0 sm:px-3 w-full sm::w-[85%] flex items-center h-full justify-center"
    >
      <div class="w-full flex items-center justify-between">
        <h1 class="sm:text-[21px] text-[18px] font-bold dark:text-white">
          {{ $route.name }}
        </h1>

        <div class="flex items-center">
          <!-- <div @click="modalOpenHandler" v-if="$route.name === 'Dashboard'" class="cursor-pointer">
            <img v-if="!store.getters.getTheme" src="../../assets/images/thinkorswim.png" class="h-6"/>
            <img v-else src="../../assets/images/dashboard/CC-LogosDark/thinkSwim.svg" class="h-6"/>
          </div> -->
          <div class="sm:hidden block" v-if="tradeData?.length">
            <CustomButton
              v-if="$route.name === 'Active Trades'"
              @onClick="activeModal"
              title="Add a New Trade"
              buttonType="primary"
              customStyle="px-5 !py-2 rounded-full dark:hover:bg-blue-800"
            />
          </div>
          <div class="cursor-pointer  mr-2 underline">
              <a :href="oldLink" v-on:click="handleCheckOut()" class="text-[#f90e0e] font-[600] hover:text-[#e20909]">Switch back to legacy app</a>
            </div>
          <button
            @click="openSidebar"
            data-drawer-target="logo-sidebar"
            data-drawer-toggle="logo-sidebar"
            aria-controls="logo-sidebar"
            type="button"
            class="inline-flex items-center p-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 
            focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              :stroke="`${store.state.theme ? 'white' : 'black'}`"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" 
              />
            </svg>
          </button>
          <div class="flex items-center">
        
            <div v-if="tradeData?.length">
              <!-- <div class=" hidden" :class="`${(addLimit === 4 || addLimit === 5) && tradeData.length < 2 ? 'sm:block' : (addLimit === 6 || addLimit === 7) && tradeData.length > 4 ? 'sm:block' : (addLimit === 8 || addLimit === 9)  ? 'sm:block' : 'sm:hidden' }`" id="tradeCard2"> -->
              <div class=" " :class="`${(addLimit === 8 || addLimit === 9)  ? 'sm:block hidden' : (addLimit === 6 || addLimit === 7) && tradeData.length < 4 ? 'sm:block hidden' : (addLimit === 4 || addLimit === 5) && tradeData.length < 2 ? 'sm:block hidden' : 'hidden' }`" id="tradeCard2">
                <CustomButton
                  v-if="$route.name === 'Active Trades'"
                  @onClick="activeModal"
                  title="Add a New Trade"
                  buttonType="primary"
                  customStyle="px-5 !py-2 rounded-full dark:hover:bg-blue-800"
                />
              </div>
            </div>
            <div
              v-for="(items, index) in btnData"
              :key="index"
              class="lg:flex hidden items-center space-x-4 ms-3"
              :title="items.desc"
            >
              <div
                class="bg-transparent dark:bg-compBgDark/20 cursor-pointer hover:bg-white/70 dark:hover:bg-lightTextColor flex justify-center p-2 items-center rounded-full"
                @click="btnClickHandler(items)"
              >
                <img :src="items.icon" :width="items.width" class="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="sm:hidden h-full w-full absolute pt-80 bg-red-500 " v-if="isSidebar"> -->

        <!-- <Sidebar v-if="isSidebar" @closeSidebar="toggleMenuHandler" /> -->
      </div>
    </div>
  </nav>
</template>

<script>
import sunSvg from "../../assets/svgs/sun.svg";
import moonSvg from "../../assets/svgs/moon.svg";
import logout from "../../assets/svgs/logout.svg";
import store,{ getUserDetailsFromStore } from "@/store";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import router from "@/router";
import userApi from "@/services/userApi";

export default {
  name: "NavBar",
  components: {
    CustomButton,
    // Sidebar,
  },

  data() {
    return {
      btnData: [
        {
          name: "Theme",
          desc: "Switch theme",
          icon: moonSvg,
          width: 23,
        },
        {
          name: "logout",
          desc: "Sign Out",

          icon: logout,
          width: 25,
        },
      ],
      routeName: "",
      addLimit:null
    };
  },
  setup() {
    return {
      store,
    };
  },
  mounted() {
    // Accessing route parameters in the component's lifecycle hook
    // const userId = this.$route.name;
    this.routeName = this.$route.name;

    console.log("User ID:", getUserDetailsFromStore().subscription);
    this.addLimit = getUserDetailsFromStore().subscription
    this.$watch(() => getUserDetailsFromStore().subscription, (newValue) => {
    // React to changes here
    console.log("User subscription changed. New value:", newValue);
    this.addLimit = newValue;
  });
  },
  computed: {
    tradeData() {
      console.log('tarding data', store.getters.getTradeData)
      return store.getters.getTradeData;
    },
    oldLink(){
      console.log('store.getters', store.getters)
      // let userId = 406
      // handleCheckOut(userId)
      return `https://legacy.condorcash.com/jwt/authorize.php?token=${localStorage.getItem("token")}`
    }
  },
  methods: {
    btnClickHandler(items) {
      console.log("logout");
      if (items.name == "logout") {
        localStorage.clear();
        store.commit("SET_TOGGLE_THEME", false);
        store.commit("RESET_STATE");
        router.push("/login");
        // location.reload();
      } else if (items.name == "Theme") {
        store.commit("SET_TOGGLE_THEME", !store.getters.getTheme);
      }
    },

    async handleCheckOut(){
      try {
        const checkOutNumber = localStorage.getItem("userId");
        const checkOutresponse = await userApi.checkOut(checkOutNumber);
        console.log("Check-out successful:", checkOutresponse?.data);
      } catch (error) {
        console.log("Check-out failed:", error);
      }
      
      // window.location.href = `https://legacy.condorcash.com/jwt/authorize.php?token=${localStorage.getItem("token")}`;
    },

    activeModal() {
      store.commit("SET_TRADE_MODAL", true);
    },
    modalOpenHandler() {
      this.$emit("toggleModal",true);
    },
    openSidebar() {
      store.commit("SET_SIDEBAR_TOGGLE", !store.getters.getSidebarToggle);
    },
  },
  watch: {
    "store.state.theme"() {
      this.btnData[0].icon = store.state.theme ? sunSvg : moonSvg;
    },
  },
};
</script>
