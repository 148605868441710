<template>
  <!-- <div class="w-full sm:h-[105vh] sm:mb-48"> -->
  <div class="w-full sm:mb-48">
    <div
      class="h-full flex w-full justify-center items-center py-4 md:pt-0"
      v-if="!isProfileLoading"
    >
      <div class="w-full h-full">
        <div v-if="store.getters.getSettingsTab === 'Address & Billing'">
          <Account />
        </div>

        <div
          class="h-full w-full"
          v-if="store.getters.getSettingsTab === 'Profile'"
        >
          <Profile />
        </div>
        <div
          class="h-full w-full"
          v-if="store.getters.getSettingsTab === 'Subscription Plans'"
        >
          <SubscriptionBilling />
        </div>
        <div v-if="store.getters.getSettingsTab === 'Notifications'">
          <NotificationsPage />
        </div>
      </div>
    </div>
    <div v-else class="w-full h-full flex justify-center items-center">
      <div>
        <LoaderComponent />
      </div>
    </div>
  </div>
</template>

<script>
import Account from "../../components/SettingsComp/Account.vue";
import Profile from "../../components/SettingsComp/Profile2.vue";
import SubscriptionBilling from "../../components/SettingsComp/subscriptionBilling.vue";
import NotificationsPage from "../../components/SettingsComp/NotificationsPage.vue";
import store, { getUserDetailsFromStore } from "../../store";
import LoaderComponent from "../../components/SharedComponents/LoaderComponent.vue";

export default {
  setup() {
    return {
      store,
    };
  },
  name: "SettingsComp",
  components: {
    Account,
    Profile,
    SubscriptionBilling,
    NotificationsPage,
    LoaderComponent,
  },
  data() {
    return {
      updateComponent: 0,
    };
  },
  methods: {
    handleClick() {
      console.log("clicked");
    },
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileLoading() {
      return store.getters.isProfileLoading;
    },
  },
  mounted() {},
  watch: {
    userDetails(n, o) {
      console.log(n, o);
      this.updateComponent = this.updateComponent + 1;
    },
  },
};
</script>
