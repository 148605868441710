<template>
  <div class="w-full h-full flex space-x-6"> 
    <div v-if="!tradeLoading" class="w-full h-full">
      <div class="sm:pb-16 pb-28" v-if="tradeData?.length">
        <div
          class="w-full sm:grid lg:grid-cols-3 sm:mt-1 mt-5 md:grid-cols-2 sm:grid-cols-2 sm:space-y-0 space-y-5 sm:gap-5 sm:mb-5 mb-20"
        >
          <div class="w-full" v-for="(items, index) in tradeData" :key="index">
            <TradeCard class="" :data="items" :Svg="true" id="tradeCard1" />
          </div>
        </div>
      </div>

      <div v-else class="w-full h-full">
        <div class="w-full flex justify-center h-full items-center">
          <div class="space-y-3 cursor-pointer hover:bg-[#edebeb] dark:hover:bg-[#292832] p-6 rounded-md" id="activeTradeElement1" @click="activeModal">
            <BoardSVGVue  v-if="store.getters.getTheme" />
            <!-- <img
              src="../../assets/svgs/slateDarkmode.svg"
              v-if="store.getters.getTheme"
            /> -->
            
             <BoardSVGLight  v-else/>
            

            <div class="text-center">
              <p class="text-[#5D5D5D] dark:text-darkTextColor text-[12px]">
                No open trades at the moment
              </p>
              <p
                class="text-btnPrimary dark:text-[#a4cafe] font-bold"
              >
                Add a new trade
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <Modal/> -->
    <div v-else class="w-full h-full flex justify-center items-center">
      <div>
        <LoaderComponent />
      </div>
    </div>
  </div>
</template>

<script>
import TradeCard from "@/components/TradeCard.vue";
import store, { getUserDetailsFromStore } from "@/store";
import LoaderComponent from "@/components/SharedComponents/LoaderComponent.vue";
import tradeApi from "@/services/tradeApi";
import tourService from "@/services/tourService";
import moment from "moment";
import BoardSVGVue from './BoardSVG.vue';
import BoardSVGLight from "./BoardSVGLight.vue"
// import moment from "moment";

// import Modal from '@/components/Modal'

export default {
  name: "ActiveTrades",
  components: {
    LoaderComponent,
    TradeCard,
    BoardSVGVue,
    BoardSVGLight
    // Modal
    // Navbar,
    // SideBar
  },

  setup() {
    return {
      store,
    };
  },
  mounted() {
    this.loadingTradingData();
    this.getObject();

    if (
      this.isAuthenticated &&
      !this?.tourObj?.activeTradeInitial &&
      this.isProfileComplete
    ) {
      this.startActiveTradeTour();
    }
  },
  data() {
    return {
      isAuthenticated: localStorage.getItem("token"),
      tradeLoading: true,
      // tradeData: [],
      showModal: false,
      tourObj: {activeTradeInitial:false},
      filteredData:{}
    };
  },

  methods: {
    onTourEndInitial() {
      this.tourObj.activeTradeInitial = true;

      localStorage.setItem("appTour", JSON.stringify(this.tourObj));
    },
    onTourEndFinal() {
      this.tourObj.activeTradeInitial = true;

      localStorage.setItem("appTour", JSON.stringify(this.tourObj));
    },
    getObject() {
      const storedObject = localStorage.getItem("appTour");

      if (storedObject) {
        this.tourObj = JSON.parse(storedObject);
        console.log(" active", this.tourObj);
      } else {
        return;
      }
    },

    startTradeCardTour() {
      setTimeout(() => {
        const steps = [
          {
            element: "#tradeCard1",
            intro:
              "This box shows your trade information that includes call, put, expiry and most importantly the trade risk factor at the bottom of the box. Downward caret shows whether the trade is in excellent shape, normal, or you need to take action with green, orange, and red colors respectively. The history icon at the top-right corner will take you to your trade history page and you can remove this trade by clicking trash icon at the bottom-right corner.",
          },
          {
            element: "#tradeCard2",
            intro:
              "You can add more trade(s) based on the subscription by clicking on this button. ",
          },
        ];

        tourService.startTour(steps, this.onTourEndFinal);
      }, 500);
    },
    activeModal() {
      store.commit("SET_TRADE_MODAL", true);
    },

    loadingTradingData: function () { 
      tradeApi
        .tradeDetails()
        .then((d) => {
          console.log("D.dta 2", d.data);
          this.filteredData = d?.data?.trades.filter((obj)=>{
            console.log('D.dta 4', obj)
            const specificDate = moment(obj?.exp_date).diff(moment().format("YYYY-MM-DD"),'days')
            console.log(specificDate,"specificdat")
            return specificDate !== 0
          })
          store.commit("SET_TRADE_DATA_LIST", d.data.trades);
          this.tradeLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.tradeLoading = false;
        });
    },
  },
  
    // switch off webtour

  // async updated() {
  //   // Check if the elements you want to highlight are present in the DOM
  //   const elementsExist = document.getElementById("activeTradeElement1");

  //   if (!this?.tourObj.activeTradeInitial && this.isProfileComplete) {
  //     if (elementsExist) {
  //       const steps = [
  //         {
  //           element: document.querySelector("#activeTradeElement1"),
  //           intro:
  //             "Click on the 'Add a New Trade' link to start adding your trades here and benefit from our proprietary risk management tool.",
  //         },
  //       ];

  //       setTimeout(() => {
  //         tourService.startTour(steps, this.onTourEndInitial);
  //       }, 500);
  //     } 
  //   }
  // },
  computed: {
    isProfileLoading() {
      return store.state.isProfileLoading;
    },
    tradeData() {
      console.log('store trade history', store.getters.getTradeData)
      return store.getters.getTradeData;
    },
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() { 
      let user = this.userDetails;
      console.log("userDetails11", this.userDetails.profile_status);
      return user?.is_profile_complete ?? false;
    },
  },

  watch: {
    // isProfileLoading: function (n, o) {
    //   console.log("isProfileLoading", n, o);
    //   if (n) {
    //     this.loadingTradingData();
    //     store.commit("SET_PROFILE_LOADING", false);
    //   }
    // },
    tradeData(newVal) {
      if (newVal && newVal.length !== this.filteredData.length) {
        // Update filteredData
        this.filteredData = newVal.filter(obj => {
          const specificDate = moment(obj?.exp_date).diff(moment(), 'days');
          return specificDate !== 0;
        });
      }
    },
    tradINGData: {
      
      handler(newVal) {
        if (
          this.isAuthenticated && 
          this.isProfileComplete &&
          !this.tourObj.activeTradeFinal
          
        ) {
          if (newVal && newVal.length > 0) {
            // switch off webtour
            // this.startTradeCardTour();
          }
        } 
      },
    },
  },
};
</script>
