<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3332 14.3643V13.0309C13.3332 12.3237 13.0522 11.6454 12.5521 11.1453C12.052 10.6452 11.3737 10.3643 10.6665 10.3643H5.33317C4.62593 10.3643 3.94765 10.6452 3.44755 11.1453C2.94746 11.6454 2.6665 12.3237 2.6665 13.0309V14.3643"
      :stroke="isActive ? activeColor : disableColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3332 14.3643V13.0309C13.3332 12.3237 13.0522 11.6454 12.5521 11.1453C12.052 10.6452 11.3737 10.3643 10.6665 10.3643H5.33317C4.62593 10.3643 3.94765 10.6452 3.44755 11.1453C2.94746 11.6454 2.6665 12.3237 2.6665 13.0309V14.3643"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3332 14.3643V13.0309C13.3332 12.3237 13.0522 11.6454 12.5521 11.1453C12.052 10.6452 11.3737 10.3643 10.6665 10.3643H5.33317C4.62593 10.3643 3.94765 10.6452 3.44755 11.1453C2.94746 11.6454 2.6665 12.3237 2.6665 13.0309V14.3643"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00016 7.69759C9.47292 7.69759 10.6668 6.50368 10.6668 5.03092C10.6668 3.55817 9.47292 2.36426 8.00016 2.36426C6.5274 2.36426 5.3335 3.55817 5.3335 5.03092C5.3335 6.50368 6.5274 7.69759 8.00016 7.69759Z"
      :stroke="isActive ? activeColor : disableColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00016 7.69759C9.47292 7.69759 10.6668 6.50368 10.6668 5.03092C10.6668 3.55817 9.47292 2.36426 8.00016 2.36426C6.5274 2.36426 5.3335 3.55817 5.3335 5.03092C5.3335 6.50368 6.5274 7.69759 8.00016 7.69759Z"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00016 7.69759C9.47292 7.69759 10.6668 6.50368 10.6668 5.03092C10.6668 3.55817 9.47292 2.36426 8.00016 2.36426C6.5274 2.36426 5.3335 3.55817 5.3335 5.03092C5.3335 6.50368 6.5274 7.69759 8.00016 7.69759Z"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import { watch, ref } from "vue";
import store from "@/store";
export default {
  name: "ProfileSvg",

  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
