<template>
  <div class="flex bg-[#D1D1D1] dark:bg-compBgDark space-x-1">
    <div
      class="w-[33%] h-0.5 text-gray-500 items-center"
      :class="{
        'bg-red-500':
          passwordStrength === 'Weak' ||
          passwordStrength === 'Medium' ||
          passwordStrength === 'Strong',
        // 'bg-yellow-300': passwordStrength === 'Medium',
        // 'bg-yellow-300': passwordStrength === 'Strong',
      }"
    />
    <div
      class="w-[33%] h-0.5 text-gray-500 items-center"
      :class="{
        'bg-[#ef950e]/90':
          passwordStrength === 'Medium' || passwordStrength === 'Strong',
      }"
    />
    <div
      class="w-[33%] h-0.5 text-gray-500 items-center"
      :class="{
        'bg-green': passwordStrength === 'Strong',
      }"
    />
  </div>
</template>
<script>
export default {
  name: "PasswordStrengthComponent",
  props: {
    password: {},
  },
  data() {
    return {
      passwordStrength: "",
    };
  },
  methods: {
    checkPasswordStrength() {
      let password = this.password.trim();
      const strength = this.calculatePasswordStrength(password);
      // const strength = this.calculatePasswordStrength(this.password);
      this.passwordStrength = strength;
    },
    calculatePasswordStrength(password) {
      const uppercaseRegex = /[A-Z]/;
      // const digitRegex = /\d/;
      const specialCharRegex = /\W/;

      if (
        password.length > 0 &&
        !uppercaseRegex.test(password) &&
        !specialCharRegex.test(password)
      ) {
        this.$emit("password-strength", "Weak");
        return "Weak";
      } else if (
        password.length > 0 &&
        (uppercaseRegex.test(password) || specialCharRegex.test(password)) &&
        (!uppercaseRegex.test(password) || !specialCharRegex.test(password))
      ) {
        this.$emit("password-strength", "Medium");

        return "Medium";
      } else if (
        password.length > 0 &&
        uppercaseRegex.test(password) &&
        specialCharRegex.test(password)
      ) {
        this.$emit("password-strength", "Strong");

        return "Strong";
      } else {
        this.passwordStrength = "";
        this.$emit("password-strength", "Invalid");

        return "Invalid"; // Handle any other cases as needed
      }
    },
  },
  watch: {
    password() {
      console.log("this.password==>",this.password);
      
      this.checkPasswordStrength();
    },
  },
};
</script>
