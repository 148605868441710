<template>
  <CardBox
    customClass=" rounded-3xl w-full sm:px-8 sm:py-8 px-4 py-4 flex justify-between items-center "
  >
    <div class="w-full space-y-6">
      <div class="text-[16px] flex items-center space-x-4">
        <div class="flex items-center relative">
          <div
            class="radial-progress"
            :style="{ '--value': `${getUserDetails?.percent}%` }"
            role="progressbar"
          ></div>
          <div class="centered-text">
            {{ profileCompleteDisplay(getUserDetails?.percent) }} / 4
          </div>
        </div>
        <div>
          <p class="font-bold dark:text-white text-[20px]">
            Welcome {{ getUserDetails?.firstname }}!
          </p>
          <p class="text-[14px] dark:text-white">
            Take your first steps towards building successful investing habits
          </p>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-5 gap-y-3 sm:px-0 px-4">
        <div
          v-for="(items, index) in profileSteps2"
          :key="index"
          :class="`flex sm:text-[16px] text-[12px] sm:p-2 sm:px-4 px-3 space-x-1 py-1 rounded-full sm:h-full h-[4vh] items-center justify-between
        ${items.classes}
        `"
        >
          <div
            @click="`${items.classes === currentStepColor ? navSetting() : ''}`"
            class="flex justify-between w-full"
          >
            <p class="">{{ items.name }}</p>
            <img
              v-if="items.checked"
              src="../../assets/svgs/checkboxGreen.svg"
              class="lg:w-6 sm:w-6 w-5"
            />
            <!-- <img v-else src="../../assets/svgs/checkboxGray.svg" class="lg:w-5 sm:w-5 w-4" /> -->
            <GrayCheckbox v-else />
          </div>
        </div>
      </div>
    </div>
  </CardBox>
</template>

<script>
import CardBox from "../../components/SharedComponents/CardBox.vue";
import store, { clickOnProfileSettingButton } from "@/store";
import GrayCheckbox from "../../assets/svgs/GrayCheckBox.vue";

export default {
  name: "signupProgress",
  components: {
    CardBox,
    GrayCheckbox,
  },
  setup() {
    return {
      store,
    };
  },
  mounted() {
    console.log("signupProgress", this?.profileSteps2);
  },
  data() {
    return {
      inCompleteColor:
        "bg-white dark:bg-transparent dark:text-white/30 dark border-2 dark:border-[#888888]/30 text-black border-[#E7E7E7]",
      currentStepColor:
        "bg-[#FFF9E3] border-2 dark:bg-[#FFC0411A] dark:text-[#FFC041] dark:border-[#FFC041] text-[#C27500] border-[#C27500] cursor-pointer",
      completeStepClasses:
        "bg-[#E7E7E7] dark:bg-transparent dark:border-[#3EAF3F] dark:border-2 dark:text-[#3EAF3F] text-[#888888]",
      profileSteps: [
        {
          step: "1",
          name: "Sign up for an account",
        },
        {
          step: "2",
          name: "Complete your profile",
        },
        {
          step: "3",
          name: "Address and Billing information",
        },

        {
          step: "4",
          name: "Select a subscription plan",
        },
      ],
    };
  },
  computed: {
    getUserDetails() {
      return store?.getters?.getUserDetails;
    },
    profileSteps2() {
      let userDetails = this.getUserDetails;
      let next_step = "";
      let profile_status = userDetails["profile_status"];
      if (userDetails["next_step"]) {
        next_step = userDetails["next_step"];
      }
      console.log("userDetails", next_step);
      let steps = [];
      steps.push({
        step: "1",
        name: "Sign up for an account",
        classes: this.completeStepClasses,
        checked: true,
      });
      let classes = "";

      if ("profile" != next_step) {
        classes = profile_status?.["profile"]
          ? this.completeStepClasses
          : this.inCompleteColor;
      } else {
        classes = this.currentStepColor;
      }
      steps.push({
        step: "2",
        name: "Complete your profile",
        classes: classes,
        checked: profile_status?.["profile"],
      });
      if ("address" != next_step && "billing" != next_step) {
        classes =
          profile_status?.["address"] && profile_status?.["billing"]
            ? this.completeStepClasses
            : this.inCompleteColor;
      } else {
        classes = this.currentStepColor;
      }
      steps.push({
        step: "3",
        name: "Address and Billing information",
        classes: classes,
        checked: profile_status?.["address"] && profile_status?.["billing"],
      });
      if ("plan" != next_step) {
        classes = profile_status?.["plan"]
          ? this.completeStepClasses
          : this.inCompleteColor;
      } else {
        classes = this.currentStepColor;
      }
      steps.push({
        step: "4",
        name: "Select a subscription plan",
        classes: classes,
        checked: profile_status?.["plan"],
      });
      return steps;
    },
  },
  methods: {
    navSetting() {
      clickOnProfileSettingButton();
    },
    profileCompleteDisplay(percent) {
      if (percent == 25) {
        return 1;
      } else if (percent == 50) {
        return 2;
      } else if (percent == 62.5) {
        return 2;
      } else if (percent == 75) {
        return 3;
      } else if (percent == 100) {
        return 4;
      }
    },
  },
};
</script>

<style scoped>
.radial-progress {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: lightgray;
  overflow: hidden;
}
.dark .radial-progress {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(45, 45, 45);
  overflow: hidden;
}

.radial-progress::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  background: conic-gradient(
    #0b3d90 0 var(--value),
    #c2caf2 var(--value) 10% /* Increase the percentage to widen the path */
  );
}

.dark .radial-progress::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  background: conic-gradient(
    #0853ce 0 var(--value),
    #c2caf2 var(--value) 10% /* Increase the percentage to widen the path */
  );
}

.radial-progress::after {
  content: "";
  position: absolute;
  width: 70%;
  height: 70%;
  border-radius: 50%;

  background-color: white;
  top: 15%;
  left: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  font-weight: bold;
}
.dark .radial-progress::after {
  content: "";
  position: absolute;
  width: 70%;
  height: 70%;
  border-radius: 50%;

  background-color: #38383e;
  top: 15%;
  left: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  font-weight: bold;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  white-space: nowrap;
}

.dark .centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  white-space: nowrap;
  color: white;
}
</style>
