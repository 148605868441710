<template>
  <div v-if="isProfileComplete" class="h-full w-full sm-pb-0 pb-[40px]">
    <CardBox customClass="border border-[#E7E7E7] rounded-3xl w-full !p-5">
      <div class="w-full flex justify-between items-center">
        <div class="flex flex-col space-y-1">
          <h1 class="text-black dark:text-white font-bold text-[17px]">
            Account Information
          </h1>
          <span class="text-lightTextColor bg- dark:text-darkTextColor text-[11px]">
            Update your account settings
          </span>
        </div>
        <div class="md:flex hidden items-center space-x-3">
          <CustomButton
            title="Discard Changes"
            buttonType="secondary"
            customStyle="dark:bg-lightTextColor/40 dark:hover:bg-lightTextColor/20"
            @onClick="discardChanges"
            :disabledVal="isDisable"
            @keypressEnter="discardChanges"
          />
          <CustomButton
            title="Save"
            buttonType="primary"
            customStyle="px-5"
            @onClick="resetPasswordHandler"
            @keypressEnter="keypressEnterReset"
            :isLoading="isLoading"
            :loaderText="`Saving`"
            :disabledVal="isDisable"
          />
        </div>
      </div>

      <div class="space-y-6 pt-6">
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Email</span
          >
          <TextInput
            placeholder="Eg: yourmail@domain.com"
            :value="userEmail"
            :readOnly="true"
          />
          <span class="text-lightTextColor dark:text-darkTextColor text-[12px]"
            >To change your email address
            <span
              class="text-btnPrimary dark:text-[#5174d6] text-[12px] font-semibold hover:underline cursor-pointer"
              >contact us</span
            ></span
          >
        </div>

        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Create password</span
          >
          <span
            class="text-lightTextColor dark:text-darkTextColor text-[12px] md:w-full w-fit"
            >To confirm with our Strong Password policy, you are required to use
            a sufficiently strong password. Password must be more than 7
            characters.
          </span>
          <TextInput
            placeholder="Password"
            :handleChange="onChangeHandler"
            :value="password"
            :type="showEyePassword ? 'text' : 'password'"
            v-model="password"
            name="password"
            @keypressEnter="resetPasswordHandler"
            :className="`  ${
               errorMessage ? 'border-2 border-danger rounded-md' : ''
            }`"
            :secondImageSrc="
              require(`../../assets/svgs/${
                showEyePassword ? 'eyeOpen' : 'eyeClose'
              }.svg`)
            "
            :imageClass="`w-5 h-5 cursor-pointer`"
            @onSecondImageClicked="
              () => (this.showEyePassword = !this.showEyePassword)
            "
          />
          <div>
            <PasswordStrengthComponent
              :password="password"
              @password-strength="passwordStrengthHandler"
            />
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <span
            class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >Confirm Password</span
          >
          <TextInput
            placeholder="8-12 characters"
            v-model="confirmPassword"
            :value="confirmPassword"
            @keypressEnter="resetPasswordHandler"
            name="confirmPassword"
            :type="showEyeConfirmPassword ? 'text' : 'password'"
            :handleChange="onChangeHandler"
            :className="`  ${
              errorMessage ? 'border-2 border-danger rounded-md' : ''
            }`"
            :secondImageSrc="
              require(`../../assets/svgs/${
                showEyeConfirmPassword ? 'eyeOpen' : 'eyeClose'
              }.svg`)
            "
            :imageClass="`w-5 h-5 cursor-pointer`"
            @onSecondImageClicked="
              () => (this.showEyeConfirmPassword = !this.showEyeConfirmPassword)
            "
          />
          <div>
            <span v-if="errorMessage" class="text-danger text-[13px]">{{
              errorMessage
            }}</span>
            <span v-if="successResetPassword" class="text-green text-[13px]">
              {{ successResetPassword }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex md:hidden items-center space-x-3 pt-4">
        <CustomButton
          title="Discard Changes"
          buttonType="secondary"
          @onClick="discardChanges"
          :disabledVal="isDisable"
        />
        <CustomButton
          title="Save"
          buttonType="primary"
          customStyle="px-5"
          @onClick="resetPasswordHandler"
          :isLoading="isLoading"
          :loaderText="`Saving`"
          :disabledVal="isDisable"
        />
      </div>
    </CardBox>
  </div>
</template>
<script>
import CardBox from "../../components/SharedComponents/CardBox.vue";
import CustomButton from "../../components/SharedComponents/CustomButton.vue";
import TextInput from "../../components/SharedComponents/TextInput.vue";
import PasswordStrengthComponent from "@/pages/userStory/newSignup/PasswordStrengthComponent.vue";
import userApi from "@/services/userApi";
export default {
  name: "ProfileEmailInformationComponent",
  components: { PasswordStrengthComponent, CardBox, CustomButton, TextInput },
  props: {
    isProfileComplete: {},
    userEmail: {},
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorMessage: "",
      isEmptyPass: false,
      isLoading: false,
      successResetPassword: "",
      showEyePassword: false,
      showEyeConfirmPassword: false,
      passwordStrength: "",
      isEditable: false,
      isDisable: true,
    };
  },
  methods: {
    keypressEnterReset(){
      console.log("checking reset")
      !this.isDisable && this.resetPasswordHandler()
    },
    onChangeHandler(e) {
      this[e.target.name] = e.target.value;
      this.errorMessage = "";
      this.isEmptyPass = false;
      this.isEditable = false;
      this.isDisable = false;
      
    },
    discardChanges() {
      this.isEditable = false;
      this.isEmptyPass = false;
      this.isDisable = true;
      this.password = "";
      this.confirmPassword = "";
      this.errorMessage = "";
    },
    passwordStrengthHandler(strength) {
      this.passwordStrength = strength;
    },
    async resetPasswordHandler() {
     
      this.successResetPassword = "";
      if (this.password.trim() !== "" && this.confirmPassword.trim() !== "") {
        if (this.password === this.confirmPassword) {
          if (
            this.passwordStrength === "Strong" ||
            this.passwordStrength === "Medium"
          ) {
            this.isLoading = true;
            try {
              let payload = {
                password: this.password,
                confirmPassword: this.confirmPassword,
              };
              const response = await userApi.changePassword(payload);
              if (response?.data?.status) {
                this.isLoading = false;
                this.isEditable = false;
                this.isDisable = true;
                this.isEmptyPass = false,
                this.successResetPassword = "Password updated successfully";
                this.discardChanges();
                console.log("response", response.data);
              } else {
                console.log("response err", response.data);
                this.isLoading = false;
                this.errorMessage = response?.data?.message;
              }
            } catch (err) {
              console.log(err);
              this.isLoading = false;
            }
          } else {
            this.errorMessage =
              "Password should be atleast 8 characters long, 1 uppercase letter and/or 1 special character";
          }
        } else {
          this.errorMessage = "Passwords does not match";
        }
      } else {
        if(this.password.trim() === "" || this.confirmPassword.trim() === "") {
        this.errorMessage =
              "Password should be atleast 8 characters long, 1 uppercase letter and/or 1 special character";
      }else{
        this.errorMessage = "Please fill all the fields";

      }
      }
    },
  },
};
</script>
