<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_404_19016)">
      <path
        d="M4 14.3643L14 4.36426L12 2.36426L2 12.3643L4 14.3643Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 4.36426L12 6.36426"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99984 2.36426C5.99984 2.71788 6.14031 3.05702 6.39036 3.30707C6.64041 3.55712 6.97955 3.69759 7.33317 3.69759C6.97955 3.69759 6.64041 3.83807 6.39036 4.08812C6.14031 4.33816 5.99984 4.6773 5.99984 5.03092C5.99984 4.6773 5.85936 4.33816 5.60931 4.08812C5.35926 3.83807 5.02013 3.69759 4.6665 3.69759C5.02013 3.69759 5.35926 3.55712 5.60931 3.30707C5.85936 3.05702 5.99984 2.71788 5.99984 2.36426Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6668 9.03125C12.6668 9.38487 12.8073 9.72401 13.0574 9.97406C13.3074 10.2241 13.6465 10.3646 14.0002 10.3646C13.6465 10.3646 13.3074 10.5051 13.0574 10.7551C12.8073 11.0052 12.6668 11.3443 12.6668 11.6979C12.6668 11.3443 12.5264 11.0052 12.2763 10.7551C12.0263 10.5051 11.6871 10.3646 11.3335 10.3646C11.6871 10.3646 12.0263 10.2241 12.2763 9.97406C12.5264 9.72401 12.6668 9.38487 12.6668 9.03125Z"
        :stroke="isActive ? activeColor : disableColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_404_19016">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.364258)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import store from "@/store";
import { watch, ref } from "vue";

export default {
  name: "SubscriptionPlansSvg",
  props: {
    isActive: {
      type: Boolean,
      defaults: false,
    },
  },
  setup() {
    const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    const disableColor = "#9a9a9a";

    watch(
      () => store.state.theme,
      (newTheme) => {
        activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
      }
    );

    return {
      activeColor,
      disableColor,
    };
  },
};
</script>
