import { API_LIST } from "./apiConfig";

const domain_prefix = API_LIST.userApi;

export const SIGN_IN = domain_prefix + "auth/signin";
export const SIGN_UP = domain_prefix + "auth/signup";

export const USER_SESSION_CHECK_IN = domain_prefix + "auth/check-in";
export const USER_SESSION_CHECK_OUT = domain_prefix + "auth/check-out";

export const USER_DETAIL = domain_prefix + "auth/user-detail";

export const TRADE_DETAIL = domain_prefix + "trade/get-all-trades";
export const GET_CHART_DATA = domain_prefix + "trade/get-chart-data";

export const UPDATE_BASIC_INFO = domain_prefix + "auth/update-basic-info";
export const UPDATE_ADDRESS_INFO = domain_prefix + "auth/update-address-info";
export const UPDATE_SUBS = domain_prefix + "auth/update-subscription-info";
export const UPDATE_BILLING_INFO = domain_prefix + "auth/update-billing-info";
export const RESET_PROFILE = domain_prefix + "auth/reset-profile";
export const VERIFY_EMAIL = domain_prefix + "auth/verify-email";
export const SEND_MOBILE_OTP = domain_prefix + "auth/send-otp-code";
export const VERIFY_MOBILE_OTP = domain_prefix + "auth/verify-otp-code";

export const CALCULATE_RISK_FACTOR =
  domain_prefix + "trade/get-trade-risk-factor";

export const ADD_TRADE = domain_prefix + "trade/add-trade";
export const DELETE_TRADE = domain_prefix + "trade/delete-trade";
export const GET_IRON_CONDOR = domain_prefix + "trade/get-iron-condor";

export const CHART_CALC = domain_prefix + "calculation/get_ic_return";
export const get_option_data = domain_prefix + "calculation/option-data";

export const TRADE_HISTORY = domain_prefix + "trade/get-trade-history";
export const FORGET_PASSWORD = domain_prefix + "auth/forget-password";
export const RESET_PASSWORD = domain_prefix + "auth/reset-password-by-token";
export const FACEBOOK_LOGIN = domain_prefix + "fb/auth";
export const GOOGLE_LOGIN = domain_prefix + "google/auth";
export const LINKDIN_LOGIN = domain_prefix + "linkedin/auth";
export const CHANGE_PASSWORD = domain_prefix + "auth/reset-user-password";
export const SUBSCRIPTION_PLANS = domain_prefix + "subscription/plan-list";
export const UPDATE_PLAN = domain_prefix + "subscription/subscribe";
export const INVOICE_LIST = domain_prefix + "subscription/invoice-list";
export const CANCEL_SUBSCRIPTION = domain_prefix + "subscription/remove-pending_subscription";
export const NOTIFICATIONS_UPDATE =
  domain_prefix + "auth/update-user-notification";

export const GET_SITE_SETTINGS = domain_prefix + "contact/get_site_settings";

export const CONTACT_US = domain_prefix + "contact/add_contact";
