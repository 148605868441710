<!-- BaseModal.vue -->

<template>
  <div class="base-modal">
    <div
      class="fixed inset-0 backdrop-blur-sm bg-[#00000050] transition-opacity base-modal"
    ></div>

    <div class="fixed base-modal inset-0 sm:overflow-y-auto">
      <div
        class="flex items-center justify-center min-h-full p-4 text-center sm:p-0"
      >
        <div
          @click.stop
          :class="customClass"
          class="relative bg-white shadow-xl p-2 overflow-x-auto text-left overflow-hidden transform transition-all sm:my-8 rounded-3xl"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: "dark:bg-black/80",
    },
  },
};
</script>

<style scoped>
.base-modal {
  z-index: 1000;
}
/* Add your scoped styles here */
</style>
