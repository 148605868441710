import { createStore } from "vuex";
import { findSettingTabFromUserSettings } from "@/pages/userStory/login/afterSigninActivty";
import tradeApi from "@/services/tradeApi";
import moment from "moment/moment";

let itemVal = localStorage.getItem("DarkTheme");

const store = createStore({
  state: {
    ironCondorData:null,
    spxIronCondor:null,
    tradeHistoryById: "",
    emailBeforeSignup: "",
    settingsTab: "Profile",
    isSettingsTab: false,
    isProfileLoading: true,
    showCreateAccountSettings: false,
    spxData: {},
    tradeData: [],
    userDetail: {},
    navBarValue: "Dashboard",
    initialSuccess: false,
    tradeModal: false,
    brookerModal:false,
    deleteTradeModal: false,
    deleteTradeData: {},
    tradeHistory: false,
    tradeHistoryData: {},
    tradeHistoryLoader: false,
    theme: itemVal && itemVal == "true" ? true : false,
    isSidebarOpen: false,
    calculator: {
      rate_of_return: 0,
      expiry_days: 0,
      buy_call_strike_price: 0,
      sell_call_strike_price: 0,
      sell_put_strike_price: 0,
      buy_put_strike_price: 0,
      expiry_date: "24th Dec, 2023",
      ic: 0,
    },
    checkedNotifications: {
      browser_notifications: [],
      app_notifications: [],
      email_notifications: [],
    },
    tourNextStep: "",
    covalTrendModal: false,
    currentPricing: {},
    siteSettings: "",
    subscription_prices:[]
  },

  mutations: {
    UPDATE_CALCULATOR_DATA(state, spxData) {
      // let spxData = state.spxData;
      console.log("spxData", spxData);
      state.calculator.rate_of_return = spxData.rate_of_return;
      state.calculator.ic = spxData.ic;

      state.calculator.expiry_days = spxData.expiry_days;
      state.calculator.sell_call_strike_price = spxData.sell_call_strike_price;
      state.calculator.buy_call_strike_price = spxData.buy_call_strike_price;
      state.calculator.sell_put_strike_price = spxData.sell_put_strike_price;
      state.calculator.buy_put_strike_price = spxData.buy_put_strike_price;
      state.calculator.expiry_date = moment(spxData.expiry_date).format(
        "Do MMM, YYYY"
      );
    },
    SET_IRON_CONDOR(state, data){
      state.spxIronCondor = data
    },
    SET_IRONCONDOR_DATA(state, data){
      state.ironCondorData = data
    },
    ADD_PUTS(state, callback) {
      state.calculator.sell_put_strike_price =
        state.calculator.sell_put_strike_price + 5;
      state.calculator.buy_put_strike_price =
        state.calculator.buy_put_strike_price + 5;
      callback();
    },
    MINUS_PUTS(state, callback) {
      state.calculator.sell_put_strike_price =
        state.calculator.sell_put_strike_price - 5;
      state.calculator.buy_put_strike_price =
        state.calculator.buy_put_strike_price - 5;
      callback();
    },
    ADD_CALLS(state, callback) {
      state.calculator.sell_call_strike_price =
        state.calculator.sell_call_strike_price + 5;
      state.calculator.buy_call_strike_price =
        state.calculator.buy_call_strike_price + 5;
      callback();
    },
    MINUS_CALLS(state, callback) {
      state.calculator.sell_call_strike_price =
        state.calculator.sell_call_strike_price - 5;
      state.calculator.buy_call_strike_price =
        state.calculator.buy_call_strike_price - 5;
      callback();
    },
    SET_TRADE_HISTORY_LOADER(state, data) {
      state.tradeHistoryLoader = data;
    },
    SET_SITE_SETTING(state, data) {
      state.siteSettings = data;
    },
    SET_TRADE_HISTORY_BY_ID(state, data) {
      state.tradeHistoryById = data;
    },
    SET_TRADE_HISTORY_DATA(state, data) {
      state.tradeHistoryData = data;
    },
    SET_TOGGLE_THEME(state, data) {
      console.log("SET_TOGGLE_THEME", data);
      localStorage.setItem("DarkTheme", data);
      state.theme = data;
    },

    SET_CovalTrendModal(state, data) {
      state.covalTrendModal = data;
    },

    SET_TRADE_DATA_LIST(state, data) {
      state.tradeData = data;
    },
    SET_TRADE_HISTORY(state, data) {
      state.tradeHistory = data;
    },
    SET_TOUR_NEXT_STEP(state, data) {
      console.log("storeeeee", data);
      state.tourNextStep = data;
    },

    SET_SETTINGS_TAB(state, data) {
      state.settingsTab = data;
    },
    SET_TOGGLE_SETTINGS(state, data) {
      console.log("clicked", data);
      state.isSettingsTab = data;
    },
    SET_SPX_DATA(state, data) {
      console.log("clicked", data);
      state.spxData = data;
    },
    SET_USER_DETAIL(state, data) {
      console.log("userDetail", data);
      state.userDetail = data;
    },

    SET_SUBSCRIPTION_PRICES(state, data){
      console.log("subscription_prices",data)
      state.subscription_prices = data
    },

    SET_PROFILE_LOADING(state, data) {
      console.log("userDetail", data);
      state.isProfileLoading = data;
    },
    SET_NAVBAR_LABEL(state, data) {
      state.navBarValue = data;
    },
    SET_INITIAL_SUCCESS(state, data) {
      console.log("userDetail", data);
      state.initialSuccess = data;
    },
    SET_BROOKER_MODAL(state, data) {
      console.log("brooker modal", data);
      state.brookerModal = data;
    },
    SET_TRADE_MODAL(state, data) {
      console.log("userDetail", data);
      state.tradeModal = data;
    },
    SET_DELETE_TRADE(state, data) {
      console.log("userDetail", data);
      state.deleteTradeModal = data;
    },
    SET_DELETE_TRADE_DATA(state, data) {
      state.deleteTradeData = data;
    },
    SET_SIDEBAR_TOGGLE(state, data) {
      state.isSidebarOpen = data;
    },
    SET_NOTIFICATIONS(state, { type, notifications }) {
      state.checkedNotifications[type] = notifications;
    },
    SET_CURRENT_PRICING(state, data) {
      state.currentPricing = data;
    },
    RESET_STATE(state) {
      state.userDetail = {};
    },
    // Your mutations go here
  },
  actions: {},
  getters: {
    getTradeCalculator: (state) => state.calculator,
    getTradeHistoryById: (state) => state.tradeHistoryById,
    getTradeData: (state) => state.tradeData,
    getSettingsTab: (state) => state.settingsTab,
    isProfileLoading: (state) => state.isProfileLoading,
    showCreateAccountSettings: (state) => state.showCreateAccountSettings,
    getToggleSettings: (state) => state.isSettingsTab,
    getSpxData: (state) => state.spxData,
    getDefaultSpx: (state) => state.spxData?.five,
    getNaBarValue: (state) => state.navBarValue,
    getUserDetails: (state) => state.userDetail,
    getInitialSuccess: (state) => state.initialSuccess,
    getTradeModal: (state) => state.tradeModal,
    getBrookerModal: (state) => state.brookerModal,
    getDeleteTrade: (state) => state.deleteTradeModal,
    getDeleteTradeData: (state) => state.deleteTradeData,
    getTradeHistory: (state) => state.tradeHistory,
    getTradeHistoryData: (state) => state.tradeHistoryData,
    getTradeHistoryLoader: (state) => state.tradeHistoryLoader,
    getTheme: (state) => state.theme,
    getSidebarToggle: (state) => state.isSidebarOpen,
    getCurrentPricing: (state) => state.currentPricing,
    getCovalTrendModal: (state) => state.covalTrendModal,
    getSiteSettings: (state) => state.siteSettings,
    getTourNextStep: (state) => state.tourNextStep,
    getSubscriptionPrices:(state) => state.subscription_prices,
    getIronCondor: (state) => state.spxIronCondor,
    getIronCondorData : (state) => state.ironCondorData
  },
});

export function getUserDetailsFromStore() {
  return store?.getters?.getUserDetails;
}
export function tradingData() {
  return store?.getters?.getTradeData;
}

export function getPackages() {
  return store?.getters?.getSubscriptionPrices;
}

export function updateSideBarByUserDetails(userDetails) {
  let nextStep = userDetails?.next_step;
  let profileComplete = userDetails?.is_profile_complete;
  let settingTab = "Profile";
  console.log("profile settings ", userDetails, profileComplete, nextStep);
  if (!profileComplete) {
    settingTab = findSettingTabFromUserSettings(nextStep);
  }
  console.log("settingTab", settingTab);
  store.commit("SET_TOGGLE_SETTINGS", true);
  store.commit("SET_NAVBAR_LABEL", settingTab);
  store.commit("SET_SETTINGS_TAB", settingTab);
}

export function clickOnProfileSettingButton() {
  let elementById = document.getElementById("settings");
  if (elementById?.click) {
    elementById?.click();
  }
}
// export function isStandardProduct() {
//   if (store?.state?.currentPricing?.planName === "Standard Plan") {
//     return true;
//   } else {
//     return false;
//   }
// }

export async function getTradeHistory(payload) {
  store.commit("SET_TRADE_HISTORY_LOADER", true);
  try {
    store.commit("SET_TRADE_HISTORY_BY_ID", payload);
    const resp = await tradeApi.getTradeHistory(payload);
    console.log("respTradeHistory", resp);
    if (resp.data.status) {
      store.commit("SET_TRADE_HISTORY_DATA", resp.data.trades);
    }
  } catch (err) {
    console.log("err", err);
  }
  store.commit("SET_TRADE_HISTORY_LOADER", false);
}

export default store;
