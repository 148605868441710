<template>
  <div class="">
    <button
      v-if="!isLoading"
      :class="[
        'text-center space-x-3  flex items-center justify-center   font-semibold tracking-wide  rounded-[10px] py-3 px-4',
        buttonClasses,
        customStyle,
        message !== '' ? additionalStyle : customStyle,
      ]"
      @click="$emit('onClick')"
      :disabled="disabledVal"
    >
      <svg
        v-if="svgType === 'google'"
        class="h-5 w-5 fill-none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1713 8.36788H17.5001V8.33329H10.0001V11.6666H14.7097C14.0226 13.607 12.1763 15 10.0001 15C7.23883 15 5.00008 12.7612 5.00008 9.99996C5.00008 7.23871 7.23883 4.99996 10.0001 4.99996C11.2747 4.99996 12.4342 5.48079 13.3172 6.26621L15.6742 3.90913C14.1859 2.52204 12.1951 1.66663 10.0001 1.66663C5.398 1.66663 1.66675 5.39788 1.66675 9.99996C1.66675 14.602 5.398 18.3333 10.0001 18.3333C14.6022 18.3333 18.3334 14.602 18.3334 9.99996C18.3334 9.44121 18.2759 8.89579 18.1713 8.36788Z"
          fill="#FFC107"
        />
        <path
          d="M2.62744 6.12121L5.36536 8.12913C6.10619 6.29496 7.90036 4.99996 9.99994 4.99996C11.2745 4.99996 12.4341 5.48079 13.317 6.26621L15.6741 3.90913C14.1858 2.52204 12.1949 1.66663 9.99994 1.66663C6.79911 1.66663 4.02327 3.47371 2.62744 6.12121Z"
          fill="#FF3D00"
        />
        <path
          d="M10 18.3334C12.1525 18.3334 14.1084 17.5096 15.5871 16.17L13.008 13.9875C12.1713 14.6213 11.1313 15 10 15C7.83255 15 5.99213 13.618 5.2988 11.6892L2.5813 13.783C3.96047 16.4817 6.7613 18.3334 10 18.3334Z"
          fill="#4CAF50"
        />
        <path
          d="M18.1712 8.36796H17.5V8.33337H10V11.6667H14.7096C14.3796 12.5988 13.78 13.4025 13.0067 13.988L13.0079 13.9871L15.5871 16.1696C15.4046 16.3355 18.3333 14.1667 18.3333 10C18.3333 9.44129 18.2758 8.89587 18.1712 8.36796Z"
          fill="#1976D2"
        />
      </svg>

      <svg
        v-else-if="svgType === 'email'"
        class="h-5 w-5 fill-none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33341 3.33325H16.6667C17.5834 3.33325 18.3334 4.08325 18.3334 4.99992V14.9999C18.3334 15.9166 17.5834 16.6666 16.6667 16.6666H3.33341C2.41675 16.6666 1.66675 15.9166 1.66675 14.9999V4.99992C1.66675 4.08325 2.41675 3.33325 3.33341 3.33325Z"
          stroke="white"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.3334 5L10.0001 10.8333L1.66675 5"
          stroke="white"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        v-else-if="svgType === 'facebook'"
        class="h-5 w-5 fill-none"
        viewBox="0 0 21 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_581_18295)">
          <path
            d="M20.5 10C20.5 4.47719 16.0228 0 10.5 0C4.97719 0 0.5 4.47719 0.5 10C0.5 14.9913 4.15687 19.1284 8.9375 19.8785V12.8906H6.39844V10H8.9375V7.79688C8.9375 5.29063 10.4305 3.90625 12.7147 3.90625C13.8088 3.90625 14.9531 4.10156 14.9531 4.10156V6.5625H13.6922C12.4499 6.5625 12.0625 7.33336 12.0625 8.12422V10H14.8359L14.3926 12.8906H12.0625V19.8785C16.8431 19.1284 20.5 14.9913 20.5 10Z"
            fill="#1877F2"
          />
          <path
            d="M14.3926 12.8906L14.8359 10H12.0625V8.12422C12.0625 7.33328 12.4499 6.5625 13.6922 6.5625H14.9531V4.10156C14.9531 4.10156 13.8088 3.90625 12.7146 3.90625C10.4305 3.90625 8.9375 5.29063 8.9375 7.79688V10H6.39844V12.8906H8.9375V19.8785C9.45439 19.9595 9.9768 20.0001 10.5 20C11.0232 20.0002 11.5456 19.9595 12.0625 19.8785V12.8906H14.3926Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_581_18295">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        v-else-if="svgType === 'linkedin'"
        class="h-5 w-5 fill-none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_581_18300)">
          <path
            d="M9.9999 0.400024C4.6979 0.400024 0.399902 4.69802 0.399902 10C0.399902 15.302 4.6979 19.6 9.9999 19.6C15.3019 19.6 19.5999 15.302 19.5999 10C19.5999 4.69802 15.3019 0.400024 9.9999 0.400024ZM7.6499 13.979H5.7059V7.72302H7.6499V13.979ZM6.6659 6.95502C6.0519 6.95502 5.6549 6.52002 5.6549 5.98202C5.6549 5.43302 6.0639 5.01102 6.6909 5.01102C7.3179 5.01102 7.7019 5.43302 7.7139 5.98202C7.7139 6.52002 7.3179 6.95502 6.6659 6.95502ZM14.7499 13.979H12.8059V10.512C12.8059 9.70502 12.5239 9.15702 11.8209 9.15702C11.2839 9.15702 10.9649 9.52803 10.8239 9.88503C10.7719 10.012 10.7589 10.192 10.7589 10.371V13.978H8.8139V9.71803C8.8139 8.93703 8.7889 8.28402 8.7629 7.72202H10.4519L10.5409 8.59102H10.5799C10.8359 8.18302 11.4629 7.58102 12.5119 7.58102C13.7909 7.58102 14.7499 8.43802 14.7499 10.28V13.979Z"
            fill="#0A66C2"
          />
        </g>
        <defs>
          <clipPath id="clip0_581_18300">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <span class="whitespace-nowrap lg:text-[15px] sm:text-[20px] text-[15px] font-bold">{{ title }}</span>
    </button>
    <button
      v-else
      disabled
      type="button"
      class="text-center h-12 flex text-[15px] font-semibold text-white items-center justify-center tracking-wide w-full rounded-[10px] px-3 py-4 bg-[#888]"
    >
      <span v-if="isLoaderSvg">
        <svg
          aria-hidden="true"
          role="status"
          class="inline w-4 h-4 me-3 text-white animate-spin"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentColor"
          />
        </svg>
      </span>

      {{ loaderText }} ...
    </button>
  </div>
</template>

<script>
// import SvgComponent from "./SvgComponent.vue"; // Replace with your actual path
// import facebookSvg from "../../assets/svgs/sidebar/facebook.svg"
import LoaderSvg from "../../assets/svgs/loader.svg";

export default {
  setup() {
    return {
      LoaderSvg,
    };
  },
  name: "LoginButtons",
  props: {
    title: String,
    message: String,
    buttonType: String,
    svgType: {
      type: String,
      required: true,
    },
    disabledVal: {
      type: Boolean,
      default: false,
    },
    onClick: Function,
    customStyle: String,
    additionalStyle: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    loaderText: {
      type: String,
      default: "Loading",
    },
    isLoaderSvg: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonClasses() {
      if (this.buttonType === "primary") {
        return "bg-btnPrimary text-white  hover:opacity-90";
      } else if (this.buttonType === "secondary") {
        return "bg-btnSecondary text-black  hover:bg-[#cfcfd4]";
      } else if (this.buttonType === "success") {
        return "bg-bgColorSuccess text-white  hover:bg-[#069c08]";
      } else if (this.buttonType === "danger") {
        return "bg-danger text-white  hover:bg-[#c4544e]";
      } else if (this.buttonType === "rounded") {
        return "bg-transparent border border-btnPrimary text-[14px] text-btnPrimary !rounded-[20px] hover:bg-[#d3deed]";
      } else {
        return "border-2 lg:border-lightTextColor/5 border-lightTextColor/20 hover:bg-gray-50";
      }
    },
  },
  components: {
    // SvgComponent,
    // facebookSvg
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
