<template>
  <div>
    <div v-click-outside="isMobileOrTabletDevice ? null : handleClickOutside">
      <CardBox customClass="border border-[#E7E7E7] rounded-3xl w-full sm:!p-6 !p-2">
        <div class="w-full h-full sm:py-0 py-3">
          <div class="flex flex-col space-y-3">
            <div class="flex items-center justify-between">
              <div class="space-y-1 sm:pl-0 pl-3">
                <h1 class="text-[17px] dark:text-white font-bold">Invoices</h1>
                <p class="text-lightTextColor dark:text-darkTextColor text-[12px]">
                  Your invoices with status
                </p>
              </div>
              <!-- :class="isLoading ? 'pointer-events-none opacity-50' : ''" -->
              <!-- -->

              <div class="flex items-center w-28 justify-around">
                <div class="flex items-center" :class="currentPage === 1 ? '' : 'cursor-pointer hover:text-black'"
                  @click=" previousPage">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="#525253" class="w-3 h-3" :class="currentPage === 1 ? '' : 'hover:stroke-black dark:hover:stroke-white/40'">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                  </svg>
                  <span :class="currentPage === 1 ? '' : 'cursor-pointer hover:text-black'" class="text-[10px] dark:text-white/40 text-lightTextColor/70">Prev</span>

                </div>
                <div class="flex items-center " :class="currentPage === pageCount ? '' : 'cursor-pointer hover:text-black'" @click="nextPage">
                <!-- <div class="flex items-center" :class="currentPage < pageCount ? 'cursor-pointer' : ''"
                  @click="currentPage < pageCount && nextPage"> -->

                  <span class="text-[10px] dark:text-white/40 text-lightTextColor/70 " :class="currentPage === pageCount ? '' : 'hover:text-black dark:hover:text-white/40' " >Next</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="#525253" class="w-3 h-3" :class="currentPage === pageCount ? '' : ' hover:stroke-black dark:hover:stroke-white/40' ">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                  </svg>
                </div>
              </div>

            </div>
            <div v-if="loading" class="w-full h-full flex items-center justify-center">
              <LoaderComponent :isCenter="true" />
            </div>
            <div v-else class="w-full h-full flex items-center justify-center">
              <!-- when no data -->
              <div v-if="invoiceData.length === 0" class="flex flex-col space-y-1">
                <div>
                  <InvoiceSvgsVue />
                </div>

                <h1 class="text-lightTextColor dark:text-darkTextColor text-[13px]">
                  No Invoices
                </h1>
              </div>
              <!-- when no data -->
              <div v-else class="w-full h-full sm:px-0 px-1 space-y-5 overflow-y-auto overflow-x-hidden">
                <div v-for="(items, index) in paginatedData" :key="index"
                  class="flex sm:flex-row flex-col space-y-3 sm:space-y-0 items-center border-2 dark:bg-lightTextColor/50 border-lightTextColor/10 rounded-3xl sm:h-28 h-full sm:space-x-0 space-x-3 sm:w-full w-full px-4 py-3">
                  <div class="flex items-center w-full space-x-4">
                    <div class="flex flex-col w-3/12 items-center justify-center space-y-1">
                      <!-- <div class=""> -->
                      <InvoiceSvgsVue />
                      <!-- </div> -->
                      <span class="text-lightTextColor/70 sm:text-[14px] text-[11px] dark:text-white/40">#{{ items?.id
                        }}</span>
                    </div>
                    <div class="flex flex-col w-9/12">
                      <div class="flex w-full space-x-2">
                        <span class="sm:text-[18px] white-space-nowrap text-[15px] font-bold dark:text-white/70">
                          {{ items?.offer_name }}
                        </span>
                        <span :class="`text-[11px] italic font-semibold w-fit h-5 py-0.5 px-2 rounded-lg text-center ${setVariant(
      items.status
    )}`">
                          {{ items.status }}
                        </span>
                      </div>
                      <div class="">
                        <span class="text-[12px] font-semibold dark:text-white/30">{{
      showStandardDate(items.start_date) + " - "
    }}</span>
                        <!-- <span class="dark:text-white/70 text-[10px]">{{
                      " " + "-" + " "
                    }}</span> -->
                        <span class="text-[12px] font-semibold dark:text-white/30">{{ showStandardDate(items.end_date)
                          }}</span>
                      </div>
                      <span class="text-[16px] font-bold dark:text-white">{{
      "$" + items.invoice_amount
    }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col sm:w-4/12 w-full justify-end">
                    <CustomButton @onClick="openModal(items)" button-type="secondary" title="View Invoice"
                      customStyle="w-10 dark:bg-lightTextColor/70 dark:hover:bg-lightTextColor/40" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBox>
    </div>
    <BaseModal v-if="showInvoice">
      <div class="flex flex-col h-full sm:w-[43vw] w-full">
        <div
          class="flex h-20 w-full items-center justify-between rounded-t-xl border-b-2 border-b-lightTextColor/25 sm:px-7 px-2">
          <span class="font-bold text-[16px] dark:text-white">Invoice <span>#{{ invoiceSheet.id }}</span></span>
          <svg class="cursor-pointer hover:bg-lightTextColor/10 dark:hover:bg-lightTextColor/20 rounded-full"
            @click="showInvoice = false" width="24" height="25" viewBox="0 0 24 25" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6.04785L6 18.0479" stroke="gray" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M6 6.04785L18 18.0479" stroke="gray" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>

        <div class="flex flex-col h-full w-full rounded-b-xl sm:px-7 px-0">
          <div class="flex w-full justify-between border-b-2 border-b-lightTextColor/20 py-5">
            <div class="flex-col flex sm:w-full w-9/12">
              <span class="font-bold text-[18px] dark:text-white">Billed To:</span>
              <div class="space-y-0.5 flex-col flex">
                <span class="text-[15px] mt-1 dark:text-white/70">{{
      userDetail.firstname + " " + userDetail.lastname
    }}</span>
                <p :title="userDetail.email" class="text-[15px] dark:text-white/30">
                  {{ userDetail.email }}
                </p>
                <span class="text-[15px] dark:text-white/30">{{
      userDetail.phone
    }}</span>
                <span class="text-[15px] dark:text-white/30">{{
        userDetail.city
      }}</span>
                <span class="text-[15px] dark:text-white/30">{{
        userDetail.state + ", " + userDetail.zip
      }}</span>
              </div>
            </div>
            <div :class="`sm:w-28 w-3/12 h-fit pt-0.5 sm:px-0 px-2  flex justify-center items-center rounded-full ${setVariant(
        invoiceSheet.status
      )}`">
              <span class="sm:text-[17px] text-[12px] italic font-semibold text-center">
                {{ invoiceSheet.status }}
              </span>
            </div>
          </div>
          <div class="flex w-full border-b-2 border-b-lightTextColor/20 py-5">
            <div class="flex-col flex w-full space-y-1">
              <span class="text-[16px] dark:text-white/50">
                Payment Method
              </span>
              <span class="sm:text-[18px] text-[13px] font-bold dark:text-white">
                {{ "Credit Card **" + userDetail.cc_number.slice(15) }}
              </span>
            </div>
            <div class="flex-col flex w-full space-y-1 items-end">
              <span class="text-[16px] dark:text-white/50"> Due Date </span>
              <span class="sm:text-[18px] text-[13px] font-bold dark:text-white">
                {{ showStandardDate(invoiceSheet?.end_date) }}
              </span>
            </div>
          </div>
          <div class="flex flex-col w-full space-y-4 border-b-2 border-b-lightTextColor/20 py-5">
            <span class="text-[20px] font-bold dark:text-white">Order Summary</span>

            <div class="flex w-full whitespace-nowrap sm:space-x-5 space-x-2 justify-between">
              <div class="flex-col flex space-y-1 sm:w-3/12 w-full">
                <span class="sm:text-[16px] text-[12px] dark:text-white/40">
                  Offering
                </span>
                <span class="sm:text-[17px] text-[12px] font-bold dark:text-white/80">
                  {{ invoiceSheet.offer_name }}
                </span>
              </div>
              <div class="flex-col flex sm:w-3/12 w-full space-y-1 text-center">
                <span class="sm:text-[16px] text-[12px] dark:text-white/40">
                  Start Date
                </span>
                <span class="sm:text-[17px] text-[12px] font-bold dark:text-white/80">
                  {{ showStandardDate(invoiceSheet.start_date) }}
                </span>
              </div>
              <div class="flex-col flex sm:w-3/12 w-full space-y-1 text-center">
                <span class="sm:text-[16px] text-[12px] dark:text-white/40">
                  End Date
                </span>
                <span class="sm:text-[17px] text-[12px] font-bold dark:text-white/80">
                  {{ showStandardDate(invoiceSheet.end_date) }}
                </span>
              </div>
              <div class="flex-col flex sm:w-3/12 w-full space-y-1 text-center">
                <span class="sm:text-[16px] text-[12px] dark:text-white/40">
                  Price
                </span>
                <span class="sm:text-[17px] text-[12px] font-bold dark:text-white/80">
                  {{ "$" + invoiceSheet.invoice_amount }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex items-center text-end place-content-end py-10 sm:space-x-20 space-x-5">
            <span class="text-[17px] dark:text-white/70">Total</span>
            <span class="sm:text-[24px] text-[18px] font-bold dark:text-white">{{
              "$" + parseFloat(invoiceSheet.invoice_amount).toFixed(2)
              }}</span>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import CardBox from "../SharedComponents/CardBox.vue";
import CustomButton from "../SharedComponents/CustomButton.vue";
// import moment from "moment";
import InvoiceSvgsVue from "./InvoiceSvgs.vue";
import BaseModal from "../SharedComponents/BaseModal.vue";
import service from "@/services/userApi";
import LoaderComponent from "../SharedComponents/LoaderComponent.vue";
import vClickOutside from "vue-click-outside";

import moment from "moment";
import {
  getUserDetailsFromStore,
  // updateSideBarByUserDetails,
} from "@/store";
// import InvoicesSvg from "./InvoiceSvg.vue";

export default {
  name: "InvoicesComp",
  directives: {
    "click-outside": vClickOutside,
  },
  components: {
    CardBox,
    InvoiceSvgsVue,
    CustomButton,
    BaseModal,
    LoaderComponent,
  },
  data() {
    return {
      showInvoice: false,
      userDetail: {},
      invoiceSheet: {},
      currentPage: 1,
      itemsPerPage: 5,
      invoiceData: [],
      loading: true,
      openDropdown: false,
      menuList: ["5", "10", "15", "All"],
      isMobileOrTabletDevice:
        /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|Tablet|Kindle/i.test(
          navigator.userAgent
        ),
    };
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    handleCategory(val) {
      // this.nextPage(val)
      this.selectedCategory = val;
      this.openDropdown = false;
    },
    openModal(item) {
      this.showInvoice = true;
      this.invoiceSheet = item;

      this.userDetail = getUserDetailsFromStore();
    },
    handleClickOutside() {
      this.showInvoice = false;
    },

    showStandardDate(date) {
      return moment(date).format("Do MMM YYYY");
    },
    setVariant(status) {
      if (status === "Paid" || status === "Refunded") {
        return "text-[#76BE00] bg-[#76BE001A]";
      } else if (status === "Canceled" || status === "Removed") {
        return "text-[#E14942] bg-[#E149421A]";
      } else if (status === "Extended") {
        return "bg-[#2A67F71A] text-[#2A67F7]";
      } else {
        return "bg-[#9797971A] text-[#979797]";
      }
    },

    async getAllInvoices() {
      this.loading = true;
      try {
        const res = await service.invoiceList();
        if (res?.data?.list?.length > 0) {
          console.log("res==>", res?.data?.list?.length);
          this.invoiceData = res?.data?.list;
        } else {
          this.invoiceData = [];
        }
      } catch (err) {
        this.invoiceData = [];
      }
      this.loading = false;
    },
  },
  computed: {
    pageCount() {
      // console.log('ehh',Math.ceil(this.data.length / this.itemsPerPage))
      console.log('ehh', Math.ceil(this.invoiceData.length / this.itemsPerPage))
      return Math.ceil(this.invoiceData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log('prev', startIndex, ',', endIndex)
      return this.invoiceData.slice(startIndex, endIndex);
    },
    userDetails() {
      // let axy = getUserDetailsFromStore
      return getUserDetailsFromStore();
      // console.log('hello user deatil', axy);
    },
  },
  mounted() {
    this.getAllInvoices();
  },
  watch: {
    newFunc() {
      this.currentPage
    }
  }
};
</script>

<style scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
