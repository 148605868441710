<template>
    <div></div>
  </template>
  <script>
  import userApi from "@/services/userApi";
  import router from "@/router";
  import {afterSigninActivty} from "@/pages/userStory/login/afterSigninActivty";
  import store from "@/store";
  
  export default {
    name: "ThirdPartyAuth",
    mounted() {
      const queryParams = this.$route.query;
      let token = queryParams.token;
      let status = queryParams.status;
      let isNew = queryParams.new_signup
      console.log("response==>", status, token);
      localStorage.setItem("token", token);
      if (status == "True" && token != "None") {
          
            userApi.userDetails()
                    .then((resp) => {
                    
                    //   store.commit("SET_USER_DETAIL", resp.data.user_detail);
                    store.commit("SET_PROFILE_LOADING", true);
                    setTimeout(() => {  
                        const afterCall = () => {
                            console.log("access token complete")
                            store.commit("SET_PROFILE_LOADING", false)
                            console.log("isNew",isNew)
                            if(isNew == "True"){
                                store.commit('SET_INITIAL_SUCCESS', true)
                            }
                            
                        }
                        afterSigninActivty(resp, true, afterCall,token);
                    },10);
                    })
                    .catch((e) => {
                      console.log(e);
                      store.commit("SET_PROFILE_LOADING", false);
                    });

            
  
  
        } else {
            router.push("/404");
        }
    },
  };
  </script>
  