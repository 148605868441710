<template>
    <svg
    width="20"
    height="20"
    viewBox="0 0 16 17"
    fill="#d6d6d6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_491_5765)">
      <path
        d="M8.00016 14.9349C11.6821 14.9349 14.6668 11.9501 14.6668 8.26823C14.6668 4.58633 11.6821 1.60156 8.00016 1.60156C4.31826 1.60156 1.3335 4.58633 1.3335 8.26823C1.3335 11.9501 4.31826 14.9349 8.00016 14.9349Z"
        stroke="none"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 5.60156V8.26823"
        stroke="white"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 10.9336H8.00667"
        stroke="white"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_491_5765">
        <rect
          width="16"
          height="16"
          fill="red"
          transform="translate(0 0.267578)"
        />
      </clipPath>
    </defs>
  </svg>
  </template>
  <script>
//   import { watch, ref } from "vue";
//   import store from "@/store";
  export default {
    name: "infoSvg",
    props: {
      isActive: {
        type: Boolean,
        defaults: false,
      },
    },
    // setup() {
    //   const activeColor = ref(store.state.theme ? "#A9C9FF" : "#0B3D90");
    //   const disableColor = "#9a9a9a";
  
    //   watch(
    //     () => store.state.theme,
    //     (newTheme) => {
    //       activeColor.value = newTheme ? "#A9C9FF" : "#0B3D90";
    //     }
    //   );
  
    //   return {
    //     activeColor,
    //     disableColor,
    //   };
    // },
  };
  </script>
  