<template>
  <div class="w-full h-full flex justify-center items-center !pt-4">
    <RadialGauge :value="value" :options="options"/>
  </div>
</template>

<script>
// import RadialGauge from "vue2-gauges/src/RadialGauge";
import RadialGauge from "./RadialGuageComp.vue";
import store from "@/store";
export default {
  name: "guage-guage",
  components: {
    RadialGauge,
  },
  setup() {
    return {
      store,
    };
  },
  props: {
    loading:{
      type:Boolean,
      default:false
    },
    value: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    options: {
      // https://canvas-gauges.com/documentation/user-guide/configuration
      type: Object,
      default:()=>{},
    },
  },
  // https://github.com/adnanmusil/canvas-gauges.git

};
</script>
