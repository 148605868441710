<template>
  <div class="h-full w-full flex items-center justify-center">
    <CardBox
      customClass=" border border-[#E7E7E7] h-full md:w-[40vw] lg:w-[40vw] sm:w-[80vw] w-full rounded-3xl lg:!p-10 sm:p-6 !p-5"
    >
      <div
        class="h-full w-full flex flex-row justify-around space-x-2 -bg-red-400"
      >
        <div class="h-full sm:w-4/6 w-full flex flex-col pt-5">
          <span
            class="lg:text-[25px] sm:text-[25px] text-[18px] dark:text-white/70"
            >We are creating some</span
          >
          <span
            class="lg:text-[25px] sm:text-[25px] text-[18px] font-bold dark:text-white/70"
            >exciting new features,</span
          >
          <span
            class="lg:text-[25px] sm:text-[25px] text-[18px] font-bold dark:text-white/70"
            >they will be available soon.</span
          >
        </div>
        <div class="h-full w-2/6 justify-end flex -flex-row -bg-green-800">
          <img
            src="../../assets/svgs/vector-icon.svg"
            v-if="!store.state.theme"
          />
          <img src="../../assets/svgs/vector-icon-dark.svg" v-else />
        </div>
      </div>
    </CardBox>
  </div>
</template>

<script>
import store from "@/store";
// import Navbar from "../../components/NavBar"
import CardBox from "../../components/SharedComponents/CardBox.vue";
export default {
  name: "HelpPage",
  components: {
    CardBox,
  },
  setup() {
    return {
      store,
    };
  },
};
</script>
